import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { CurrentUserService } from 'dfs-common-ngx';
import { Subscription } from 'rxjs';
import { EquipmentTypes } from '../marketing/equipment-types.enum';
import { LoginService } from '../services/login.service';
import { UserRole } from '../enums/user-role';

@Component({
  selector: 'fin-header-nav',
  templateUrl: './header-nav.component.html',
  styleUrls: ['./header-nav.component.css'],
})
export class HeaderNavComponent implements OnInit, OnDestroy {
  private readonly LOGIN = 'Login';
  private readonly LOGOUT = 'Logout';
  equipmentTypes = EquipmentTypes;
  buttonText = this.LOGIN;
  private userProfileSubscription: Subscription | undefined;
  welcomeLabel: string = '';
  payOnlineRoute: string = '/login';

  constructor(
    private readonly loginService: LoginService,
    private readonly currentUserSvc: CurrentUserService,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.userProfileSubscription = this.currentUserSvc.profileO.subscribe({
      next: (user) => {
        if (user) {
          if (user.role.includes(UserRole.Customer)) {
            this.payOnlineRoute = '/payOnline';
          }
          this.buttonText = this.LOGOUT;
          this.welcomeLabel =
            user.name.length > 0 ? `Welcome, ${user.name}` : '';
        } else {
          this.buttonText = this.LOGIN;
          this.welcomeLabel = '';
        }
      },
      error: (error) => {
        console.log('Error subscribing to userProfile', error);
      },
    });
  }

  ngOnDestroy(): void {
    this.userProfileSubscription?.unsubscribe();
  }

  logInOut() {
    if (this.buttonText === this.LOGOUT) {
      this.currentUserSvc.logout();
      this.loginService.logout().subscribe({
        next: (_result) => {
          console.log('logged out');

          //Forcing a reload to help with cache issues.
          document.location.reload();
        },
        error: (error) => {
          console.log('Error Logging out', error);
        },
      });
      this.router.navigate(['/login']);
    } else {
      this.router.navigate(['/login']);
    }
  }
}
