<p-table
  id="ApplicationTable"
  styleClass="p-datatable-striped p-datatable-sm"
  [value]="applications"
  [scrollable]="true"
  [columns]="cols"
  selectionMode="single"
  [paginator]="true"
  [rows]="10"
  [loading]="loadingData"
  [rowsPerPageOptions]="[5, 10, 25, 50]"
  [showCurrentPageReport]="true"
  currentPageReportTemplate="Showing {first} to {last} of {totalRecords} applications"
>
  <ng-template *ngIf="captionText?.length" pTemplate="caption">
    <h5>{{ captionText }}</h5>
  </ng-template>
  <ng-template pTemplate="header" let-column let-columns>
    <tr>
      <th *ngFor="let col of columns" [pSortableColumn]="col.field">
        <div *ngIf="col.show" class="flex align-items-center">
          {{ col.header }}
          <p-sortIcon [field]="col.field"></p-sortIcon>
        </div>
      </th>
      <th></th>
    </tr>
  </ng-template>
  <ng-template pTemplate="body" let-item let-columns="columns">
    <tr [pSelectableRow]="item">
      <td *ngFor="let col of columns">
        <span *ngIf="col.type === 'text'" class="ms-1 vertical-align-middle">{{
          item[col.field]
        }}</span>
        <span
          *ngIf="col.type === 'numeric'"
          class="ms-1 vertical-align-middle"
          >{{ item[col.field] | currency : "USD" : "symbol" }}</span
        >
        <span *ngIf="col.type === 'date'" class="ms-1 vertical-align-middle"
          >{{ item[col.field] | date : "MM/dd/yyyy" }}
        </span>
      </td>

      <td
        ngbDropdown
        container="body"
        #drop="ngbDropdown"
        style="text-align: right; padding-right: 1rem"
      >
        <button
          class="dfs-btn dfs-btn-primary dfs-btn-grid"
          id="actionDropdown"
          ngbDropdownToggle
        >
          <span
            *ngIf="shouldDisplaySpinner(item.applicationId)"
            class="spinner-border spinner-border-sm"
            role="status"
            aria-hidden="true"
          ></span>
          Action
        </button>
        <div ngbDropdownMenu aria-labelledby="actionDropdown">
          <div *ngIf="showApprovalFields && !showCurrentlFields">
            <button
              class="dfs-btn-grid"
              ngbDropdownItem
              *ngIf="item.canGenerateDocs"
              (click)="showSpinner(item.applicationId)"
              [routerLink]="['/generate-documents', item.applicationId]"
            >
              Generate Documents
            </button>
            <button
              class="dfs-btn-grid"
              ngbDropdownItem
              featureFlag="eSignatures"
              *ngIf="item.canGenerateDocs"
              (click)="showSpinner(item.applicationId)"
              [routerLink]="['/generate-documents', item.applicationId]"
              [queryParams]="{ edit: true }"
            >
              Edit Application
            </button>
            <button
              class="dfs-btn-grid"
              ngbDropdownItem
              featureFlag="eSignatures"
              *ngIf="item.canGenerateDocs"
              (click)="showSpinner(item.applicationId)"
              [routerLink]="['/generate-documents', item.applicationId]"
            >
              E-Sign Documents
            </button>
            <button
              class="dfs-btn-grid"
              ngbDropdownItem
              featureFlag="eSignatures"
              *ngIf="
                showEsignDetail(item.esignatureEnvelopeStatus) &&
                item.canGenerateDocs
              "
              (click)="actionViewEsignatureStatus(item.applicationId)"
            >
              E-Sign Details
            </button>
          </div>
          <button
            class="dfs-btn-grid"
            ngbDropdownItem
            *ngIf="showCurrentlFields"
            (click)="
              editApplication(item.applicationId, item.sentToCustomerDate)
            "
          >
            Edit
          </button>
          <button
            class="dfs-btn-grid"
            ngbDropdownItem
            *ngIf="showCurrentlFields && item.sentToCustomerDate"
            (click)="sendFinishAppLink(item.applicationId)"
          >
            Resend E-mail
          </button>
          <button
            class="dfs-btn-grid"
            ngbDropdownItem
            *ngIf="!showCurrentlFields"
            (click)="actionPrint(item.applicationId)"
          >
            Download Application
          </button>
          <button
            class="dfs-btn-grid"
            ngbDropdownItem
            *ngIf="item.hasAttachments && !showCurrentlFields"
            (click)="actionViewAttachments(item.applicationId)"
          >
            View Attachments
          </button>
          <button
            class="dfs-btn-grid"
            ngbDropdownItem
            *ngIf="isDealerAdmin && !(this.statusTypes.length > 1)"
            (click)="viewManagementScreen(item)"
          >
            Reassign
          </button>
          <button
            class="dfs-btn-grid"
            ngbDropdownItem
            *ngIf="showCurrentlFields"
            (click)="deleteApplication(item.applicationId)"
          >
            Delete Application
          </button>
        </div>
      </td>
    </tr>
  </ng-template>
  <ng-template pTemplate="emptymessage">
    <tr>
      <td colspan="99">There are currently no applications to display.</td>
    </tr>
  </ng-template>
</p-table>
