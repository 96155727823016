<section>
  <form novalidate class="card p-4 fs-6" [formGroup]="paymentCalculatorForm">
    <div class="col-md-2"></div>
    <div class="card col-md-8 container px-4 py-5">
      <div>
        <div class="row">
          <h4 class="text-primary fw-bold mb-4 d-flex justify-content-center">
            Loan Payment Calculator
          </h4>
        </div>
        <div class="row">
          <div class="col-md-5"></div>
          <div class="col-md-2" style="text-align: end">
            <label for="salesPrice" class="form-label fw-bold"
              >Sales Price</label
            >
            <input
              aria-label="Sales Price"
              class="form-control mb-2 fs-6"
              id="salesPrice"
              formControlName="salesPrice"
              dfsCurrencyMask
              type="text"
              placeholder="$0.00"
              ng-maxlength="14"
              (blur)="calculateLoanAmount()"
              [ngClass]="{ 'is-invalid': isControlValid('salesPrice') }"
            />
            <div class="text-danger">
              <span *ngIf="fieldHasError('salesPrice', 'required')">
                Sales price required.
              </span>
            </div>
          </div>
          <div class="col-md-5"></div>
        </div>
        <div class="row">
          <div class="col-md-5"></div>
          <div class="col-md-2" style="text-align: end">
            <label for="downPayment" class="form-label fw-bold"
              >Down Payment</label
            >
            <input
              aria-label="Down Payment"
              class="form-control mb-2 fs-6"
              id="downPayment"
              formControlName="downPayment"
              dfsCurrencyMask
              type="text"
              placeholder="$0.00"
              ng-maxlength="14"
              (blur)="calculateLoanAmount()"
            />
          </div>
          <div class="col-md-5"></div>
        </div>
        <div class="row">
          <div class="col-md-5"></div>
          <div class="col-md-2" style="text-align: end">
            <label for="salesTax" class="form-label fw-bold">Sales Tax</label>
            <input
              aria-label="Sales Tax"
              class="form-control mb-2 fs-6"
              id="salesTax"
              dfsCurrencyMask
              formControlName="salesTax"
              type="text"
              placeholder="$0.00"
              ng-maxlength="14"
              (blur)="calculateLoanAmount()"
            />
          </div>
          <div class="col-md-5"></div>
        </div>
        <div class="row">
          <div class="col-md-5"></div>
          <div class="col-md-2" style="text-align: end">
            <label for="loanAmount" class="form-label fw-bold"
              >Loan Amount</label
            >
            <input
              aria-label="Loan Amount"
              class="form-control mb-2 fs-6"
              id="loanAmount"
              formControlName="loanAmount"
              dfsCurrencyMask
              type="text"
              placeholder="$0.00"
              ng-maxlength="14"
              [attr.disabled]="true"
            />
            <div class="text-danger">
              <span *ngIf="fieldHasError('loanAmount', 'required')">
                Loan amount required.
              </span>
            </div>
            <div class="col-md-5"></div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-5"></div>
          <div class="col-md-2 d-flex justify-content-end">
            <div style="text-align: end">
              <label class="col-form-label fw-bold">Interest Rate</label>
              <input
                aria-label="Interest Rate"
                id="rate"
                formControlName="rate"
                dfsPercentInput
                class="form-control col-md-2 mb-2 fs-6"
                style="text-align: end"
                type="text"
                placeholder="0.000%"
                [ngClass]="{ 'is-invalid': isControlValid('rate') }"
              />
              <div class="text-danger">
                <span *ngIf="fieldHasError('rate', 'required')">
                  Interest rate required.
                </span>
              </div>
              <div class="col-md-5"></div>
            </div>
          </div>
          <div class="col-md-5"></div>
        </div>
        <div class="row">
          <div class="col-md-5"></div>
          <div class="col-md-2 d-flex justify-content-end">
            <div style="text-align: end">
              <label class="col-form-label fw-bold">Loan Term</label>
              <ng-select
                aria-label="Loan Term"
                id="term"
                class="form-control col-md-2 mb-2 fs-6 align-content-end"
                style="text-align: end"
                [items]="loanTermList"
                bindLabel="value"
                bindValue="key"
                [selectOnTab]="true"
                formControlName="term"
                placeholder="Select loan term"
                [ngClass]="{ 'is-invalid': isControlValid('term') }"
              >
              </ng-select>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-5"></div>
          <div class="text-danger col-md-2 d-flex justify-content-end">
            <span *ngIf="fieldHasError('term', 'required')">
              Please select loan term.
            </span>
          </div>
        </div>
        <div class="row">
          <div class="col-md-5"></div>
          <div class="col-md-2 d-flex justify-content-end">
            <div style="text-align: end">
              <label class="col-form-label fw-bold">Payment Frequency</label>
              <ng-select
                aria-label="Payment Frequency"
                id="paymentFrequency"
                formControlName="paymentFrequency"
                class="form-control col-md-2 mb-2 fs-6"
                [items]="paymentFrequencyList"
                bindLabel="value"
                bindValue="key"
                placeholder="Select frequency"
                [selectOnTab]="true"
                [ngClass]="{ 'is-invalid': isControlValid('paymentFrequency') }"
              >
              </ng-select>
            </div>
          </div>
          <div class="col-md-5"></div>
        </div>
        <div class="row">
          <div class="col-md-4"></div>
          <div class="text-danger col-md-3 d-flex justify-content-end">
            <span *ngIf="fieldHasError('paymentFrequency', 'required')">
              Please select payment frequency.
            </span>
          </div>
        </div>
        <div class="row">
          <div class="col-md-3"></div>
          <div class="form-group col-md-4 mb-2 fs-6 d-flex justify-content-end">
            <div>
              <label class="col-form-label fw-bold px-2"
                >Show amortization schedule</label
              >
              <input
                aria-label="Show payment schedule"
                class="form-check-input mb-2 fs-6 align-middle"
                id="showPayments"
                type="checkbox"
                formControlName="showAmortization"
                [defaultChecked]="false"
              />
            </div>
          </div>
          <div class="col-md-5"></div>
        </div>
        <div class="row">
          <div class="col-lg-4"></div>
          <div class="px-3 col-md-4 d-flex justify-content-center">
            <button
              [disabled]="false"
              class="btn btn-primary mt-2 d-flex justify-content-center"
              (click)="computePayments()"
            >
              <span
                *ngIf="isCalculating"
                class="spinner-border spinner-border-sm"
                role="status"
                aria-hidden="true"
              ></span>
              Calculate
            </button>
          </div>
          <div class="col-md-4"></div>
        </div>
        <div
          *ngIf="
            this.amortizationSchedule &&
            this.amortizationSchedule.amortizationLineItems &&
            this.amortizationSchedule.amortizationLineItems.length > 0
          "
          class="container d-flex justify-content-center"
        >
          <div class="col-md-6">
            <h4 class="fw-bold mb-4 d-flex justify-content-center">
              Payment Summary
            </h4>
            <table class="table table-striped table-hover table-responsive">
              <thead>
                <tr>
                  <th>Event</th>
                  <th>Amount</th>
                  <th>Term</th>
                  <th>Period</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Loan</td>
                  <td>
                    {{ amortizationTerms[0].principalBalance | currency }}
                  </td>
                  <td>1</td>
                  <td></td>
                </tr>
                <tr>
                  <td>Payment</td>
                  <td>{{ amortizationDetail[0].payment | currency }}</td>
                  <td>{{ paymentCount }}</td>
                  <td>
                    {{
                      this.paymentFrequencyList[
                        paymentCalculatorForm.get("paymentFrequency")?.value - 1
                      ].value
                    }}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div></div>
        <div
          class="container d-flex justify-content-center"
          *ngIf="
            this.showAmortizationSchedule &&
            this.amortizationSchedule &&
            this.amortizationSchedule.amortizationLineItems &&
            this.amortizationSchedule.amortizationLineItems.length > 0
          "
        >
          <div>
            <h4 class="fw-bold mb-4 d-flex justify-content-center">
              Amortization Schedule
            </h4>
            <table class="table table-striped table-hover table-responsive">
              <thead>
                <tr>
                  <th>Type</th>
                  <th>Date</th>
                  <th>Payment</th>
                  <th>Interest</th>
                  <th>Principal</th>
                  <th>Balance</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let item of this.amortizationTerms">
                  <td>{{ item.type }}</td>
                  <td>{{ item.date | date : "MM/dd/yyyy" }}</td>
                  <td>{{ item.payment | currency }}</td>
                  <td>{{ item.interest | currency }}</td>
                  <td>{{ item.principle | currency }}</td>
                  <td>{{ item.principalBalance | currency }}</td>
                </tr>
                <tr *ngFor="let item of this.amortizationDetail">
                  <td>{{ item.type }}</td>
                  <td>{{ item.date | date : "MM/dd/yyyy" }}</td>
                  <td>{{ item.payment | currency }}</td>
                  <td>{{ item.interest | currency }}</td>
                  <td>{{ item.principle | currency }}</td>
                  <td>{{ item.principalBalance | currency }}</td>
                </tr>
                <tr *ngFor="let item of this.amortizationSummary">
                  <td>
                    <b>Grand Total</b>
                  </td>
                  <td>
                    <b>{{ item.date | date : "MM/dd/yyyy" }}</b>
                  </td>
                  <td>
                    <b>{{ item.payment | currency }}</b>
                  </td>
                  <td>
                    <b>{{ item.interest | currency }}</b>
                  </td>
                  <td>
                    <b>{{ item.principle | currency }}</b>
                  </td>
                  <td>
                    <b>{{ item.principalBalance | currency }}</b>
                  </td>
                </tr>
              </tbody>
            </table>
            <h5 class="text-danger">
              The information provided by this calculator is intended for
              estimate purposes only.
            </h5>
          </div>
          <!-- <div class="col-md-2"></div> -->
        </div>
        <div class="col-md-2"></div>
      </div>
    </div>
  </form>
</section>
