<form [formGroup]="form" #formDirective="ngForm">
  <h5
    [ngClass]="{
      'text-danger':
        equipmentTypeControl.touched && equipmentTypeControl.invalid
    }"
  >
    Which type of equipment will you be financing? *
  </h5>
  <div class="form-inline d-flex align-items-center">
    <div class="btn-group" btnRadioGroup formControlName="equipmentType">
      <button
        class="btn btn-wizard"
        (click)="filterSalespersons(equipmentTypes.farm)"
        btnRadio="{{ equipmentTypes.farm }}"
        [ngClass]="{
          'invalid-border':
            equipmentTypeControl.touched && equipmentTypeControl.invalid
        }"
      >
        {{ equipmentTypes.farm }}
      </button>
      <button
        class="btn btn-wizard"
        (click)="filterSalespersons(equipmentTypes.irrigation)"
        btnRadio="{{ equipmentTypes.irrigation }}"
        [ngClass]="{
          'invalid-border':
            equipmentTypeControl.touched && equipmentTypeControl.invalid
        }"
      >
        {{ equipmentTypes.irrigation }}
      </button>
      <button
        class="btn btn-wizard"
        (click)="filterSalespersons(equipmentTypes.commercial)"
        btnRadio="{{ equipmentTypes.commercial }}"
        [ngClass]="{
          'invalid-border':
            equipmentTypeControl.touched && equipmentTypeControl.invalid
        }"
      >
        {{ equipmentTypes.commercial }}
      </button>
    </div>
  </div>
  <span
    class="text-danger"
    *ngIf="equipmentTypeControl.touched && equipmentTypeControl.invalid"
  >
    Equipment Type is Required.
  </span>

  <h5
    [ngClass]="{
      'text-danger': sellerTypeControl.touched && sellerTypeControl.invalid
    }"
  >
    Who are you purchasing the equipment from? *
  </h5>
  <div class="form-inline d-flex align-items-center">
    <div class="btn-group" btnRadioGroup formControlName="sellerType">
      <button
        class="btn btn-wizard mb-0"
        [ngClass]="{
          'invalid-border':
            sellerTypeControl.touched && sellerTypeControl.invalid
        }"
        btnRadio="{{ sellerTypes.dealer }}"
      >
        {{ sellerTypes.dealer }}
      </button>
      <button
        class="btn btn-wizard mb-0"
        [ngClass]="{
          'invalid-border':
            sellerTypeControl.touched && sellerTypeControl.invalid
        }"
        btnRadio="{{ sellerTypes.privateParty }}"
      >
        {{ sellerTypes.privateParty }}
      </button>
    </div>
  </div>
  <span
    class="text-danger"
    *ngIf="sellerTypeControl.touched && sellerTypeControl.invalid"
  >
    Seller type is Required.
  </span>
  <ca-dealer-seller
    *ngIf="sellerType == sellerTypes.dealer"
    [form]="form"
    [salesPersons]="salesPersonsDisplay"
    [salesPersonsLoading]="salesPersonsLoading"
  ></ca-dealer-seller>
  <ca-private-seller
    *ngIf="sellerType == sellerTypes.privateParty"
    [form]="form"
  ></ca-private-seller>
</form>
