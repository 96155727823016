<div class="card-body">
  <form [formGroup]="form" #formDirective="ngForm">
    <div class="row">
      <div class="col-md-6">
        <fin-basic-contract-terms
          [form]="form"
          [equipmentItems]="equipmentItems"
          [equipmentType]="equipmentType"
          [rates]="rates"
          (onCalculate)="onBasicTermsCalculate($event)"
        ></fin-basic-contract-terms>
      </div>
      <div class="col-md-6">
        <div class="form-group">
          <label class="col-form-label">Sales/Lease Price</label>
          <div class="form-control dfs-disabled">
            {{ form.get("saleLeasePrice")?.value | currency }}
          </div>
        </div>
        <div class="form-group">
          <label class="col-form-label">Sales Tax</label>
          <div class="form-control dfs-disabled">
            {{ form.get("salesTax")?.value | currency }}
          </div>
        </div>
        <div class="form-group">
          <label for="tradeAllowance">Trade Allowance $</label>
          <div class="form-control dfs-disabled">
            {{ form.get("tradeAllowance")?.value | currency }}
          </div>
        </div>
        <div class="form-group">
          <label for="owedAmount">Amount Owed on Trade $</label>
          <div class="form-control dfs-disabled">
            {{ form.get("owedAmount")?.value | currency }}
          </div>
        </div>
      </div>
    </div>
  </form>
</div>
