import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { map } from 'rxjs';

import { FileAttachmentsRepo } from './FileAttachmentsRepo';
import { environment } from 'src/environments/environment';
import { ApplicationItem } from './entities/ApplicationItem';

@Injectable()
export class CreditApplicationsRepo {
  constructor(
    private http: HttpClient,
    private fileAttachmentsRepo: FileAttachmentsRepo
  ) {}

  getAll() {
    const url = this.buildCurrentUserAppsUrl();
    return this.http
      .get<any>(url)
      .pipe(map((appData) => ApplicationItem.createList(appData)));
  }

  update(app: any, hasNewFileAttachments: boolean, attachments: FormData) {
    const url = this.buildCurrentUserAppsUrl();
    return this.http.put<any>(url, app);
  }

  getOne(id: string) {
    const url = this.buildCurrentUserAppsUrl() + id;
    return this.http.get<any>(url);
  }

  deleteApp(id: string) {
    const url = this.buildCurrentUserAppsUrl() + id;

    return this.http.delete(url);
  }

  add(application: any, hasNewFileAttachments: boolean, attachments: FormData) {
    const url = this.buildCurrentUserAppsUrl();
    return this.http.post<any>(url, application);
  }

  private buildCurrentUserAppsUrl() {
    const url = `${environment.ApiBaseUrl}/users/credit-applications/`;
    return url;
  }
}
