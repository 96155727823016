import { Component, Inject } from '@angular/core';
import {
  AbstractControl,
  FormBuilder,
  FormGroup,
  ValidationErrors,
  ValidatorFn,
  Validators,
} from '@angular/forms';

import { DialogRef, DIALOG_DATA } from '@angular/cdk/dialog';

import { LoginService } from 'src/app/services/login.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'fin-reset-password-dialog',
  templateUrl: './reset-password-dialog.component.html',
  styleUrls: ['../marketing/marketing-styles.css', '../PortalMain.scss'],
})
export class ResetPasswordDialogComponent {
  constructor(
    public dialogRef: DialogRef,
    @Inject(DIALOG_DATA) public token: string,
    private formBuilder: FormBuilder,
    private loginService: LoginService,
    private readonly toastr: ToastrService
  ) {}

  form!: FormGroup; // Defined in ngOnInit
  error = false;
  loading = false;
  success = false;

  ngOnInit() {
    this.form = this.formBuilder.group(
      {
        password: [
          '',
          [
            Validators.required,
            Validators.minLength(8),
            Validators.maxLength(20),
            Validators.pattern(
              /(?=^.{8,20}$)((?=.*\d)|(?=.*\W+))(?![.\n])(?=.*[A-Z])(?=.*[a-z]).*$/
            ),
          ],
        ],
        confirm: ['', Validators.required],
      },
      { validators: this.passwordMatchValidator, updateOn: 'blur' }
    );
  }

  private passwordMatchValidator: ValidatorFn = (
    control: AbstractControl
  ): ValidationErrors | null => {
    const password = control.get('password');
    const confirm = control.get('confirm');

    return password && confirm && password.value === confirm.value
      ? null
      : { passwordNotMatched: true };
  };

  closeDialog(): void {
    this.dialogRef.close();
  }

  submitForm(): void {
    this.loading = true;
    this.error = false;
    this.loginService
      .resetPassword(this.form.get('password')?.value, this.token)
      .subscribe({
        next: (resp) => {
          this.loading = false;
          this.success = true;
          this.form.get('password')?.disable();
          this.form.get('confirm')?.disable();
          this.dialogRef.close();
          this.toastr.success(
            'Password has been reset successfully',
            'Password Reset'
          );
        },
        error: (error) => {
          console.error(error);
          this.error = true;
          this.loading = false;
        },
      });
  }
}
