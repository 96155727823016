
    <div>
      <input
        type="file"
        class="file-input"
        (change)="selectFile($event)"
        #fileUpload
      />
      <div>
        <button
          type="button"
          style="margin: 0"
          color="primary"
          class="dfs-btn dfs-btn-primary"
          (click)="fileUpload.click()"
          tooltip="Click here to attach file"
        >
          Add
        </button>
        <span *ngIf="!fileAdded"> -- {{ buttonText }} </span>
        <span
          title="Bold text indicates file has been uploaded"
          *ngIf="fileAdded"
          ><strong> -- {{ buttonText }} - ({{ fileName }}) </strong></span
        >
      </div>
    </div>
