<section class="bg-dark">
  <footer class="container pt-2">
    <div class="row">
      <div class="col-12 col-md">
        <a href="/" class="d-flex align-items-center mb-3 mb-lg-0 me-lg-auto">
          <img
            type="image/svg+xml"
            src="assets/svg/dfs-logo-white.svg"
            alt="Your browser does not support SVGs"
          />
        </a>
      </div>
      <div class="col-6 col-md">
        <h5 class="text-primary">Financing</h5>
        <ul class="list-unstyled text-small">
          <li>
            <a
              class="link-light"
              [routerLink]="['/financing', equipmentTypes.farm]"
              >Equipment Financing</a
            >
          </li>
          <li>
            <a
              class="link-light"
              [routerLink]="['/financing', equipmentTypes.irrigation]"
              >Irrigation Financing</a
            >
          </li>
        </ul>
      </div>
      <div class="col-6 col-md">
        <h5 class="text-primary">Company</h5>
        <ul class="list-unstyled text-small">
          <li>
            <a class="link-light" [routerLink]="['/about']"
              >About DFS Finance</a
            >
          </li>
          <li><a class="link-light" [routerLink]="['/careers']">Careers</a></li>
          <li>
            <a class="link-light" [routerLink]="['/contact']">Contact Us</a>
          </li>
        </ul>
        <h5 class="text-primary mt-4">Dealers</h5>
        <ul class="list-unstyled text-small">
          <li>
            <a class="link-light" [routerLink]="['/login']">Dealer Login</a>
          </li>
          <li>
            <a class="link-light" [routerLink]="['/becomeADealer']"
              >Become a Dealer Partner</a
            >
          </li>
          <li>
            <a class="link-light" [routerLink]="['/dealerRegistration']"
              >Dealer Registration</a
            >
          </li>
        </ul>
      </div>
      <div class="col-6 col-md">
        <h5 class="text-primary">Resources</h5>
        <ul class="list-unstyled text-small">
          <li>
            <a class="link-light" [routerLink]="['/applicationforms']"
              >Application Forms
            </a>
          </li>
          <li *ngIf="showRegisterOnline">
            <a class="link-light" [routerLink]="['/customerRegistration']"
              >Register to Pay Online</a
            >
          </li>
          <li>
            <a class="link-light" [routerLink]="[payOnlineRoute]">{{
              payOnlineText
            }}</a>
          </li>
          <li>
            <a class="link-light" [routerLink]="['/paymentCalculator']"
              >Loan Payment Calculator</a
            >
          </li>
          <li><a class="link-light" [routerLink]="['/faqs']">FAQs</a></li>
          <li>
            <a
              class="link-light"
              [routerLink]="['/contact']"
              [queryParams]="{ contacttype: 2 }"
              >Request Automatic Payment</a
            >
          </li>
          <li>
            <a
              class="link-light"
              [routerLink]="['/contact']"
              [queryParams]="{ contacttype: 1 }"
              >Request Contract Number</a
            >
          </li>
        </ul>
      </div>
      <div class="col-6 col-lg">
        <h5 class="text-primary">Contact Us</h5>
        <p class="text-small text-light">
          14010 FNB Parkway Suite 400<br />
          Omaha, NE 68154<br />
          P: 800.648.8026
        </p>
      </div>
    </div>
    <div class="d-flex justify-content-between pt-2 mt-1 border-top text-light">
      <p>
        &copy; 2024 - DFS Finance is a division of First National Bank of Omaha
        (FNBO). All rights reserved. You can access FNBO's Privacy Notification
        here:
        <a
          class="text-light"
          href="https://urldefense.proofpoint.com/v2/url?u=https-3A__www.fnbo.com_privacy&d=DwMFAg&c=euGZstcaTDllvimEN8b7jXrwqOf-v5A_CdpgnVfiiMM&r=QgUS-CjIfMRlros0ESSFKGwFtcY62ARd7mPRaArUYxE&m=rVcQe7pc9VCNqO7_N_CLkb9r_yxOPNlVnVn7lfpgRtK5MzQ5msvjY37dgYDmM6PS&s=8SliizamJmo5UFDl4AXg3Z-3mZI8b0p_SIbKRaBucw4&e="
          target="_blank"
          >Visit the FNBO Privacy Notice</a
        >
        <br />
        <a
          class="text-light"
          href="assets/forms/DFS%20Finance%20Terms%20of%20Use.pdf"
          target="_blank"
          >Terms of Use</a
        >
        |
        <a
          class="text-light"
          href="https://health1.aetna.com/app/public/#/one/insurerCode=AETNACVS_I&brandCode=ALICSI/machine-readable-transparency-in-coverage?searchTerm=102287322&lock=true%20[health1.aetna.com]"
          target="_blank"
          >Transparency Compliance -Machine Readable File</a
        >
      </p>
      <ul class="list-unstyled d-flex">
        <li class="ms-3">
          <a class="link-light" href="https://twitter.com/DivFinancial"
            ><svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              fill="currentColor"
              class="bi bi-twitter"
              viewBox="0 0 16 16"
              role="img"
              aria-label="DFS Finance Twitter"
            >
              <path
                d="M5.026 15c6.038 0 9.341-5.003 9.341-9.334 0-.14 0-.282-.006-.422A6.685 6.685 0 0 0 16 3.542a6.658 6.658 0 0 1-1.889.518 3.301 3.301 0 0 0 1.447-1.817 6.533 6.533 0 0 1-2.087.793A3.286 3.286 0 0 0 7.875 6.03a9.325 9.325 0 0 1-6.767-3.429 3.289 3.289 0 0 0 1.018 4.382A3.323 3.323 0 0 1 .64 6.575v.045a3.288 3.288 0 0 0 2.632 3.218 3.203 3.203 0 0 1-.865.115 3.23 3.23 0 0 1-.614-.057 3.283 3.283 0 0 0 3.067 2.277A6.588 6.588 0 0 1 .78 13.58a6.32 6.32 0 0 1-.78-.045A9.344 9.344 0 0 0 5.026 15z"
              />
            </svg>
          </a>
        </li>
        <li class="ms-3">
          <a class="link-light" href="https://www.facebook.com/divfinancial"
            ><svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              fill="currentColor"
              class="bi bi-facebook"
              viewBox="0 0 16 16"
              role="img"
              aria-label="DFS Finance Facebook"
            >
              <path
                d="M16 8.049c0-4.446-3.582-8.05-8-8.05C3.58 0-.002 3.603-.002 8.05c0 4.017 2.926 7.347 6.75 7.951v-5.625h-2.03V8.05H6.75V6.275c0-2.017 1.195-3.131 3.022-3.131.876 0 1.791.157 1.791.157v1.98h-1.009c-.993 0-1.303.621-1.303 1.258v1.51h2.218l-.354 2.326H9.25V16c3.824-.604 6.75-3.934 6.75-7.951z"
              />
            </svg>
          </a>
        </li>
      </ul>
    </div>
  </footer>
</section>
