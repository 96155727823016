<main>
  <section
    style="
      background-image: url('assets/images/home-hero.jpg');
      background-size: cover;
      background-position: center;
    "
  >
    <!-- reference link: https://getbootstrap.com/docs/5.0/components/toasts/
      https://fontawesome.com/v4/icons/
      https://fontawesome.com/v4/icon/exclamation-triangle
    -->
    <div class="container py-5">
      <div class="row align-items-start g-lg-5 py-5">
        <div class="col-md-10 mx-auto col-lg-5 d-none d-lg-block login-row">
          <fin-login *ngIf="!loggedIn"></fin-login>
        </div>
        <div class="col-lg-7 text-lg-start text-light">
          <h1 class="display-4 lh-1 mb-4">Helping growers grow.</h1>
          <p class="col-lg-10 fs-5">
            We’re proud to offer the ag industry the custom financing options
            and competitive rates you need, and the personalized service you
            value.
          </p>
        </div>
      </div>
    </div>
  </section>

  <div class="container px-5 py-3 py-lg-5 mt-lg-5" id="featuredtasks">
    <div class="row g-4 py-5 row-cols-1 row-cols-md-3">
      <div class="feature col border-end">
        <div class="feature-icon">
          <img
            type="image/svg+xml"
            src="assets/svg/apply-icon.svg"
            alt="Your browser does not support SVGs"
          />
        </div>
        <h4 class="my-4">Apply for Financing</h4>
        <p class="mb-5">Apply online for your next ag equipment purchase.</p>
        <a
          *ngIf="!loggedIn || isCustomer"
          (click)="customerRoute()"
          class="btn btn-outline-primary mb-auto"
          >Customer </a
        >&nbsp;
        <a
          *ngIf="!loggedIn || isDealer"
          (click)="dealerRoute()"
          class="btn btn-outline-primary mb-auto"
          >Dealer
        </a>
      </div>
      <hr class="d-md-none" />
      <div class="feature col border-end ps-md-5">
        <div class="feature-icon">
          <img
            type="image/svg+xml"
            src="assets/svg/pay-online-icon.svg"
            alt="Your browser does not support SVGs"
          />
        </div>
        <h4 class="my-4">Pay Online</h4>
        <p class="mb-5">Login to submit a payment with our ACH Service.</p>
        <a [routerLink]="[makePaymentLink]" class="btn btn-outline-primary">
          Make a Payment
        </a>
      </div>
      <hr class="d-md-none" />
      <div class="feature col ps-md-5">
        <div class="feature-icon">
          <img
            type="image/svg+xml"
            src="assets/svg/dealer-signup-icon.svg"
            alt="Your browser does not support SVGs"
          />
        </div>
        <h4 class="my-4">Dealer Sign Up</h4>
        <p class="mb-5">
          Offer your customers the flexible financing options they need.
        </p>
        <a href="/becomeADealer" class="btn btn-outline-primary"> Sign Up </a>
      </div>
    </div>
  </div>

  <div class="container">
    <div class="d-md-flex flex-md-equal w-100 my-md-5">
      <div class="overflow-hidden d-flex justify-content-center">
        <img
          class="h-100"
          src="assets/images/about-relationship.jpg"
          alt="Two people walking through a field in front of agricultural machinery"
        />
      </div>
      <div class="pt-3 pt-md-5 overflow-hidden bg-secondary">
        <div class="my-3 p-3 ps-5">
          <h2 class="display-5 mb-4">Relationship-driven</h2>
          <p class="lead mb-5">
            The "personal touch" has always set us apart and helped grow us into
            America's largest family-owned ag lender. Our fast, flexible
            financing options are a product of just how well we know and
            understand your business.
          </p>
          <a class="btn btn-primary" [routerLink]="['/about']"
            >Learn More about DFS</a
          >
        </div>
      </div>
    </div>
  </div>
  <div class="container">
    <div class="d-md-flex flex-md-equal w-100 my-md-5 align-items-center">
      <div class="overflow-hidden">
        <div class="ps-3" style="padding-right: 1rem">
          <p style="font-weight: bold; font-size: calc(1.28125rem + 0.5vw)">
            "Our family has been farming for over 100 years and done business
            with countless lenders and banks. Our experience with DFS has been
            unmatched! Their rates have always been extremely competitive and
            often the best we can find."
          </p>
          <p class="fw-semibold">
            Aaron M.<br /><small class="fw-normal">NG Turf</small>
          </p>
        </div>
      </div>
      <div class="overflow-hidden d-flex justify-content-start">
        <img
          class="h-100"
          src="assets/images/customer-testimonial.jpg"
          alt="Happy DFS customer"
        />
      </div>
    </div>
  </div>
</main>
