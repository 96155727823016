import { Component, Input, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ISimpleItem } from '../simpleList';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { SupervisorSalesmenRepository } from 'src/app/dfs-fin-api/supervisor-salesman.repository';
import { CreditApplicationsRepository } from 'src/app/dfs-fin-api/credit-applications.repository';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';

@Component({
  selector: 'fin-view-reassign-app-modal',
  templateUrl: './view-reassign-app-modal.component.html',
  styleUrls: ['../../PortalMain.scss'],
})
export class ViewReassignAppModalComponent implements OnInit {
  @Input() application: any;
  selectedUser: ISimpleItem | undefined;
  userList: ISimpleItem[] = [];
  customerName!: string;

  constructor(
    private readonly bsModalRef: BsModalRef,
    private readonly supervisorSalesmenRepo: SupervisorSalesmenRepository,
    private readonly creditAppRepo: CreditApplicationsRepository,
    private readonly toastr: ToastrService,
    private router: Router
  ) {}

  ngOnInit() {
    let salespersonId = this.application.salespersonId;
    this.customerName = this.application.primaryCustomerName;
    this.supervisorSalesmenRepo
      .getSalesmenForReassignment(this.application.dealerCodeId)
      .subscribe((result) => {
        this.userList = result;
        this.selectedUser = this.userList.filter(
          (r) => r.id == salespersonId
        )[0];
      });
  }
  onChangeDealer(item: ISimpleItem) {
    this.selectedUser = item;
  }

  onSubmit() {
    if (
      this.selectedUser &&
      this.selectedUser.id != this.application.salespersonId
    ) {
      this.creditAppRepo
        .updateDealer(this.application.applicationId, this.selectedUser.id)
        .subscribe({
          next: () => {
            this.close();
            this.router.navigate(['creditApplication']);
          },
          error: (err) => {
            console.error(err);
            this.toastr.error(
              `<a href="mailto:OnlineSupport@dfsfin.com">
            There was an error reassigning the user salesman.
            If the issue persists, click here to contact Online Support.
           </a>`,
              'Error',
              { enableHtml: true }
            );
          },
        });
    }
    this.close();
  }
  close() {
    this.bsModalRef.hide();
  }
}
