<div id="page-content-wrapper">
  <h2>Credit Application</h2>
  <hr />
  <div class="card">
    <form
      class="wizard-container"
      [formGroup]="creditApplicationForm"
      novalidate
      #caFormDirective="ngForm"
    >
      <ca-steps></ca-steps>

      <div class="wizard-page-container">
        <ng-container [ngSwitch]="currentStep.stepIndex">
          <ng-container *ngSwitchCase="1">
            <br />
            <dca-basic-information
              [step]="currentStep"
              [form]="$any(basicInformationForm)"
              [rates]="rates"
              [locations]="locations"
              [applicationOwner]="applicationOwner || false"
            ></dca-basic-information>
          </ng-container>
          <ng-container *ngSwitchCase="2">
            <br />
            <ca-equipment-step
              [step]="currentStep"
              [form]="$any(equipmentForm)"
              [equipmentType]="$any(equipmentType)"
              [sellerType]="sellerTypeEnum.dealerStart"
            ></ca-equipment-step>
          </ng-container>
          <ng-container *ngSwitchCase="3">
            <br />
            <dca-contract-terms
              [step]="currentStep"
              [form]="$any(contractTermsForm)"
              [equipmentItems]="equipmentItems"
              [rates]="rates"
              [equipmentType]="$any(equipmentType)"
              [bonusRateTypes]="bonusRateTypes"
            ></dca-contract-terms>
          </ng-container>
          <ng-container *ngSwitchCase="4">
            <br />
            <fin-submit
              [step]="currentStep"
              [form]="$any(submissionDetails)"
              [equipmentType]="$any(equipmentType)"
              [sellerType]="sellerTypeEnum.dealerStart"
              (attachFileUpload)="attachFile($event)"
              (creditReportAuthorizedChange)="
                creditReportAuthorizationChangeInSubmit($event)
              "
            ></fin-submit>
          </ng-container>
        </ng-container>
      </div>

      <div>
        <button
          type="button"
          (click)="onPreviousStep()"
          [disabled]="isFirstStep"
          class="dfs-btn dfs-btn-primary"
        >
          Previous
        </button>
        <button
          type="button"
          id="nextButton"
          (click)="onNextStep()"
          [ngClass]="
            nextButtonText === 'Save' || nextButtonText === 'E-mail app'
              ? 'dfs-btn dfs-btn-success'
              : 'dfs-btn dfs-btn-primary'
          "
        >
          {{ nextButtonText }}
        </button>
        <button
          type="button"
          (click)="creditSummaryReRoute()"
          class="dfs-btn dfs-btn-primary"
        >
          Cancel
        </button>
      </div>
    </form>

    <div *ngIf="isShowInvalidEntries" class="text-danger">
      Some of the entries on the application are invalid and need to be
      corrected. Required fields are marked with an asterisk *
      <br />
    </div>
    <br />
  </div>
</div>
