<form
  [formGroup]="form"
  class="p-4 p-md-5 shadow bg-light h-100"
  (submit)="resetPassword()"
>
  <small class="fw-semibold">Forgot Password/Login</small>
  <div *ngIf="submittedMsg">{{submittedMsg}}</div>
  <div *ngIf="error">
    There was an error processing your request. If the issue continues, please
    contact Customer Service.
  </div>
  <div class="form-floating my-3">
    <input
      type="text"
      formControlName="username"
      class="form-control"
      id="floatingInput"
      autocomplete="off"
      placeholder="name@example.com"
      [ngClass]="{ 'is-invalid': submitted && theForm['username'].errors }"
    />
    <div
      *ngIf="submitted && theForm['username'].errors"
      class="invalid-feedback"
    >
      <div *ngIf="theForm['username'].errors['required']">
        Username/e-mail is required
      </div>
    </div>
    <label for="floatingInput">Enter your e-mail address or username:</label>
  </div>

  <button class="w-100 btn btn-primary" [disabled]="loading" type="submit">
    <span *ngIf="loading" class="spinner-border spinner-border-sm me-1"></span>
    Submit
  </button>
</form>
