<div class="modal-header">
      <h4 class="modal-title">Application Submitted</h4>
    </div>
    <div class="modal-body">
      <h4>
        Your application has been successfully submitted!
        {{ getAppNumberForPopup() }}
      </h4>
    </div>
    <div class="modal-footer">
      <button
        type="button"
        class="dfs-btn dfs-btn-primary"
        aria-label="Ok"
        (click)="homeRedirect()"
      >
        Ok
      </button>
    </div>
