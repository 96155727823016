<form [formGroup]="form" #formDirective="ngForm">
  <div class="row">
    <div class="col-md-6">
      <fin-basic-contract-terms
        [form]="form"
        [equipmentItems]="equipmentItems"
        [equipmentType]="equipmentType"
        [sellerType]="sellerType"
        [rates]="workingRates"
        (onCalculate)="onCalculationsChange($event)"
      ></fin-basic-contract-terms>
    </div>
    <div class="col-md-6">
      <ca-contract-totals
        [loanCalculatorResults]="loanCalculatorResults"
        [contractType]="contractType"
        [sellerType]="sellerType"
      ></ca-contract-totals>
    </div>
  </div>
</form>
