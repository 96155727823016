import { inject } from '@angular/core';
import { Router } from '@angular/router';
import { CurrentUserService } from 'dfs-common-ngx';
import { CookieService } from 'ngx-cookie-service';
// import { UserRole } from '../enums/user-role';

export const authGuard = () => {
  const userService = inject(CurrentUserService);
  const router = inject(Router);
  const cookieService = inject(CookieService);

  return userService.profileO.subscribe((user) => {

    if (user) {
      return true;
    }

    // Redirect to the login page
    router.navigate(['/login']);
    return false;
  });
};
