import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { map, Observable, Subject, Subscription } from 'rxjs';
import { environment } from 'src/environments/environment';
import { ContactUs } from './contact-us';
import { IState } from '../interfaces/state';
import { StatesService } from '../services/states.service';
import { ContactUsService } from '../services/contact-us.service';
import { SpinnerService } from 'dfs-common-ngx';
import {
  Positions,
  UserNotificationSvc,
} from '../common/services/UserNotificationSvc';
import { OrderByPipe } from '../common/pipes/order-by.pipe';

@Component({
  selector: 'fin-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['marketing-styles.css'],
})
export class ContactComponent implements OnInit, OnDestroy {
  contactForm!: FormGroup;
  contactUs: ContactUs = new ContactUs();
  submitted = false;
  sub!: Subscription;
  errorMessage: string = '';
  selectedState: string = '';
  showCaptchaError: boolean = true;
  captchaIsValid = false;
  captchaKey = environment.CaptchaKey; //  '6LdVqG4gAAAAABPTSIocqqnK14DyV72U8ieKjRpN';
  contractType: number | null | undefined;

  statesList: IState[] = [];
  statesList$: Observable<IState[]> | undefined;
  inputState$ = new Subject<string>();

  ContactTypeList = [
    { key: 1, value: 'Request Contract Number' },
    { key: 2, value: 'Request an ACH Packet' },
    { key: 3, value: 'General Inquiry' },
  ];

  constructor(
    private route: ActivatedRoute,
    private statesService: StatesService,
    private formBuilder: FormBuilder,
    private contactUsService: ContactUsService,
    private userNotificationSvc: UserNotificationSvc,
    private readonly spinnerService: SpinnerService,
    private readonly orderBy: OrderByPipe
  ) {}

  ngOnInit(): void {
    this.contractType =
      Number(this.route.snapshot.queryParamMap.get('contacttype')) || null;

    this.sub = this.statesService.getStatesList().subscribe({
      next: (currentState) => {
        this.statesList = this.orderBy.transform(currentState, 'name');

        this.statesList$ = this.inputState$.pipe(
          map((term) => this.searchSates(term))
        );
      },
      error: (err) => (this.errorMessage = err),
    });

    this.createFrom();

    this.setDynamicValidation();
  }

  searchSates(term: string): IState[] {
    const searchTerm = term ? term : '';
    return this.statesList.filter((state) => {
      return state.name.toLowerCase().startsWith(searchTerm.toLowerCase());
    });
  }

  createFrom() {
    this.contactForm = this.formBuilder.group({
      reason: [this.contractType, [Validators.required]],
      fullName: ['', [Validators.required]],
      address: '',
      city: '',
      state: null,
      zip: '',
      contractNumber: '',
      phoneNumber: '',
      email: ['', [Validators.required, Validators.email]],
      comments: ['', [Validators.maxLength(200)]],
    });
  }

  setDynamicValidation() {
    switch (this.contactForm.get('reason')?.value) {
      case 1:
        this.contactForm
          .get('phoneNumber')
          ?.setValidators([Validators.required]);
        this.contactForm
          .get('contractNumber')
          ?.removeValidators([Validators.required]);
        break;
      case 2:
        this.contactForm
          .get('contractNumber')
          ?.setValidators([Validators.required]);
        this.contactForm
          .get('phoneNumber')
          ?.removeValidators([Validators.required]);
        break;
      case 3:
      default:
        this.contactForm
          .get('phoneNumber')
          ?.removeValidators([Validators.required]);
        this.contactForm
          .get('contractNumber')
          ?.removeValidators([Validators.required]);
        break;
    }

    this.contactForm.get('phoneNumber')?.updateValueAndValidity();
    this.contactForm.get('contractNumber')?.updateValueAndValidity();
  }

  getCaptchaValue(captcha: string) {
    this.captchaIsValid = !(captcha === null || captcha === undefined);
  }

  ngOnDestroy(): void {
    this.sub.unsubscribe();
  }

  onSubmit(event: MouseEvent): void {
    if (!this.captchaIsValid) {
      return;
    }
    this.submitted = true;

    if (this.contactForm.invalid) {
      return;
    }

    (event.target as HTMLButtonElement).disabled = true;
    this.spinnerService.show();

    this.setContactUsValues();

    this.contactUsService.sendEmail(this.contactUs).subscribe({
      next: () => {
        (event.target as HTMLButtonElement).disabled = false;
        this.spinnerService.hide();
        this.userNotificationSvc.success(
          'Your request was successfully sent. Thank you.',
          'Request sent',
          10000,
          Positions.topRight
        );
        this.onReset();
      },
      error: (err) => {
        (event.target as HTMLButtonElement).disabled = false;
        this.spinnerService.hide();
        this.userNotificationSvc.error(
          'There was an error sending your request.',
          'Error',
          10000,
          Positions.topRight
        );
      },
    });
  }

  setContactUsValues() {
    Object.assign(this.contactUs, this.contactForm.value);

    this.contactUs.state = this.statesList
      .filter((s) => s.code === this.contactUs.state)
      .map(function (item) {
        return item.name;
      })[0];

    this.contactUs.reason = this.ContactTypeList.filter(
      (s) => s.key === +this.contactUs.reason
    ).map(function (item) {
      return item.value;
    })[0];
  }

  sendContactUsEmail() {}

  onReset(): void {
    this.submitted = false;
    this.contactForm.reset();
  }
}
