<div class="dfs-modal-header">
  <h3 class="dfs-modal-title">Salesperson Reassign</h3>
</div>
<div class="dfs-modal-body">
  <div>
    <h4>
      Select the salesperson to reassign the application to for customer:
      {{ customerName }}
    </h4>
  </div>
  <div>
    <label>Salesperson Name</label>
    <ng-select
      [items]="userList"
      [selectOnTab]="true"
      [clearable]="false"
      [ngModel]="selectedUser"
      bindLabel="value"
      (change)="onChangeDealer($event)"
    >
      <ng-template ng-label-tmp let-item="item">
        {{ item.value }}
      </ng-template>
      <ng-template ng-option-tmp let-item="item">
        {{ item.value }}
      </ng-template>
    </ng-select>
  </div>
</div>
<div class="dfs-modal-footer">
  <button type="button" class="dfs-btn dfs-btn-primary" (click)="onSubmit()">
    Save
  </button>
  <button type="button" class="dfs-btn dfs-btn-danger" (click)="close()">
    Close
  </button>
</div>
