<section>
  <form novalidate [formGroup]="selfIdentificationForm">
    <div class="modal-header">
      <h4 class="text-primary mb-4">
        Voluntary Applicant Self-Identification Form
      </h4>
      <button
        type="button"
        class="btn-close"
        (click)="modalRef.hide()"
        aria-label="Close"
      ></button>
    </div>
    <div
      class="modal-body"
      style="max-height: calc(100vh - 210px); overflow-y: auto"
    >
      <section>
        <h5 class="text-center text-uppercase">Instructions</h5>
        <p class="text-center">
          <strong
            >Please read all instructions carefully before completing this
            form</strong
          >
        </p>
        <p>
          <strong>Anti-Discrimination Notice:</strong> It is unlawful practice
          for an employer to fail or refuse to hire or discharge any individual,
          or otherwise to discriminate against any individual with respect to
          that individual's terms and conditions of employment, because of such
          individual's race, color, religion, sex, or national origin.
        </p>
        <p>
          This employer is subject to certain nondiscrimination and affirmative
          action recordkeeping and reporting requirements which require the
          employer to invite employees to voluntarily self-identify their
          race/ethnicity. The information obtained is voluntary and refusal to
          provide it will not subject you to any adverse treatment. The
          information obtained will be kept confidential and may only be used in
          accordance with the provisions of applicable federal laws, executive
          orders, and regulations, including those which require the information
          to be summarized and reported to the Federal Government for civil
          rights enforcement purposes.
        </p>
        <p>
          If you choose not to self-identify your race/ethnicity at this time,
          the federal government requires this employer to determine this
          information by visual survey and/or other available information.
        </p>
        <p>
          For civil rights monitoring and enforcement purposes only, all
          race/ethnicity information will be collected and reported in the seven
          categories identified below. The definitions for each category have
          been established by the federal government.
        </p>
      </section>
      <hr />
      <section>
        <h5 class="text-center text-uppercase">Invitation to Self-Identify</h5>
        <p class="text-center">
          <strong>Please answer the following questions</strong>
        </p>
        <div class="card">
          <div class="form-group mb-2 px-3" btnRadioGroup>
            <span><strong>What is your gender?</strong></span>
            <div>
              <input type="radio" value="male" formControlName="gender" /> Male
            </div>
            <div>
              <input type="radio" value="female" formControlName="gender" />
              Female
            </div>
          </div>
          <div class="form-group mb-2 px-3" btnRadioGroup>
            <span>
              <strong
                >What is your race/ethnicity? Please mark the option that
                describes the race/ethnicity category with which you primarily
                identify.</strong
              >
            </span>
            <div class="radio mb-2 px-4">
              <label>
                <input
                  type="radio"
                  name="race"
                  value="Hispanic or Latino"
                  formControlName="race"
                />
                <strong> Hispanic or Latino:</strong> a person of Cuban,
                Mexican, Chicano, Puerto Rican, South or Central American, or
                other Spanish culture or origin, regardless of race.
              </label>
            </div>
            <div class="radio mb-2 px-4">
              <label>
                <input
                  type="radio"
                  name="race"
                  formControlName="race"
                  value="White"
                />
                <strong> White:</strong> a person having origins in any of the
                original peoples of Europe, the Middle East, or North Africa.
              </label>
            </div>
            <div class="radio mb-2 px-4">
              <label>
                <input
                  type="radio"
                  name="race"
                  formControlName="race"
                  value="Black or African American"
                />
                <strong> Black or African American:</strong> a person having
                origins in any of the black racial groups of Africa.
              </label>
            </div>
            <div class="radio mb-2 px-4">
              <label>
                <input
                  type="radio"
                  name="race"
                  formControlName="race"
                  value="Asian"
                />
                <strong> Asian:</strong> a person having origins in any of the
                original peoples of the Far East, Southeast Asia, or the Indian
                subcontinent including, for example, Cambodia, China, India,
                Japan, Korea, Malaysia, Pakistan, the Philippine Islands,
                Thailand, and Vietnam.
              </label>
            </div>
            <div class="radio mb-2 px-4">
              <label>
                <input
                  type="radio"
                  name="race"
                  formControlName="race"
                  value="Native Hawaiian or Other Pacific Islander"
                />
                <strong> Native Hawaiian or Other Pacific Islander:</strong> a
                person having origins in any of the original peoples of Hawaii,
                Guam, Samoa, or other Pacific Islands.
              </label>
            </div>
            <div class="radio mb-2 px-4">
              <label>
                <input
                  type="radio"
                  name="race"
                  formControlName="race"
                  value="American Indian or Alaska Native"
                />
                <strong> American Indian or Alaska Native:</strong> a person
                having origins in any of the original peoples of North and South
                American (including Central American), and who maintains tribal
                affiliation or community attachment.
              </label>
            </div>
            <div class="radio mb-2 px-4">
              <label>
                <input
                  type="radio"
                  name="race"
                  formControlName="race"
                  value="Two or More Races"
                />
                <strong> Two or More Races:</strong> a person who primarily
                identifies with two or more of the above race/ethnicity
                categories.
              </label>
            </div>
          </div>
        </div>
        <div class="form-group">
          <div class="checkbox">
            <label>
              <input type="checkbox" value="true" formControlName="optOut" />
              <strong> I do not wish to self-identify.</strong>
            </label>
          </div>
        </div>
      </section>
    </div>
    <div class="modal-footer">
      <div class="text-danger">
        <div *ngIf="isControlValid('gender')">
          Please select gender or opt out.
        </div>
        <div *ngIf="isControlValid('race')">Please select race or opt out.</div>
      </div>
      <button type="button" (click)="close()" class="btn btn-success">
        Submit
      </button>
    </div>
  </form>
</section>
