import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { ContactUs } from '../marketing/contact-us';

@Injectable({
  providedIn: 'root',
})
export class ContactUsService {
  constructor(private httpClient: HttpClient) {}

  sendEmail(data: ContactUs): Observable<string> {
    return this.httpClient.post<string>(
      `${environment.ApiBaseUrl}/user/contact-us`,
      data
    );
  }
}
