import { Injectable } from '@angular/core';

import { DeletedApplicationModalComponent } from './deleted-application-modal.component';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';

@Injectable({ providedIn: 'root' })
export class DeleteApplicationModalSvc {
  bsModalRef!: BsModalRef;
  constructor(private modalService: BsModalService) {}

  display(customerName: string, applicationId: string) {
    const initialState = {
      customerName,
      applicationId,
    };
    this.bsModalRef = this.modalService.show(DeletedApplicationModalComponent, {
      initialState,
      ignoreBackdropClick: true,
      class: 'modal-lg',
    });
  }
}
