<form [formGroup]="form">
  <div form-control-group>
    <div
      class="panel panel-default important-panel"
      [ngClass]="{
        'invalid-border':
          creditReportAuthorized.touched && creditReportAuthorized.invalid
      }"
    >
      <div form-control-group>
        <label>
          <input
            type="checkbox"
            id="creditReportAuthorized"
            name="creditReportAuthorized"
            formControlName="creditReportAuthorized"
            [defaultChecked]="false"
            (change)="clickHappend($event)"
          />
          * By checking this box, I/we authorize DFS Finance to obtain credit
          reports for all permissible business purposes related to financing and
          leasing transactions with DFS Finance and authorize the release of any
          and all information necessary from the Primary Lender listed above.
          All information collected by DFS Finance (whether directly or
          indirectly) is subject to and governed by our privacy policy which can
          be found on our home page.
        </label>
      </div>
      <div class="panel panel-default">
        <div class="panel-heading">
          <a
            type="link"
            class="card-title btn btn-link"
            aria-expanded="false"
            aria-controls="termsConditionsPanel"
            (click)="isCollapsed = !isCollapsed"
          >
            Click Here to View Terms and Conditions
          </a>
        </div>

        <section
          [isAnimated]="true"
          [collapse]="isCollapsed"
          class="card card-body p-panel-content panel-collapse collapse collapsible-text-panel m-3"
        >
          <h4><b>Terms and Conditions:</b></h4>
          <p>
            <b>NOTICE:</b> The federal Equal Credit Opportunity Act prohibits
            creditors from discriminating against credit applicants on the basis
            of race, color, religion, national origin, sex, marital status, age
            (provided the applicant has the capacity to enter into a binding
            contract), because all or part of the applicant’s income derives
            from any public assistance program or because the applicant has in
            good faith exercised any right under the Consumer Credit Protection
            Act. The federal agency that administers compliance with this law
            concerning this creditor is: Bureau of Consumer Financial
            Protection, 1700 G Street NW, Washington, DC 20552.
          </p>
          <p>
            If your application for business credit is denied, you have the
            right to a written statement of the specific reasons for the denial.
            To obtain the statement, please contact (DFS Finance at
            800-648-8026) within 60 days from the date you are notified of our
            decision. We will send you a written statement of reasons for the
            denial within 30 days of receiving your request for the statement.
          </p>
          <p>
            The USA PATRIOT Act requires all financial institutions to obtain,
            verify and record information that identifies each person (including
            business entities) who opens an account. What this means for you:
            When you open an account, we will ask for your name, physical
            address, date of birth, taxpayer identification number, and other
            information that will allow us to identify you. We may also ask to
            see your driver’s license or other identifying documents. We will
            let you know if additional information is required.
          </p>
          <p>
            I/We understand and agree that you may assign or transfer this
            credit application to others to decide whether or not to extend
            credit. I/We authorize DFS Finance, a division of First National
            Bank of Omaha to obtain credit reports for all permissible business
            purposes related to financing and leasing transactions with DFS
            Finance, a division of First National Bank of Omaha. I/We authorize
            the above bank and business references to give any and all necessary
            information to you, your assignees or transferees, which will assist
            you in your credit inquiry. This application and financial statement
            is given for the purpose of obtaining credit. I/We hereby certify
            under penalty of law that the foregoing is a true and complete
            statement of my/our financial condition. In the event of any
            material change in my/our financial condition, I/we will notify you
            immediately in writing. I/We agree to periodically furnish financial
            or other information if requested by DFS Finance, a division of
            First National Bank of Omaha. I/We hereby authorize DFS Finance, a
            division of First National Bank of Omaha, Dealer and their
            respective assignees, transferees and agents to authenticate and
            file financing statements and amendments thereto regarding the
            requested financing and any subsequent financing which DFS Finance,
            a division of First National Bank of Omaha may grant to us.
          </p>
        </section>
      </div>
    </div>
  </div>
</form>
