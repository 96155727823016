import { DialogRef, DIALOG_DATA } from '@angular/cdk/dialog';
import { Component, Inject } from '@angular/core';

@Component({
  selector: 'fin-delete-envelope-modal',
  templateUrl: './delete-envelope-modal.component.html',
  styleUrls: ['../../PortalMain.scss'],
})
export class DeleteEnvelopeModalComponent {
  constructor(
    @Inject(DIALOG_DATA) public input: any,
    private dialogRef: DialogRef
  ) {}

  close(response: string) {
    this.dialogRef.close(response);
  }
}
