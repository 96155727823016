<form name="esigRecipientForm" #esigRecipientForm="ngForm">
  <div class="modal-header">
    <h5>E-Signature Recipients For {{this.signingLocation}} Signing</h5>
  </div>
  <div class="modal-body">
    <div class="row">
      <div class="col-md-5 form-group">
        <label class="control-label" for="dealerName">Dealer Signer Name</label>
        <input
          type="text"
          id="dealerName"
          class="form-control"
          [ngClass]="{ 'is-invalid': (esigRecipientForm.submitted || dealerName.touched) && dealerName.invalid }"
          name="dealerName"
          maxlength="50"
          [required]="true"
          #dealerName="ngModel"
          [(ngModel)]="dealerRecipient.name"
        />
        <span
          class="text-danger"
          *ngIf="(esigRecipientForm.submitted || dealerName.touched) && dealerName.invalid"
          >Dealer signer name is required</span
        >
      </div>
      <div class="col-md-5 form-group">
        <label class="control-label" for="dealerEmail"
          >Dealer Signer E-Mail Address</label
        >
        <input
          type="email"
          id="dealerEmail"
          name="dealerEmail"
          #dealerEmail="ngModel"
          class="form-control"
          maxlength="50"
          [email]="true"
          [required]="true"
          [(ngModel)]="dealerRecipient.email"
          [ngClass]="{ 'is-invalid': (esigRecipientForm.submitted || dealerEmail.touched) && dealerEmail.invalid }"
        />
        <span
          class="text-danger"
          *ngIf="(esigRecipientForm.submitted || dealerEmail.touched) && dealerEmail.errors?.['required']"
          >Dealer signer email is required</span
        >
        <span
          class="text-danger"
          *ngIf="(esigRecipientForm.submitted || dealerEmail.touched) && dealerEmail.errors?.['email']"
          >Enter a valid Dealer signer email</span
        >
      </div>
    </div>
    <div class="form-check">
      <label class="form-check-label">
        <input
          type="checkbox"
          class="form-check-input"
          id="updateDealerEmail"
          name="updateDealerEmail"
          [(ngModel)]="updateDealerEmail"
        />
        Save this as my default e-signature dealer signer name and e-mail
      </label>
    </div>
    <div class="row" *ngIf="ccRecipientEnabled && signingLocation == 'Remote'">
      <div class="col-md-5 form-group">
        <label class="control-label" for="ccName">Dealer CC Name</label>
        <input
          type="text"
          id="ccName"
          class="form-control"
          [ngClass]="{ 'is-invalid': (esigRecipientForm.submitted || ccName.touched) && ccName.invalid }"
          name="ccName"
          maxlength="50"
          [required]="ccRecipientEnabled"
          #ccName="ngModel"
          [(ngModel)]="ccRecipient.name"
        />
        <span
          class="text-danger"
          *ngIf="(esigRecipientForm.submitted || ccName.touched) && ccName.invalid"
          >Dealer CC Name is required</span
        >
      </div>
      <div class="col-md-5 form-group">
        <label class="control-label" for="dealerEmail"
          >Dealer CC E-Mail Address</label
        >
        <input
          type="email"
          id="ccEmail"
          name="ccEmail"
          #ccEmail="ngModel"
          class="form-control"
          [email]="true"
          maxlength="50"
          [(ngModel)]="ccRecipient.email"
          [required]="ccRecipientEnabled"
          [ngClass]="{ 'is-invalid': (esigRecipientForm.submitted || ccEmail.touched) && ccEmail.invalid }"
          [autocomplete]="false"
        />
        <span
          class="text-danger"
          *ngIf="(esigRecipientForm.submitted || ccEmail.touched) && ccEmail.errors?.['required']"
          >Dealer CC Email is required</span
        >
        <span
          class="text-danger"
          *ngIf="(esigRecipientForm.submitted || ccEmail.touched) && ccEmail.errors?.['email']"
          >Enter a valid Dealer CC Email</span
        >
      </div>
    </div>
    <div *ngIf="signingLocation == 'Remote'" class="form-check">
      <label class="form-check-label">
        <input
          type="checkbox"
          class="form-check-input"
          id="ccRecipientEnabled"
          name="ccRecipientEnabled"
          [(ngModel)]="ccRecipientEnabled"
        />
        Enter Dealer CC Email Address
      </label>
    </div>
    <div>
      <p class="text-info pi pi-exclamation-triangle">
        A code will be sent to the recipients via text message. Standard text
        messaging rates may apply
      </p>
    </div>
    <div
      class="row"
      *ngFor="let recipient of nonDealerRecipients; let i = index"
    >
      <div class="col-md-4 form-group">
        <label class="control-label" for="recipientName{{i}}"
          >Customer Signer Name</label
        >
        <input
          type="text"
          class="form-control"
          id="recipientName{{i}}"
          name="recipientName{{i}}"
          [disabled]="true"
          [(ngModel)]="recipient.name"
        />
      </div>
      <div *ngIf="signingLocation == 'Remote'" class="col-md-4 form-group">
        <label class="control-label" for="recipientEmail{{i}}"
          >Customer Signer E-Mail Address</label
        >
        <input
          type="email"
          id="recipientEmail{{i}}"
          name="recipientEmail{{i}}"
          #recipientEmail="ngModel"
          class="form-control"
          [email]="true"
          maxlength="50"
          [required]="true"
          [(ngModel)]="recipient.email"
          [ngClass]="{ 'is-invalid': (esigRecipientForm.submitted || recipientEmail.touched) && recipientEmail.invalid }"
          [autocomplete]="false"
        />
        <span
          class="text-danger"
          *ngIf="(esigRecipientForm.submitted || recipientEmail.touched) && recipientEmail.errors?.['required']"
          >Recipient Email is required</span
        >
        <span
          class="text-danger"
          *ngIf="(esigRecipientForm.submitted || recipientEmail.touched) && recipientEmail.errors?.['email']"
          >Enter a valid Recipient Email</span
        >
      </div>
      <div class="col-md-4 form-group">
        <label class="control-label" for="recipientPhone{{i}}"
          >Customer Signer Mobile #</label
        >
        <p-inputMask
          class="w-100"
          inputId="recipientPhone{{i}}"
          name="recipientPhone{{i}}"
          prevent-phone-match-validator
          [user-number]="currentUserPhone"
          [mobile-number]="recipient.phone"
          [recipient-list]="tempRecipientList"
          [ngClass]="{ 'is-invalid': recipientPhone.invalid }"
          #recipientPhone="ngModel"
          [required]="true"
          placeholder="###-###-####"
          mask="999-999-9999"
          [unmask]="true"
          [(ngModel)]="recipient.phone"
          (onComplete)="askToUpdatePhone(esigRecipientForm, i)"
        />
        <div
          class="text-danger"
          *ngIf="(esigRecipientForm.submitted || recipientPhone.touched) && recipientPhone.invalid && recipientPhone.errors?.['required']"
        >
          Mobile Number is required
        </div>
        <div
          class="text-danger"
          *ngIf="(esigRecipientForm.submitted || recipientPhone.touched) && recipientPhone.errors?.['mobileMatchesDealer']"
        >
          Please enter a customer phone number. <br />
          Documents can't be sent to DocuSign <br />until the number is changed.
        </div>
        <div
          class="text-danger"
          *ngIf="(esigRecipientForm.submitted || recipientPhone.touched) &&
          recipientPhone.errors?.['duplicateMobileFound']"
        >
          Mobile number exists on multiple recipients. <br />
          Documents can't be sent to DocuSign <br />until all numbers are
          unique.
        </div>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <button type="button" class="dfs-btn dfs-btn-danger" (click)="close()">
      Close
    </button>
    <button
      type="submit"
      class="dfs-btn dfs-btn-primary"
      (click)="submit(esigRecipientForm, $event)"
    >
      <span
        *ngIf="isSubmitting"
        class="spinner-border spinner-border-sm"
        role="status"
        aria-hidden="true"
      ></span>
      {{submitButtonText}}
    </button>
  </div>
</form>
