import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CurrentUserService } from 'dfs-common-ngx';

@Component({
  selector: 'fin-login-page',
  templateUrl: './login-page.component.html',
  styleUrls: ['./marketing-styles.css'],
})
export class LoginPageComponent implements OnInit {
  constructor(private router: Router,
    private route: ActivatedRoute,
    private currentUserService: CurrentUserService) {}
  loggedIn = false;
  forgotPasswordMode = false;

  ngOnInit(): void {
    this.forgotPasswordMode = this.route.routeConfig?.path === 'forgotpassword';
    this.currentUserService.profileO.subscribe(user => this.loggedIn = !!user);
  }
}
