import { HttpResponse } from "@angular/common/http";
import { Injectable } from "@angular/core";

@Injectable({
    providedIn: 'root',
})
export class HttpHeaderParseSvc {
      getFileName(httpResponse: HttpResponse<any>) {
          const contentDispositionHeader = httpResponse.headers.get('Content-Disposition');
          const result = contentDispositionHeader?.split(';')[1].trim().split('=')[1];
          return result?.replace(/"/g, '') ?? '';
      }
}
