import { Component, Input, OnInit } from '@angular/core';
import { PayOnlineUrlService } from 'src/app/services/pay-online-url.service';

@Component({
  templateUrl: './pay-online.component.html',
  styleUrls: ['../PortalMain.scss'],
})
export class PayOnlineComponent implements OnInit {
  @Input() paymentUrl!: string;
  waitingForUrl: boolean = true;

  constructor(private readonly payOnlineService: PayOnlineUrlService) {}
  ngOnInit(): void {
    this.payOnlineService.getPayOnlineUrl().subscribe((url) => {
      this.paymentUrl = url;
      this.waitingForUrl = false;
    });
  }
  redirectToPaymentPortal() {
    window.open(this.paymentUrl);
  }
}
