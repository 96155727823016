import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';

import { map, Observable } from 'rxjs';

import { environment } from '../../environments/environment';
import { HttpHeaderParseSvc } from '../common/services/HttpHeaderParseSvc';
import { FileDetails } from 'dfs-common-ngx';

@Injectable()
export class PrintedCreditApplicationsRepo {
  constructor(
    private http: HttpClient,
    private HttpHeaderParseSvc: HttpHeaderParseSvc
  ) {}

  get(appId: number): Observable<FileDetails> {
    const url = `${environment.ApiBaseUrl}/printed-applications/${appId}`;

    return this.http
      .get(url, { observe: 'response', responseType: 'blob' })
      .pipe(
        map((response) => {
          const { fileName, blob } = this.buildFile(response);
          return { fileName, file: blob };
        })
      );
  }

  getForUser(appId: number): Observable<FileDetails> {
    const url = `${environment.ApiBaseUrl}/users/printed-applications/${appId}`;
    return this.http
      .get(url, { observe: 'response', responseType: 'blob' })
      .pipe(
        map((response) => {
          const { fileName, blob } = this.buildFile(response);
          return { fileName, file: blob };
        })
      );
  }

  private buildFile(response: HttpResponse<Blob>) {
    const contentDispositionHeader = response.headers.get(
      'Content-Disposition'
    );
    const fileName = this.HttpHeaderParseSvc.getFileName(response);
    const blob = new Blob([response.body!], {
      type: response.headers.get('Content-Type') || undefined,
    });
    return { fileName, blob };
  }
}
