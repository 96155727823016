import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

@Injectable({ providedIn: 'root' })
export class ViewAttachmentsRepository {
  constructor(private httpClient: HttpClient) {}
  get(
    applicationId: number,
    attachmentId: number
  ): Observable<any> {
    const url = `${environment.ApiBaseUrl}/api/users/credit-applications/${applicationId}/attachments/${attachmentId}`;
    return this.httpClient
      .get(url, { observe: 'response', responseType: 'blob' })
      .pipe(
        map((result) => {
          const fileName = this.getFileName(result);
          return {
            fileName: fileName,
            file: result.body,
          };
        })
      );
  }
  delete(
    applicationId: number,
    attachmentId: number
  ): Observable<any> {
    const url = `${environment.ApiBaseUrl}/api/users/credit-applications/${applicationId}/attachments/${attachmentId}`;
    return this.httpClient.delete(url);
  }
  private getFileName(httpResponse: HttpResponse<unknown>) {
    const contentDispositionHeader = httpResponse.headers.get(
      'Content-Disposition'
    ) ?? '';
    const result = contentDispositionHeader.split(';')[1].trim().split('=')[1];
    return result.replace(/"/g, '');
  }
}
