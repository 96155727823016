import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({ providedIn: 'root' })
export class CustomerFinishedAppAttachmentRepository {
  constructor(private httpClient: HttpClient) {}
  submit(appId: string, attachments: FormData): Observable<void> {
    const url = `${environment.ApiBaseUrl}/customer-finish-applications/${appId}/attachments`;
    return this.httpClient.post<void>(url, attachments);
  }
}
