import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { environment } from '../../environments/environment';
import { EquipmentCode } from './entities/EquipmentCode';

@Injectable()
export class EquipmentCodesRepo {
  constructor(private http: HttpClient) {}

  getEquipmentCodes() {
    const equipmentCodesSegment = '/equipment-codes';
    const url = environment.ApiBaseUrl + equipmentCodesSegment;
    return this.http.get<EquipmentCode[]>(url);
  }
}
