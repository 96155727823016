import { Injectable } from '@angular/core';
import { Dialog } from '@angular/cdk/dialog';

import { ViewAttachmentsModalComponent } from './view-attachments-modal.component';
import { BsModalService } from 'ngx-bootstrap/modal';
import { initialState } from 'ngx-bootstrap/timepicker/reducer/timepicker.reducer';

@Injectable({
  providedIn: 'root',
})
export class ViewAttachmentsModalSvc {
  modalRef: any;
  constructor(
    private dialog: Dialog,
    private readonly modalService: BsModalService
  ) {}

  display(fileAttachments: any[]) {
    return this.dialog.open(ViewAttachmentsModalComponent, {
      data: fileAttachments,
      disableClose: true,
    }).closed;
  }
}
