<form [formGroup]="form" #formDirective="ngForm">
  <h4>
    Please complete the following information about the seller of the
    equipment.
  </h4>
  <br />
  <div class="row">
    <div class="col-md-4">
      <div dfsFormGroup>
        <label name="sellerName">Name *</label>
        <input
          name="sellerName"
          id="sellerName"
          formControlName="sellerName"
        />
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-md-6">
      <div dfsFormGroup>
        <label name="privateSellerAddress">Address *</label>
        <input
          name="privateSellerAddress"
          id="privateSellerAddress"
          formControlName="sellerAddress"
        />
      </div>
    </div>
    <div class="col-md-4">
      <div dfsFormGroup>
        <label name="privateSellerCity">City *</label>
        <input
          name="privateSellerCity"
          id="privateSellerCity"
          formControlName="sellerCity"
        />
      </div>
    </div>
    <div class="col-md-2">
      <div dfsFormGroup>
        <label name="privateSellerState">State *</label>
        <ng-select
          [items]="statesList"
          [selectOnTab]="true"
          name="privateSellerState"
          id="privateSellerState"
          formControlName="sellerState"
        >
        </ng-select>
      </div>
    </div>
  </div>
</form>
