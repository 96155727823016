import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { AmortizationSchedule } from '../interfaces/amortization-schedule';

@Injectable({
  providedIn: 'root',
})
export class PaymentCalculatorService {
  constructor(private readonly http: HttpClient) {}

  computePayments(
    amtFinanced: number,
    rate: number,
    paymentFrequency: string,
    termYears: number,
    termMonths: number
  ): Observable<AmortizationSchedule> {
    const paymentUrl = `${environment.QuotingApiBaseUrl}/quotes/amortization-schedule?amountFinanced=${amtFinanced}&rate=${rate}&termYears=${termYears}&termMonths=${termMonths}&paymentFrequency=${paymentFrequency}`;
    return this.http.get<AmortizationSchedule>(paymentUrl);
  }
}
