import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { IFaq } from '../interfaces/faq';
import { FaqService } from '../services/faq.service';

@Component({
  selector: 'fin-faqs',
  templateUrl: './faqs.component.html',
  styleUrls: ['./marketing-styles.css'],
})
export class FaqsComponent implements OnInit, OnDestroy {
  sub!: Subscription;
  errorMessage: string = '';

  faqs: IFaq[] = [];

  constructor(private faqService: FaqService) {}

  ngOnInit(): void {
    this.sub = this.faqService.getQuestions().subscribe({
      next: (questions) => {
        this.faqs = questions;
      },
      error: (err) => (this.errorMessage = err),
    });
  }
  ngOnDestroy(): void {
    this.sub.unsubscribe();
  }
}
