import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";

import { environment } from "../../environments/environment";
import { Constants } from "./constants";

@Injectable()
export class DealerApprovalsRepo {
    constructor(private http: HttpClient) {}
        private readonly approvalsSegment = '/approvals/';

        add(dealerId: number, isApproved: boolean) {
            const payload = {
                isApproved: isApproved
            };
            const url = environment.ApiBaseUrl + '/' + Constants.apiUrls.dealersApiSegment + dealerId + this.approvalsSegment;
            return this.http.post(url, payload);
        }
}
