import { Component, OnInit } from '@angular/core';
import {
  FormGroup,
  UntypedFormBuilder,
  Validators,
  FormControl,
  AbstractControl,
} from '@angular/forms';
import Validation from './password-validator';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { StatesService } from '../../services/states.service';
import { IState } from '../../interfaces/state';
import { environment } from '../../../environments/environment';
import { DealerRegistration } from '../../interfaces/dealer-registration';
import { UserRegistrationRepoService } from '../../services/user-registration.service';
import { map, Observable, Subject } from 'rxjs';
import { OrderByPipe } from '../../common/pipes/order-by.pipe';

@Component({
  selector: 'dealer-registration',
  templateUrl: './dealer-registration.component.html',
  styleUrls: ['./../marketing-styles.css', '../../PortalMain.scss'],
})
export class DealerRegistrationComponent implements OnInit {
  dealerRegForm: FormGroup = new FormGroup({
    dealerType: new FormControl(''),
    companyName: new FormControl(''),
    address: new FormControl(''),
    firstName: new FormControl(''),
    lastName: new FormControl(''),
    city: new FormControl(''),
    state: new FormControl(''),
    zipCode: new FormControl(''),
    phoneNumber: new FormControl(''),
    email: new FormControl(''),
    userName: new FormControl(''),
    password: new FormControl(''),
    confirmPassword: new FormControl(''),
  });

  dealerRegistration: DealerRegistration = new DealerRegistration();
  submitted = false;
  isSubmitting = false;
  errorMessage: string = '';
  showCaptchaError: boolean = true;
  captchaIsValid = false;
  captchaKey = environment.CaptchaKey;

  statesList: IState[] = [];
  statesList$: Observable<IState[]> | undefined;
  inputState$ = new Subject<string>();

  get productLine(): string {
    return this.dealerRegForm.controls['productLine'].value;
  }

  constructor(
    private formBuilder: UntypedFormBuilder,
    private statesService: StatesService,
    private readonly toastr: ToastrService,
    private router: Router,
    private readonly userRegistrationSvc: UserRegistrationRepoService,
    private readonly orderBy: OrderByPipe
  ) {}

  ngOnInit(): void {
    this.dealerRegForm = this.formBuilder.group(
      {
        productLine: ['', [Validators.required]],
        dealerType: ['', null],
        companyName: ['', [Validators.required]],
        address: ['', [Validators.required]],
        firstName: ['', [Validators.required]],
        lastName: ['', [Validators.required]],
        city: ['', [Validators.required]],
        state: ['', [Validators.required]],
        zipCode: ['', [Validators.required]],
        phoneNumber: ['', [Validators.required]],
        email: ['', [Validators.required, Validators.email]],
        userName: ['', [Validators.required]],
        password: [
          '',
          [
            Validators.required,
            Validators.pattern(
              /(?=^.{8,20}$)((?=.*\d)|(?=.*\W+))(?![.\n])(?=.*[A-Z])(?=.*[a-z]).*$/
            ),
          ],
        ],
        confirmPassword: ['', [Validators.required]],
      },
      { validators: [Validation.match('password', 'confirmPassword')] }
    );

    this.statesService.getStatesList().subscribe({
      next: (states) => {
        this.statesList = this.orderBy.transform(states, 'name');

        this.statesList$ = this.inputState$.pipe(
          map((term) => this.searchStates(term))
        );
      },
      error: (err) => (this.errorMessage = err),
    });
  }

  productLineSelect(event: any) {
    const productLine = this.dealerRegForm.get('prodcuctLine');
    if (productLine?.value === 'Irrigation') {
      this.dealerRegForm.get('dealerType')!.setValidators(Validators.required);
    } else {
      this.dealerRegForm.get('dealerType')!.setValidators(null);
    }
    this.dealerRegForm.get('dealerType')!.updateValueAndValidity();
  }
  searchStates(term: string): IState[] {
    const searchTerm = term ? term : '';
    return this.statesList.filter((state) => {
      return state.name.toLowerCase().startsWith(searchTerm.toLowerCase());
    });
  }

  getCaptchaValue(captcha: string) {
    this.captchaIsValid = !(captcha === null || captcha === undefined);
  }

  onSubmit(event: MouseEvent): void {
    this.submitted = true;
    if (!this.captchaIsValid) {
      return;
    }

    if (this.dealerRegForm.invalid) {
      return;
    }

    this.isSubmitting = true;
    (event.target as HTMLButtonElement).disabled = true;

    const newDealerUserRegistration = {
      ...this.dealerRegistration,
      ...this.dealerRegForm.value,
    };

    this.userRegistrationSvc
      .registerDealer(newDealerUserRegistration)
      .subscribe({
        next: () => {
          let registrationSuccessMessage = '';
          (event.target as HTMLButtonElement).disabled = false;
          this.isSubmitting = false;

          if (
            newDealerUserRegistration.dealerType === 'Farm' ||
            newDealerUserRegistration.dealerType === 'Construction'
          ) {
            registrationSuccessMessage = `Your Dealer Registration has been created.
              You will be redirected to the login page shortly. Please use your username/password to log in.`;
          }
          this.toastr.success(
            registrationSuccessMessage,
            'Registration Successfull',
            { enableHtml: true, positionClass: 'toast-top-full-width' }
          );
          this.router.navigate(['/login']);
        },
        error: (err) => {
          (event.target as HTMLButtonElement).disabled = false;
          this.isSubmitting = false;
          this.toastr.error(
            'There was an error during registration: ' + err.error.message,
            'Error',
            { enableHtml: true }
          );
        },
        complete: () => {
          (event.target as HTMLButtonElement).disabled = false;
        },
      });
  }
}
