import { Dialog } from '@angular/cdk/dialog';
import { Injectable } from '@angular/core';
import { DeleteEnvelopeModalComponent } from './delete-envelope-modal.component';

@Injectable({
  providedIn: 'root',
})
export class DeleteEnvelopeModalService {
  modalRef: any;
  constructor(private dialog: Dialog) {}

  display(header: string, message: string) {
    return this.dialog.open<any>(DeleteEnvelopeModalComponent, {
      disableClose: true,
      data: { header: header, message: message },
    }).closed;
  }
}
