import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Validators } from 'angular-reactive-validation';
import { SalesPerson } from '../sales-person.model';
import { Dealer } from '../dealer.model';
import { concat, Observable, of, Subject } from 'rxjs';
import {
  catchError,
  distinctUntilChanged,
  switchMap,
  tap,
} from 'rxjs/operators';
import { DealersRepositoryService } from './dealers-repository.service';
import { ToastrService } from 'ngx-toastr';
import { FormSubmitNotificationService } from 'dfs-credit-application-ngx';
import { ValidatableFormComponent } from 'dfs-credit-application-ngx';
import { FormControl, FormGroup } from '@angular/forms';

@Component({
  selector: 'ca-dealer-seller',
  templateUrl: './dealer-seller.component.html',
  styleUrls: [`../../../../PortalMain.scss`],
})
export class DealerSellerComponent
  extends ValidatableFormComponent
  implements OnInit, OnDestroy
{
  @Input() form!: FormGroup;
  @Input() salesPersons!: SalesPerson[];
  @Input() salesPersonsLoading: boolean = false;

  dealersLoading = false;
  dealers$!: Observable<Dealer[]>;
  dealersInput$ = new Subject<string>();

  get dropdownPlaceholder(): string {
    return this.salesPersonsLoading
      ? 'Salespersons loading'
      : this.salesPersons.length > 0
      ? 'Select Salesperson'
      : 'No items found';
  }
  get salespersonManuallyEnteredControl(): FormControl {
    return this.form.get('salespersonManuallyEntered') as FormControl;
  }

  get dealerManuallyEnteredControl(): FormControl {
    return this.form.get('dealerManuallyEntered') as FormControl;
  }

  constructor(
    private readonly dealersRepo: DealersRepositoryService,
    private readonly toastr: ToastrService,
    submitNotificationService: FormSubmitNotificationService
  ) {
    super(submitNotificationService);
  }

  override ngOnInit() {
    super.ngOnInit();

    this.configureFormValidation();

    this.salespersonManuallyEnteredControl.valueChanges.subscribe({
      next: (val) => {
        this.onsalespersonManuallyEntered(val);
      },
    });

    this.dealerManuallyEnteredControl.valueChanges.subscribe({
      next: (val) => {
        this.ondealerManuallyEntered(val);
      },
    });
  }

  onsalespersonManuallyEntered(value: boolean) {
    if (value) {
      // clear out any selected salesperson value since user indicated they couldn't find it
      this.form.get('salesPerson')!.setValue(null);

      this.dealers$ = concat(
        of([]), // default items
        this.dealersInput$.pipe(
          distinctUntilChanged(),
          tap(() => (this.dealersLoading = true)),
          switchMap((term) =>
            this.dealersRepo
              .get(this.form.get('dealerCity')!.value as string, term)
              .pipe(
                catchError(() => {
                  this.toastr.error(
                    `<a href="mailto:OnlineSupport@dfsfin.com">
                      There was an issue retrieving the dealer search results.
                      If the issue persists, click here to contact Online Support.
                    </a>`,
                    'Error',
                    { enableHtml: true }
                  );
                  return of([]);
                }), // empty list on error
                tap(() => (this.dealersLoading = false))
              )
          )
        )
      );
    } else {
      this.form.get('salespersonName')!.setValue(null);
      this.form.get('dealerCity')!.setValue(null);
      this.form.get('dealer')!.setValue(null);
      this.form.get('dealershipName')!.setValue(null);
      this.form.get('dealerManuallyEntered')!.setValue(false);
    }

    this.configureFormValidation();
  }

  ondealerManuallyEntered(value: boolean) {
    if (value) {
      // clear out any entered dealership name value since user indicated they couldn't find it
      this.form.get('dealer')!.setValue(null);
    } else {
      this.form.get('dealershipName')!.setValue(null);
    }

    this.configureFormValidation();
  }

  private configureFormValidation() {
    if (this.salespersonManuallyEnteredControl.value) {
      this.form.get('salesPerson')!.clearValidators();
      this.form
        .get('dealer')!
        .setValidators([
          Validators.required('Dealer required'),
          Validators.maxLength(
            50,
            (maxLength) => `Maximum length is ${maxLength} characters`
          ),
        ]);
      this.form
        .get('salespersonName')!
        .setValidators([
          Validators.required('Salesperson name required'),
          Validators.maxLength(
            50,
            (maxLength) => `Maximum length is ${maxLength} characters`
          ),
        ]);
    } else {
      this.form.get('dealer')!.clearValidators();
      this.form.get('salespersonName')!.clearValidators();
      this.form
        .get('salesPerson')!
        .setValidators(Validators.required('Salesperson required'));
      this.form.get('dealerManuallyEntered')!.clearValidators();
    }
    if (this.dealerManuallyEnteredControl.value) {
      this.form.get('dealer')!.clearValidators();
      this.form
        .get('dealershipName')!
        .setValidators(Validators.required('Dealership name required'));
    } else {
      this.form.get('dealershipName')!.clearValidators();
    }

    this.form.get('salesPerson')!.updateValueAndValidity();
    this.form.get('dealer')!.updateValueAndValidity();
    this.form.get('dealershipName')!.updateValueAndValidity();
    this.form.get('salespersonName')!.updateValueAndValidity();
  }

  override ngOnDestroy(): void {
    super.ngOnDestroy();
  }
}
