<main>
  <section
    style="
      background-image: url('assets/images/customer-registration-hero.jpg');
      background-size: cover;
      background-position: center;
    "
  >
    <div class="container px-4 py-3"></div>
  </section>
  <div class="container">
    <div class="row py-4">
      <fin-credit-application></fin-credit-application>
    </div>
  </div>
</main>
