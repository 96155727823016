import { HttpClientModule } from '@angular/common/http';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { CurrencyPipe, DatePipe, PercentPipe } from '@angular/common';
import { DialogModule } from '@angular/cdk/dialog';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgxMaskModule } from 'ngx-mask';
import { NgSelectModule } from '@ng-select/ng-select';
import { ToastrModule } from 'ngx-toastr';
import { ButtonsModule } from 'ngx-bootstrap/buttons';
import { RecaptchaModule } from 'ng-recaptcha';

import { TableModule } from 'primeng/table';
import { SplitButtonModule } from 'primeng/splitbutton';
import { MenuModule } from 'primeng/menu';
import { DropdownModule } from 'primeng/dropdown';
import { PasswordModule } from 'primeng/password';
import { InputTextModule } from 'primeng/inputtext';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { InputMaskModule } from 'primeng/inputmask';
import { InputNumber, InputNumberModule } from 'primeng/inputnumber';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HeaderNavComponent } from './header-nav/header-nav.component';
import { FooterComponent } from './footer/footer.component';
import { HomeComponent } from './marketing/home.component';
import { FaqsComponent } from './marketing/faqs.component';
import { AboutComponent } from './marketing/about.component';
import { CareersComponent } from './marketing/careers.component';
import { ContactComponent } from './marketing/contact.component';
import { MetatagsService } from './services/metatags.service';
import { PaymentCalculatorService } from './services/payment-calculator.service';
import { CustomerApplicationComponent } from './marketing/customer-application.component';
import { FinancingComponent } from './marketing/financing-info.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ReactiveValidationModule } from 'angular-reactive-validation';
import { CustomerStartedModule } from './customer-started/customer-started.module';
import { LeaseMatrixProgramsComponent } from './lease-matrix-programs/lease-matrix-programs.component';

import { DfsCommonNgxModule, SpinnerService } from 'dfs-common-ngx';
import { DealerStartedModule } from './dealer-started/dealer-started.module';
import { CustomerFinishModule } from './customer-finish/customer-finish.module';
import { DocumentpreviewModule } from './document-preview/document-preview.module';
import { DfsFinApiModule } from './dfs-fin-api/dfs-fin-api.module';
import { DfsSharedModule } from './shared/dfs-shared.module';

import { BecomeADealerComponent } from './marketing/become-a-dealer.component';
import { LeftSideNavComponent } from './left-side-nav/left-side-nav.component';
import { ApplicationFormsComponent } from './marketing/application-forms.component';
import { LoginPageComponent } from './marketing/login-page.component';
import { JobPostingsComponent } from './job-postings/job-postings.component';
import { JobApplicationComponent } from './job-postings/job-application.component';
import { JobDescriptionModalService } from './marketing/job-description-modal.service';
import { JobApplicationsRepoService } from './services/job-applications-repo.service';
import {
  SelfIdentificationModalService,
  SelfIdentificationModalComponent,
} from './job-postings/self-identification-modal.service';
import { LoanPaymentCalculatorComponent } from './marketing/loan-payment-calculator.component';
import { AlertMessagesRepoService } from './services/alert-messages-repo.service';
import { UnimplementedRouteComponent } from './unimplemented-route/unimplemented-route.component';
import { DealerRegistrationComponent } from './marketing/registration/dealer-registration.component';
import { ForgotPasswordComponent } from './auth/forgot-password.component';
import { ResetPasswordComponent } from './auth/reset-password.component';
import { ResetPasswordDialogComponent } from './auth/reset-password-dialog.component';
import { LoginComponent } from './auth/login.component';
import { CustomerRegistrationComponent } from './marketing/registration/customer-registration.component';
import { DfsContactComponent } from './contact-us/dfs-contact.component';

import { ApplicationArchiveComponent } from './application-archive/application-archive.component';
import { PayOnlineComponent } from './customer-accounts/pay-online.component';
import { PayOnlineUrlService } from './services/pay-online-url.service';
import { ApplicationRatesResolverService } from './services/application-rates-resolver.service';
import { ViewReassignAppModalComponent } from './shared/application-grid/view-reassign-app-modal.component';
import { UserDealerLinkResolverService } from './services/user-dealer-link-resolver.service';
import { ViewCreditAttachmentsModalService } from './dealer-started/wizard-forms/view-credit-attachments-modal.service';
import { DocumentDetailsResolverService } from './services/document-details-resolver.service';
import { EsignatureStatusComponent } from './shared/esignatures/esignature-status.component';
import { DeleteEnvelopeModalComponent } from './shared/esignatures/delete-envelope-modal.component';
import { OrderByPipe } from './common/pipes/order-by.pipe';
import { TrackCapslockDirective } from './common/directives/track-capslock.directive';
import { ContractTermsApiRepoService } from './services/contract-terms-api.service';

@NgModule({
  declarations: [
    AppComponent,
    HeaderNavComponent,
    FooterComponent,
    HomeComponent,
    FaqsComponent,
    AboutComponent,
    CareersComponent,
    ContactComponent,
    CustomerApplicationComponent,
    FinancingComponent,
    BecomeADealerComponent,
    LeftSideNavComponent,
    ApplicationFormsComponent,
    LoginPageComponent,
    ForgotPasswordComponent,
    JobApplicationComponent,
    JobPostingsComponent,
    SelfIdentificationModalComponent,
    LoanPaymentCalculatorComponent,
    UnimplementedRouteComponent,
    DealerRegistrationComponent,
    CustomerRegistrationComponent,
    ResetPasswordComponent,
    ResetPasswordDialogComponent,
    LoginComponent,
    DfsContactComponent,
    LeaseMatrixProgramsComponent,
    ApplicationArchiveComponent,
    PayOnlineComponent,
    ViewReassignAppModalComponent,
    EsignatureStatusComponent,
    DeleteEnvelopeModalComponent,
    TrackCapslockDirective,
  ],
  providers: [
    MetatagsService,
    AlertMessagesRepoService,
    JobDescriptionModalService,
    JobApplicationsRepoService,
    ContractTermsApiRepoService,
    PaymentCalculatorService,
    SelfIdentificationModalService,
    SpinnerService,
    PayOnlineUrlService,
    CurrencyPipe,
    PercentPipe,
    DatePipe,
    ApplicationRatesResolverService,
    DocumentDetailsResolverService,
    UserDealerLinkResolverService,
    ViewCreditAttachmentsModalService,
    OrderByPipe,
  ],
  bootstrap: [AppComponent],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    ReactiveFormsModule,
    ReactiveValidationModule,
    BrowserAnimationsModule,
    CustomerStartedModule,
    DealerStartedModule,
    DocumentpreviewModule,
    CustomerFinishModule,
    NgxMaskModule.forRoot(),
    DialogModule,
    NgSelectModule,
    TableModule,
    SplitButtonModule,
    InputNumberModule,
    InputTextModule,
    PasswordModule,
    InputMaskModule,
    DropdownModule,
    MenuModule,
    ToastrModule.forRoot({ timeOut: 15000 }),
    DfsCommonNgxModule.forRoot({ useAuth: true }),
    ButtonsModule.forRoot(),
    FormsModule,
    RecaptchaModule,
    NgbModule,
    ConfirmDialogModule,
    DfsSharedModule,
    DfsFinApiModule,
  ],
  exports: [],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class AppModule {}
