import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgSelectModule } from '@ng-select/ng-select';
import { DfsCommonNgxModule, DfsFormGroupDirective } from 'dfs-common-ngx';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { DealerStartApplicationComponent } from './dealer-start-app.component';
import { BasicInformationComponent } from './wizard-forms/basic-information.component';
import { ContractTermsComponent } from './wizard-forms/contract-terms/contract-terms.component';
import {
  ViewCreditAttachmentsModalComponent,
  ViewCreditAttachmentsModalService,
} from './wizard-forms/view-credit-attachments-modal.service';
import {
  DealerStartSubmitConfirmationModalService,
  DealerStartSubmitConfirmationModalServiceModalComponent,
} from './dealer-start-submit-confirmation-modal.service';
import { DealerStartedApplicationsRepository } from './dealer-started-applications.repository';
import { DfsCreditApplicationNgxModule } from 'dfs-credit-application-ngx';
import { AccordionModule } from 'ngx-bootstrap/accordion';
import { ReactiveValidationModule } from 'angular-reactive-validation';
import { environment } from 'src/environments/environment';
import { GroupByPipe } from '../common/pipes/group-by.pipe';
import { OrderByPipe } from '../common/pipes/order-by.pipe';
import { DfsSharedModule } from '../shared/dfs-shared.module';

@NgModule({
  declarations: [
    DealerStartApplicationComponent,
    BasicInformationComponent,
    ContractTermsComponent,
    ViewCreditAttachmentsModalComponent,
    DealerStartSubmitConfirmationModalServiceModalComponent,
  ],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    FormsModule,
    DfsCommonNgxModule.forRoot({ useAuth: true }),
    DfsCreditApplicationNgxModule.forRoot(environment),
    HttpClientModule,
    NgSelectModule,
    AccordionModule.forRoot(),
    ReactiveValidationModule,
    DfsSharedModule,
  ],
  providers: [
    DealerStartedApplicationsRepository,
    ViewCreditAttachmentsModalService,
    DealerStartSubmitConfirmationModalService,
    DfsFormGroupDirective,
    GroupByPipe,
    OrderByPipe,
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class DealerStartedModule {}
