<main>
  <section
    style="
      background-image: url('assets/images/dealer-registration-hero.jpg');
      background-size: cover;
      background-position: center;
    "
  >
    <div class="container px-4 py-5">
      <div class="row align-items-start g-lg-5 py-5">
        <div class="col-lg-10 text-lg-start text-light">
          <h1 class="display-4 lh-1 mb-3 py-4">Dealer Registration</h1>
        </div>
      </div>
    </div>
  </section>

  <div class="container">
    <div class="row px-4 py-4 mt-5 text-center">
      <p class="display-5 mb-4">Register for Online Access</p>
      <div class="col-lg-10 mx-auto"></div>
    </div>
  </div>

  <div class="container px-4 mb-5">
    <div class="row g-5">
      <div class="col-12">
        <form class="card p-4 fs-6" [formGroup]="dealerRegForm">
          <div class="row gx-5 gy-4">
            <div class="col-12">
              <label for="productLine" class="form-label"
                >Select Dealer Type<span class="text-danger">*</span></label
              >
              <select
                id="productLine"
                class="form-select fs-6"
                aria-label="Dealer Type Select"
                formControlName="productLine"
                (click)="productLineSelect($event)"
                [ngClass]="{
                  'is-invalid':
                    (submitted ||
                      dealerRegForm.controls['productLine'].touched) &&
                    dealerRegForm.controls['productLine'].errors
                }"
                required
              >
                <option selected disabled value="">Choose...</option>
                <option value="Irrigation">Irrigation Equipment</option>
                <option value="Farm">Farm Equipment</option>
                <option value="Construction">Construction Equipment</option>
              </select>
              <div
                *ngIf="
                  (submitted ||
                    dealerRegForm.controls['productLine'].touched) &&
                  dealerRegForm.controls['productLine'].errors
                "
                class="invalid-feedback"
              >
                <div
                  *ngIf="
                    dealerRegForm.controls['productLine'].errors['required']
                  "
                >
                  Dealer type is Required
                </div>
              </div>
            </div>
            <div class="col-12" *ngIf="productLine === 'Irrigation'">
              <label for="dealerType" class="form-label"
                >Select Manufacturer<span class="text-danger">*</span></label
              >
              <select
                id="dealerType"
                class="form-select fs-6"
                aria-label="Dealer Type Select"
                formControlName="dealerType"
                [ngClass]="{
                  'is-invalid':
                    (submitted ||
                      dealerRegForm.controls['dealerType'].touched) &&
                    dealerRegForm.controls['dealerType'].errors
                }"
                required
              >
                <option selected disabled value="">Choose...</option>
                <option value="Valley">Valley</option>
                <option value="Reinke">Reinke</option>
                <option value="Zimmatic">Zimmatic</option>
                <option value="Irrigation General">Other</option>
              </select>
              <div
                *ngIf="
                  (submitted || dealerRegForm.controls['dealerType'].touched) &&
                  dealerRegForm.controls['dealerType'].errors
                "
                class="invalid-feedback"
              >
                <div
                  *ngIf="
                    dealerRegForm.controls['dealerType'].errors['required']
                  "
                >
                  Manufacturer is Required
                </div>
              </div>
            </div>
            <div class="col-md-6">
              <label for="firstName" class="form-label"
                >First Name<span class="text-danger">*</span></label
              >
              <input
                type="text"
                class="form-control fs-6"
                id="firstName"
                formControlName="firstName"
                [ngClass]="{
                  'is-invalid':
                    (submitted ||
                      dealerRegForm.controls['firstName'].touched) &&
                    dealerRegForm.controls['firstName'].errors
                }"
                required
              />
              <div
                *ngIf="
                  (submitted || dealerRegForm.controls['firstName'].touched) &&
                  dealerRegForm.controls['firstName'].errors
                "
                class="invalid-feedback"
              >
                <div
                  *ngIf="dealerRegForm.controls['firstName'].errors['required']"
                >
                  First Name is required
                </div>
              </div>
            </div>
            <div class="col-md-6">
              <label for="lastName" class="form-label"
                >Last Name<span class="text-danger">*</span></label
              >
              <input
                type="text"
                class="form-control fs-6"
                id="lastName"
                formControlName="lastName"
                [ngClass]="{
                  'is-invalid':
                    (submitted || dealerRegForm.controls['lastName'].touched) &&
                    dealerRegForm.controls['lastName'].errors
                }"
                required
              />
              <div
                *ngIf="
                  (submitted || dealerRegForm.controls['lastName'].touched) &&
                  dealerRegForm.controls['lastName'].errors
                "
                class="invalid-feedback"
              >
                <div
                  *ngIf="dealerRegForm.controls['lastName'].errors['required']"
                >
                  Last Name is required
                </div>
              </div>
            </div>
            <div class="col-12">
              <label for="companyName"
                >Company <span class="text-danger">*</span></label
              >
              <input
                class="form-control fs-6"
                id="companyName"
                formControlName="companyName"
                [ngClass]="{
                  'is-invalid':
                    (submitted ||
                      dealerRegForm.controls['companyName'].touched) &&
                    dealerRegForm.controls['companyName'].errors
                }"
              />
              <div
                *ngIf="
                  (submitted ||
                    dealerRegForm.controls['companyName'].touched) &&
                  dealerRegForm.controls['companyName'].errors
                "
                class="invalid-feedback"
              >
                <div
                  *ngIf="
                    dealerRegForm.controls['companyName'].errors['required']
                  "
                >
                  Company Name is required
                </div>
              </div>
            </div>
            <div class="col-12">
              <label for="address"
                >Company Address<span class="text-danger">*</span></label
              >
              <input
                class="form-control fs-6"
                id="address"
                formControlName="address"
                [ngClass]="{
                  'is-invalid':
                    (submitted || dealerRegForm.controls['address'].touched) &&
                    dealerRegForm.controls['address'].errors
                }"
              />
              <div
                *ngIf="
                  (submitted || dealerRegForm.controls['address'].touched) &&
                  dealerRegForm.controls['address'].errors
                "
                class="invalid-feedback"
              >
                <div
                  *ngIf="dealerRegForm.controls['address'].errors['required']"
                >
                  Company Name is required
                </div>
              </div>
            </div>
            <div class="col-md-4">
              <label for="city" class="form-label"
                >City<span class="text-danger">*</span></label
              >
              <input
                type="text"
                class="form-control fs-6"
                id="city"
                formControlName="city"
                [ngClass]="{
                  'is-invalid':
                    (submitted || dealerRegForm.controls['city'].touched) &&
                    dealerRegForm.controls['city'].errors
                }"
              />
              <div
                *ngIf="
                  (submitted || dealerRegForm.controls['city'].touched) &&
                  dealerRegForm.controls['city'].errors
                "
                class="invalid-feedback"
              >
                <div *ngIf="dealerRegForm.controls['city'].errors['required']">
                  City is required
                </div>
              </div>
            </div>
            <div class="col-md-4">
              <label for="state" class="form-label"
                >State<span class="text-danger">*</span></label
              >
              <ng-select
                id="state"
                class="form-control fs-6"
                [items]="statesList$ | async"
                bindLabel="name"
                bindValue="code"
                [selectOnTab]="true"
                formControlName="state"
                [typeahead]="inputState$"
                [ngClass]="{
                  'is-invalid':
                    (submitted || dealerRegForm.controls['state'].touched) &&
                    dealerRegForm.controls['state'].errors
                }"
              >
              </ng-select>
              <div
                *ngIf="
                  (submitted || dealerRegForm.controls['state'].touched) &&
                  dealerRegForm.controls['state'].errors
                "
                class="invalid-feedback"
              >
                <div *ngIf="dealerRegForm.controls['state'].errors['required']">
                  State is required
                </div>
              </div>
            </div>
            <div class="col-md-4">
              <label for="zipCode" class="form-label"
                >Zip<span class="text-danger">*</span></label
              >
              <input
                type="text"
                class="form-control fs-6"
                id="zipCode"
                formControlName="zipCode"
                [ngClass]="{
                  'is-invalid':
                    (submitted || dealerRegForm.controls['zipCode'].touched) &&
                    dealerRegForm.controls['zipCode'].errors
                }"
              />
              <div
                *ngIf="
                  (submitted || dealerRegForm.controls['zipCode'].touched) &&
                  dealerRegForm.controls['zipCode'].errors
                "
                class="invalid-feedback"
              >
                <div
                  *ngIf="dealerRegForm.controls['zipCode'].errors['required']"
                >
                  Zip is required
                </div>
              </div>
            </div>
            <div class="col-md-6">
              <label for="phoneNumber" class="form-label"
                >Phone Number<span class="text-danger">*</span></label
              >
              <input
                type="text"
                class="form-control fs-6"
                id="phoneNumber"
                mask="(000) 000-0000"
                formControlName="phoneNumber"
                [ngClass]="{
                  'is-invalid':
                    (submitted ||
                      dealerRegForm.controls['phoneNumber'].touched) &&
                    dealerRegForm.controls['phoneNumber'].errors
                }"
                required
              />
              <div
                *ngIf="
                  (submitted ||
                    dealerRegForm.controls['phoneNumber'].touched) &&
                  dealerRegForm.controls['phoneNumber'].errors
                "
                class="invalid-feedback"
              >
                <div
                  *ngIf="
                    dealerRegForm.controls['phoneNumber'].errors['required']
                  "
                >
                  Phone Number is required
                </div>
              </div>
            </div>
            <div class="col-md-6">
              <label for="email" class="form-label required"
                >Email address<span class="text-danger">*</span></label
              >
              <input
                type="email"
                class="form-control fs-6"
                id="email"
                formControlName="email"
                [ngClass]="{
                  'is-invalid':
                    (submitted || dealerRegForm.controls['email'].touched) &&
                    dealerRegForm.controls['email'].errors
                }"
                required
              />
              <div
                *ngIf="
                  (submitted || dealerRegForm.controls['email'].touched) &&
                  dealerRegForm.controls['email'].errors
                "
                class="invalid-feedback"
              >
                <div *ngIf="dealerRegForm.controls['email'].errors['required']">
                  Email is required
                </div>
                <div *ngIf="dealerRegForm.controls['email'].errors['email']">
                  Enter a valid email
                </div>
              </div>
            </div>

            <div class="col-12">
              <label for="userName" class="form-label"
                >Login Username<span class="text-danger">*</span></label
              >
              <input
                type="text"
                class="form-control fs-6"
                id="userName"
                formControlName="userName"
                [ngClass]="{
                  'is-invalid':
                    (submitted || dealerRegForm.controls['userName'].touched) &&
                    dealerRegForm.controls['userName'].errors
                }"
              />
              <div
                *ngIf="
                  (submitted || dealerRegForm.controls['userName'].touched) &&
                  dealerRegForm.controls['userName'].errors
                "
                class="invalid-feedback"
              >
                <div
                  *ngIf="dealerRegForm.controls['userName'].errors['required']"
                >
                  UserName is required
                </div>
              </div>
            </div>
            <div class="col-md-6">
              <label for="password" class="form-label w-100"
                >Password<span class="text-danger">*</span></label
              >
              <p-password
                id="password"
                [toggleMask]="true"
                required="true"
                formControlName="password"
                class="w-100"
                [ngClass]="{
                  'is-invalid':
                    (submitted || dealerRegForm.controls['password'].touched) &&
                    dealerRegForm.controls['password'].errors
                }"
              >
                <ng-template pTemplate="header">
                  <h6>Pick a password</h6>
                </ng-template>
                <ng-template pTemplate="footer">
                  <p-divider></p-divider>
                  <p class="mt-2">Password Requirements:</p>
                  <ul class="pl-2 ml-2 mt-0" style="line-height: 1.5">
                    <li>At least one lowercase</li>
                    <li>At least one uppercase</li>
                    <li>At least one numeric or special character</li>
                    <li>Must contain between 8 and 20 characters</li>
                  </ul>
                </ng-template>
              </p-password>
              <div
                *ngIf="
                  (submitted || dealerRegForm.controls['password'].touched) &&
                  dealerRegForm.controls['password'].errors
                "
                class="invalid-feedback"
              >
                <div
                  *ngIf="dealerRegForm.controls['password'].errors['required']"
                >
                  Password is required
                </div>
                <div
                  *ngIf="dealerRegForm.controls['password'].errors['pattern']"
                >
                  Passwords must contain between 8 and 20 characters, both lower
                  and uppercase letters, and at least one number or special
                  character
                </div>
              </div>
            </div>
            <div class="col-md-6">
              <label for="confirmPassword" class="form-label w-100"
                >Confirm Password<span class="text-danger">*</span></label
              >
              <p-password
                id="confirmPassword"
                [toggleMask]="true"
                required="true"
                formControlName="confirmPassword"
                [feedback]="false"
                class="w-100"
                [ngClass]="{
                  'is-invalid':
                    (submitted ||
                      dealerRegForm.controls['confirmPassword'].touched) &&
                    dealerRegForm.controls['confirmPassword'].errors
                }"
              >
              </p-password>
              <div
                *ngIf="
                  (submitted ||
                    dealerRegForm.controls['confirmPassword'].touched) &&
                  dealerRegForm.controls['confirmPassword'].errors
                "
                class="invalid-feedback"
              >
                <div
                  *ngIf="
                    dealerRegForm.controls['confirmPassword'].errors['required']
                  "
                >
                  Confirm Password is required
                </div>
                <div
                  *ngIf="
                    dealerRegForm.controls['confirmPassword'].errors['matching']
                  "
                >
                  Confirm Password does not match
                </div>
              </div>
            </div>
            <div class="col-12">
              <re-captcha
                class="form-control mb-2 fs-6"
                class="mb-2 fs-6"
                (resolved)="getCaptchaValue($event)"
                siteKey="{{ captchaKey }}"
              ></re-captcha>
              <p class="text-danger" *ngIf="submitted && !captchaIsValid">
                Please validate you are not a robot before submitting:
              </p>
            </div>
            <div class="col-12">
              <button
                class="btn btn-primary mt-2"
                type="submit"
                (click)="onSubmit($event)"
              >
                <span
                  *ngIf="isSubmitting"
                  class="spinner-border spinner-border-sm"
                  role="status"
                  aria-hidden="true"
                ></span>
                Submit
              </button>
            </div>
          </div>
          <div
            *ngIf="dealerRegForm.invalid && submitted"
            class="text-danger"
            [ngClass]="{
              'is-invalid': dealerRegForm.invalid && submitted
            }"
          >
            Please correct errors and resubmit
          </div>
        </form>
      </div>
    </div>
  </div>
</main>
