<form
  [formGroup]="form"
  (submit)="userLogin()"
  autocomplete="off"
  class="p-4 p-md-5 shadow bg-light h-100"
>
  <small class="fw-semibold">Login</small>
  <div class="text-danger" *ngIf="showLoginError">{{ loginError }}</div>
  <div>
    <input
      type="text"
      formControlName="username"
      class="w-100 h-100"
      id="username"
      placeholder="Login"
      pInputText
      [ngClass]="{ 'is-invalid': submitted && theForm['username'].errors }"
    />
    <div
      *ngIf="submitted && theForm['username'].errors"
      class="invalid-feedback"
    >
      <div *ngIf="theForm['username'].errors['required']">
        Username is required
      </div>
    </div>
  </div>
  <label class="text-info" style="font-style: italic" *ngIf="capsOn"
    >Caps Locked</label
  >
  <div (capsLock)="capsOn = $event">
    <p-password
      id="password"
      [toggleMask]="true"
      placeholder="Password"
      formControlName="password"
      [feedback]="false"
      class="w-100 h-100"
      [ngClass]="{ 'is-invalid': submitted && theForm['password'].errors }"
    >
    </p-password>
    <div
      *ngIf="submitted && theForm['password'].errors"
      class="invalid-feedback"
    >
      <div *ngIf="theForm['password'].errors['required']">
        Password is required
      </div>
    </div>
  </div>
  <div class="text-end mb-3">
    <a class="text-info text-decoration-none" [routerLink]="['/forgotpassword']"
      >Forgot Password?</a
    >
  </div>

  <button class="w-100 btn btn-primary" [disabled]="loading" type="submit">
    <span *ngIf="loading" class="spinner-border spinner-border-sm me-1"></span>
    Login
  </button>
  <hr class="my-4" />

  <div class="dropdown">
    <a
      class="dropdown-toggle text-dark text-decoration-none"
      href="#"
      role="button"
      data-bs-toggle="dropdown"
      aria-expanded="false"
      style="font-weight: bold; font-size: large"
    >
      New to DFS Finance? Register
    </a>

    <ul class="dropdown-menu">
      <li>
        <a class="dropdown-item" [routerLink]="['/customerRegistration']"
          >I am a Customer</a
        >
      </li>
      <li>
        <a class="dropdown-item" [routerLink]="['/dealerRegistration']"
          >I am a Dealer</a
        >
      </li>
    </ul>
  </div>
</form>
