import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { CreditApplicationAttachmentItem } from './wizard-forms/credit-attachment.model';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({ providedIn: 'root' })
export class DealerStartedApplicationAttachmentRepository {
  constructor(private httpClient: HttpClient) {}
  getOne(appId: number, attachmentId: number): Observable<any> {
    const url = `${environment.ApiBaseUrl}/users/credit-applications/${appId}/attachments/${attachmentId}`;
    return this.httpClient.get<any>(url);
  }
  getAll(
    appId: number
  ): Observable<CreditApplicationAttachmentItem[]> {
    const url = `${environment.ApiBaseUrl}/users/credit-applications/${appId}/attachments`;
    return this.httpClient.get<CreditApplicationAttachmentItem[]>(url);
  }
  submit(appId: number, attachments: FormData): Observable<number> {
    const url = `${environment.ApiBaseUrl}/customer-credit-applications/${appId}/attachments`;
    return this.httpClient.post<number>(url, attachments);
  }
}
