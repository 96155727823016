<div class="dfs-modal-dialog dfs-modal-content">
  <div class="dfs-modal-header">
    <h4 class="dfs-modal-title">Application Attachments</h4>
    <button
      type="button"
      class="dfs-modal-close-button"
      (click)="close()"
      aria-hidden="true"
    >
      &times;
    </button>
  </div>
  <div class="dfs-modal-body">
    <div class="well" *ngIf="!attachments?.length">
      No attachments found for this application.
    </div>
    <table *ngIf="attachments?.length" class="table table-striped table-hover">
      <thead>
        <tr>
          <th>File Name</th>
          <th>Description</th>
          <th></th>
          <th></th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let attach of attachments">
          <td>{{ attach.fileName }}</td>
          <td>{{ attach.description }}</td>
          <td>
            <button
              class="dfs-btn dfs-btn-primary dfs-btn-grid"
              type="button"
              (click)="download(attach)"
            >
              Download
              <!-- <span class="fa-solid fa-download"></span> -->
            </button>
          </td>
          <td>
            <button
              class="dfs-btn dfs-btn-danger dfs-btn-grid"
              type="button"
              (click)="delete(attach)"
            >
              Delete
              <!-- <span class="fa-solid fa-xmark"></span> -->
            </button>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
  <div class="dfs-modal-footer">
    <button type="button" class="dfs-btn dfs-btn-primary" (click)="close()">
      Close
    </button>
  </div>
</div>
