import { Observable} from 'rxjs';
import { Injectable } from '@angular/core';
import { UserDealerLink } from '../dfs-fin-api/entities/user-dealer-link.model';
import { UserDealerLinkRepo } from '../dfs-fin-api/UserDealerLinkRepo';


@Injectable({ providedIn: 'root' })
export class UserDealerLinkResolverService {

  constructor(
    private readonly userDealerLinkRepo: UserDealerLinkRepo,
  ) {}

  resolve(): Observable<UserDealerLink[]> {
    return this.userDealerLinkRepo.getUserDealerLinks();
  }
}
