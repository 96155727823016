import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { Validators } from 'angular-reactive-validation';

import { LoginService } from '../services/login.service';

@Component({
  selector: 'fin-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['../marketing/marketing-styles.css'],
})
export class ForgotPasswordComponent implements OnInit {
  form!: FormGroup;
  loading = false;
  submitted = false;
  submittedMsg: string|undefined;
  error = false;

  constructor(
    private readonly loginService: LoginService,
    private formBuilder: FormBuilder
  ) {}

  ngOnInit() {
    this.form = this.formBuilder.group({
      username: ['', Validators.required],
    });
  }

  // convenience getter for easy access to form fields
  get theForm() {
    return this.form.controls;
  }
  resetPassword() {
    this.submitted = true;
    // stop here if form is invalid
    if (this.form.invalid) {
      return;
    }
    this.loading = true;
    this.error = false;
    this.submittedMsg = undefined;

    const username = this.theForm['username'].value;
    this.loginService.forgotPassword(username).subscribe({
      next: result => {
        this.loading = false;
        this.submittedMsg = result;
      },
      error: error => {
        this.loading = false;
        this.error = true;
        this.submitted = false;
        console.log('Error calling forgotPassword', error);
      }
    });
  }
}
