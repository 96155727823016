export class ContactUs {
  reason: string = '';
  fullName: string = '';
  address: string = '';
  city: string = '';
  state: string = '';
  zip: string = '';
  contractNumber: string = '';
  phoneNumber: string = '';
  email: string = '';
  comments: string = '';
}
