import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { FilingFee } from 'dfs-common-ngx/lib/api-entities/filing-fee.model';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class FilingFeesService {
  private: string = environment.ContractTermsApiBaseUrl;
  constructor(private http: HttpClient) {}

  getFeeByProductLine(productLineId: number): Observable<FilingFee> {
    return this.http.get<any>(
      `${environment.ContractTermsApiBaseUrl}/filing-fees/product-line/${productLineId}`
    );
  }

  getFeeByProductLineForTerm(
    productLineId: number,
    contractTermsId: string
  ): Observable<FilingFee> {
    return this.http.get<any>(
      `${environment.ContractTermsApiBaseUrl}/filing-fees/product-line/${productLineId}/${contractTermsId}`
    );
  }
}
