import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DocumentsRepo } from 'src/app/dfs-fin-api/DocumentsRepo';
import { DocumentDetails } from 'src/app/dfs-fin-api/entities/DocumentDetails';
import { SigningLocation } from 'src/app/shared/models/signing-location';
import { FileSaverService } from 'ngx-filesaver';
import { ToastrService } from 'ngx-toastr';
import { RecipientList } from 'src/app/shared/models/recipient-list';
import { EsignatureRecipientRepository } from '../esignature-recipient.repository';
import { EsignatureModalRecipientService } from '../esignature-modal/esignature-modal-recipient.service';

@Component({
  selector: 'fin-document-preview',
  templateUrl: './document-preview.component.html',
  styleUrls: ['./document-preview.component.scss'],
})
export class DocumentPreviewComponent {
  docDetails!: DocumentDetails;
  fileSaveInfo: any;
  docsGenerated: boolean = false;
  docPreviewTitle: string = '';
  signingLocation = SigningLocation;
  appId: number;
  voidedByAdmin: Boolean = false;

  constructor(
    private readonly route: ActivatedRoute,
    private readonly documentsRepo: DocumentsRepo,
    private readonly fileSaverService: FileSaverService,
    private readonly toastr: ToastrService,
    private readonly router: Router,
    private readonly esigRecipientRepo: EsignatureRecipientRepository,
    private readonly esigModalRecipientSvc: EsignatureModalRecipientService
  ) {
    this.appId = Number(this.route.snapshot.params['id']);
  }

  ngOnInit(): void {
    this.route.data.subscribe((data: any) => {
      this.docDetails = data.documentDetails;
      this.voidedByAdmin = this.isVoidedByAdmin();
    });
    this.generate();
  }

  generate() {
    this.documentsRepo.generate(this.docDetails).subscribe({
      next: (file) => {
        if (file.file != null && file.fileName === 'NoDocumentsFound') {
          this.toastr.error(`No document data found.`, 'Error');
        } else {
          this.fileSaveInfo = file;
          this.docsGenerated = true;
          this.docPreviewTitle = 'Document Preview';
        }
      },
      error: (error) => {
        this.toastr.error(
          `There was an error generating your documents.`,
          'Error'
        );
      },
    });
  }

  printDocuments() {
    this.sendDocsEmail();
    this.fileSaverService.save(
      this.fileSaveInfo.file,
      this.fileSaveInfo.fileName
    );
  }

  //user doesn't need to know about failed/success emails
  sendDocsEmail() {
    this.documentsRepo
      .sendAttachmentsEmail(
        this.docDetails.remoteApplicationNumber,
        this.docDetails.customerDetails.fullName,
        this.docDetails.customerDetails.type,
        this.fileSaveInfo
      )
      .subscribe({
        next: () => {},
        error: () => {},
      });
    this.router.navigate(['creditApplication']);
  }

  generateEsignatureRecipients(signingLocation: SigningLocation) {
    const recipientList: RecipientList = {
      signingLocation: signingLocation,
      recipients: [],
    };

    this.esigRecipientRepo
      .create(this.docDetails.remoteApplicationNumber, recipientList)
      .subscribe({
        next: (result) => {
          this.esigModalRecipientSvc
            .show(this.appId, result)
            ?.subscribe((content) => {
              if (content == 'Submit') {
                this.sendDocsEmail();
              }
            });
        },
        error: () => {
          this.toastr.error(
            `There was an issue starting the E-Signature process. If the issue persists, contact your Credit Manager for assistance.`,
            'Issue Generating Recipients'
          );
        },
      });
  }

  isVoidedByAdmin(): boolean {
    const voidedByAdminDescription = 'VoidedByAdmin';
    return this.docDetails.esignatureEnvelopeStatus == voidedByAdminDescription;
  }
}
