import { ContractTypeEnum } from 'dfs-credit-application-ngx';
import { ApplicationRate } from '../interfaces/ApplicationRate';

export class ApplicationTermsService {
  getRateForTerms(
    workingRates: ApplicationRate[] | undefined,
    programTypeId: number,
    term: number,
    amountToFinance: number
  ): number | null {
    var termYears = term / 12;

    var ratesByProgramType = workingRates?.filter(
      (r) => r.programTypeId === programTypeId && r.termYears === termYears
    );

    var ratesByRange = ratesByProgramType?.filter(
      (r) =>
        r.lowerBoundInclusive <= amountToFinance &&
        (!r.upperBoundExclusive || r.upperBoundExclusive >= amountToFinance)
    );
    if (ratesByRange === undefined || ratesByRange?.length === 0) {
      return null;
    }

    const defaultRate = Number(ratesByRange[0].value * 100).toFixed(2);

    return Number(defaultRate);
  }

  getContractTermRateTypeId(
    applicationContractTypeId: number
  ): number | undefined {
    switch (Number(applicationContractTypeId)) {
      case ContractTypeEnum.ContractFixed:
      case ContractTypeEnum.LeaseLP:
      case ContractTypeEnum.LeaseTL: {
        return 1; //Fixed
      }
      case ContractTypeEnum.ContractVariable: {
        return 2; //Variable
      }
      default: {
        return undefined;
      }
    }
  }

  getContractTermsContractTypeId(
    applicationContractTypeId: number
  ): number | undefined {
    switch (applicationContractTypeId) {
      case ContractTypeEnum.ContractFixed:
      case ContractTypeEnum.ContractVariable: {
        return 1; //Loan
      }
      case ContractTypeEnum.LeaseLP:
      case ContractTypeEnum.LeaseTL: {
        return 2; //Lease
      }
      default: {
        return undefined;
      }
    }
  }

  getDistinctTermsFromRates(
    applicationRates: ApplicationRate[] | undefined
  ): number[] {
    return (
      (applicationRates ?? [])
        .map((rate: { termYears: number }) => rate.termYears * 12) //convert term years to months
        .reduce(
          (distinct: number[], item: number) =>
            !distinct.includes(item) ? [...distinct, item] : distinct,
          []
        )
        .sort() ?? []
    );
  }

  getWorkingRates(
    rates: ApplicationRate[],
    contractTypeId: number
  ): ApplicationRate[] {
    const rateTypeId = this.getContractTermRateTypeId(contractTypeId);
    const contractTermsContractTypeId =
      this.getContractTermsContractTypeId(contractTypeId);
    return rates?.filter(
      (r) =>
        r.rateTypeId === rateTypeId &&
        r.contractTypeId === contractTermsContractTypeId
    );
  }
}
