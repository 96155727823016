import { ActivatedRouteSnapshot, Resolve, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { CurrentUserService, Dealer, ContractTerms } from 'dfs-common-ngx';
import { DealerContractTermsRepo } from '../dfs-fin-api/DealerContractTermsRepo';
import { ApplicationRate } from '../interfaces/ApplicationRate';

@Injectable({ providedIn: 'root' })
export class ApplicationRatesResolverService {
  dealer: Dealer;

  constructor(
    private readonly userSvc: CurrentUserService,
    private readonly dealerContractTermsRepo: DealerContractTermsRepo
  ) {
    this.dealer = this.userSvc.profile as Dealer;
  }

  resolve(route: ActivatedRouteSnapshot): Observable<ApplicationRate[]> {
    var docDetails = route.parent?.data['docDetails'];

    if (docDetails) {
      var contractTermsId = docDetails.contractTermsId;
      return this.dealerContractTermsRepo.getByTermsId(
        this.dealer.dealerCode,
        contractTermsId,
        this.dealer.dealerTypeId
      );
    } else {
      return this.dealerContractTermsRepo.getApplicationRates(
        this.dealer.dealerCode,
        this.dealer.dealerTypeId
      );
    }
  }
}
