<div class="panel-body">
  <form [formGroup]="form" #formDirective="ngForm">
    <div class="row">
      <div class="col-md-12">
        <div form-control-group>
          <label for="comments"
            >Comments (please note any specifics on equipment, attachments,
            customer needs, special requests, etc.)</label
          >
        </div>
      </div>
      <div class="col-md-12">
        <div form-control-group>
          <textarea
            id="comments"
            name="comments"
            class="col-md-12"
            formControlName="comments"
          ></textarea>
        </div>
      </div>
    </div>
    <br />
    <div class="row">
      <div class="col-md-12">
        <p>Note:</p>
        <p>* Minimum amount financed is $10,000</p>
        <p>
          * Complete financial statements are required on transactions over
          <span
            *ngIf="
              equipmentType == equipmentTypes.farm ||
              equipmentType == equipmentTypes.commercial
            "
            >$500,000</span
          >
          <span *ngIf="equipmentType == equipmentTypes.irrigation"
            >$350,000</span
          >
        </p>
        <p>
          * Tax returns, income statements or three years balance sheets will be
          required on all outstanding balances over

          <span
            *ngIf="
              equipmentType == equipmentTypes.farm ||
              equipmentType == equipmentTypes.commercial
            "
            >$650,000</span
          >
          <span *ngIf="equipmentType == equipmentTypes.irrigation"
            >$450,000</span
          >
        </p>
      </div>
    </div>
    <div>
      <em class="text-info"
        >Attachments above 8MB will not be saved or submitted with the
        application.</em
      >
    </div>
    <div class="form-group" *ngIf="sellerType !== sellerTypes.dealerStart">
      <fin-file-upload
        class="mb-3"
        [buttonText]="licenseText"
        [uploadType]="'DRIVERSLICENSE'"
        (attachFileUpload)="attachFile($event)"
      ></fin-file-upload>
    </div>

    <div
      *ngIf="
        equipmentType == equipmentTypes.farm ||
        equipmentType == equipmentTypes.commercial
      "
    >
      <div class="form-group" *ngIf="sellerType === sellerTypes.dealerStart">
        <fin-file-upload
          class="mb-3"
          [buttonText]="'Invoice'"
          [uploadType]="'SALESINVOICE'"
          (attachFileUpload)="attachFile($event)"
        ></fin-file-upload>
      </div>
      <div
        class="form-group"
        *ngIf="sellerType !== sellerTypes.dealerStart"
        style="margin-top: 0.5rem"
      >
        <fin-file-upload
          class="mb-3"
          [buttonText]="'Financial Statement'"
          [uploadType]="'FINANCIALSTATEMENT'"
          (attachFileUpload)="attachFile($event)"
        ></fin-file-upload>
      </div>
    </div>
    <div *ngIf="equipmentType == equipmentTypes.irrigation">
      <div class="form-group add-space">
        <fin-file-upload
          class="mb-3"
          [buttonText]="'Sales Proposal'"
          [uploadType]="'SALESPROPOSAL'"
          (attachFileUpload)="attachFile($event)"
        ></fin-file-upload>
      </div>
    </div>

    <fin-credit-report-authorization
      *ngIf="sellerType !== sellerTypes.dealerStart"
      [form]="form"
      (creditReportAuthorizedChange)="creditReportAuthorizationChangeInSubmit($event)"
    ></fin-credit-report-authorization>
    <div
      *ngIf="
        sellerType !== sellerTypes.dealerStart &&
        sellerType !== sellerTypes.customerFinish
      "
    >
      <p class="text-danger" *ngIf="showCaptchaError">
        Please validate you are not a robot before submitting:
      </p>
      <re-captcha
        (resolved)="resolved($event)"
        siteKey="{{ captchaKey }}"
      ></re-captcha>
    </div>
  </form>
</div>
