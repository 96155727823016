import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'fin-file-upload',
  templateUrl: './file-upload.component.html',
  styles: [
    `
      .file-input {
        display: none;
      }
    `,
  ],
})
export class FileUploadComponent {
  @Input() buttonText!: string;
  @Input() uploadType!: string;
  @Output() attachFileUpload = new EventEmitter<any>();

  fileAdded: boolean = false;
  fileName?: string;

  selectFile(event: any) {
    const file: File = event.target.files[0];
    if (file) {
      this.fileName = file.name;
      const fileUpload = { fileType: this.uploadType, file: file };
      this.attachFileUpload.emit(fileUpload);
      this.fileAdded = true;
    }
  }
}
