import { Component } from '@angular/core';

@Component({
  selector: 'fin-application-archive',
  templateUrl: './application-archive.component.html',
  styleUrls: ['../PortalMain.scss'],
})
export class ApplicationArchiveComponent {
  statusTypes: string[] = [
    'Complete',
    'Cancelled',
    'Rejected',
    'Booked',
    'PendingBooking',
    'Declined',
  ];
}
