import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { SalesPerson } from '../sales-person.model';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({ providedIn: 'root' })
export class SalesPersonsRepositoryService {
  constructor(private httpClient: HttpClient) {}

  get(): Observable<SalesPerson[]> {
    const url = `${environment.ApiBaseUrl}/sales-persons`;
    return this.httpClient.get<SalesPerson[]>(url);
  }
}
