import * as CryptoJS from 'crypto-js';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class EncryptionService {
  constructor() {}
  public encryptValue(value: string): string {
    let cryptoKey = CryptoJS.enc.Utf8.parse(environment.DfsCryptKey);
    let cryptoiv = CryptoJS.enc.Utf8.parse(environment.DfsCryptIv);

    let encrypted = CryptoJS.AES.encrypt(
      CryptoJS.enc.Utf8.parse(value),
      cryptoKey,
      {
        keySize: 128 / 8,
        iv: cryptoiv,
        mode: CryptoJS.mode.CBC,
        padding: CryptoJS.pad.Pkcs7,
      }
    );

    return encrypted.toString();
  }
}
