import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Validators } from 'angular-reactive-validation';
import {
  ValidatableFormComponent,
  FormSubmitNotificationService,
} from 'dfs-credit-application-ngx';
import { FormGroup } from '@angular/forms';
import { StatesService } from 'src/app/services/states.service';

@Component({
  selector: 'ca-private-seller',
  templateUrl: 'private-seller.component.html',
  styleUrls: [`../../../PortalMain.scss`],
})
export class PrivateSellerComponent
  extends ValidatableFormComponent
  implements OnInit, OnDestroy
{
  @Input() form!: FormGroup;
  statesList: string[] = [];

  constructor(
    private readonly stateService: StatesService,
    submitNotificationService: FormSubmitNotificationService
  ) {
    super(submitNotificationService);
  }

  override ngOnInit() {
    super.ngOnInit();
    this.configureFormValidation();
    this.stateService.getStatesList().subscribe((st) => {
      this.statesList = st.map((st) => st.code);
    });
  }

  private configureFormValidation() {
    this.form
      .get('sellerName')!
      .setValidators([
        Validators.required('Seller name required'),
        Validators.maxLength(
          75,
          (maxLength) => `Maximum length is ${maxLength} characters`
        ),
      ]);
    this.form
      .get('sellerAddress')!
      .setValidators([
        Validators.required('Seller address required'),
        Validators.maxLength(
          50,
          (maxLength) => `Maximum length is ${maxLength} characters`
        ),
      ]);
    this.form
      .get('sellerCity')!
      .setValidators([
        Validators.required('Seller city required'),
        Validators.maxLength(
          25,
          (maxLength) => `Maximum length is ${maxLength} characters`
        ),
      ]);
    this.form
      .get('sellerState')!
      .setValidators(Validators.required('Seller state required'));

    this.form.get('sellerName')!.updateValueAndValidity();
    this.form.get('sellerAddress')!.updateValueAndValidity();
    this.form.get('sellerCity')!.updateValueAndValidity();
    this.form.get('sellerState')!.updateValueAndValidity();
  }

  override ngOnDestroy(): void {
    super.ngOnDestroy();
  }
}
