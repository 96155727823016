import { Injectable } from '@angular/core';
import { DocumentsRepo } from '../dfs-fin-api/DocumentsRepo';
import { Observable } from 'rxjs';
import { DocumentDetails } from '../dfs-fin-api/entities/DocumentDetails';
import { ActivatedRouteSnapshot } from '@angular/router';
import { BusinessDaysRepo } from '../dfs-fin-api/BusinessDaysRepo';

@Injectable({ providedIn: 'root' })
export class DocumentDetailsResolverService {
  constructor(
    private readonly documentsRepoSvc: DocumentsRepo,
    readonly businessDaysRepoSvc: BusinessDaysRepo
  ) {}

  resolve(route: ActivatedRouteSnapshot): Observable<DocumentDetails> {
    const applicationId = Number(route.paramMap.get('id'));
    const editsAllowed = route.data['editsAllowed'];
    return this.documentsRepoSvc.get(applicationId, editsAllowed);
  }
}
