import { Injectable } from '@angular/core';
import { CurrencyPipe } from '@angular/common';

import { GroupByPipe } from '../pipes/group-by.pipe';
import { OrderByPipe } from '../pipes/order-by.pipe';
import { environment } from 'src/environments/environment';
import { ApplicationRate } from 'src/app/interfaces/ApplicationRate';

@Injectable({
  providedIn: 'root',
})
export class TermRateGroupFactory {
  constructor(
    private readonly groupBy: GroupByPipe,
    private readonly orderBy: OrderByPipe,
    private readonly currencyPipe: CurrencyPipe
  ) {}

  create(
    rates: ApplicationRate[],
    termLimit: number | undefined,
    amountToFinance: number | undefined
  ) {
    const specialProgramDescription = 'special';

    const dealerRates = this.getCurrentDealerRates(
      rates,
      termLimit,
      amountToFinance
    )?.filter(
      (r) => !r.programType.toLowerCase().includes(specialProgramDescription) // do not include special programs
    );
    if (!dealerRates) return [];

    const ratesByType = this.groupBy.transform(dealerRates, 'programType');

    this.buildRateGroups(ratesByType);

    return ratesByType;
  }

  private getCurrentDealerRates(
    userProductLineRates: ApplicationRate[] | undefined | null,
    termLimit: number | undefined,
    amountToFinance: number | undefined
  ) {
    if (termLimit && amountToFinance) {
      const maxFinancedWithTolerance = amountToFinance + amountToFinance * 0.02;
      const regularTermUpperLimit = Math.ceil(termLimit / 12) * 12;

      return userProductLineRates?.filter((rate) => {
        return (
          rate.termYears * 12 <= regularTermUpperLimit &&
          rate.termYears <= this.getRatesThreshold(amountToFinance) &&
          rate.lowerBoundInclusive <= maxFinancedWithTolerance
        );
      });
    } else {
      return userProductLineRates ?? undefined;
    }
  }
  private getRatesThreshold(amountFinanced: number): number {
    if (amountFinanced >= environment.sevenYearFinanceThreshold) {
      return 7;
    }
    if (amountFinanced >= environment.sixYearFinanceThreshold) {
      return 6;
    }
    return 5;
  }

  /* TODO Figure out date types */
  private buildRateGroups(rateGroups: { [x: string]: any }) {
    Object.keys(rateGroups).forEach((key) => {
      const rates = rateGroups[key];
      //terms must be ordered by loan amount range so that they line up with loan amount range descriptions
      const orderedRates = this.orderBy.transform(rates, 'lowerBoundInclusive');
      const ratesByTerm = this.groupBy.transform(orderedRates, 'termYears');
      //loan amount range descriptions are set here
      rates.loanRanges = this.buildLoanRangeDescriptions(rates);
      rates.ratesByTerm = ratesByTerm;
    });
  }

  private buildLoanRangeDescriptions(rates: any[]) {
    const rangeDescriptions: string[] = [];
    const ratesByRange = this.groupBy.transform(rates, 'lowerBoundInclusive');
    const ranges = Object.keys(ratesByRange);
    ranges.forEach((key) => {
      const lowerBoundDescription = this.currencyPipe.transform(
        key,
        'USD',
        'symbol',
        '1.0-0'
      );
      // TODO: FIgure out data type
      const upperBound = (ratesByRange as any)[key][0]
        .financedAmountUpperBoundExclusive;
      const upperBoundDescription = upperBound
        ? `to ${this.currencyPipe.transform(
            upperBound,
            'USD',
            'symbol',
            '1.0-0'
          )}`
        : 'and Up';
      const rangeDescription = `${lowerBoundDescription} ${upperBoundDescription}`;
      rangeDescriptions.push(rangeDescription);
    });
    return rangeDescriptions;
  }
}
