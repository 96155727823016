import { Component } from '@angular/core';
import { IJobPosting } from '../interfaces/job-posting';
import { JobDescriptionModalService } from '../marketing/job-description-modal.service';
import { JobPostingsService } from '../services/job-postings.service';
import { SelfIdentificationModalService } from './self-identification-modal.service';

@Component({
  selector: 'fin-job-postings',
  templateUrl: './job-postings.component.html',
  styleUrls: ['../marketing/marketing-styles.css'],
})
export class JobPostingsComponent {
  jobPostings: IJobPosting[] = [];
  get jobPostingsCount(): number {
    return this.jobPostings.length;
  }
  constructor(
    private readonly jobPostingService: JobPostingsService,
    private readonly jobDescriptionModalService: JobDescriptionModalService
  ) {
    jobPostingService.getJobPostings().subscribe((data) => {
      this.jobPostings = data;
    });
  }

  ngOnInit(): void {}
  showJobDescription(jobDescription: IJobPosting) {
    this.jobDescriptionModalService.show(jobDescription);
  }
}
