import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'orderBy',
})
export class OrderByPipe implements PipeTransform {
  transform(array: undefined | null, field: string): undefined;
  transform<T extends { [key: string]: any }>(
    array: Array<T> | T[],
    field: string
  ): T[];
  transform<T extends { [key: string]: any }>(
    array: Array<T> | T[] | undefined | null,
    field: string
  ): T[] | undefined {
    if (!array) {
      return undefined;
    }
    array.sort((a, b) => {
      const valA = field in a ? a[field] : undefined;
      const valB = field in b ? b[field] : undefined;
      let textA: any | undefined;
      let textB: any | undefined;
      if (!(field in a)) {
        console.warn(field + ' not in', a);
      }
      if (!(field in b)) {
        console.warn(field + ' not in', b);
      }
      if (typeof valA === 'string') {
        textA = valA.toUpperCase();
      } else if (typeof valA === 'number' || typeof valA === 'boolean') {
        textA = valA;
      } else if (valA) {
        textA = valA.toString();
      }
      if (typeof valB === 'string') {
        textB = valB.toUpperCase();
      } else if (typeof valB === 'number' || typeof valB === 'boolean') {
        textB = valB;
      } else if (valB) {
        textB = valB.toString();
      }
      return textA
        ? !textB || textA < textB
          ? -1
          : textA > textB
          ? 1
          : 0
        : textB
        ? -1
        : 0;
    });
    return array;
  }
}
