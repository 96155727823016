import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Dealer } from '../dealer.model';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({ providedIn: 'root' })
export class DealersRepositoryService {
  constructor(private httpClient: HttpClient) {}

  get(dealerCity: string, dealerName: string): Observable<Dealer[]> {
    let url = `${environment.ApiBaseUrl}/back-office-dealers`;
    url += `?dealerName=${dealerName}`;
    if (dealerCity) {
      url += `&dealerCity=${dealerCity}`;
    }
    return this.httpClient.get<Dealer[]>(url);
  }
}
