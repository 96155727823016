import { Component, Injectable, Input, OnInit } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { Observable } from 'rxjs';
import { IJobPosting } from '../interfaces/job-posting';

@Injectable({
  providedIn: 'root',
})
export class JobDescriptionModalService {
  private modalRef: BsModalRef = new BsModalRef();

  constructor(private readonly bsModalService: BsModalService) {}
  show(jobPost: IJobPosting) {
    this.bsModalService.show(JobDescriptionModalComponent, {
      initialState: {
        jobPost: jobPost,
      },
      ignoreBackdropClick: true,
      class: 'modal-xl',
    });
  }
}

@Component({
  styleUrls: ['../marketing/marketing-styles.css'],
  templateUrl: './job-description.html',
})
export class JobDescriptionModalComponent implements OnInit {
  @Input()
  jobPost!: IJobPosting;
  modalRef: BsModalRef;
  constructor(bsModalRef: BsModalRef) {
    this.modalRef = bsModalRef;
  }

  ngOnInit(): void {}
}
