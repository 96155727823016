import { Component } from '@angular/core';

@Component({
  selector: 'fin-unimplemented-route',
  templateUrl: './unimplemented-route.component.html',
  styleUrls: ['./unimplemented-route.component.css']
})
export class UnimplementedRouteComponent {

}
