&nbsp;
<span
  class="text-primary pi pi-info-circle"
  [ngbPopover]="popContent"
  popoverTitle="Contract Terms"
  triggers="mouseenter:mouseleave"
  placement="right"
></span>
<ng-template #popContent>
  <div *ngIf="ratesByType">
    <div *ngIf="contractHeader" class="text-center">
      <h4>{{ contractHeader }}</h4>
    </div>
    <table
      *ngFor="let rbt of ratesByType | keyvalue : keepOrder"
      class="table table-bordered table-sm table-striped"
    >
      <thead>
        <tr>
          <th
            [colSpan]="$any(rbt.value).loanRanges.length + 1"
            class="text-center"
          >
            {{ rbt.key }}
          </th>
        </tr>
        <tr>
          <th scope="col">&nbsp;</th>
          <th *ngFor="let range of $any(rbt.value).loanRanges" scope="col">
            {{ range }}
          </th>
        </tr>
      </thead>
      <tbody>
        <tr
          *ngFor="
            let byTerm of $any(rbt.value).ratesByTerm | keyvalue : keepOrder
          "
        >
          <td style="vertical-align: middle">
            <strong>{{ byTerm.key }}yr</strong>
          </td>
          <td *ngFor="let rate of $any(byTerm.value)">
            <div>
              {{
                rate.value >= 0
                  ? (rate.value * 100 | number : "0.2-2") + "%"
                  : "N/A"
              }}
            </div>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</ng-template>
