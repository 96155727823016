    <form [formGroup]="form" #formDirective="ngForm">
      <div class="row">
        <div class="col-md-8">
          <div class="form-group">
            <label class="col-form-label" for="documentDescription"
              >Description</label
            >
            <input
              id="documentDescription"
              name="documentDescription"
              disabled="true"
              class="form-control"
              [value]="description"
            />
          </div>
        </div>
        <div class="col-md-2">
          <div class="form-group">
            <label class="col-form-label" for="transactionAmount">
              <span *ngIf="!tradeInFlag">Sales Price *</span>
              <span *ngIf="tradeInFlag">Trade Allowance</span>
            </label>
            <input
              class="form-control"
              id="transactionAmount"
              name="transactionAmount"
              disabled="true"
              [value]="transactionAmount"
            />
          </div>
        </div>
        <div *ngIf="!tradeInFlag" class="col-md-2">
          <div class="form-group">
            <label class="col-form-label" for="salesTax">Sales Tax</label>
            <input
              class="form-control"
              id="salesTax"
              name="salesTax"
              disabled="true"
              [value]="salesTax"
            />
          </div>
        </div>
        <div *ngIf="tradeInFlag" class="col-md-2">
          <div class="form-group">
            <label class="col-form-label" for="amountOwed">Amount Owed</label>
            <input
              class="form-control"
              id="amountOwed"
              name="amountOwed"
              disabled="true"
              [value]="amountOwed"
            />
          </div>
        </div>
      </div>
    </form>
