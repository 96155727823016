<div id="page-content-wrapper">
  <div class="row">
    <h4>Contact Us</h4>
  </div>
  <div class="card" style="width: 50%; min-width: 32rem">
    <p-table
      styleClass="p-datatable-striped p-datatable-sm"
      [value]="dfsContactList"
      [scrollable]="true"
      sortField="departmentName"
      rowGroupMode="subheader"
      groupRowsBy="departmentName"
      [tableStyle]="{ 'min-width': '50%' }"
    >
      <ng-template pTemplate="header">
        <tr>
          <th>
            <span class="m-3"><b>Name</b></span>
          </th>
          <th><b>Email</b></th>
          <th><b>Direct Number</b></th>
        </tr>
      </ng-template>
      <ng-template pTemplate="groupheader" let-contact>
        <tr pRowGroupHeader>
          <td colspan="3">
            <span class="font-bold"
              ><b>{{ contact.departmentName }}:</b></span
            >
          </td>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-contact let-rowIndex="rowIndex">
        <tr>
          <td>
            <span class="m-3">{{ contact.name }}</span>
          </td>
          <td>
            <strong
              ><a href="mailto:{{ contact.email }}">{{
                contact.email
              }}</a></strong
            >
          </td>
          <td>
            {{ contact.directNumber }}
          </td>
        </tr>
      </ng-template>
    </p-table>
  </div>
</div>
