import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, Observable, throwError } from 'rxjs';
import { DfsContactInfo } from '../contact-us/dfs-contact-info';

@Injectable({
  providedIn: 'root',
})
export class DfsContactService {
  private contactInfoUrl = 'api/dfs-contact-info.json';
  constructor(private http: HttpClient) {}

  getDealerContactList(): Observable<DfsContactInfo[]> {
    return this.http
      .get<DfsContactInfo[]>(this.contactInfoUrl)
      .pipe(catchError(this.handleError));
  }

  private handleError(err: HttpErrorResponse): Observable<never> {
    let errorMessage = 'There was an issue retrieving Contact Information!';
    if (err.error instanceof ErrorEvent) {
      errorMessage = `An error occurred: ${err.error.message}`;
    } else {
      errorMessage = `Server returned code: ${err.status}, error message is: ${err.message}`;
    }
    console.error(errorMessage);
    return throwError(() => errorMessage);
  }
}
