<div class="row">
  <section class="col-md-4">
    <form [formGroup]="form" #formDirective="ngForm">
      <div class="row">
        <div class="col-md-12">
          <fin-basic-contract-terms
            [form]="form"
            [equipmentItems]="equipmentItems"
            [sellerType]="sellerTypes.dealerStart"
            [rates]="rates"
            [bonusRateTypes]="bonusRateTypes"
          ></fin-basic-contract-terms>
          <div dfsFormGroup style="margin-bottom: 15px">
            <label for="rentalCredit">Rental Credit</label>
            <input
              formControlName="rentalCredit"
              id="rentalCredit"
              name="rentalCredit"
              dfsCurrencyMsk
              (blur)="calculate()"
            />
          </div>
          <div dfsFormGroup style="margin-bottom: 15px">
            <label for="dealerDocFee">Dealer Doc Fee</label>
            <input
              formControlName="dealerDocFee"
              id="dealerDocFee"
              name="dealerDocFee"
              dfsCurrencyMsk
              (blur)="calculate()"
            />
          </div>
          <div
            dfsFormGroup
            style="margin-bottom: 15px"
            *ngIf="this.productLine !== 'Irrigation'"
          >
            <label for="interestRate" *ngIf="!isCustomInterestRate"
              >Contract Interest Rate %
            </label>
            <label for="interestRate" *ngIf="isCustomInterestRate"
              >Contract Interest Rate % *</label
            >
            <dsa-interest-rate-guide
              *ngIf="workingRates"
              [applicationRates]="workingRates"
              [contractType]="contractTypeField?.value?.value"
            ></dsa-interest-rate-guide>
            <input
              class="form-control"
              formControlName="interestRate"
              id="interestRate"
              name="interestRate"
              [readOnly]="!isCustomInterestRate"
            />
          </div>
          <div
            dfsFormGroup
            style="margin-bottom: 15px"
            *ngIf="isCustomInterestRate"
          >
            <label name="customRateReason">Reason *</label>
            <ng-select
              formControlName="customRateReason"
              id="id"
              bindLabel="value"
              bindValue="id"
              name="customRateReason"
              [selectOnTab]="true"
              [items]="customRateReason"
            >
            </ng-select>
          </div>
          <div>
            <div
              class="row text-danger col-md-12"
              *ngIf="this.productLine !== 'Irrigation' && outsideRateTier"
            >
              An interest rate could not be found in our terms for the selected
              term, finance amount, contract type, and bonus type. Please
              contact your Credit Manager for further assistance.
            </div>
          </div>
        </div>
        <div class="col-md-12">
          <div
            dfsFormGroup
            *ngIf="
              contractTypeField?.value == 'Lease-LP' ||
              contractTypeField?.value == 'Lease-PRO'
            "
          >
            <label for="residualPercentage">Residual %</label>
            <input
              formControlName="residualPercentage"
              id="residualPercentage"
              type="text"
              name="residualPercentage"
              mask="percent"
              suffix="%"
              placeholder="## %"
            />
          </div>
        </div>
      </div>
    </form>
  </section>
  <div class="col-md-6">
    <ca-contract-totals
      [loanCalculatorResults]="loanCalculatorSvc"
      [contractType]="contractTypeField?.value"
    ></ca-contract-totals>
  </div>
</div>
