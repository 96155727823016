<main>
  <section
    style="
      background-image: url('assets/images/contact-hero.jpg');
      background-size: cover;
      background-position: center;
    "
  >
    <div class="container px-4 py-5">
      <div class="row align-items-start g-lg-5 py-5">
        <div class="col-lg-10 text-lg-start text-light">
          <h1 class="display-4 lh-1 mb-3 py-4">Contact Us</h1>
        </div>
      </div>
    </div>
  </section>

  <div class="container px-4 py-5">
    <div class="row g-5">
      <div class="col-md-6">
        <h4 class="text-primary mb-4">Mailing Address</h4>
        <p>
          DFS Finance<br />
          14010 FNB Pkwy Ste. 400<br />
          Omaha, NE 68154
        </p>

        <h4 class="text-primary mb-4 mt-5">
          Finance Information <br />and General Inquiries
        </h4>
        <p>
          <a class="text-dark text-decoration-none" href="tel:8006488026"
            >800.648.8026</a
          ><br />
          Monday through Friday, 8:00 AM to 5:00 PM (CST).
        </p>

        <h4 class="text-primary mb-4 mt-5">Customer Contract Information</h4>
        <p>Request a Payoff or to Make a Payment by Phone</p>

        <p>
          <a
            class="text-dark text-decoration-none"
            href="mailto:customerservice@dfsfin.com"
            >customerservice@dfsfin.com</a
          ><br />
          <a class="text-dark text-decoration-none" href="tel:8888866242"
            >888.886.6242</a
          ><br />
          Monday through Friday, 8:00 AM to 5:00 PM (CST).
        </p>
      </div>
      <div class="col-md-6">
        <form
          class="card p-4 fs-6"
          [formGroup]="contactForm"
          (ngSubmit)="onSubmit($event)"
        >
          <div class="col-12">
            <label for="reason" class="form-label"
              >Why are you contacting us?<span class="text-danger"
                >*</span
              ></label
            >
            <ng-select
              id="reason"
              class="form-control mb-2 fs-6"
              [items]="ContactTypeList"
              bindLabel="value"
              bindValue="key"
              [selectOnTab]="true"
              [searchable]="false"
              formControlName="reason"
              (change)="setDynamicValidation()"
              placeholder="Choose reason for contacting DFS (required)"
              [ngClass]="{
                'is-invalid':
                  (submitted || contactForm.controls['reason'].touched) &&
                  contactForm.controls['reason'].invalid
              }"
            >
            </ng-select>
            <div
              *ngIf="
                (submitted || contactForm.controls['reason'].touched) &&
                contactForm.controls['reason'].errors
              "
              class="invalid-feedback"
            >
              <div *ngIf="contactForm.controls['reason'].errors['required']">
                Reason for contacting is required
              </div>
            </div>
          </div>
          <div class="col-12">
            <label for="fullName" class="form-label"
              >Full Name<span class="text-danger">*</span></label
            >
            <input
              aria-label="Full Name"
              type="text"
              class="form-control mb-2 fs-6"
              id="fullName"
              formControlName="fullName"
              placeholder="Full Name (required)"
              [ngClass]="{
                'is-invalid':
                  (submitted || contactForm.controls['fullName'].touched) &&
                  contactForm.controls['fullName'].errors
              }"
            />
            <div
              *ngIf="
                (submitted || contactForm.controls['fullName'].touched) &&
                contactForm.controls['fullName'].errors
              "
              class="invalid-feedback"
            >
              <div *ngIf="contactForm.controls['fullName'].errors['required']">
                Full name is required
              </div>
            </div>
          </div>
          <div class="col-12">
            <label for="address" class="form-label">Address</label>
            <input
              type="text"
              class="form-control mb-2 fs-6"
              id="address"
              formControlName="address"
              placeholder="Address"
            />
          </div>
          <div class="row">
            <div class="col-sm-12 col-md-5">
              <label for="city" class="form-label">City</label>
              <input
                type="text"
                class="form-control mb-2 fs-6"
                id="city"
                formControlName="city"
                placeholder="City"
              />
            </div>
            <div class="col-sm-12 col-md-4">
              <label for="state" class="form-label">State</label>
              <ng-select
                id="state"
                class="form-control mb-2 fs-6"
                [items]="statesList$ | async"
                bindLabel="name"
                bindValue="code"
                [selectOnTab]="true"
                formControlName="state"
                placeholder="State"
                [typeahead]="inputState$"
              >
              </ng-select>
            </div>
            <div class="col-sm-12 col-md-3">
              <label for="zip" class="form-label">Zip</label>
              <input
                type="text"
                class="form-control mb-2 fs-6"
                id="zip"
                formControlName="zip"
                placeholder="Zip Code"
              />
            </div>
          </div>
          <div class="col-12">
            <label for="contractNumber" class="form-label"
              >Contract Number (if applicable)</label
            >
            <input
              type="text"
              class="form-control mb-2 fs-6"
              id="contractNumber"
              formControlName="contractNumber"
              placeholder="Contract Number"
              [ngClass]="{
                'is-invalid':
                  (submitted ||
                    contactForm.controls['contractNumber'].touched) &&
                  contactForm.controls['contractNumber'].errors
              }"
            />
            <div
              *ngIf="
                (submitted || contactForm.controls['contractNumber'].touched) &&
                contactForm.controls['contractNumber'].errors
              "
              class="invalid-feedback"
            >
              <div
                *ngIf="
                  contactForm.controls['contractNumber'].errors['required']
                "
              >
                Contract number is required
              </div>
            </div>
          </div>
          <div class="col-12">
            <label for="phoneNumber" class="form-label">Phone Number</label>
            <input
              type="tel"
              class="form-control mb-2 fs-6"
              id="phoneNumber"
              formControlName="phoneNumber"
              placeholder="Phone Number"
              [ngClass]="{
                'is-invalid':
                  (submitted || contactForm.controls['phoneNumber'].touched) &&
                  contactForm.controls['phoneNumber'].errors
              }"
            />
            <div
              *ngIf="
                (submitted || contactForm.controls['phoneNumber'].touched) &&
                contactForm.controls['phoneNumber'].errors
              "
              class="invalid-feedback"
            >
              <div
                *ngIf="contactForm.controls['phoneNumber'].errors['required']"
              >
                Phone number is required
              </div>
            </div>
          </div>
          <div class="col-12">
            <label for="email" class="form-label"
              >Email Address<span class="text-danger">*</span></label
            >
            <input
              type="email"
              class="form-control mb-2 fs-6"
              id="email"
              formControlName="email"
              placeholder="Email Address (required)"
              [ngClass]="{
                'is-invalid':
                  (submitted || contactForm.controls['email'].touched) &&
                  contactForm.controls['email'].errors
              }"
            />
            <div
              *ngIf="
                (submitted || contactForm.controls['email'].touched) &&
                contactForm.controls['email'].errors
              "
              class="invalid-feedback"
            >
              <div *ngIf="contactForm.controls['email'].errors['required']">
                Email is required
              </div>
              <div *ngIf="contactForm.controls['email'].errors['email']">
                Please enter a valid email
              </div>
            </div>
          </div>

          <div class="col-12">
            <label for="comments" class="form-label"
              >Any comments or questions?</label
            >
            <textarea
              id="comments"
              rows="3"
              maxlength="200"
              class="form-control mb-2 fs-6"
              style="width: 100%; resize: none"
              formControlName="comments"
              placeholder="Comments or Questions"
            ></textarea>
          </div>
          <div class="col-12">
            <re-captcha
              class="form-control mb-2 fs-6"
              class="mb-2 fs-6"
              (resolved)="getCaptchaValue($event)"
              siteKey="{{ captchaKey }}"
            ></re-captcha>
            <p class="text-danger" *ngIf="!captchaIsValid">
              Please validate you are not a robot before submitting:
            </p>
          </div>
          <div class="col-12">
            <button
              [disabled]="!captchaIsValid"
              class="btn btn-primary mt-2"
              type="submit"
            >
              Submit
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
</main>
