<div class="dfs-modal-dialog dfs-modal-content">
  <div class="dfs-modal-header">
    <h4 class="dfs-modal-title">Confirm Delete</h4>
  </div>
  <div class="dfs-modal-body">
    Are you sure you want to delete the application for
    {{ customerName }}
    ?
  </div>
  <div class="dfs-modal-footer">
    <button type="button" (click)="delete()" class="dfs-btn dfs-btn-primary">
      Delete
    </button>
    <button
      type="button"
      (click)="dialogRef.hide()"
      class="dfs-btn dfs-btn-danger"
    >
      Cancel
    </button>
  </div>
</div>
