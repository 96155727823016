<div id="page-content-wrapper">
  <div class="row">
    <h4>{{ lmp?.header }}</h4>
  </div>
  <div class="card" style="width: 50%; min-width: 28rem">
    <p-table
      *ngIf="lmp !== undefined"
      styleClass="p-datatable-striped p-datatable-sm"
      [value]="lmp.programFiles"
      [scrollable]="true"
      sortField="departmentName"
      rowGroupMode="subheader"
      groupRowsBy="departmentName"
      [loading]="loadingData"
    >
      <ng-template pTemplate="header">
        <tr>
          <th>
            <span class="m-3"><b>File Name</b></span>
          </th>
          <th><b>Type</b></th>
          <th></th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-item let-rowIndex="rowIndex">
        <tr>
          <td>
            <span class="m-3">{{ item.fileName }}</span>
          </td>
          <td>
            <span class="m-3">{{ item.fileType }}</span>
          </td>
          <td>
            <button
              style="font-size: small"
              type="button"
              class="dfs-btn dfs-btn-primary"
              (click)="GetFile(item)"
            >
              View
            </button>
          </td>
        </tr>
      </ng-template>
      <ng-template pTemplate="emptymessage">
        <tr>
          <td colspan="3">There are currently no files to display.</td>
        </tr>
      </ng-template>
    </p-table>
  </div>
</div>
