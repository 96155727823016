import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";

import { Observable } from "rxjs";

import { environment } from "src/environments/environment";
import { FinanceDetails } from './entities/FinanceDetails';


@Injectable()
export class FinanceCalculationsSvc {
    constructor(private http: HttpClient) {}

  calculatePayment(financeDetails: FinanceDetails): Observable<number> {
    const url = `${environment.ApiBaseUrl}/finance-calculations`;

    return this.http.post<number>(url, financeDetails);
  }
}
