import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { UserDealerLink } from './entities/user-dealer-link.model'
import { environment } from '../../environments/environment';
import { Observable } from 'rxjs';

@Injectable()
export class UserDealerLinkRepo {
  constructor(
    private readonly http: HttpClient
  ) {}

  getUserDealerLinks() : Observable<UserDealerLink[]> {
    const url = environment.ApiBaseUrl + '/user-dealers/simple'
    return this.http.get<UserDealerLink[]>(url);
  }
}
