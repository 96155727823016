<form [formGroup]="form" #formDirective="ngForm">
  <div dfsFormGroup style="margin-bottom: 15px">
    <label
      name="paymentFrequency"
      *ngIf="this.sellerType === this.sellerTypes.dealerStart"
      >Payment Frequency</label
    >
    <label
      name="paymentFrequency"
      *ngIf="this.sellerType !== this.sellerTypes.dealerStart"
      >Payment Frequency *</label
    >
    <ng-select
      formControlName="paymentFrequency"
      id="paymentFrequency"
      name="paymentFrequency"
      [selectOnTab]="true"
      [items]="paymentFrequencies"
      (change)="changePaymentFrequency()"
      [clearable]="false"
    >
    </ng-select>
  </div>
  <div dfsFormGroup style="margin-bottom: 15px">
    <label for="contractType" *ngIf="sellerType === sellerTypes.customerFinish"
      >Contract Type</label
    >
    <label for="contractType" *ngIf="sellerType !== sellerTypes.customerFinish"
      >Contract Type *</label
    >
    <ng-select
      formControlName="contractTypeId"
      id="contractTypeId"
      name="contractTypeId"
      [selectOnTab]="true"
      bindLabel="text"
      bindValue="value"
      [items]="contractTypes"
      [clearable]="false"
      [readonly]="sellerType === sellerTypes.customerFinish"
      (change)="changedContractType()"
    >
    </ng-select>
  </div>
  <div
    class="row"
    *ngIf="sellerType === sellerTypes.dealerStart"
    style="margin-bottom: 15px"
  >
    <div dfsFormGroup>
      <label name="rateType">Rate Type *</label>
      <ng-select
        formControlName="programTypeId"
        id="programTypeId"
        name="programTypeId"
        [selectOnTab]="true"
        bindLabel="tabDisplay"
        bindValue="programTypeId"
        [items]="currentBonusRateTypes"
        [searchable]="false"
        [clearable]="false"
        [readonly]="false"
        (change)="changedRateType()"
      >
      </ng-select>
    </div>
  </div>
  <div
    class="row"
    *ngIf="sellerType === sellerTypes.dealerStart"
    style="margin-bottom: 15px"
  >
    <div dfsFormGroup>
      <label name="bonusType">Bonus Type *</label>
      <em
        class="text-info"
        style="font-size: small"
        *ngIf="bonusType === 'Double'"
      >
        Double Bonus Rates are subject to prepayment penalties.
      </em>
      <ng-select
        formControlName="bonusType"
        id="bonusType"
        name="bonusType"
        [selectOnTab]="true"
        [items]="availableBonuses"
        [searchable]="false"
        [clearable]="false"
        [readonly]="!isCustomInterestRate"
      >
      </ng-select>
    </div>
  </div>
  <div dfsFormGroup style="margin-bottom: 15px">
    <label
      for="contractTerm"
      *ngIf="this.sellerType === this.sellerTypes.dealerStart"
      >Contract Term</label
    >
    <label
      for="contractTerm"
      *ngIf="this.sellerType !== this.sellerTypes.dealerStart"
      >Contract Term *</label
    >
    <ng-select
      formControlName="contractTerm"
      id="contractTerm"
      name="contractTerm"
      [selectOnTab]="true"
      [items]="contractTerms"
      [clearable]="false"
    >
    </ng-select>
  </div>
  <div dfsFormGroup style="margin-bottom: 15px">
    <label for="cashDown">Cash Down</label>
    <input
      class="form-control"
      formControlName="cashDown"
      id="cashDown"
      name="cashDown"
      dfsCurrencyMsk
    />
  </div>
  <div class="col-md-12">
    <div
      dfsFormGroup
      *ngIf="
        sellerType === sellerTypes.customerFinish &&
        (contractType === contractTypesEnum.LeaseLp.valueOf ||
          contractType === contractTypesEnum.LeasePro.valueOf)
      "
    >
      <label for="residualPercentage">Residual %</label>
      <input
        class="form-control"
        formControlName="residualPercentage"
        id="residualPercentage"
        type="text"
        name="residualPercentage"
        mask="percent"
        suffix="%"
        placeholder="## %"
      />
    </div>
  </div>
</form>
