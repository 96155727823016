<div id="page-content-wrapper">
  <h3>{{ docPreviewTitle }}</h3>
  <div *ngIf="!docsGenerated">
    <p-progressSpinner
      strokeWidth="4"
      styleClass="custom-spinner"
      aria-label="Loading"
    ></p-progressSpinner>
    <h4>Loading...</h4>
  </div>
  <div *ngIf="docsGenerated" class="row">
    <div class="col-md-10">
      <!-- https://pdfviewer.net/extended-pdf-viewer/simple -->
      <ngx-extended-pdf-viewer
        [src]="fileSaveInfo.file"
        [height]="'auto'"
        [useBrowserLocale]="true"
        [showToolbar]="false"
      ></ngx-extended-pdf-viewer>
    </div>
    <div class="col-md-2">
      <div class="btn-group-vertical" role="group">
        <button class="btn btn-primary" (click)="printDocuments()">
          Print Documents
        </button>
        <a [routerLink]="['/generate-documents', appId]" class="btn btn-danger"
          >Edit Documents</a
        >
        <button
          class="btn btn-success"
          href="#"
          role="button"
          data-bs-toggle="dropdown"
          aria-expanded="false"
          [disabled]="voidedByAdmin"
        >
          E-Signatures
        </button>
        <div class="text-info" *ngIf="voidedByAdmin">
          <span class="pi pi-info-circle"></span>&nbsp;
          <em
            >Creating E-Signatures has been disabled for this application.
            Contact your Credit Manager for more information.</em
          >
        </div>
        <ul class="dropdown-menu">
          <li>
            <a
              class="dropdown-item btn btn-link"
              (click)="generateEsignatureRecipients(signingLocation.Remote)"
              >Remote Signing</a
            >
          </li>
          <li>
            <a
              class="dropdown-item btn btn-link"
              (click)="generateEsignatureRecipients(signingLocation.InPerson)"
              >In-Person Signing</a
            >
          </li>
        </ul>
      </div>
    </div>
  </div>
</div>
