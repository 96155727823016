import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { tap, Observable } from 'rxjs';

import { JwtHelperService } from '@auth0/angular-jwt';

import { environment } from 'src/environments/environment';
import { UserDataCookieParserService } from 'dfs-common-ngx';
import { Constants } from '../dfs-fin-api/constants';

@Injectable({
  providedIn: 'root',
})
export class LoginService {
  constructor(
    private httpClient: HttpClient,
    private readonly jwtHelper: JwtHelperService,
    private userDataCookieService: UserDataCookieParserService
  ) {}

  login(username: string, password: string): Observable<Object | null> {
    const url = `${environment.ApiBaseUrl}/users/login`;
    const encryptedCreds = { username: username, password: password };
    return this.httpClient.post(url, encryptedCreds).pipe(
      tap((resp) => {
        this.userDataCookieService.setSession();
      })
    );
  }

  logout(): Observable<void> {
    const url = `${environment.ApiBaseUrl}/users/logout`;
    return this.httpClient.post<void>(url, null);
  }

  forgotPassword(username: string): Observable<any> {
    const url = `${environment.ApiBaseUrl}/users/forgotpassword`;
    return this.httpClient.post(url, { userIdentifier: username });
  }

  resetPassword(password: string, token: string): Observable<any> {
    const data = { password: password, token: token };
    const credentialsApiSegment = 'passwords';

    const tokenData = this.jwtHelper.decodeToken(token);
    const url = `${environment.ApiBaseUrl}/${Constants.apiUrls.usersUrlSegment}/${tokenData.systemid}/${credentialsApiSegment}`;
    return this.httpClient.post(url, data);
  }
}
