import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { CurrentUserService } from 'dfs-common-ngx';
import {
  EquipmentItem,
  FormSubmitNotificationService,
  StepFormComponent,
  LoanCalculatorService,
  SellerType,
  EquipmentType,
} from 'dfs-credit-application-ngx';
import { Validators } from 'angular-reactive-validation';
import { Dealer } from 'dfs-common-ngx';
import { environment } from 'src/environments/environment';
import { CustomRateReason } from 'src/app/enums/custom-rate-reason';
import { ISimpleItem } from 'src/app/shared/simpleList';
import { FilingFeesService } from 'src/app/credit-application/common/services/filing-fees.service';
import { ApplicationRate } from 'src/app/interfaces/ApplicationRate';
import { ApplicationTermsService } from 'src/app/shared/application-terms.service';
import { RatesDisplayOption } from 'src/app/interfaces/rates-display-option';

@Component({
  selector: 'dca-contract-terms',
  templateUrl: 'contract-terms.component.html',
  styleUrls: [`../../../PortalMain.scss`],
})
export class ContractTermsComponent
  extends StepFormComponent
  implements OnInit, OnDestroy
{
  @Input() equipmentItems!: EquipmentItem[];
  @Input() rates: ApplicationRate[] = [];
  @Input() equipmentType!: EquipmentType;
  @Input() bonusRateTypes: RatesDisplayOption[] = [];

  outsideRateTier: boolean = false;
  productLine: string;
  productLineId: number;
  sellerTypes = SellerType;
  customRateReason: ISimpleItem[] = [];
  filingFee!: number;
  workingRates: ApplicationRate[] = [];
  isCustomInterestRate: boolean = true;

  get contractTypeField() {
    return this.form.get('contractTypeId');
  }

  get amountToFinance() {
    return this.form.get('amountToFinance');
  }

  get customRateReasonField() {
    return this.form.get('customRateReason');
  }

  constructor(
    submitNotificationService: FormSubmitNotificationService,
    public readonly loanCalculatorSvc: LoanCalculatorService, // Used in HTML
    private readonly currentUserSvc: CurrentUserService,
    private filingFeeService: FilingFeesService,
    private applicationTermsService: ApplicationTermsService
  ) {
    super(submitNotificationService);
    const dealer = this.currentUserSvc.profile as Dealer;
    this.productLine = dealer.productLine;
    this.productLineId = dealer.productLineId;
    this.getFilingFee();
  }

  SetCustomRateReason() {
    for (var n in CustomRateReason) {
      if (typeof CustomRateReason[n] === 'number') {
        this.customRateReason.push({ id: <any>CustomRateReason[n], value: n });
      }
    }
  }

  override ngOnInit() {
    super.ngOnInit();

    this.contractTypeField?.valueChanges.subscribe({
      next: () => this.setWorkingRates(),
    });
    this.form
      .get('programTypeId')
      ?.valueChanges.subscribe({ next: () => this.changedRateType() });
    this.form
      .get('contractTerm')
      ?.valueChanges.subscribe({ next: () => this.selectInterestRate() });
    this.form
      .get('cashDown')
      ?.valueChanges.subscribe({ next: () => this.calculate() });
    this.form
      .get('residualPercentage')
      ?.valueChanges.subscribe({ next: () => this.calculate() });

    this.SetCustomRateReason();

    this.calculate();

    this.addFormValidation();
  }

  addFormValidation() {
    this.contractTypeField?.setValidators(
      Validators.required('Contract Type is required')
    );
    this.contractTypeField?.updateValueAndValidity();

    const rateTypeField = this.form.get('programTypeId');
    rateTypeField?.setValidators(Validators.required('Rate Type is required'));
    rateTypeField?.updateValueAndValidity();

    const bonusTypeField = this.form.get('bonusType');
    bonusTypeField?.setValidators(Validators.required('Rate Type is required'));
    bonusTypeField?.updateValueAndValidity();
  }

  addFormCustomRateValidation(needValidation: boolean) {
    const interestRate = this.form.get('interestRate');
    if (needValidation) {
      this.customRateReasonField?.setValidators(
        Validators.required('Custom Rate Reason is required')
      );
      interestRate?.setValidators(
        Validators.required('Contract Interest Rate is required')
      );
    } else {
      this.customRateReasonField?.clearValidators();
      interestRate?.clearValidators();
    }
    this.customRateReasonField?.updateValueAndValidity();
    interestRate?.updateValueAndValidity();
  }

  calculate() {
    this.loanCalculatorSvc.equipment = this.equipmentItems;
    this.loanCalculatorSvc.cashDown = Number(this.form.get('cashDown')?.value);
    this.loanCalculatorSvc.rentalCredit = Number(
      this.form.get('rentalCredit')?.value
    );
    this.loanCalculatorSvc.filingFee = this.filingFee;
    this.loanCalculatorSvc.dealerDocFee = Number(
      this.form.get('dealerDocFee')?.value
    );
    this.loanCalculatorSvc.contractType = this.contractTypeField?.value;
    this.loanCalculatorSvc.residualPercentage = Number(
      this.form.get('residualPercentage')?.value
    );
    //need this if we want to calculate othersfees correctly for FL/TN
    // this.loanCalculatorSvc.customerState = 'NE';
    this.loanCalculatorSvc.dealerState =
      this.currentUserSvc.profile?.state || '';

    this.loanCalculatorSvc.calculate();

    this.amountToFinance?.setValue(this.loanCalculatorSvc.amountToFinance);
    this.form.get('filingFee')?.setValue(this.loanCalculatorSvc.filingFee);

    //do we need more fields set to the form for saving after calculation is completed like in dealer app?
    // such as residual amount, tradinLoan, tradein, salePrice.. etcc..

    this.selectInterestRate();
  }
  getDownPaymentPercentage() {
    switch (this.form.get('paymentFrequency')?.value) {
      case 'Annual':
        return 0.2;
      case 'Semi-Annual':
      case 'Quarterly':
        return 0.15;
      case 'Monthly':
        return 0.1;
      default:
        return -1;
    }
  }
  selectInterestRate() {
    const term = this.form.get('contractTerm')?.value;

    if (this.isCustomInterestRate || term === null || term === undefined) {
      return;
    }
    const rateForTerms = this.applicationTermsService.getRateForTerms(
      this.workingRates,
      this.form.get('programTypeId')?.value,
      term,
      this.amountToFinance?.value
    );

    if (!rateForTerms || rateForTerms <= 0) {
      this.outsideRateTier = true;
      this.form.get('interestRate')?.setValue(null);
    } else {
      this.outsideRateTier = false;
      this.form.get('interestRate')?.setValue(rateForTerms);
      this.form.updateValueAndValidity();
    }
  }

  setWorkingRates() {
    const contractType = this.contractTypeField?.value;
    if (contractType !== null) {
      this.workingRates = this.applicationTermsService.getWorkingRates(
        this.rates,
        contractType
      );
    }

    this.selectInterestRate();
  }

  changedRateType() {
    const programTypeId: number = this.form.get('programTypeId')?.value;
    var isCustomRate: boolean;
    if (programTypeId === 0) {
      isCustomRate = true;
      this.outsideRateTier = false;
    } else {
      isCustomRate = false;
      this.outsideRateTier = false;
      this.form.get('customRateReason')?.setValue(null);
    }
    this.addFormCustomRateValidation(isCustomRate);

    this.isCustomInterestRate = isCustomRate;

    this.setWorkingRates();
    this.selectInterestRate();
  }

  getFilingFee() {
    var filingFee!: number;

    this.filingFeeService
      .getFeeByProductLine(this.productLineId)
      .subscribe((fee) => {
        filingFee = fee.fee;
      });

    if (filingFee === undefined || filingFee === null) {
      filingFee = environment.defaultFilingFee;
    }

    this.filingFee = filingFee;
  }

  override ngOnDestroy(): void {
    super.ngOnDestroy();
  }
}
