import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { CreditApplication } from './credit-application.model';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({ providedIn: 'root' })
export class CustomerCreditApplicationRepository {
  constructor(private httpClient: HttpClient) {}

  create(creditApplication: CreditApplication): Observable<number> {
    const url = `${environment.ApiBaseUrl}/customer-credit-applications`;
    return this.httpClient.post<number>(url, creditApplication);
  }
}
