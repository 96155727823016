<section>
  <div class="modal-header">
    <h4 class="text-primary mb-4">
      Job Description: <b>{{ jobPost.jobTitle }}</b>
    </h4>
    <button
      type="button"
      class="btn-close"
      (click)="modalRef.hide()"
      aria-label="Close"
    ></button>
  </div>
  <div
    class="modal-body"
    [innerHTML]="jobPost.jobDescription"
    style="max-height: calc(100vh - 210px); overflow-y: auto"
  ></div>
  <div class="modal-footer">
    <a
      class="btn btn-primary text-black"
      href="/jobApplication/{{ jobPost.id }}/{{jobPost.jobTitle}}"
      >Apply</a
    >
    <button
      type="button"
      aria-label="Cancel"
      (click)="modalRef.hide()"
      class="btn btn-secondary text-black"
    >
      Close
    </button>
  </div>
</section>
