import { Injectable } from '@angular/core';

import { ToastrService } from 'ngx-toastr';

export enum Positions {
  topRight = 'top-right',
  topCenter = 'top-center',
  topLeft = 'top-left',
  topFullWidth = 'top-full-width',
  bottomRight = 'bottom-right',
  bottomCenter = 'bottom-center',
  bottomLeft = 'bottom-left',
  bottomFullWidth = 'bottom-full-width',
  centerScreen = 'center-screen',
}
@Injectable({
  providedIn: 'root',
})
export class UserNotificationSvc {
  constructor(private readonly toastr: ToastrService) {
    toastr.toastrConfig.timeOut = 10000;
    toastr.toastrConfig.positionClass = 'toast-top-right';
  }

  readonly topRight = Positions.topRight;
  readonly topCenter = Positions.topCenter;
  readonly topLeft = Positions.topLeft;
  readonly topFullWidth = Positions.topFullWidth;
  readonly bottomRight = Positions.bottomRight;
  readonly bottomCenter = Positions.bottomCenter;
  readonly bottomLeft = Positions.bottomLeft;
  readonly bottomFullWidth = Positions.bottomFullWidth;
  readonly centerScreen = Positions.centerScreen;

  clear() {
    this.toastr.remove(this.toastr.currentlyActive);
  }

  error(
    message?: string,
    title?: string,
    duration?: number,
    position?: Positions
  ) {
    this.setToastrOptions(duration, position);
    this.toastr.error(message, title);
  }

  info(
    message?: string,
    title?: string,
    duration?: number,
    position?: Positions
  ) {
    this.setToastrOptions(duration, position);
    this.toastr.info(message, title);
  }

  success(
    message?: string,
    title?: string,
    duration?: number,
    position?: Positions
  ) {
    this.setToastrOptions(duration, position);
    this.toastr.success(message, title);
  }

  warning(
    message?: string,
    title?: string,
    duration?: number,
    position?: Positions
  ) {
    this.setToastrOptions(duration, position);
    this.toastr.warning(message, title);
  }

  private setToastrOptions(
    duration: number | undefined,
    position: Positions | undefined
  ) {
    if (duration || duration === 0) {
      this.toastr.toastrConfig.timeOut = duration;
    }
    if (position) {
      this.toastr.toastrConfig.positionClass = 'toast-' + position;
    }
  }
}
