<main>
    <section style="background-image: url('assets/images/faqs-hero.jpg');background-size: cover;background-position:center;">
    <div class="container px-4 py-5">
      <div class="row align-items-start g-lg-5 py-5">
        <div class="col-lg-10 text-lg-start text-light">
          <h1 class="display-4 lh-1 mb-3 py-4">FAQs</h1>
        </div>
      </div>
    </div>
  </section>
  <div class="container">
    <div class="row px-4 py-4 text-center">
      <p class="display-5 mb-4">FAQs</p>
    </div>
    <div class="row pb-5">
      <div class="accordion" id="faqs">
        <div *ngFor="let item of faqs "class="accordion-item border-1 border-top mb-4 rounded">
          <h2 class="accordion-header" id="{{'faq-heading'+ item.order}}">
            <button class="accordion-button collapsed bg-body rounded" type="button" data-bs-toggle="collapse" [attr.data-bs-target]="'#faq-collapse'+item.order" aria-expanded="false" [attr.aria-controls]="'faq-collapse'+item.order">
              <span [innerHTML]="item.question"></span>
            </button>
          </h2>
          <div id="{{'faq-collapse'+ item.order}}" class="accordion-collapse collapse border-0" [attr.aria-labelledby]="'faq-heading'+item.order">
            <div class="accordion-body">
              <span [innerHTML]="item.answer"></span>
            </div>
          </div>
        </div>

      </div>
    </div>
  </div>
</main>
