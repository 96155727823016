import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, Observable, throwError } from 'rxjs';
import { ISalesPoint } from '../interfaces/sales-point';

@Injectable({
  providedIn: 'root',
})
export class SalesPointService {
  private salesPointUrl = 'api/sales-points.json';

  constructor(private http: HttpClient) {}

  getBulletPointsForEquipmentType(): Observable<ISalesPoint[]> {
    // this will eventually call our API endpoint to pull data from the database.
    return this.http
      .get<ISalesPoint[]>(this.salesPointUrl)
      .pipe(catchError(this.handleError));
  }
  private handleError(err: HttpErrorResponse): Observable<never> {
    let errorMessage = 'There was an issue retrieving sales bullet points!';
    if (err.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      errorMessage = `An error occurred: ${err.error.message}`;
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      errorMessage = `Server returned code: ${err.status}, error message is: ${err.message}`;
    }
    console.error(errorMessage);
    return throwError(() => errorMessage);
  }
}
