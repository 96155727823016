import { Component, Input, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { ReplaySubject, Subject } from 'rxjs';

@Component({
  selector: 'fin-popup-message-modal',
  templateUrl: './popup-message-modal.component.html',
  styleUrls: ['../../PortalMain.scss'],
})
export class PopupMessageModalComponent implements OnInit {
  @Input() title!: string;
  @Input() message!: string;
  @Input() okButtonCaption: string = 'Ok';
  @Input() cancelButtonCaption: string = 'Cancel';

  public onClose!: Subject<string>;
  public okClicked: boolean = false;

  constructor(private readonly bsModalRef: BsModalRef) {}

  ngOnInit() {
    this.onClose = new Subject();
  }

  action() {
    this.okClicked = true;
    this.onClose.next(this.okButtonCaption);
    this.bsModalRef.hide();
  }

  close() {
    this.onClose.next(this.cancelButtonCaption);
    this.bsModalRef.hide();
  }
}
