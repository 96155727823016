import { Component, Input, OnInit } from '@angular/core';
import {
  CurrentUserService,
  Dealer,
  FileDetails,
  MessageBoxModalService,
} from 'dfs-common-ngx';
import { MenuItem } from 'primeng/api';
import { Subscription } from 'rxjs';
import { FileSaverService } from 'ngx-filesaver';
import { PrintedCreditApplicationsRepo } from '../../dfs-fin-api/PrintedCreditApplicationsRepo';
import { UserSubmittedApplicationsRepo } from '../../dfs-fin-api/UserSubmittedApplicationsRepo';
import { CreditApplicationItem } from '../../interfaces/credit-application-item';
import {
  Positions,
  UserNotificationSvc,
} from '../../common/services/UserNotificationSvc';
import { ViewAttachmentsModalSvc } from '../view-attachments/ViewAttachmentsModalSvc';
import { FileAttachmentsRepo } from '../../dfs-fin-api/FileAttachmentsRepo';
import { NgbDropdown } from '@ng-bootstrap/ng-bootstrap';
import { CreditApplicationsRepo } from 'src/app/dfs-fin-api/CreditApplicationsRepo';
import { FinishAppLinkRepo } from 'src/app/dfs-fin-api/FinishAppLinkRepo';
import { DeleteApplicationModalSvc } from 'src/app/shared/application-grid/DeleteApplicationModalSvc';
import { Router } from '@angular/router';
import { ViewReassignAppModalService } from './view-reassign-app-service';
import { ViewEsignatureEnvelopeStatusService } from 'src/app/shared/esignatures/view-esignature-envelope-status.service';
import { EsignatureEnvelopeStatusService } from 'src/app/shared/esignatures/esignature-envelope-status.service';
import { UserRole } from 'src/app/enums/user-role';

@Component({
  selector: 'fin-application-grid',
  templateUrl: './application-grid.component.html',
  styleUrls: ['../../PortalMain.scss'],
})
export class ApplicationGridComponent implements OnInit {
  @Input() statusTypes: string[] = [];
  @Input() showCurrentlFields: boolean = false;
  @Input() showApprovalFields: boolean = false;
  @Input() allowReassign: boolean = false;
  @Input() captionText: string = '';

  sub!: Subscription;
  dealer: Dealer | undefined;
  errorMessage: string = '';
  applications: CreditApplicationItem[] = [];
  cols: any[] = [];
  isDealerAdmin: boolean | undefined = false;
  actionItems: MenuItem[] = [];
  loadingData: boolean = true;
  runningPrintSummaryReport: boolean = false;
  rerouting: boolean = false;
  selectedApplicationId: number | undefined;

  constructor(
    private appService: UserSubmittedApplicationsRepo,
    private readonly currentUserSvc: CurrentUserService,
    private printService: PrintedCreditApplicationsRepo,
    private readonly fileSaverService: FileSaverService,
    private viewAttachmentsModalSvc: ViewAttachmentsModalSvc,
    private userNotificationSvc: UserNotificationSvc,
    private fileAttachmentsRepo: FileAttachmentsRepo,
    private creditApplicationsRepo: CreditApplicationsRepo,
    private readonly messageBoxModalService: MessageBoxModalService,
    private deleteApplicationModalSvc: DeleteApplicationModalSvc,
    private readonly viewReassignModalSvc: ViewReassignAppModalService,
    private finishAppLinkRepo: FinishAppLinkRepo,
    private readonly router: Router,
    private esignatureEnvelopeStatusService: EsignatureEnvelopeStatusService,
    private viewEsignatureEnvelopeStatusService: ViewEsignatureEnvelopeStatusService
  ) {}

  ngOnInit(): void {
    this.loadingData = true;

    this.isDealerAdmin = this.currentUserSvc.profile?.role.includes(
      UserRole.DealerAdmin
    );

    if (this.isDealerAdmin === undefined) {
      this.isDealerAdmin = false;
    }

    this.setColumnArray();

    this.sub = this.appService.getByType(this.statusTypes).subscribe({
      next: (items) => {
        this.applications = items;
        this.loadingData = false;
      },
    });
  }

  actionPrint(applicationId: number) {
    this.runningPrintSummaryReport = true;
    this.selectedApplicationId = applicationId;
    this.sub = this.printService.getForUser(applicationId).subscribe({
      next: (fileDetails: FileDetails) => {
        this.fileSaverService.save(fileDetails.file, fileDetails.fileName);
        this.runningPrintSummaryReport = false;
        this.selectedApplicationId = undefined;
      },
    });
  }

  actionViewAttachments(applicationId: number) {
    try {
      this.sub = this.fileAttachmentsRepo.getAll(applicationId).subscribe({
        next: (attachments: any) => {
          this.viewAttachmentsModalSvc.display(attachments);
        },
      });
    } catch (error) {
      this.userNotificationSvc.error(
        'There was an error retrieving the application attachments.',
        'Error',
        10000,
        Positions.topRight
      );
    }
  }

  actionViewEsignatureStatus(applicationId: number) {
    try {
      this.sub = this.esignatureEnvelopeStatusService
        .getCurrentEnvelopeStatus(applicationId)
        .subscribe({
          next: (envelopeStatus: any) => {
            this.viewEsignatureEnvelopeStatusService.display(envelopeStatus);
          },
        });
    } catch (error) {
      this.userNotificationSvc.error(
        'There was an error retrieving the application attachments.',
        'Error',
        10000,
        Positions.topRight
      );
    }
  }

  editApplication(applicationId: string, sentToCustomerDate: Date) {
    this.creditApplicationsRepo.getOne(applicationId).subscribe((app) => {
      const unsubmittedStatusId = 0;
      if (app.applicationStatusId !== unsubmittedStatusId) {
        const messageBody =
          'The application has been submitted, edit not allowed.';
        const messageTitle = 'Edit Not Allowed';
        this.messageBoxModalService.show(messageBody, messageTitle);
        return;
      }
      if (sentToCustomerDate) {
        this.router.navigate(['dealerStartApp', applicationId]);
      } else {
        this.transitionApp(applicationId);
      }
    });
  }

  transitionApp(applicationId: string | undefined) {
    const dealer = this.currentUserSvc.profile as Dealer;

    var appType: string = 'farm-application';

    if (dealer.productLine === 'Irrigation') {
      appType = 'irrigation-application';
    }

    this.router.navigate([`creditApplication/${appType}`, applicationId]);
  }

  deleteApplication(applicationId: string) {
    const app = this.applications.filter(
      (x) => x.applicationId === applicationId
    )[0];

    this.deleteApplicationModalSvc.display(
      app.primaryCustomerName,
      applicationId
    );
  }

  sendFinishAppLink(applicationId: string) {
    this.creditApplicationsRepo.getOne(applicationId).subscribe((app) => {
      const unsubmittedStatusId = 0;
      if (app.applicationStatusId !== unsubmittedStatusId) {
        const messageBody =
          'The application has been submitted, edit not allowed.';
        const messageTitle = 'Edit Not Allowed';
        this.messageBoxModalService.show(messageBody, messageTitle);
        return;
      }
      this.finishAppLinkRepo.create(applicationId.toString()).subscribe({
        next: () => {
          this.userNotificationSvc.success(
            'The customer has been e-mailed a new link to finish the application.',
            'Success',
            10000,
            this.userNotificationSvc.topRight
          );
        },
        error: () => {
          this.userNotificationSvc.error(
            'An error has occurred while trying to e-mail a new link to the customer. ' +
              'Please try again or contact Online Support.',
            'Error',
            10000,
            this.userNotificationSvc.topRight
          );
        },
      });
    });
  }

  showEsignDetail(esignatureEnvelopeStatus: string) {
    let invalidEnvelopeStatuses: string[] = [
      'Delivered',
      'Completed',
      'Correcting',
      'Sent',
      'Waiting for Others',
    ];
    return invalidEnvelopeStatuses.includes(esignatureEnvelopeStatus);
  }

  viewManagementScreen(application: CreditApplicationItem) {
    this.viewReassignModalSvc.show(application);
  }
  showSpinner(applicationId: number) {
    this.selectedApplicationId = applicationId;
    this.rerouting = true;
  }
  shouldDisplaySpinner(applicationId: number): boolean {
    // add additional logic here to check for other menu items clicked
    return (
      (this.runningPrintSummaryReport || this.rerouting) &&
      this.selectedApplicationId === applicationId
    );
  }
  setColumnArray() {
    var loadedCols = [
      {
        header: 'Application Date',
        field: 'applicationDate',
        type: 'date',
        show: true,
      },
      {
        header: 'Application Status',
        field: 'applicationStatus',
        type: 'text',
        show: this.statusTypes.length > 1,
      },
      {
        header: 'Salesperson',
        field: 'salespersonName',
        type: 'text',
        show: this.isDealerAdmin,
      },
      {
        header: 'Customer Name',
        field: 'primaryCustomerName',
        type: 'text',
        show: true,
      },
      {
        header: 'Dealer Location',
        field: 'dealerLocation',
        type: 'text',
        show: this.isDealerAdmin,
      },
      {
        header: 'City',
        field: 'primaryCustomerCity',
        type: 'text',
        show: true,
      },
      {
        header: 'State',
        field: 'primaryCustomerState',
        type: 'text',
        show: true,
      },
      {
        header: 'Approval Status',
        field: 'conditionalApprovalStatus',
        type: 'text',
        show: this.showApprovalFields,
      },
      {
        header: 'E-Sign Status',
        field: 'esignatureEnvelopeStatus',
        type: 'text',
        show: this.showApprovalFields,
      },
    ];

    this.cols = loadedCols.filter((c) => c.show === true);
  }
}
