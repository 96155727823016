import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'groupBy' })
export class GroupByPipe implements PipeTransform {
  transform(collection: undefined|null, property: string): undefined;
  transform<T extends { [key: string]: any}>(collection: Array<T>, property: string): Array<T>;
  transform<T extends { [key: string]: any}>(collection: Array<T>|undefined|null, property: string): Array<T>|undefined {
    // prevents the application from breaking if the array of objects doesn't exist yet
    if (!collection) {
      return undefined;
    }

    const groupedCollection = collection.reduce((previous, current) => {
      if (!previous[current[property]]) {
        previous[current[property]] = [current];
      } else {
        previous[current[property]].push(current);
      }

      return previous;
    }, {} as any);

    return groupedCollection;
  }
}
