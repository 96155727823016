import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { map } from 'rxjs';

import { environment } from '../../environments/environment';
import { HttpHeaderParseSvc } from '../common/services/HttpHeaderParseSvc';

@Injectable()
export class FileAttachmentsRepo {
  constructor(
    private http: HttpClient,
    private headerParseSvc: HttpHeaderParseSvc
  ) {}

  saveToFileSystem(applicationId: string | number, attachments: FormData) {
    const url = this.buildUrl(applicationId);

    return this.http.post(url, attachments);
  }
  saveToDbOnly(applicationId: string | number, attachments: FormData) {
    const url = this.buildUrl(applicationId) + '/save';

    return this.http.post(url, attachments);
  }

  getAll(applicationId: string | number) {
    const url = this.buildUrl(applicationId.toString());

    return this.http.get<any>(url);
  }

  get(applicationId: string | number, fileAttachmentId: string) {
    const url = this.buildUrl(applicationId) + '/' + fileAttachmentId;

    return this.http
      .get(url, { responseType: 'blob', observe: 'response' })
      .pipe(
        map((result) => {
          const fileName = this.headerParseSvc.getFileName(result);
          const file = {
            file: result.body,
            fileName: fileName,
          };
          return file;
        })
      );
  }

  delete(applicationId: string | number, fileAttachmentId: string) {
    const url = this.buildUrl(applicationId) + '/' + fileAttachmentId;

    return this.http.delete(url);
  }

  private buildUrl(applicationId: string | number) {
    const url = `${environment.ApiBaseUrl}/users/credit-applications/${applicationId}/attachments`;

    return url;
  }
}
