<h2>Credit Application</h2>
<hr />
<div class="card">
  <form
    class="wizard-container"
    novalidate
    [formGroup]="creditApplicationForm"
    #caFormDirective="ngForm"
  >
    <ca-steps></ca-steps>

    <div class="wizard-page-container">
      <ng-container [ngSwitch]="currentStep.stepIndex">
        <ng-container *ngSwitchCase="1">
          <br />
          <ca-basic-information
            [step]="currentStep"
            [form]="basicInformationForm"
            [salesPersonsData]="salesPersonsData"
            [salesPersonsLoading]="salesPersonsLoading"
            (setRates)="setRates()"
          ></ca-basic-information>
        </ng-container>
        <ng-container *ngSwitchCase="2">
          <br />
          <ca-applicant-details
            [step]="currentStep"
            [form]="applicantDetailsForm"
          ></ca-applicant-details>
        </ng-container>
        <ng-container *ngSwitchCase="3">
          <ca-financial-info
            [step]="currentStep"
            [form]="businessFinancialInformationForm"
            [mainApplicantDateOfBirth]="
              applicantDetailsForm.value.mainApplicant.dateOfBirth
            "
            [yearsInBusinessOptions]="yearsInBusinessOptions"
          ></ca-financial-info>
        </ng-container>
        <ng-container *ngSwitchCase="4">
          <ca-equipment-step
            [step]="currentStep"
            [form]="equipmentForm"
            [equipmentType]="equipmentType"
            [sellerType]="basicInformationForm.get('sellerType')!.value"
          ></ca-equipment-step>
        </ng-container>
        <ng-container *ngSwitchCase="5">
          <ca-contract-terms
            [step]="currentStep"
            [form]="contractTermsForm"
            [equipmentItems]="equipmentItems"
            [equipmentType]="basicInformationForm.get('equipmentType')!.value"
            [sellerType]="basicInformationForm.get('sellerType')!.value"
            [rates]="rates"
          ></ca-contract-terms>
        </ng-container>
        <ng-container *ngSwitchCase="6">
          <fin-submit
            [step]="currentStep"
            [form]="submissionDetails"
            [equipmentType]="equipmentType"
            [captchaKey]="captchaKey"
            [sellerType]="basicInformationForm.get('sellerType')!.value"
            (attachFileUpload)="attachFile($event)"
            (captchaValue)="getCaptchaValue($event)"
            (creditReportAuthorizedChange)="
              creditReportAuthorizationChangeInSubmit($event)
            "
          ></fin-submit>
        </ng-container>
      </ng-container>
    </div>

    <div class="wizard-button-row">
      <button
        type="button"
        (click)="onPreviousStep()"
        [disabled]="isFirstStep"
        class="dfs-btn dfs-btn-primary"
      >
        Previous
      </button>
      <button
        type="button"
        (click)="onNextStep($event)"
        [ngClass]="
          nextButtonText === 'Submit'
            ? 'dfs-btn dfs-btn-success'
            : 'dfs-btn dfs-btn-primary'
        "
      >
        {{ nextButtonText }}
        <dfs-spinner
          style="position: relative"
          [radius]="10"
          [lines]="10"
          [width]="5"
          [length]="6"
          [top]="'.9%'"
          [left]="'-25px'"
        ></dfs-spinner>
      </button>
    </div>
  </form>

  <div *ngIf="isShowInvalidEntries" class="text-danger">
    Some of the entries on the application are invalid and need to be corrected.
    Required fields are marked with an asterisk *
    <br />
  </div>
  <br />
</div>
