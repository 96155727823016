import { Component, HostListener, OnInit } from '@angular/core';
import { FormArray, FormGroup, UntypedFormBuilder } from '@angular/forms';
import { JwtHelperService } from '@auth0/angular-jwt';
import {
  ContractTerms,
  MessageBoxModalService,
  SpinnerService,
} from 'dfs-common-ngx';
import {
  FormSubmitNotificationService,
  SellerType,
  StepModel,
  StepsService,
  BusinessTypesList,
  YearsInBusiness,
} from 'dfs-credit-application-ngx';
import { ToastrService } from 'ngx-toastr';
import { of } from 'rxjs';
import { concatMap, finalize } from 'rxjs/operators';
import { CustomerFinishedApplication } from '../customer-finish-application.model';
import { CustomerFinishedAppRepo } from '../customer-finished-app.repository';
import { CustomerFinishedAppAttachmentRepository } from '../customer-finished-app-attachments.repository';
import { YearsInBusinessRepository } from 'src/app/dfs-fin-api/years-in-business.repository';
import { SubmitSuccessModalService } from 'src/app/shared/wizard-forms/submit/submit-success-modal.service';
import { DealerContractTermsRepo } from 'src/app/dfs-fin-api/DealerContractTermsRepo';
import { ApplicationRate } from 'src/app/interfaces/ApplicationRate';

@Component({
  selector: 'cfa-customer-finish-app',
  templateUrl: 'customer-finish-app.component.html',
  styleUrls: [`../../PortalMain.scss`],
})
export class CustomerFinishAppComponent implements OnInit {
  private token!: string;
  creditApplicationForm!: FormGroup;
  originalApplication!: CustomerFinishedApplication;
  invalidSubmitAttempted = false;
  rates!: ApplicationRate[];
  businessTypes = BusinessTypesList;
  yearsInBusinessOptions!: YearsInBusiness[];

  isSubmissionSuccessful = false;
  sellerType = SellerType.customerFinish;
  fileAttachments = new FormData();
  @HostListener('window:beforeunload', ['$event'])
  checkForChanges($event: any) {
    if (this.creditApplicationForm.dirty && !this.isSubmissionSuccessful) {
      $event.returnValue =
        'You are about to leave the credit application. The application will not be saved. Are you sure you wish to continue?';
    }
  }
  get currentStep(): StepModel {
    return this.stepsService.currentStep;
  }
  get steps(): StepModel[] {
    return this.stepsService.steps;
  }
  get nextButtonText() {
    return !this.stepsService.isLastStep ? 'Next' : 'Submit';
  }
  get isFirstStep() {
    return this.stepsService.isFirstStep;
  }
  get isShowInvalidEntries(): boolean {
    return (
      this.invalidSubmitAttempted &&
      (!this.currentStep.isComplete ||
        (this.stepsService.isLastStep && !this.creditApplicationForm.valid))
    );
  }
  get mainApplicantState() {
    return this.applicantDetailsForm?.value.mainApplicant.address.state;
  }
  get mainApplicantDateOfBirth() {
    const mainDob = this.applicantDetailsForm
      ?.get('mainApplicant')
      ?.get('dateOfBirth');
    return mainDob ? mainDob.value : null;
  }
  get applicantDetailsForm(): any {
    return this.creditApplicationForm.get('applicantDetails');
  }
  get businessFinancialInformationForm(): any {
    return this.creditApplicationForm.get('businessFinancialInformation');
  }
  get equipmentForm(): any {
    return this.creditApplicationForm.get('equipment');
  }
  get equipmentItems() {
    return this.equipmentForm?.get('equipments')?.value;
  }
  get contractTermsForm(): any {
    return this.creditApplicationForm.get('contractTerms');
  }
  get submissionDetailsForm(): any {
    return this.creditApplicationForm.get('submissionDetails');
  }

  constructor(
    private readonly stepsService: StepsService,
    private readonly toastr: ToastrService,
    private readonly messageBoxModalService: MessageBoxModalService,
    private readonly spinnerService: SpinnerService,
    private readonly submitNotificationService: FormSubmitNotificationService,
    private readonly successModalService: SubmitSuccessModalService,
    private readonly customerFinishedAppRepo: CustomerFinishedAppRepo,
    private readonly customerFinishedAppAttachmentsRepo: CustomerFinishedAppAttachmentRepository,
    private readonly fb: UntypedFormBuilder,
    private readonly dealerContractTermsRepo: DealerContractTermsRepo,
    private readonly yearsInBusinessRepo: YearsInBusinessRepository
  ) {
    this.stepsService.steps = [
      {
        stepIndex: 1,
        title: 'Applicants',
        isComplete: false,
      },
      {
        stepIndex: 2,
        title: 'Business/Financial Information',
        isComplete: false,
      },
      {
        stepIndex: 3,
        title: 'Equipment',
        isComplete: false,
      },
      {
        stepIndex: 4,
        title: 'Contract Terms',
        isComplete: false,
      },
      {
        stepIndex: 5,
        title: 'Submit',
        isComplete: false,
      },
    ];
  }

  ngOnInit(): void {
    this.getApplicationData();

    this.creditApplicationForm = new FormGroup({
      businessFinancialInformation: new FormGroup({}),
      contractTerms: new FormGroup({}),
      submissionDetails: new FormGroup({}),
    }) as FormGroup;

    this.yearsInBusinessRepo.getYearsInBusiness().subscribe((result) => {
      this.yearsInBusinessOptions = result;
    });
  }

  private getApplicationData() {
    const parameters = new URLSearchParams(window.location.search);

    this.token = parameters.get('token') || '';
    const helper = new JwtHelperService();
    const tokenData = helper.decodeToken(this.token);
    const applicationId = tokenData?.['application-id'];
    this.customerFinishedAppRepo.getOne(applicationId, this.token).subscribe({
      next: (app) => {
        if (
          this.applicationLinkIsExpired(app.sentToCustomerDate) ||
          this.applicationIsSubmitted(app.applicationStatusId)
        ) {
          return;
        }
        this.dealerContractTermsRepo
          .getApplicationRates(app.contractTerms.dealerCode, app.dealerTypeId)
          .pipe(finalize(() => this.spinnerService.hide()))
          .subscribe((data) => {
            this.rates = data;
            this.buildEquipmentForm(app);
            this.buildApplicantDetailsForm(app);
            this.originalApplication = app;
            this.creditApplicationForm.addControl(
              'applicationStartedTypeCode',
              this.fb.control(
                this.originalApplication.applicationStartedTypeCode
              )
            );
          });
      },
      error: (e) => {
        console.error(e);
        this.spinnerService.hide();
        const message =
          'There was an issue retrieving the application data. ' +
          'If the issue persists, click here to contact Online Support.';
        this.toastr.error(
          `<a href="mailto:OnlineSupport@dfsfin.com">
              ${message}
             </a>`,
          'Error',
          { enableHtml: true }
        );
      },
    });

    this.spinnerService.show();
  }

  private buildEquipmentForm(application: CustomerFinishedApplication) {
    const equipmentGroup = this.fb.group(application.equipment);

    const equipments = this.fb.array([]);
    application.equipment.equipments.forEach((e) =>
      equipments.push(this.fb.group(e))
    );
    equipmentGroup.setControl('equipments', equipments);
    equipmentGroup.addControl('damageInsurance', this.fb.control(null));

    this.creditApplicationForm.addControl('equipment', equipmentGroup);
  }

  private buildApplicantDetailsForm(application: CustomerFinishedApplication) {
    const applicantDetailsGroup = this.fb.group({
      mainApplicant: this.fb.group({
        businessType: this.fb.control(this.businessTypes[0]),
        ssn: this.fb.control(null),
        address: this.fb.group({
          address: this.fb.control(null),
          city: this.fb.control(null),
          state: this.fb.control(null),
          postalCode: this.fb.control(null),
          county: this.fb.control(null),
        }),
        phone: this.fb.control(null),
        businessPhone: this.fb.control(null),
        email: this.fb.control(
          application.applicantDetails.mainApplicant.email
        ),
        dba: this.fb.control(null),
        spouseIsCoapp: this.fb.control(null),
        spouseName: this.fb.control(null),
      }),
    });

    this.creditApplicationForm.addControl(
      'applicantDetails',
      applicantDetailsGroup
    );
  }

  attachFile(fileData: any) {
    const fileSizeLimit = 1024 * 1000 * 8;
    if (fileData.file.size > fileSizeLimit) {
      const modalTitle = 'Attachment File Size';
      const modalMessage =
        'Attachment is larger than 8MB.  It will not be uploaded.';
      this.messageBoxModalService.show(modalMessage, modalTitle);
      return;
    } else {
      if (this.fileAttachments.has(fileData.fileType)) {
        this.fileAttachments.delete(fileData.fileType);
      }
      this.fileAttachments.append(fileData.fileType, fileData.file);
    }
  }
  creditReportAuthorizationChangeInSubmit(value: any) {
    let submissionDetail = this.submissionDetailsForm?.get(
      'CustomerCreditReportAuthorization'
    );

    if (value === true) {
      submissionDetail?.setValue(this.getApplicantName());
    } else {
      submissionDetail?.setValue(null);
    }
  }

  getApplicantName(): string {
    let mainApplicant = this.applicantDetailsForm?.get('mainApplicant');
    let name: string;
    if (!mainApplicant) {
      return '';
    }
    if (mainApplicant?.value.businessType?.id === 1) {
      //Ind./Proprietorship
      name =
        mainApplicant?.value.firstName + ' ' + mainApplicant?.value.lastName;
    } else {
      let businessPartner = mainApplicant.get('businessPartners')?.value[0];
      name = businessPartner.firstName + ' ' + businessPartner.lastName;
    }

    return name;
  }
  private applicationIsSubmitted(applicationStatusId: number): boolean {
    if (applicationStatusId !== 0) {
      const modalMessage = 'Your application has already been submitted.';
      const modalTitle = 'Application Submitted';
      this.spinnerService.hide();
      this.messageBoxModalService.show(modalMessage, modalTitle);
      return true;
    }
    return false;
  }
  private applicationLinkIsExpired(sentToCustomerDate: Date): boolean {
    const now = new Date();
    const expirationDate = new Date(sentToCustomerDate);
    const expirationDaysToAdd = 7;
    expirationDate.setDate(expirationDate.getDate() + expirationDaysToAdd);
    if (expirationDate < now) {
      const modalMessage =
        'This application link has expired. Please contact your dealer for a new link.';
      const modalTitle = 'Link Expired';
      this.spinnerService.hide();
      this.messageBoxModalService.show(modalMessage, modalTitle);
      return true;
    }
    return false;
  }

  onNextStep(event: MouseEvent) {
    this.equipmentForm?.updateValueAndValidity();

    if (this.currentStep && !this.currentStep.isComplete) {
      this.submitNotificationService.notify();
      this.markCurrentFormAsTouched(this.currentStep.title);
      this.invalidSubmitAttempted = true;
      return;
    }

    this.invalidSubmitAttempted = false;
    if (!this.stepsService.isLastStep) {
      this.stepsService.moveToNextStep();
    } else {
      this.onSubmit(event);
    }
  }

  onPreviousStep() {
    if (!this.stepsService.isFirstStep) {
      this.stepsService.moveToPreviousStep();
    }
  }

  markCurrentFormAsTouched(formName: string) {
    switch (formName) {
      case 'Applicants':
        this.applicantDetailsForm?.markAllAsTouched();
        break;
      case 'Business/Financial Information':
        this.businessFinancialInformationForm?.markAllAsTouched();
        break;
      case 'Equipment':
        this.equipmentForm?.markAllAsTouched();
        break;
      case 'Contract Terms':
        this.contractTermsForm?.markAllAsTouched();
        break;
      case 'Submit':
        this.submissionDetailsForm?.markAllAsTouched();
        break;
      default:
        throw new Error('Invalid step name');
    }
  }

  onSubmit(event: MouseEvent): void {
    if (this.creditApplicationForm.valid) {
      this.invalidSubmitAttempted = false;
      const creditApp = {
        ...this.creditApplicationForm.value,
      } as CustomerFinishedApplication;
      creditApp.id = this.originalApplication.id;
      creditApp.contractTerms.filingFee =
        this.originalApplication.contractTerms.filingFee;
      this.formatComments(creditApp);

      (event.target as HTMLButtonElement).disabled = true;
      this.spinnerService.show();
      this.customerFinishedAppRepo
        .update(creditApp, this.token)
        .pipe(
          concatMap((result) => {
            this.originalApplication.rapportAppNumber = result.rapportAppNumber;
            if (this.fileAttachments.entries().next().done) {
              return of({});
            }
            return this.customerFinishedAppAttachmentsRepo.submit(
              creditApp.id,
              this.fileAttachments
            );
          }),
          finalize(() => {
            (event.target as HTMLButtonElement).disabled = false;
            this.spinnerService.hide();
          })
        )
        .subscribe({
          next: () => {
            this.isSubmissionSuccessful = true;
            this.successModalService.show(
              this.sellerType,
              this.originalApplication.rapportAppNumber
            );
          },
          error: (err) => {
            console.error(err);
            this.toastr.error(
              `<a href="mailto:OnlineSupport@dfsfin.com">
              There was an issue submitting the application.
              If the issue persists, click here to contact Online Support.
             </a>`,
              'Error',
              { enableHtml: true }
            );
          },
        });
    } else {
      this.invalidSubmitAttempted = true;
      this.creditApplicationForm.markAllAsTouched();
    }
  }

  formatComments(creditApp: CustomerFinishedApplication) {
    const customerComments =
      creditApp.submissionDetails.comments !== null
        ? 'Customer comments: ' + creditApp.submissionDetails.comments + '\n'
        : null;
    const dealerComments =
      this.originalApplication.submissionDetails.comments !== null
        ? 'Dealer Comments: ' +
          this.originalApplication.submissionDetails.comments
        : null;

    if ((customerComments ?? '') + (dealerComments ?? '') !== '') {
      creditApp.submissionDetails.comments =
        (customerComments ?? '') + (dealerComments ?? '');
    }
  }

  getAllFormErrors(form: FormGroup | FormArray): { [key: string]: any } | null {
    let hasError = false;
    const result = Object.keys(form.controls).reduce((acc, key) => {
      const control = form.get(key);
      const errors =
        control instanceof FormGroup || control instanceof FormArray
          ? this.getAllFormErrors(control)
          : control?.errors;
      if (errors) {
        acc[key] = errors;
        hasError = true;
      }
      return acc;
    }, {} as { [key: string]: any });
    return hasError ? result : null;
  }
}
