<main>
  <section
    style="
      background-image: url('assets/images/application-forms-hero.jpg');
      background-size: cover;
      background-position: center;
    "
  >
    <div class="container px-4 py-5">
      <div class="row align-items-start g-lg-5 py-5">
        <div class="col-lg-10 text-lg-start text-light">
          <h1 class="display-4 lh-1 mb-3">Application Forms</h1>
        </div>
      </div>
    </div>
  </section>
  <div class="container">
    <div class="row px-4 py-4 my-5 text-center">
      <p class="display-5">Let's get you the financing you need.</p>
      <div class="col-lg-10 mx-auto">
        <p class="fs-5">Download and fill out your application.</p>
      </div>
    </div>
  </div>

  <div class="container" id="applicationForms">
    <div class="row g-4 row-cols-1 row-cols-md-3">
      <div class="col hover-shadow">
        <a
          href="assets/forms/IrrigationCreditApplication.pdf"
          target="_blank"
          class="text-dark text-decoration-none"
        >
          <div
            class="d-flex flex-column feature border border-2 border-primary py-5 px-4 h-100"
          >
            <div class="feature-icon mt-3">
              <img
                type="image/svg+xml"
                src="assets/svg/download-icon.svg"
                alt="Your browser does not support SVGs"
              />
            </div>
            <h4 class="my-4">Irrigation/Grain Bin Credit App</h4>
            <p class="mt-auto">Financing for pivot systems and grain bins.</p>
          </div>
        </a>
      </div>
      <div class="col hover-shadow">
        <a
          href="assets/forms/FarmEquipmentCreditApplication.pdf"
          target="_blank"
          class="text-dark text-decoration-none"
        >
          <div
            class="d-flex flex-column feature border border-2 border-primary py-5 px-4 h-100"
          >
            <div class="feature-icon mt-3">
              <img
                type="image/svg+xml"
                src="assets/svg/download-icon.svg"
                alt="Your browser does not support SVGs"
              />
            </div>
            <h4 class="my-4">Farm Equipment Credit App</h4>
            <p class="mt-auto">
              Financing for new and used farm equipment of all kinds.
            </p>
          </div>
        </a>
      </div>
      <div class="col hover-shadow">
        <a
          href="assets/forms/CommercialCreditApplication.pdf"
          target="_blank"
          class="text-dark text-decoration-none"
        >
          <div
            class="d-flex flex-column feature border border-2 border-primary py-5 px-4 h-100"
          >
            <div class="feature-icon mt-3">
              <object type="image/svg+xml" data="assets/svg/download-icon.svg">
                Your browser does not support SVGs
              </object>
            </div>
            <h4 class="my-4">Commercial Credit App</h4>
            <p class="mt-auto">
              Financing for equipment that is needed for Commercial use.
            </p>
          </div>
        </a>
      </div>
    </div>
  </div>

  <div class="px-4 py-4 my-5 text-center">
    <p class="display-5 mb-4">Apply Online</p>
    <div class="col-lg-11 mx-auto">
      <p class="fs-5 mb-4">Discover an easier, more efficient way to apply.</p>
      <div class="d-grid gap-2 d-sm-flex justify-content-sm-center">
        <a
          *ngIf="!loggedIn || isCustomer"
          (click)="customerRoute()"
          class="btn btn-outline-primary mb-auto"
          >Customer
        </a>
        <a
          *ngIf="!loggedIn || isDealer"
          (click)="dealerRoute()"
          class="btn btn-outline-primary mb-auto"
          >Dealer
        </a>
      </div>
    </div>
  </div>

  <div
    class="container-fluid bg-primary px-5 mb-5"
    style="margin-top: 175px; padding-bottom: 100px"
  >
    <div class="row justify-content-center">
      <div
        class="d-md-flex flex-md-equal w-100 ps-md-3"
        style="margin-top: -100px; max-width: 1400px"
      >
        <div class="bg-secondary pt-3 pb-4 px-3 px-md-5 overflow-hidden">
          <div class="my-3 p-3">
            <h2 class="fs-4 mb-4">Already have an account?</h2>
            <p class="mb-4">
              Login to manage your account, make a one-time payment, or set up
              autopay.
            </p>
            <a class="btn btn-primary" [routerLink]="['/login']">Login</a>
          </div>
        </div>
        <div class="bg-success pt-3 pb-4 px-3 px-md-5 overflow-hidden">
          <div class="my-3 py-3">
            <h2 class="fs-4 mb-4">Estimate your payment.</h2>
            <p class="mb-4">
              Use our Payment Calculator to get a detailed look at your payment
              estimate and schedule.
            </p>
            <a class="btn btn-primary" [routerLink]="['/paymentCalculator']"
              >Use the Calculator</a
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</main>
