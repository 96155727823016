import { Component, Input, OnInit, OnDestroy } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { Validators } from 'angular-reactive-validation';
import {
  EquipmentItem,
  FormSubmitNotificationService,
  StepFormComponent,
  EquipmentType,
  SellerType,
} from 'dfs-credit-application-ngx';
import { ApplicationRate } from 'src/app/interfaces/ApplicationRate';
import { BasicTermsCalculation } from 'src/app/shared/wizard-forms/basic-terms-calculation';

@Component({
  selector: 'ca-contract-terms',
  templateUrl: 'contract-terms.component.html',
  styleUrls: [`../../../PortalMain.scss`],
})
export class ContractTermsComponent
  extends StepFormComponent
  implements OnInit, OnDestroy
{
  @Input() equipmentItems!: EquipmentItem[];
  @Input() equipmentType!: EquipmentType;
  @Input() sellerType!: SellerType;
  @Input() rates!: ApplicationRate[];

  constructor(
    private readonly fb: FormBuilder,
    submitNotificationService: FormSubmitNotificationService
  ) {
    super(submitNotificationService);
  }

  override ngOnInit(): void {
    super.ngOnInit();
    this.addFormControls();
  }

  private addFormControls() {
    this.form.addControl(
      'paymentFrequency',
      this.fb.control(
        null,
        Validators.required('Payment Frequency is required')
      )
    );
    this.form.addControl(
      'contractTypeId',
      this.fb.control(null, Validators.required('Contract Type is required'))
    );
    this.form.addControl(
      'contractTerm',
      this.fb.control(null, Validators.required('Contract Term is required'))
    );
    this.form.addControl(
      'cashDown',
      this.fb.control(
        0,
        Validators.required('Enter a valid currency for Cash Down')
      )
    );
    this.form.addControl('saleLeasePrice', this.fb.control(0));
    this.form.addControl('salesTax', this.fb.control(0));
    this.form.addControl('tradeAllowance', this.fb.control(0));
    this.form.addControl('owedAmount', this.fb.control(0));
    this.form.addControl('isCustomInterestRate', this.fb.control(0));
  }

  public onBasicTermsCalculate(basicTermsCalculate: BasicTermsCalculation) {
    this.form.get('saleLeasePrice')!.setValue(basicTermsCalculate.salesPrice);
    this.form.get('salesTax')!.setValue(basicTermsCalculate.salesTax);
    this.form
      .get('tradeAllowance')!
      .setValue(basicTermsCalculate.tradeAllowance);
    this.form.get('owedAmount')!.setValue(basicTermsCalculate.owedAmount);
  }

  override ngOnDestroy(): void {
    super.ngOnDestroy();
  }
}
