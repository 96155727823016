import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { CurrentUserService, User } from 'dfs-common-ngx';
import { UserRole } from '../enums/user-role';

@Component({
  selector: 'fin-application-forms',
  templateUrl: './application-forms.component.html',
  styleUrls: ['./marketing-styles.css'],
})
export class ApplicationFormsComponent implements OnInit {
  loggedIn = false;
  isDealer: boolean = false;
  isCustomer: boolean = false;

  constructor(
    private router: Router,
    private currentUserService: CurrentUserService
  ) {}

  ngOnInit(): void {
    this.currentUserService.profileO.subscribe((user) => {
      this.loggedIn = !!user;
    });
    var profile = this.currentUserService.profile as User;
    if (profile) {
      this.isCustomer = profile.role.includes(UserRole.Customer);
      this.isDealer = profile.role.includes(UserRole.Dealer);
    }
  }

  customerRoute() {
    this.router.navigate(['/application']);
  }

  dealerRoute() {
    if (this.loggedIn && this.isDealer) {
      this.router.navigate(['/creditApplication']);
    } else {
      this.router.navigate(['/login']);
    }
  }
}
