<div class="dfs-modal-dialog dfs-modal-content">
  <div class="dfs-modal-header">
    <h4 class="modal-title">{{ input.header }}</h4>
  </div>
  <div class="dfs-modal-body" [innerHTML]="input.message"></div>
  <div class="dfs-modal-footer">
    <button
      type="button"
      (click)="close('yes')"
      class="dfs-btn dfs-btn-primary"
    >
      Yes
    </button>
    <button type="button" (click)="close('no')" class="dfs-btn dfs-btn-primary">
      No
    </button>
  </div>
</div>
