import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, Observable, throwError } from 'rxjs';
import { environment } from 'src/environments/environment';
import { ISideNav } from '../interfaces/side-nav';

@Injectable({
  providedIn: 'root',
})
export class SideNavigationService {
  private sideNavUrl = `${environment.ApiBaseUrl}/LeftSideMenu`;

  constructor(private http: HttpClient) {}

  getSideNavigationData(): Observable<ISideNav> {
    return this.http
      .get<ISideNav>(this.sideNavUrl)
      .pipe(catchError(this.handleError));
  }

  private handleError(err: HttpErrorResponse): Observable<never> {
    // in a real world app, we may send the server to some remote logging infrastructure
    // instead of just logging it to the console
    let errorMessage =
      'There was an issue retrieving data for the Left Side Navigation!';
    if (err.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      errorMessage = `An error occurred: ${err.error.message}`;
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      errorMessage = `Server returned code: ${err.status}, error message is: ${err.message}`;
    }
    console.error(errorMessage);
    return throwError(() => errorMessage);
  }
}
