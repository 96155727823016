import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({ providedIn: 'root' })
export class CustomerCreditAttachmentRepository {
  constructor(private httpClient: HttpClient) {}
  submit(appId: number, attachments: FormData): Observable<void> {

    const url =  `${environment.ApiBaseUrl}/customer-credit-applications/${appId}/attachments`;
    return this.httpClient.post<void>(url, attachments);
  }
}
