import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { LoginService } from '../services/login.service';
import { Router } from '@angular/router';
import { EncryptionService } from '../services/encryption.service';

@Component({
  selector: 'fin-login',
  templateUrl: './login.component.html',
  styleUrls: ['../marketing/marketing-styles.css', '../PortalMain.scss'],
})
export class LoginComponent implements OnInit {
  form!: FormGroup;
  loading = false;
  submitted = false;
  showLoginError = false;
  loginError = '';
  capsOn: any;
  public showPasswordOnPress: boolean = false;

  constructor(
    private readonly loginService: LoginService,
    private readonly encryptionService: EncryptionService,
    private router: Router,
    private formBuilder: FormBuilder
  ) {}

  ngOnInit() {
    this.form = this.formBuilder.group({
      username: ['', Validators.required],
      password: ['', Validators.required],
    });
  }

  // convenience getter for easy access to form fields
  get theForm() {
    return this.form.controls;
  }

  userLogin() {
    this.submitted = true;

    // stop here if form is invalid
    if (this.form.invalid) {
      return;
    }
    this.loading = true;

    const encryptedUsername = this.encryptionService.encryptValue(
      this.theForm['username'].value
    );
    const encryptedPassword = this.encryptionService.encryptValue(
      this.theForm['password'].value
    );

    this.showLoginError = false;
    this.loginService.login(encryptedUsername, encryptedPassword).subscribe({
      next: (result) => {
        this.loading = false;
        this.router.navigate([result]);
      },
      error: (response) => {
        if (response.error.innerException) {
          this.loginError = response.error.innerException.exceptionMessage;
        } else {
          this.loginError = response.error.message;
        }
        this.showLoginError = true;
        this.loading = false;
      },
    });
  }

  toggleshowPassword(showPassword: boolean) {
    this.showPasswordOnPress = showPassword;
  }
}
