import { Component, HostListener, OnInit } from '@angular/core';
import { FormArray, FormControl, FormGroup } from '@angular/forms';
import { CreditApplication } from './credit-application.model';
import { CustomerCreditApplicationRepository } from './customer-credit-application.repository';
import { CustomerCreditAttachmentRepository } from './customer-credit-attachments.repository';
import { ToastrService } from 'ngx-toastr';
import { BusinessTypes, EquipmentType } from 'dfs-credit-application-ngx';
import {
  StepModel,
  StepsService,
  FormSubmitNotificationService,
} from 'dfs-credit-application-ngx';
import { concatMap, finalize } from 'rxjs/operators';
import { of } from 'rxjs';
import { SpinnerService, MessageBoxModalService } from 'dfs-common-ngx';
import { YearsInBusiness } from 'dfs-credit-application-ngx/lib/shared/years-in-business.model';
import { YearsInBusinessRepository } from '../dfs-fin-api/years-in-business.repository';
import { SubmitSuccessModalService } from '../shared/wizard-forms/submit/submit-success-modal.service';
import { ApplicationRate } from '../interfaces/ApplicationRate';
import { DealerContractTermsRepo } from '../dfs-fin-api/DealerContractTermsRepo';
import { SalesPerson } from './wizard-forms/basic-information/sales-person.model';
import { SalesPersonsRepositoryService } from './wizard-forms/basic-information/dealer-seller/sales-persons-repository.service';

@Component({
  selector: 'fin-credit-application',
  templateUrl: './credit-application.component.html',
  styleUrls: [`../PortalMain.scss`],
})
export class CreditApplicationComponent implements OnInit {
  creditApplicationForm!: FormGroup;
  invalidSubmitAttempted = false;
  isSubmissionSuccessful = false;
  captchaIsValid = false;
  yearsInBusinessOptions!: YearsInBusiness[];
  businessTypeEnum = BusinessTypes;
  captchaKey: string = '6LdVqG4gAAAAABPTSIocqqnK14DyV72U8ieKjRpN';
  fileAttachments = new FormData();
  rates!: ApplicationRate[];
  salesPersonsData!: SalesPerson[];
  salesPersonsLoading = false;
  @HostListener('window:beforeunload', ['$event'])
  checkForChanges($event: { returnValue: string }) {
    if (this.creditApplicationForm.dirty && !this.isSubmissionSuccessful) {
      $event.returnValue =
        'You are about to leave the credit application. The application will not be saved. Are you sure you wish to continue?';
    }
  }
  get currentStep(): StepModel {
    return this.stepsService.currentStep;
  }
  get steps(): StepModel[] {
    return this.stepsService.steps;
  }
  get nextButtonText() {
    return !this.stepsService.isLastStep ? 'Next' : 'Submit';
  }
  get isFirstStep() {
    return this.stepsService.isFirstStep;
  }
  get basicInformationForm() {
    return this.creditApplicationForm.get('basicInformation') as FormGroup;
  }
  get applicantDetailsForm() {
    return this.creditApplicationForm.get('applicantDetails') as FormGroup;
  }
  get businessFinancialInformationForm() {
    return this.creditApplicationForm.get(
      'businessFinancialInformation'
    ) as FormGroup;
  }
  get equipmentForm() {
    return this.creditApplicationForm.get('equipment') as FormGroup;
  }
  get equipmentItems() {
    return this.equipmentForm!.get('equipments')!.value;
  }
  get contractTermsForm() {
    return this.creditApplicationForm.get('contractTerms') as FormGroup;
  }
  get equipmentType() {
    return this.basicInformationForm!.get('equipmentType')!.value;
  }
  get submissionDetails() {
    return this.creditApplicationForm.get('submissionDetails') as FormGroup;
  }
  get isShowInvalidEntries(): boolean {
    return (
      this.invalidSubmitAttempted &&
      (!this.currentStep.isComplete ||
        (this.stepsService.isLastStep && !this.creditApplicationForm.valid))
    );
  }
  constructor(
    private readonly stepsService: StepsService,
    private readonly salesPersonRepositoryService: SalesPersonsRepositoryService,
    private readonly creditApplicationRepo: CustomerCreditApplicationRepository,
    private readonly creditAttachmentRepo: CustomerCreditAttachmentRepository,
    private readonly messageBoxModalService: MessageBoxModalService,
    private readonly toastr: ToastrService,
    private readonly spinnerService: SpinnerService,
    private readonly submitNotificationService: FormSubmitNotificationService,
    private readonly successModalService: SubmitSuccessModalService,
    private readonly yearsInBusinessRepo: YearsInBusinessRepository,
    private readonly dealerContractTermsRepo: DealerContractTermsRepo
  ) {
    this.stepsService.steps = [
      {
        stepIndex: 1,
        title: 'Basic Information',
        isComplete: false,
      },
      {
        stepIndex: 2,
        title: 'Applicants',
        isComplete: false,
      },
      {
        stepIndex: 3,
        title: 'Business/Financial Information',
        isComplete: false,
      },
      {
        stepIndex: 4,
        title: 'Equipment',
        isComplete: false,
      },
      {
        stepIndex: 5,
        title: 'Contract Terms',
        isComplete: false,
      },
      {
        stepIndex: 6,
        title: 'Submit',
        isComplete: false,
      },
    ];
  }

  ngOnInit(): void {
    this.creditApplicationForm = new FormGroup({
      id: new FormControl(0),
      basicInformation: new FormGroup({}),
      applicantDetails: new FormGroup({}),
      businessFinancialInformation: new FormGroup({}),
      equipment: new FormGroup({}),
      contractTerms: new FormGroup({}),
      submissionDetails: new FormGroup({}),
    }) as FormGroup;

    this.yearsInBusinessRepo.getYearsInBusiness().subscribe((result) => {
      this.yearsInBusinessOptions = result;
    });
    this.loadSalesPersons();
  }

  onNextStep(event: MouseEvent) {
    if (this.currentStep && !this.currentStep.isComplete) {
      this.submitNotificationService.notify();
      this.invalidSubmitAttempted = true;
      this.creditApplicationForm.markAllAsTouched();
      return;
    }

    this.invalidSubmitAttempted = false;
    if (!this.stepsService.isLastStep) {
      this.stepsService.moveToNextStep();
    } else {
      this.onSubmit(event);
    }
  }

  onPreviousStep() {
    if (!this.stepsService.isFirstStep) {
      this.stepsService.moveToPreviousStep();
    }
  }

  attachFile(fileData: any) {
    const fileSizeLimit = 1024 * 1000 * 8;
    if (fileData.file.size > fileSizeLimit) {
      const modalTitle = 'Attachment File Size';
      const modalMessage =
        'Attachment is larger than 8MB.  It will not be uploaded.';
      this.messageBoxModalService.show(modalMessage, modalTitle);
      return;
    } else {
      if (this.fileAttachments.has(fileData.fileType)) {
        this.fileAttachments.delete(fileData.fileType);
      }
      this.fileAttachments.append(fileData.fileType, fileData.file);
    }
  }

  getCaptchaValue(captcha: string) {
    this.captchaIsValid = !(captcha === null || captcha === undefined);
  }

  onSubmit(event: MouseEvent): void {
    if (this.creditApplicationForm.valid && this.captchaIsValid) {
      this.invalidSubmitAttempted = false;
      const creditApp = {
        ...this.creditApplicationForm.value,
      } as CreditApplication;

      (event.target as HTMLButtonElement).disabled = true;
      this.spinnerService.show();
      this.creditApplicationRepo
        .create(creditApp)
        .pipe(
          concatMap((appId: number) => {
            if (this.fileAttachments.entries().next().done) {
              return of({});
            }
            return this.creditAttachmentRepo.submit(
              appId,
              this.fileAttachments
            );
          }),
          finalize(() => {
            (event.target as HTMLButtonElement).disabled = false;
            this.spinnerService.hide();
          })
        )
        .subscribe({
          next: () => {
            this.isSubmissionSuccessful = true;
            this.successModalService.show();
          },
          error: (err: any) => {
            console.error(err);
            this.toastr.error(
              `<a href="mailto:OnlineSupport@dfsfin.com">
                      There was an issue submitting the application.
                      If the issue persists, click here to contact Online Support.
                    </a>`,
              'Error',
              { enableHtml: true }
            );
          },
        });
    } else {
      this.invalidSubmitAttempted = true;
      this.creditApplicationForm.markAllAsTouched();
    }
  }

  getAllFormErrors(form: FormGroup | FormArray): { [key: string]: any } | null {
    let hasError = false;
    const result = Object.keys(form.controls).reduce((acc, key) => {
      const control = form.get(key);
      const errors =
        control instanceof FormGroup || control instanceof FormArray
          ? this.getAllFormErrors(control)
          : control?.errors;
      if (errors) {
        acc[key] = errors;
        hasError = true;
      }
      return acc;
    }, {} as { [key: string]: any });
    return hasError ? result : null;
  }

  setRates() {
    const equipmentType: EquipmentType | undefined =
      this.basicInformationForm!.get('equipmentType')!.value;
    if (!equipmentType) {
      return;
    }

    let dealerTypeId: number = 4; //Farm General
    if (equipmentType === EquipmentType.irrigation) {
      dealerTypeId = 1; //Irrigation General
    }

    this.dealerContractTermsRepo
      .getApplicationRates('', dealerTypeId)
      .subscribe((data) => {
        this.rates = data;
      });
  }

  loadSalesPersons() {
    this.salesPersonsLoading = true;
    this.salesPersonRepositoryService.get().subscribe({
      next: (salesPersons) => {
        this.salesPersonsData = salesPersons.map((item: SalesPerson) => {
          return {
            userId: item.userId,
            dealershipName: item.dealershipName,
            dealershipState: item.dealershipState,
            productLine: item.productLine,
            productLineId: item.productLineId,
            name: item.name,
            userDealer: item.name + '' + item.dealershipName,
          };
        });
        this.salesPersonsLoading = false;
      },
      error: () => {
        this.toastr.error(
          `<a href="mailto:OnlineSupport@dfsfin.com">
                There was an issue retrieving the salesperson list.
                If the issue persists, click here to contact Online Support.
              </a>`,
          'Error',
          { enableHtml: true }
        );
        this.salesPersonsData = [];
      },
    });
  }

  creditReportAuthorizationChangeInSubmit(value: any) {
    let submissionDetail = this.submissionDetails?.get(
      'CustomerCreditReportAuthorization'
    );

    if (value === true) {
      submissionDetail?.setValue(this.getApplicantName());
    } else {
      submissionDetail?.setValue(null);
    }
  }

  getApplicantName(): string {
    let mainApplicant = this.applicantDetailsForm?.get('mainApplicant');
    let name: string;
    if (!mainApplicant) {
      return '';
    }
    if (mainApplicant?.value.businessType?.id === 1) {
      //Ind./Proprietorship
      name =
        mainApplicant?.value.firstName + ' ' + mainApplicant?.value.lastName;
    } else {
      let businessPartner = mainApplicant.get('businessPartners')?.value[0];
      name = businessPartner.firstName + ' ' + businessPartner.lastName;
    }

    return name;
  }
}
