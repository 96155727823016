import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, map, Observable, throwError } from 'rxjs';
import { environment } from 'src/environments/environment';
import { LeaseMatrixProgram } from '../classes/lease-matrix-program';
import { LeaseMatrixProgramFile } from '../classes/lease-matrix-program-file';

@Injectable({
  providedIn: 'root',
})
export class LeaseMatrixProgramsService {
  constructor(private http: HttpClient) {}

  getfileList(): Observable<LeaseMatrixProgram> {
    return this.http
      .get<LeaseMatrixProgram>(`${environment.ApiBaseUrl}/leaseMatrixPrograms`)
      .pipe(catchError(this.handleError));
  }

  private handleError(err: HttpErrorResponse): Observable<never> {
    let errorMessage = 'There was an issue retrieving Lease Matrix Programs!';
    if (err.error instanceof ErrorEvent) {
      errorMessage = `An error occurred: ${err.error.message}`;
    } else {
      errorMessage = `Server returned code: ${err.status}, error message is: ${err.message}`;
    }
    return throwError(() => errorMessage);
  }

  getSelectedFile(selectedFile: LeaseMatrixProgramFile) {
    return this.http
      .post(
        `${environment.ApiBaseUrl}/leaseMatrixPrograms/file`,
        selectedFile,
        { observe: 'response', responseType: 'blob' }
      )
      .pipe(
        map((response: any) => {
          const contentDispositionHeader: string = response.headers.get(
            'Content-Disposition'
          );
          const fileName = contentDispositionHeader.split('=')[1].split('.')[0];

          const blob = new Blob([response.body], {
            type: response.headers.get('Content-Type'),
          });
          return { fileName, file: blob };
        })
      );
  }
}
