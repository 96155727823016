import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { RecipientList } from '../shared/models/recipient-list';
import { environment } from 'src/environments/environment';

@Injectable({ providedIn: 'root' })
export class EsignatureRecipientRepository {
  constructor(private httpClient: HttpClient) {}

  create(applicationId: Number | undefined, recipientList: RecipientList) {
    const url = `${environment.ApiBaseUrl}/applications/${applicationId}/esignature-recipients`;
    return this.httpClient.post<RecipientList>(url, recipientList);
  }
}
