import { Component, OnInit } from '@angular/core';
import { DfsContactService } from '../services/dfs-contact.service';
import { DfsContactInfo } from './dfs-contact-info';
import { TableModule } from 'primeng/table';

@Component({
  selector: 'fin-dealer-contact',
  templateUrl: './dfs-contact.component.html',
  styleUrls: ['../PortalMain.scss'],
})
export class DfsContactComponent implements OnInit {
  dfsContactList: DfsContactInfo[] = [];

  constructor(private readonly dfsContactService: DfsContactService) {}
  ngOnInit(): void {
    this.dfsContactService
      .getDealerContactList()
      .subscribe((data) => (this.dfsContactList = data));
  }
}
