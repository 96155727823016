import { NgModule } from "@angular/core";

import { AssetCodesRepo } from "./asset-codes-repo.service";
import { DealerContractTermsRepo } from './DealerContractTermsRepo';
import { UserDealerLinkRepo } from './UserDealerLinkRepo';
import { BusinessDaysRepo } from "./BusinessDaysRepo";
import { CreditApplicationsRepository } from "./credit-applications.repository";
import { CreditApplicationsRepo } from "./CreditApplicationsRepo";
import { CustomersRepo } from "./CustomersRepo";
import { DealerApprovalsRepo } from "./DealerApprovalsRepo";
import { DealerCodesRepo } from "./DealerCodesRepo";
import { DealersRepo } from "./DealersRepo";
import { DocumentsRepo } from "./DocumentsRepo";
import { EquipmentCodesRepo } from "./EquipmentCodesRepo";
import { FileAttachmentsRepo } from "./FileAttachmentsRepo";
import { FinanceCalculationsSvc } from "./FinanceCalculationsSvc";
import { FinishAppLinkRepo } from "./FinishAppLinkRepo";
import { ManufacturersRepo } from "./manufacturers-repo.service";
import { PrintedCreditApplicationsRepo } from "./PrintedCreditApplicationsRepo";
import { SubmittedApplicationsRepo } from "./SubmittedApplicationsRepo";
import { SupervisorSalesmenRepository } from "./supervisor-salesman.repository";
import { UserSubmittedApplicationsRepo } from "./UserSubmittedApplicationsRepo";

@NgModule({
    providers: [
        AssetCodesRepo,
        BusinessDaysRepo,
        CreditApplicationsRepository,
        CreditApplicationsRepo,
        CustomersRepo,
        DealerApprovalsRepo,
        DealerCodesRepo,
        DealerContractTermsRepo,
        DealersRepo,
        DocumentsRepo,
        EquipmentCodesRepo,
        FileAttachmentsRepo,
        FinanceCalculationsSvc,
        FinishAppLinkRepo,
        ManufacturersRepo,
        PrintedCreditApplicationsRepo,
        SubmittedApplicationsRepo,
        SupervisorSalesmenRepository,
        UserDealerLinkRepo,
        UserSubmittedApplicationsRepo,
    ]
})
export class DfsFinApiModule {}
