import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { EsignatureEnvelopeStatus } from './esignature-envelope-status';

@Injectable({
  providedIn: 'root',
})
export class EsignatureEnvelopeService {
  constructor(private readonly http: HttpClient) {}

  createEnvelope(appId: number | string, envelope: any) {
    const url = this.getEsigBaseUrl(appId);
    return this.http.post(url, envelope);
  }

  deleteEnvelope(appId: number | string, envelopeId: number | string) {
    let url = this.getEsigBaseUrl(appId);
    return this.http.delete(`${url}/${envelopeId}`);
  }
  resendEnvelope(
    appId: number | string,
    esigEnvelopeStatus: EsignatureEnvelopeStatus
  ) {
    let url = this.getEsigBaseUrl(appId);
    return this.http.put(`${url}/resend`, esigEnvelopeStatus);
  }

  getEsigBaseUrl(appId: number | string): string {
    return `${environment.ApiBaseUrl}/applications/${appId}/esignature-envelopes`;
  }
}
