import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { FormGroup } from '@angular/forms';
import { CurrentUserService, Dealer } from 'dfs-common-ngx';
import {
  ContractType,
  ContractTypeEnum,
  EquipmentItem,
  EquipmentType,
  FormSubmitNotificationService,
  PaymentFrequency,
  SellerType,
  ValidatableFormComponent,
} from 'dfs-credit-application-ngx';
import { BasicTermsCalculation } from './basic-terms-calculation';
import { ApplicationRate } from 'src/app/interfaces/ApplicationRate';
import { RatesDisplayOption } from 'src/app/interfaces/rates-display-option';
import { ApplicationTermsService } from '../application-terms.service';

@Component({
  selector: 'fin-basic-contract-terms',
  templateUrl: './basic-contract-terms.component.html',
})
export class BasicContractTermsComponent
  extends ValidatableFormComponent
  implements OnInit, OnDestroy, OnChanges
{
  @Input() equipmentItems!: EquipmentItem[];
  @Input() equipmentType!: EquipmentType;
  @Input() sellerType!: SellerType;
  @Input() form!: FormGroup;
  @Input() rates!: ApplicationRate[];
  @Input() bonusRateTypes: RatesDisplayOption[] = [];
  @Output() onCalculate = new EventEmitter<BasicTermsCalculation>();

  private salesPrice!: number;
  private salesTax!: number;
  private tradeIn!: number;
  private tradeAllowance!: number;
  private owedAmount!: number;
  availableBonuses: string[] = [];
  isBonusEligible: boolean = true;
  currentBonusRateTypes: RatesDisplayOption[] = [];
  workingRates: ApplicationRate[] | undefined;
  contractTermsRateTypeId: number | undefined;
  dealerUser!: Dealer;
  isCustomInterestRate: boolean = false;

  sellerTypes = SellerType;
  contractTypesEnum = ContractType;

  paymentFrequencies: Array<PaymentFrequency> = [
    PaymentFrequency.Annual,
    PaymentFrequency.SemiAnnual,
    PaymentFrequency.Quarterly,
    PaymentFrequency.Monthly,
  ];
  readonly contractTypes = [
    { value: ContractTypeEnum.ContractFixed, text: 'Contract-Fixed' },
    { value: ContractTypeEnum.ContractVariable, text: 'Contract-Variable' },
    { value: ContractTypeEnum.LeaseLP, text: 'Lease-LP' },
    { value: ContractTypeEnum.LeaseTL, text: 'Lease-PRO' },
  ];
  contractTerms: Array<number> = [];

  get contractType() {
    let contractTypeId = this.form.get('contractTypeId')?.value;

    return contractTypeId;
  }

  get bonusType() {
    let bonusType = this.form.get('bonusType')?.value;

    return bonusType;
  }

  constructor(
    formSubmissionService: FormSubmitNotificationService,
    private applicationTermsService: ApplicationTermsService,
    protected readonly userService: CurrentUserService
  ) {
    super(formSubmissionService);
  }

  override ngOnInit() {
    super.ngOnInit();
    this.dealerUser = this.userService.profile as Dealer;
    this.contractTerms = this.applicationTermsService.getDistinctTermsFromRates(
      this.rates
    );
    this.isCustomInterestRate = this.form.get('isCustomInterestRate')?.value;

    this.changedContractType();
    this.changedRateType();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['equipmentItems']) {
      this.setEquipmentTotals();
      this.calculateLoan();
    }
  }

  private setEquipmentTotals() {
    const equipments = this.equipmentItems;
    let salesPrice = 0;
    let salesTax = 0;
    let tradeInValue = 0;
    let tradeAllowance = 0;
    let owedAmount = 0;

    equipments.forEach((eq: EquipmentItem) => {
      if (eq.tradeInFlag) {
        tradeAllowance = Number(eq.transactionAmount);
        owedAmount = Number(eq.amountOwed);
        tradeInValue = eq.transactionAmount - eq.amountOwed;
      } else {
        salesPrice += +eq.transactionAmount || 0;
        salesTax += +eq.salesTax || 0;
      }
    });

    this.salesPrice = Number(salesPrice.toFixed(2));
    this.salesTax = Number(salesTax.toFixed(2));
    this.tradeIn = Number(tradeInValue.toFixed(2));
    this.tradeAllowance = Number(tradeAllowance.toFixed(2));
    this.owedAmount = Number(owedAmount.toFixed(2));
  }

  calculateLoan() {
    this.onCalculate.emit({
      salesPrice: this.salesPrice,
      salesTax: this.salesTax,
      tradeInValue: this.tradeIn,
      tradeAllowance: this.tradeAllowance,
      owedAmount: this.owedAmount,
    });
  }

  changePaymentFrequency() {
    const cashDown = this.form.get('cashDown');
    if (
      this.equipmentType === EquipmentType.irrigation ||
      (this.sellerType === SellerType.dealerStart &&
        this.dealerUser.productLine === 'Irrigation')
    ) {
      cashDown?.setValue(0);
    } else {
      cashDown?.setValue(this.calculateCashDownValue());
    }
  }

  private calculateCashDownValue(): number {
    const paymentFrequency = this.form.get('paymentFrequency')?.value;

    const amountToFinance = this.salesPrice + this.salesTax;
    let cashDown = 0;
    switch (paymentFrequency) {
      case PaymentFrequency.Annual:
        cashDown = amountToFinance * 0.2;
        break;
      case PaymentFrequency.SemiAnnual:
      case PaymentFrequency.Quarterly:
        cashDown = amountToFinance * 0.15;
        break;
      case PaymentFrequency.Monthly:
        cashDown = amountToFinance * 0.1;
        break;
    }
    const result =
      this.tradeIn > cashDown
        ? 0
        : Number((cashDown - this.tradeIn).toFixed(2));
    return result;
  }

  changedContractType() {
    const contractTypeId = this.contractType;

    if (!contractTypeId) {
      return;
    }

    if (contractTypeId) {
      var contractTermsContractTypeId =
        this.applicationTermsService.getContractTermsContractTypeId(
          contractTypeId
        );

      this.currentBonusRateTypes = this.bonusRateTypes.filter(
        (b) => b.contractTypeId === contractTermsContractTypeId
      );

      this.availableBonuses = this.currentBonusRateTypes.map(
        (v) => v.rapportBonusTypeName
      );

      this.workingRates = this.applicationTermsService.getWorkingRates(
        this.rates,
        contractTypeId
      );
    }

    if (
      this.currentBonusRateTypes !== undefined &&
      this.currentBonusRateTypes.length > 0
    ) {
      this.isBonusEligible = true;
    } else {
      this.isBonusEligible = false;
    }

    this.currentBonusRateTypes.push({
      contractTypeId: 0,
      programTypeId: 0,
      tabDisplay: 'Custom Rate',
      sortOrder: 0,
      rapportBonusTypeId: 0,
      rapportBonusTypeName: '',
      reportDisplay: '',
    });
  }

  changedRateType() {
    let customRateReason = this.form.get('customRateReason');
    const contractTypeId = this.contractType;

    const bonusType = this.form.get('bonusType');

    const programTypeId = this.form.get('programTypeId')?.value;
    if (programTypeId === 0) {
      //Custom
      this.isCustomInterestRate = true;
    } else {
      customRateReason?.setValue(null);

      var rapportBonusTypeName = this.currentBonusRateTypes.filter(
        (f) => f.programTypeId === programTypeId
      )[0]?.rapportBonusTypeName;
      bonusType?.setValue(rapportBonusTypeName);
      this.isCustomInterestRate = false;
    }

    this.form.controls['isCustomInterestRate'].setValue(
      this.isCustomInterestRate
    );

    this.workingRates = this.applicationTermsService.getWorkingRates(
      this.rates,
      contractTypeId
    );
  }

  override ngOnDestroy(): void {
    super.ngOnDestroy();
  }
}
