import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { map } from 'rxjs';

import { environment } from '../../environments/environment';
import { Dealer } from '../interfaces/dealer';

@Injectable()
export class DealersRepo {
  constructor(private http: HttpClient) {}
  baseUrl: string = `${environment.ApiBaseUrl}/dealers/`;

  getAll() {
    const url = this.baseUrl;
    return this.http
      .get<any[]>(url)
      .pipe(map((result) => result.filter((x) => !x.isDeleted)));
  }

  get(dealerId: number) {
    const url = `${this.baseUrl}${dealerId}`;
    return this.http.get<any>(url);
  }

  update(dealer: Dealer) {
    var url = this.baseUrl;

    return this.http.put(url, dealer);
  }

  markDeleted(dealerId: number) {
    const url = `${this.baseUrl}${dealerId}/markDeleted`;

    return this.http.put(url, null);
  }

  deleteDealer(dealerId: number) {
    const url = `${this.baseUrl}${dealerId}`;

    return this.http.delete(url);
  }
}
