import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { ApplicationRate } from '../interfaces/ApplicationRate';
import { RatesDisplayOption } from '../interfaces/rates-display-option';

@Injectable()
export class DealerContractTermsRepo {
  constructor(private $http: HttpClient) {}

  getByTermsId(dealerCode: string, termsId: string, dealerTypeId: number) {
    const url = `${environment.ContractTermsApiBaseUrl}/application/rates/${dealerTypeId}/termsId/${termsId}?dealerCode=${dealerCode}`;
    return this.$http.get<ApplicationRate[]>(url);
  }

  getApplicationRates(dealerCode: string, dealerTypeId: number) {
    const url = `${environment.ContractTermsApiBaseUrl}/application/rates/${dealerTypeId}?dealerCode=${dealerCode}`;
    return this.$http.get<ApplicationRate[]>(url);
  }

  getBonusRateTypes(dealerCode: string, dealerTypeId: number) {
    const url = `${environment.ContractTermsApiBaseUrl}/application/rateTypeBonus/${dealerTypeId}?dealerCode=${dealerCode}`;
    return this.$http.get<RatesDisplayOption[]>(url);
  }
}
