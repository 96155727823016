<form [formGroup]="form" #formDirective="ngForm" class="col-md-7">
  <br />
  <p>
    <strong>
      Select the name of the Salesperson you are working with. Begin typing to
      search. *
    </strong>
  </p>

  <div dfsFormGroup>
    <ng-select
      [items]="salesPersons"
      [selectOnTab]="true"
      bindLabel="userDealer"
      formControlName="salesPerson"
      [placeholder]="dropdownPlaceholder"
      [loading]="salesPersonsLoading"
    >
      <ng-template ng-label-tmp let-item="item">
        {{ item.name }} - {{ item.dealershipName }}
      </ng-template>
      <ng-template ng-option-tmp let-item="item">
        {{ item.name }} <br />
        <small>{{ item.dealershipName }}</small>
      </ng-template>
    </ng-select>
  </div>

  <br />
  <div class="form-group">
    <label for="salespersonManuallyEntered" class="col-form-label">
      <input
        type="checkbox"
        id="salespersonManuallyEntered"
        name="salespersonManuallyEntered"
        formControlName="salespersonManuallyEntered"
      />
      Check here if you cannot find your salesperson in the select box
    </label>
  </div>

  <div *ngIf="salespersonManuallyEnteredControl.value">
    <div class="row">
      <div class="col-md-6">
        <div dfsFormGroup>
          <label for="salespersonName">Enter the salesperson's name</label>
          <input
            name="salespersonName"
            id="salespersonName"
            formControlName="salespersonName"
          />
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-5">
        <div dfsFormGroup>
          <label for="dealerCity">Dealership location city</label>
          <input
            name="dealerCity"
            id="dealerCity"
            formControlName="dealerCity"
          />
        </div>
      </div>
      <div class="col-md-7">
        <div dfsFormGroup>
          <label> Select the Dealership. Begin typing to search. * </label>
          <ng-select
            [items]="dealers$ | async"
            [selectOnTab]="true"
            bindLabel="name"
            [hideSelected]="true"
            [minTermLength]="4"
            [loading]="dealersLoading"
            typeToSearchText="Please enter 4 or more characters"
            [typeahead]="dealersInput$"
            formControlName="dealer"
          >
            <ng-template ng-label-tmp let-item="item">
              {{ item.name }} - {{ item.city }}, {{ item.state }}
            </ng-template>
            <ng-template ng-option-tmp let-item="item">
              {{ item.name }} <br />
              <small>{{ item.city }}, {{ item.state }}</small>
            </ng-template>
          </ng-select>
        </div>
      </div>
    </div>
    <div class="form-group">
      <label for="dealerManuallyEntered" class="col-form-label">
        <input
          type="checkbox"
          id="dealerManuallyEntered"
          name="dealerManuallyEntered"
          formControlName="dealerManuallyEntered"
        />
        Check here if you cannot find your dealership in the select box
      </label>
    </div>
    <div *ngIf="dealerManuallyEnteredControl.value">
      <div class="row">
        <div class="col-md-6">
          <div dfsFormGroup>
            <label for="dealershipName">Dealership name</label>
            <input
              name="dealershipName"
              id="dealershipName"
              formControlName="dealershipName"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</form>
