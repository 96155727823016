import { Component, OnDestroy, OnInit } from '@angular/core';
import { EquipmentTypes } from '../marketing/equipment-types.enum';
import { CurrentUserService } from 'dfs-common-ngx';
import { Subscription } from 'rxjs';
import { UserRole } from '../enums/user-role';

@Component({
  selector: 'fin-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css'],
})
export class FooterComponent implements OnInit, OnDestroy {
  equipmentTypes = EquipmentTypes;
  payOnlineRoute: string = '/login';
  payOnlineText: string = 'Login to Pay Online';
  showRegisterOnline: boolean = true;
  private userProfileSubscription: Subscription | undefined;

  constructor(private readonly currentUserSvc: CurrentUserService) {}

  ngOnInit(): void {
    this.userProfileSubscription = this.currentUserSvc.profileO.subscribe({
      next: (user) => {
        if (user) {
          this.showRegisterOnline = false;
          if (user.role.includes(UserRole.Customer)) {
            this.payOnlineRoute = '/payOnline';
            this.payOnlineText = 'Pay Online';
          }
        }
      },
    });
  }

  ngOnDestroy(): void {
    this.userProfileSubscription?.unsubscribe();
  }
}
