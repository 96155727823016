export class DealerRegistration {
  productLine: string = '';
  dealerType: string = '';
  companyName: string = '';
  address: string = '';
  firstName: string = '';
  lastName: string = '';
  city: string = '';
  state: string = '';
  zipCode: string = '';
  phoneNumber: string = '';
  email: string = '';
  userName: string = '';
  password: string = '';
}
