import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Observable } from 'rxjs';

import { YearsInBusiness } from 'dfs-credit-application-ngx/lib/shared/years-in-business.model';
import { environment } from '../../environments/environment';

@Injectable()
export class YearsInBusinessRepository {
  readonly baseURL: string = `${environment.ApiBaseUrl}/user/`;
  constructor(private httpClient: HttpClient) {}

  getYearsInBusiness(): Observable<YearsInBusiness[]> {
    const url = this.baseURL + `years-in-business`;
    return this.httpClient.get<YearsInBusiness[]>(url);
  }
}
