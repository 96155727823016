import { HttpResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { LeaseMatrixProgram } from './classes/lease-matrix-program';
import { LeaseMatrixProgramFile } from './classes/lease-matrix-program-file';
import { LeaseMatrixProgramsService } from './services/lease-matrix-programs.service';

@Component({
  selector: 'fin-lease-matrix-programs',
  templateUrl: './lease-matrix-programs.component.html',
  styleUrls: ['../PortalMain.scss'],
})
export class LeaseMatrixProgramsComponent implements OnInit {
  sub!: Subscription;
  errorMessage: string = '';
  lmp: LeaseMatrixProgram | undefined;
  loadingData: boolean = true;

  constructor(private lms: LeaseMatrixProgramsService) {}

  ngOnInit(): void {
    this.loadingData = true;

    this.sub = this.lms.getfileList().subscribe({
      next: (program) => {
        this.lmp = program;
        this.loadingData = false;
      },
      error: (err) => {
        this.errorMessage = err;
      },
    });
  }

  GetFile(loadFile: LeaseMatrixProgramFile) {
    this.sub = this.lms.getSelectedFile(loadFile).subscribe((res) => {
      const fileURL = URL.createObjectURL(res.file);

      window.open(fileURL, '_blank');
    });
  }
}
