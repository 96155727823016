import { Component, Inject } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DialogRef, DIALOG_DATA } from '@angular/cdk/dialog';
import { FileSaverService } from 'ngx-filesaver';
import { FileAttachmentsRepo } from 'src/app/dfs-fin-api/FileAttachmentsRepo';
import { UserNotificationSvc } from 'src/app/common/services/UserNotificationSvc';

@Component({
  selector: 'fin-view-attachment-modal',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './view-attachments-modal.component.html',
  styleUrls: ['../../PortalMain.scss'],
})
export class ViewAttachmentsModalComponent {
  constructor(
    @Inject(DIALOG_DATA) public attachments: any[],
    private dialogRef: DialogRef,
    private fileAttachmentsRepo: FileAttachmentsRepo,
    private userNotificationSvc: UserNotificationSvc,
    private fileSaver: FileSaverService
  ) {}

  download(attachment: { applicationId: string; id: string }) {
    this.fileAttachmentsRepo
      .get(attachment.applicationId, attachment.id)
      .subscribe({
        next: (file) => {
          this.fileSaver.save(file.file, file.fileName);
        },
        error: () => {
          this.userNotificationSvc.error(
            'There was an error downloading the attachment.',
            'Error',
            10000,
            this.userNotificationSvc.topRight
          );
        },
      });
  }
  delete(attachment: { applicationId: any; id: any }) {
    this.fileAttachmentsRepo
      .delete(attachment.applicationId, attachment.id)
      .subscribe({
        next: () => {
          const index = this.attachments.indexOf(attachment);
          this.attachments.splice(index, 1);
          this.userNotificationSvc.success('attachment successfully deleted.');
        },
        error: () => {
          this.userNotificationSvc.error(
            'There was an error deleting the attachment.',
            'Error',
            10000,
            this.userNotificationSvc.topRight
          );
        },
      });
  }
  close() {
    this.dialogRef.close();
  }
}
