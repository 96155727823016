<div id="page-content-wrapper">
  <div class="row">
    <h4>Application Archive</h4>
  </div>
  <div class="card">
    <fin-application-grid
      [statusTypes]="statusTypes"
      [showCurrentlFields]="false"
      [showApprovalFields]="false"
      [allowReassign]="false"
      [captionText]="''"
    ></fin-application-grid>
  </div>
</div>
