<div id="page-content-wrapper">
  <h3 class="m-3">{{ pageHeader }}</h3>
  <div class="card">
    <p-table
      [value]="customerAccounts"
      styleClass="p-datatable-striped p-datatable-sm"
      [tableStyle]="{ 'min-width': '50rem' }"
      [loading]="loadingData"
    >
      <ng-template pTemplate="header">
        <tr>
          <th>Contract Number</th>
          <th>Equipment</th>
          <th>Invoice Delivery</th>
          <th>Email Address</th>
          <th>Address</th>
          <th></th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-customerAccounts>
        <tr>
          <td>
            <a
              *ngIf="!customerAccounts.accountClosed"
              class="dfs-link"
              [routerLink]="[
                '/requestPayoff/' + customerAccounts.contractNumber
              ]"
              >{{ customerAccounts.contractNumber }}</a
            >
            <span
              *ngIf="customerAccounts.contractDetails.invoiceCode != 'C'"
              class="text-danger"
              >*</span
            >
          </td>
          <td>{{ customerAccounts.equipment }}</td>
          <td>
            <div>
              <p-dropdown
                placeholder="Select delivery method"
                class="w-100"
                appendTo="body"
                [autoWidth]="true"
                [options]="
                  loadInvoiceOption(
                    customerAccounts.contractDetails.invoiceCode
                  )
                "
                optionLabel="description"
                optionValue="id"
                (onHide)="
                  updateInvoiceDelivery(
                    customerAccounts.contractDetails.invoiceDeliveryMethod,
                    customerAccounts.contractNumber,
                    customerAccounts.addressDetails
                  )
                "
                [(ngModel)]="
                  customerAccounts.contractDetails.invoiceDeliveryMethod
                "
                [disabled]="customerAccounts.contractDetails.invoiceCode != 'C'"
              >
              </p-dropdown>
            </div>
          </td>
          <td>
            {{ customerAccounts.addressDetails.email }}
          </td>
          <td>
            {{ customerAccounts.addressDetails.address1 }}
          </td>
          <td style="text-align: center">
            <div>
              <button
                type="button"
                class="dfs-btn dfs-btn-primary"
                (click)="showSpinner(customerAccounts.contractNumber)"
                [routerLink]="['/update-address/address']"
                [queryParams]="{
                  contractNumber: customerAccounts.contractNumber,
                  name: customerAccounts.addressDetails.name,
                  address1: customerAccounts.addressDetails.address1,
                  address2: customerAccounts.addressDetails.address2,
                  city: customerAccounts.addressDetails.city,
                  state: customerAccounts.addressDetails.state,
                  zip: customerAccounts.addressDetails.zip,
                  phone: customerAccounts.addressDetails.phone,
                  email: customerAccounts.addressDetails.email,
                  activeContractNumbers: this.contractNumbers,
                  redirectUrl: this.redirectUrl
                }"
              >
                <span
                  *ngIf="shouldDisplaySpinner(customerAccounts.contractNumber)"
                  class="spinner-border spinner-border-sm"
                  role="status"
                  aria-hidden="true"
                ></span>
                Edit
              </button>
            </div>
          </td>
        </tr>
      </ng-template>
      <ng-template pTemplate="emptymessage">
        <tr>
          <td colspan="99">There are currently no accounts to display.</td>
        </tr>
      </ng-template>
    </p-table>
  </div>
  <div class="m-3" *ngIf="hasSelectedACHDelivery()">
    <span class="text-danger">* </span>If Invoice Delivery is disabled, please
    contact Customer Solutions at 888-886-6242 to update.
  </div>
</div>
