import { Component, OnInit } from '@angular/core';
import { CurrentUserService, User } from 'dfs-common-ngx';
import { Route, Router } from '@angular/router';
import { UserRole } from '../enums/user-role';

@Component({
  selector: 'fin-home',
  templateUrl: './home.component.html',
  styleUrls: ['./marketing-styles.css'],
})
export class HomeComponent implements OnInit {
  constructor(
    private currentUserService: CurrentUserService,
    private router: Router
  ) {}
  loggedIn = false;
  makePaymentLink: String = '/login';
  isDealer: boolean = false;
  isCustomer: boolean = false;

  ngOnInit(): void {
    this.currentUserService.profileO.subscribe((user) => {
      this.loggedIn = !!user;
      if (user?.role.includes(UserRole.Customer)) {
        this.makePaymentLink = '/accountDetail';
      }
    });
    var profile = this.currentUserService.profile as User;
    if (profile) {
      this.isCustomer = profile.role.includes(UserRole.Customer);
      this.isDealer = profile.role.includes(UserRole.Dealer);
    }
  }

  customerRoute() {
    this.router.navigate(['/application']);
  }

  dealerRoute() {
    if (this.loggedIn && this.isDealer) {
      this.router.navigate(['/creditApplication']);
    } else {
      this.router.navigate(['/login']);
    }
  }
}
