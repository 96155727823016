import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { environment } from 'src/environments/environment';
import { RatesDisplayOption } from '../interfaces/rates-display-option';
import { SalesBonus } from '../shared/models/sales-bonus-tab';
import { DashboardRate } from '../shared/models/dashboard-rates';
import { CurrentUserService, Dealer } from 'dfs-common-ngx';

@Injectable()
export class ContractTermsApiRepoService {
  dealerTypeId!: number;
  dealerCode!: string;

  constructor(
    private $http: HttpClient,
    private readonly currentUserSvc: CurrentUserService
  ) {
    const dealer = this.currentUserSvc.profile as Dealer;
    this.dealerCode = dealer.dealerCode;
    this.dealerTypeId = dealer.dealerTypeId;
  }
  getTabs() {
    const url = `${environment.ContractTermsApiBaseUrl}/dashboard/tabs/${this.dealerTypeId}?dealerCode=${this.dealerCode}`;
    return this.$http.get<RatesDisplayOption[]>(url);
  }

  getBonuses() {
    const url = `${environment.ContractTermsApiBaseUrl}/dashboard/bonus/${this.dealerTypeId}?dealerCode=${this.dealerCode}`;
    return this.$http.get<SalesBonus[]>(url);
  }

  getRates(contractTypeId: number, programTypeId: number) {
    const url = `${environment.ContractTermsApiBaseUrl}/dashboard/rates/${this.dealerTypeId}?contractTypeId=${contractTypeId}&&programTypeId=${programTypeId}&&dealerCode=${this.dealerCode}`;

    return this.$http.get<DashboardRate[]>(url);
  }
  getDisclaimer() {
    const url = `${environment.ContractTermsApiBaseUrl}/dashboard/disclaimer/${this.dealerTypeId}`;

    return this.$http.get<any>(url);
  }
}
