import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { DOCUMENT } from '@angular/common';

import { Subscription } from 'rxjs';

import { ISideNav, ISideNavItem } from '../interfaces/side-nav';
import { SideNavigationService } from '../services/side-navigation.service';

@Component({
  selector: 'fin-left-side-nav',
  templateUrl: './left-side-nav.component.html',
  styleUrls: ['./left-side-nav.component.scss'],
})
export class LeftSideNavComponent implements OnInit, OnDestroy {
  sub!: Subscription;
  errorMessage: string = '';
  loading: boolean = true;

  sideNavData: ISideNav | undefined;

  constructor(
    @Inject(DOCUMENT) private document: Document,
    private sideNavService: SideNavigationService
  ) {}

  ngOnInit() {
    this.loadList();
  }

  private loadList() {
    this.sub = this.sideNavService.getSideNavigationData().subscribe({
      next: (sideNav) => {
        this.sideNavData = sideNav;

        this.loading = false;
      },
      error: (err) => (this.errorMessage = err),
    });
  }

  buildqueryParams(queryParams: string) {
    if (queryParams && queryParams.length > 0) {
      let params: any = {};
      var eachParam = queryParams.split(',');
      for (const par of eachParam) {
        let current = par.split('=');

        params[current[0]] = current[1];
      }

      return params;
    }
    return null;
  }

  isSelected(item: ISideNavItem): boolean {
    let path = this.document.location.pathname;
    if (path === '/generate-documents') {
      path = '/creditApplication';
    }
    return path.startsWith(item.routerLink);
  }
  ngOnDestroy() {}

  clickEvent(routerLink: string) {
    switch (routerLink) {
    }
  }
}
