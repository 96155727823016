<form [formGroup]="form" #formDirective="ngForm">
  <div class="row">
    <h4 *ngIf="equipmentType !== equipmentTypes.irrigation">Equipment</h4>
    <h4 *ngIf="equipmentType === equipmentTypes.irrigation">Pivot</h4>

    <div *ngFor="let equipmentItem of equipments">
      <cfa-equipment-item [form]="equipmentItem"></cfa-equipment-item>
    </div>
  </div>
  <div class="row" *ngIf="ancillaryItems.length > 0">
    <h4>Ancillary</h4>

    <div *ngFor="let equipmentItem of ancillaryItems">
      <cfa-equipment-item [form]="equipmentItem"></cfa-equipment-item>
    </div>
  </div>
  <div class="row" *ngIf="tradeIn">
    <h4>Trade-In</h4>
    <cfa-equipment-item [form]="tradeIn"></cfa-equipment-item>
  </div>
  <div class="text-info" style="margin-top: 2%">
    <em class="text-info"
      >Please enter the county and state where the equipment will be used.</em
    >
  </div>
  <div class="row">
    <div>
      <div dfsFormGroup class="col-md-3">
        <label name="equipmentState">Equipment Location State *</label>
        <ng-select
          name="equipmentState"
          id="equipmentState"
          formControlName="equipmentState"
          bindLabel="name"
          bindValue="code"
          [items]="statesList"
          [selectOnTab]="true"
          [searchFn]="filterStateList"
          (change)="setCountiesForState($event)"
        >
        </ng-select>
      </div>
      <div class="col-md-3">
        <label for="equipmentCounty">Equipment Location County *</label>
        <ng-select
          [items]="countyList"
          [selectOnTab]="true"
          name="equipmentCounty"
          id="equipmentCounty"
          bindLabel="name"
          bindValue="name"
          formControlName="equipmentCounty"
        >
        </ng-select>
      </div>
    </div>
  </div>
  <div
    *ngIf="
      equipmentType === equipmentTypes.farm ||
      equipmentType === equipmentTypes.commercial
    "
  >
    <label
      [ngClass]="{
        'text-danger': customFarmer.touched && customFarmer.invalid
      }"
    >
      Is the equipment for custom harvesting use? *
    </label>
    <div class="form-inline d-flex align-items-center">
      <div class="btn-group" btnRadioGroup formControlName="customFarmer">
        <button
          class="btn btn-wizard"
          btnRadio="true"
          [ngClass]="{
            'invalid-border': customFarmer.touched && customFarmer.invalid
          }"
        >
          Yes
        </button>
        <button
          class="btn btn-wizard"
          btnRadio="false"
          [ngClass]="{
            'invalid-border': customFarmer.touched && customFarmer.invalid
          }"
        >
          No
        </button>
      </div>
    </div>
  </div>
  <br />
  <label
    [ngClass]="{
      'text-danger': damageInsurance.touched && damageInsurance.invalid
    }"
  >
    Would you like a quote for Physical Damage Insurance? *
  </label>
  <div class="form-inline d-flex align-items-center">
    <div class="btn-group" btnRadioGroup formControlName="damageInsurance">
      <button
        class="btn btn-wizard"
        btnRadio="true"
        [ngClass]="{
          'invalid-border': damageInsurance.touched && damageInsurance.invalid
        }"
      >
        Yes
      </button>
      <button
        class="btn btn-wizard"
        btnRadio="false"
        [ngClass]="{
          'invalid-border': damageInsurance.touched && damageInsurance.invalid
        }"
      >
        No
      </button>
    </div>
  </div>
</form>
