import { Component, HostListener, OnDestroy, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import {
  CurrentUserService,
  UserDataCookieParserService,
} from 'dfs-common-ngx';
import { Subscription } from 'rxjs';

import { ISeoSitetag, seoSitetags } from './interfaces/seo-sitetag';
import { MetatagsService } from './services/metatags.service';
import { ToastrService } from 'ngx-toastr';
import { AlertMessagesRepoService } from './services/alert-messages-repo.service';

@Component({
  selector: 'fin-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit, OnDestroy {
  title = 'Dfs-Finance';
  navSubscription: Subscription;
  isActiveSideBar: boolean = true;
  isLoggedIn = false;
  alertMessage: string | undefined;
  tooltip: string = '';
  sideBarIcon: string = '';
  innerWidth: number = 0;

  constructor(
    private metaTagSvc: MetatagsService,
    private router: Router,
    private userService: CurrentUserService,
    private userDataCookieService: UserDataCookieParserService,
    private toastr: ToastrService,
    alert: AlertMessagesRepoService
  ) {
    alert.getAlertMessage().subscribe((message) => {
      this.alertMessage = message;
      this.setAlert();
    });

    this.navSubscription = this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.setMetaTags(event);
      }
    });
  }
  ngOnInit() {
    this.userDataCookieService.setSession();
    this.userService.profileO.subscribe((user) => (this.isLoggedIn = !!user));
    this.innerWidth = window.innerWidth;
    this.checkToolTip();
  }

  @HostListener('window:resize', ['$event'])
  onResize() {
    this.innerWidth = window.innerWidth;
    this.checkToolTip();
  }

  ngOnDestroy() {
    this.navSubscription && this.navSubscription.unsubscribe();
  }

  private setAlert() {
    if (this.alertMessage) {
      this.toastr.warning(this.alertMessage, 'Important Message', {
        timeOut: 0,
        closeButton: true,
        positionClass: 'toast-bottom-full-width',
        enableHtml: true,
        tapToDismiss: false,
        disableTimeOut: true,
        messageClass: 'banner-custom-message',
        titleClass: 'banner-custom-title',
      });
    }
  }

  public toggleSidebar(): void {
    this.isActiveSideBar = !this.isActiveSideBar;
    this.checkToolTip();
  }

  checkToolTip() {
    if (this.innerWidth > 768) {
      this.isActiveSideBar ? (this.tooltip = 'Hide') : (this.tooltip = 'Show');
    } else {
      !this.isActiveSideBar ? (this.tooltip = 'Hide') : (this.tooltip = 'Show');
    }

    this.tooltip == 'Hide'
      ? (this.sideBarIcon = 'pi pi-angle-double-left')
      : (this.sideBarIcon = 'pi pi-angle-double-right');
  }

  private setMetaTags(event: NavigationEnd) {
    const seoTag = seoSitetags.find(
      (i) => event.urlAfterRedirects === i.customUrl
    );
    if (seoTag) {
      if (seoTag.title) this.metaTagSvc.updateTitle(seoTag.title);

      const metaTags = this.getMetaTags(seoTag);
      this.metaTagSvc.updateTags(metaTags);
    } else {
      this.metaTagSvc.updateTitle('DFS Finance');
    }
  }

  private getMetaTags(seoTag: ISeoSitetag) {
    // This is a list of meta tags by property name that need to be updated
    return [
      seoTag.description
        ? { property: 'description', content: seoTag.description }
        : null,
      seoTag.description
        ? { property: 'og:description', content: seoTag.description }
        : null,
      seoTag.description
        ? { property: 'twitter:description', content: seoTag.description }
        : null,
      seoTag.image ? { property: 'image', content: seoTag.image } : null,
      seoTag.image ? { property: 'og:image', content: seoTag.image } : null,
      seoTag.image
        ? { property: 'twitter:image', content: seoTag.image }
        : null,
      seoTag.title ? { property: 'title', content: seoTag.title } : null,
      seoTag.title ? { property: 'og:title', content: seoTag.title } : null,
      seoTag.title
        ? { property: 'twitter:title', content: seoTag.title }
        : null,
      { property: 'og:url', content: window.location.href },
      { property: 'twitter:url', content: window.location.href },
    ];
  }
}
