import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { Injectable } from '@angular/core';
import { PopupMessageModalComponent } from './popup-message-modal.component';

@Injectable({
  providedIn: 'root',
})
export class PopupMessageModalService {
  modalRef!: BsModalRef;
  constructor(private readonly bsModalService: BsModalService) {}

  show(
    title: string,
    message: string,
    okButtonCaption?: string,
    cancelButtonCaption?: string
  ) {
    const initialState = {
      title,
      message,
      okButtonCaption,
      cancelButtonCaption,
    };
    return this.bsModalService.show(PopupMessageModalComponent, {
      initialState: initialState,
      ignoreBackdropClick: true,
    });
  }
}
