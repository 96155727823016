import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { DealerStartApplication } from './dealer-start-application.model';
import { Observable, of } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({ providedIn: 'root' })
export class DealerStartedApplicationsRepository {
  constructor(private httpClient: HttpClient) {}

  create(dealerStartApplication: DealerStartApplication): Observable<number> {
    const url = `${environment.ApiBaseUrl}/dealers/${dealerStartApplication.userId}/started-applications`;
    return this.httpClient.post<number>(url, dealerStartApplication);
  }

  get(
    applicationId: number,
    userId: string
  ): Observable<DealerStartApplication> {
    const url = `${environment.ApiBaseUrl}/dealers/${userId}/started-applications/${applicationId}`;
    return this.httpClient.get<DealerStartApplication>(url);
  }

  update(
    applicationId: number,
    dealerStartApplication: DealerStartApplication
  ): Observable<DealerStartApplication> {
    const url = `${environment.ApiBaseUrl}/dealers/${dealerStartApplication.userId}/started-applications/${applicationId}`;
    return this.httpClient.put<DealerStartApplication>(
      url,
      dealerStartApplication
    );
  }


}
