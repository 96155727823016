import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { IJobApplication } from '../interfaces/job-application';
import { IJobPosting } from '../interfaces/job-posting';

@Injectable({
  providedIn: 'root',
})
export class JobPostingsService {
  private jobPostingUrl = `${environment.ApiBaseUrl}/job-postings`;
  private headers = new HttpHeaders({
    'Content-Type': 'application/json; charset=utf-8',
    'Access-Control-Allow-Methods': 'OPTIONS, GET, POST',
  });

  constructor(private http: HttpClient) {}
  getJobPostings(): Observable<IJobPosting[]> {
    let params = new HttpParams().set('onlyActive', true);
    return this.http.get<IJobPosting[]>(this.jobPostingUrl, {
      headers: this.headers,
      params: params,
    });
  }
  getJobPosting(jobId: string): Observable<IJobApplication> {
    let singleJobPosting = this.jobPostingUrl + '/' + jobId;
    return this.http.get<IJobApplication>(singleJobPosting, {
      headers: this.headers,
    });
  }
}
