<main>
  <section
    style="
      background-image: url('assets/images/careers-hero.jpg');
      background-size: cover;
      background-position: center;
    "
  >
    <div class="container px-4 py-5">
      <div class="row align-items-start g-lg-5 py-5">
        <div class="col-lg-10 text-lg-start text-light">
          <h1 class="display-4 lh-1 mb-3 py-4">Careers</h1>
        </div>
      </div>
    </div>
  </section>
  <div class="container">
    <div class="row px-4 py-4 my-5 text-center">
      <p class="display-5 mb-4">Work hard. Play hard.</p>
      <div class="col-lg-10 mx-auto">
        <p class="fs-5 mb-4">
          Our culture centers around optimism, gratitude, and family. There’s an
          approachability and a level of comfort internally, and our customers
          feel it too. We work incredibly hard to provide exceptional service,
          but we also take the time to celebrate our successes.
        </p>
      </div>
    </div>
  </div>
  <fin-job-postings></fin-job-postings>
  <div class="px-4 py-4 my-5 text-center">
    <p class="display-5 mb-4">Who We Are</p>
    <div class="col-lg-11 mx-auto">
      <p class="fs-5 mb-4">
        We’ve been an industry leader for over 50 years, but it feels like we’re
        just getting started.
      </p>
      <div class="d-grid gap-2 d-sm-flex justify-content-sm-center">
        <video controls width="500" height="280">
          <source src="../../assets/videos/dfsvideo.mov" type="video/mp4" />
        </video>
      </div>
    </div>
  </div>
</main>
