
export class Constants {
    static apiUrls = {
            usersUrlSegment: 'users',
            creditAppsUrlSegment: 'credit-applications',
            // submittedAppsUrlSegment: 'submitted-applications',
            dealersApiSegment: 'dealers/',
            documentSegment: '/documents',
            saveToDbSegment: 'save',
            manufacturersApiSegment: 'manufacturers/',
            credentialsApiSegment: 'passwords'
    };
}
