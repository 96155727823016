<section>
  <form novalidate class="card p-4 fs-6" [formGroup]="jobApplicationForm">
    <div class="card col-md-6 container px-4 py-5">
      <h4 class="text-primary mb-4">
        Apply For: <b>{{ this.jobTitle }}</b>
      </h4>

      <div class="col-12">
        <label for="firstName" class="form-label"
          >First Name<span class="text-danger">*</span></label
        >
        <input
          aria-label="First Name"
          class="form-control mb-2 fs-6"
          id="firstName"
          formControlName="firstName"
          type="text"
          placeholder="John"
          ng-maxlength="100"
          [ngClass]="{ 'is-invalid': isControlValid('firstName') }"
        />
        <div class="text-danger">
          <span *ngIf="fieldHasError('firstName', 'required')">
            Please enter your first name.
          </span>
        </div>
      </div>
      <div class="col-12">
        <label for="lastName">Last Name</label>
        <input
          class="form-control mb-2 fs-6"
          id="lastName"
          formControlName="lastName"
          [ngClass]="{ 'is-invalid': isControlValid('lastName') }"
          type="text"
          placeholder="Doe"
          ng-maxlength="200"
        />
        <div class="text-danger">
          <span *ngIf="fieldHasError('lastName', 'required')">
            Please enter your last name.
          </span>
        </div>
      </div>
      <div class="col-12" p>
        <label for="email">E-mail Address</label>
        <input
          class="form-control mb-2 fs-6"
          id="email"
          type="email"
          formControlName="email"
          placeholder="you@example.com"
          ng-maxlength="500"
          [ngClass]="{ 'is-invalid': isControlValid('email') }"
        />
        <div class="text-danger">
          <div *ngIf="fieldHasError('email', 'required')">
            Email is required
          </div>
          <div *ngIf="fieldHasError('email', 'email')">
            Please enter a valid email
          </div>
        </div>
      </div>
      <div>
        <div class="col-12">
          <label for="workEligibleQuestion" class="radioLabel col-sm-6 mb-2"
            ><b>Are you eligible to work in the United States?</b></label
          >
          <div
            class="btn-group col-sm-3 mb-2"
            btnRadioGroup
            formControlName="workEligible"
          >
            <button class="btn btn-outline-primary text-small" btnRadio="true">
              Yes
            </button>
            <button class="btn btn-outline-primary text-small" btnRadio="false">
              No
            </button>
          </div>
          <div class="text-danger">
            <div *ngIf="fieldHasError('workEligible', 'required')">
              Select answer work eligiblity question.
            </div>
          </div>
        </div>
      </div>
      <div class="col-12">
        <label for="visaSponsorship" class="radioLabel col-sm-6"
          ><b>Do you require VISA sponsorship?</b></label
        >
        <div
          class="btn-group col-sm-3 mb-2"
          btnRadioGroup
          formControlName="visaSponsorship"
        >
          <button class="btn btn-outline-primary text-small" btnRadio="true">
            Yes
          </button>
          <button class="btn btn-outline-primary text-small" btnRadio="false">
            No
          </button>
        </div>
        <div class="text-danger">
          <span *ngIf="fieldHasError('visaSponsorship', 'required')">
            Please answer VISA sponsorship question.
          </span>
        </div>
      </div>

      <div class="col-12">
        <label for="salaryRange" class="form-label"
          >What annual salary range are you looking for?</label
        >
        <input
          type="text"
          id="salaryRange"
          formControlName="salaryRange"
          [ngClass]="{ 'is-invalid': isControlValid('salaryRange') }"
          class="form-control mb-2 fs-6"
          ng-maxlength="50"
        />
        <div class="text-danger">
          <span *ngIf="fieldHasError('salaryRange', 'required')">
            Please enter salary range.
          </span>
        </div>
      </div>

      <div class="col-12">
        <label for="referral">How did you hear about us?</label>
        <ng-select
          class="form-control mb-2"
          id="referral"
          formControlName="referral"
          (change)="referralSelected()"
          [ngClass]="{ 'is-invalid': isControlValid('referral') }"
          [items]="referralOptions"
        ></ng-select>
        <div class="text-danger">
          <span *ngIf="fieldHasError('referral', 'required')">
            Entry is required.
          </span>
        </div>
      </div>

      <div class="col-12" *ngIf="referralReasonOther">
        <label for="heardAboutUs">Please specify how you heard about us</label>
        <input
          class="form-control mb-2 fs-6"
          type="text"
          id="heardAboutUs"
          formControlName="heardAboutUs"
          [ngClass]="{ 'is-invalid': isControlValid('heardAboutUs') }"
          ng-maxlength="50"
        />
        <div class="text-danger">
          <span *ngIf="fieldHasError('heardAboutUs', 'required')">
            Entry is required.
          </span>
        </div>
      </div>

      <div class="col-12">
        <label
          for="resumeUpload"
          class="btn btn-primary text-small btn-file h-25 mb-2"
          >Upload Your Resume</label
        >
        <input
          class="btn btn-primary text-dark"
          style="display: none"
          type="file"
          id="resumeUpload"
          ngf-max-size="8MB"
          (change)="attachFile('Resume', $event)"
        />
        <span class="label label-info label" id="resume-file-info"></span>
        <div class="text-danger">
          <span *ngIf="!isResumeUploaded() && submitted">
            Please upload your resume.
          </span>
        </div>
      </div>

      <div class="col-12">
        <label
          for="coverLetterUpload"
          class="btn btn-primary text-small btn-file mb-2"
          >Upload Your Cover Letter (Optional)</label
        >
        <input
          class="btn btn-primary text-dark"
          style="display: none"
          type="file"
          id="coverLetterUpload"
          ngf-max-size="8MB"
          (change)="attachFile('Cover Letter', $event)"
        />
        <span class="label label-info label" id="cvr-file-info"></span>
      </div>

      <div class="form-control">
        <button
          type="button"
          class="btn btn-secondary text-small mb-2"
          name="selfIdentification"
          (click)="getEeoInformation()"
          identification-required
        >
          Complete the Voluntary Applicant Self-Identification Form
        </button>
        <br />
        <span
          *ngIf="!hasCompletedSelfIdentification() && submitted"
          class="text-danger"
          >Please complete the self-identification form.</span
        >
      </div>
      <div class="col-12">
        <re-captcha
          class="form-control mb-2 fs-6"
          class="mb-2 fs-6"
          (resolved)="getCaptchaValue($event)"
          siteKey="{{ captchaKey }}"
        ></re-captcha>
        <p class="text-danger" *ngIf="!captchaIsValid && submitted">
          Please validate you are not a robot before submitting:
        </p>
      </div>
      <div>
        <button
          [disabled]="!captchaIsValid"
          class="btn btn-primary mt-2"
          type="submit"
          (click)="submit($event)"
        >
          Submit Application
        </button>
        <dfs-spinner
          style="position: relative"
          [radius]="10"
          [lines]="10"
          [width]="5"
          [length]="6"
          [top]="'.9%'"
          [left]="'-25px'"
        ></dfs-spinner>
      </div>

      <p>
        DFS Finance, a division of First National Bank of Omaha is an Equal
        Employment Opportunity Employer.
      </p>
    </div>
  </form>
</section>
