import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { environment } from '../../environments/environment';

@Injectable()
export class SubmittedApplicationsRepo {
    constructor(private $http: HttpClient) {}

        getAll() {
            const url = this.getApiAppsUrl();

            return this.$http.get<any[]>(url);
        }

        add(application: any) {
            const url = this.getApiAppsUrl();

            return this.$http.post<any>(url, application);
        }

        private getApiAppsUrl() {
            return environment.ApiBaseUrl + '/submitted-applications';
        }
}
