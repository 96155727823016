import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { environment } from '../../environments/environment';
import { ApplicationItem } from './entities/ApplicationItem';
import { Observable } from 'rxjs';
import { CreditApplicationItem } from '../interfaces/credit-application-item';

@Injectable()
export class UserSubmittedApplicationsRepo {
  constructor(private $http: HttpClient) {}

  getAll(userId: string|number) {
    const url = this.buildCurrentUserUrl(userId);

    this.$http.get<ApplicationItem[]>(url);
  }

  get(submittedAppId: number, userId: string|number) {
    const url = this.buildCurrentUserUrl(userId) + `/${submittedAppId}`;

    return this.$http.get<ApplicationItem>(url);
  }

  private buildCurrentUserUrl(userId: string|number) {
    return `${environment.ApiBaseUrl}/users/${userId}/submitted-applications`;
  }

  getByType(statusType: string[]): Observable<CreditApplicationItem[]> {
    const url = `${environment.ApiBaseUrl}/users/submitted-applications`;

    return this.$http.post<CreditApplicationItem[]>(url, statusType);
  }
}
