<main>
  <section
    style="
      background-image: url('{{ productOfferingPageImage }}');
      background-size: cover;
      background-position: center;
    "
  >
    <div class="container px-4 py-5">
      <div class="row align-items-start g-lg-5 py-5">
        <div class="col-lg-10 text-lg-start text-light">
          <h1 class="display-4 lh-1 mb-3 py-4">
            {{ productOfferingPageHeader }}
          </h1>
        </div>
      </div>
    </div>
  </section>

  <div class="px-4 py-4 my-5 text-center">
    <p class="display-5 mb-4">Let's get started</p>
    <div class="col-lg-11 mx-auto">
      <p class="fs-5 mb-4">
        Apply for the financing you need, and we’ll quickly be in touch.
      </p>
      <div class="d-grid gap-2 d-sm-flex justify-content-sm-center">
        <a
          *ngIf="!loggedIn || isCustomer"
          (click)="customerRoute()"
          class="btn btn-outline-primary mb-auto"
          >Customer
        </a>
        <a
          *ngIf="!loggedIn || isDealer"
          (click)="dealerRoute()"
          class="btn btn-outline-primary mb-auto"
          >Dealer
        </a>
      </div>
    </div>
  </div>

  <div class="container">
    <div class="d-md-flex flex-md-equal w-100 my-md-5">
      <div class="overflow-hidden d-flex justify-content-center">
        <img class="h-100" src="{{ productOfferingSalesImage }}" />
      </div>
      <div class="px-3 px-md-5 overflow-hidden">
        <div class="mb-3 p-3">
          <p class="display-6 text-primary mb-4">
            {{ productOfferingSalesHeader }}
          </p>
          <p>
            {{ productOfferingSalesDescription }}
          </p>
        </div>
        <div class="container mb-4" id="icon-grid">
          <div class="mb-4 rounded">
            <div class="row row-cols-1 row-cols-sm-2 g-3 text-small">
              <div
                class="col d-flex align-items-start"
                *ngFor="
                  let bullet of salesBulletPoints;
                  index as i;
                  let l = count
                "
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  style="flex-shrink: 0"
                  fill="currentColor"
                  class="bi bi-check text-primary"
                  viewBox="0 0 16 16"
                >
                  <path
                    d="M10.97 4.97a.75.75 0 0 1 1.07 1.05l-3.99 4.99a.75.75 0 0 1-1.08.02L4.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093 3.473-4.425a.267.267 0 0 1 .02-.022z"
                  />
                </svg>
                <div [innerHTML]="salesBulletPoints[i].bulletPoint"></div>
              </div>
            </div>
          </div>
        </div>
        <a
          class="btn btn-outline-primary mb-4"
          href="{{ productOfferingApplicationLink }}"
          target="_blank"
          >Download an Application</a
        >
      </div>
    </div>
  </div>

  <div class="px-4 py-4 my-5 text-center">
    <p class="display-5 mb-4">Still have questions?</p>
    <div class="col-lg-11 mx-auto">
      <p class="fs-5 mb-4">
        Check out our FAQ section for account or payment answers.
      </p>
      <div class="d-grid gap-2 d-sm-flex justify-content-sm-center">
        <a class="btn btn-outline-primary px-4 gap-3" [routerLink]="['/faqs']"
          >Read our FAQs</a
        >
      </div>
    </div>
  </div>
</main>
