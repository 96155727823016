import { IEeoDisclosure } from './eeo-disclosure';

export class IJobApplication {
  jobPostingId!: string;
  firstName!: string;
  lastName!: string;
  email!: string;
  salaryRange!: string;
  referral!: string;
  workEligible!: boolean;
  visaSponsorship!: boolean;
  selfIdentification: IEeoDisclosure;
  constructor() {
    this.selfIdentification = new IEeoDisclosure();
    this.selfIdentification.gender = '';
    this.selfIdentification.race = '';
    this.selfIdentification.optOut = false;
  }
}
