<form [formGroup]="form" #formDirective="ngForm">
  <div class="row">
    <div class="col-md-4">
      <div dfsFormGroup>
        <label for="salesPerson">Salesperson *</label>
        <input
          formControlName="salesPerson"
          id="salesPerson"
          name="salesPerson"
        />
      </div>
    </div>
    <div dfsFormGroup class="col-md-4">
      <label>Dealership</label>
      <ng-select
        formControlName="dealerLocation"
        id="dealerLocation"
        name="dealerLocation"
        [selectOnTab]="true"
        [items]="locations"
        [searchable]="false"
        [clearable]="false"
        bindlabel="dealerLocation.id"
      >
        <ng-template ng-label-tmp let-item="item">
          {{ item.value }}
        </ng-template>
        <ng-template ng-option-tmp let-item="item">
          {{ item.value }}
        </ng-template>
      </ng-select>
    </div>
    <div class="col-md-4">
      <label>Phone</label>
      <div class="form-control">
        {{ currentUser?.phone }}
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-md-4">
      <div dfsFormGroup>
        <label for="customerName">Customer Name *</label>
        <input
          formControlName="customerName"
          id="customerName"
          name="customerName"
        />
      </div>
    </div>
    <div class="col-md-4">
      <div dfsFormGroup>
        <label for="customerEmail">Customer E-Mail *</label>
        <input
          formControlName="customerEmail"
          id="customerEmail"
          name="customerEmail"
        />
      </div>
    </div>
  </div>
</form>
