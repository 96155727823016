// angular imports
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { CommonModule } from '@angular/common';

// 3rd party imports
import { ButtonsModule } from 'ngx-bootstrap/buttons';
import { NgSelectModule } from '@ng-select/ng-select';
import { AccordionModule } from 'ngx-bootstrap/accordion';
import { DfsCommonNgxModule } from 'dfs-common-ngx';
import { ToastrModule } from 'ngx-toastr';
import { NgxMaskModule } from 'ngx-mask';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { ModalModule } from 'ngx-bootstrap/modal';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { DfsCreditApplicationNgxModule } from 'dfs-credit-application-ngx';
import { ReactiveValidationModule } from 'angular-reactive-validation';
import { RecaptchaModule } from 'ng-recaptcha';

// app imports
import { CreditApplicationComponent } from './credit-application.component';
import { BasicInformationComponent } from './wizard-forms/basic-information/basic-information.component';
import { ContractTermsComponent } from './wizard-forms/contract-terms/contract-terms.component';
import { DealerSellerComponent } from './wizard-forms/basic-information/dealer-seller/dealer-seller.component';
import { PrivateSellerComponent } from './wizard-forms/basic-information/private-seller.component';
import { SalesPersonsRepositoryService } from './wizard-forms/basic-information/dealer-seller/sales-persons-repository.service';
import { CustomerCreditApplicationRepository } from './customer-credit-application.repository';
import { CustomerCreditAttachmentRepository } from './customer-credit-attachments.repository';
import { environment } from 'src/environments/environment';
import { YearsInBusinessRepository } from '../dfs-fin-api/years-in-business.repository';
import { DfsSharedModule } from "../shared/dfs-shared.module";


@NgModule({
    declarations: [
        CreditApplicationComponent,
        BasicInformationComponent,
        ContractTermsComponent,
        DealerSellerComponent,
        PrivateSellerComponent,
    ],
    providers: [
        SalesPersonsRepositoryService,
        CustomerCreditApplicationRepository,
        CustomerCreditAttachmentRepository,
        YearsInBusinessRepository,
    ],
    bootstrap: [CreditApplicationComponent],
    exports: [CreditApplicationComponent],
    imports: [
        CommonModule,
        BrowserAnimationsModule,
        HttpClientModule,
        ButtonsModule.forRoot(),
        NgSelectModule,
        AccordionModule.forRoot(),
        DfsCommonNgxModule.forRoot({ useAuth: false }),
        ToastrModule.forRoot({
            timeOut: 15000,
        }),
        NgxMaskModule.forRoot(),
        BsDatepickerModule.forRoot(),
        ModalModule.forRoot(),
        TooltipModule.forRoot(),
        DfsCreditApplicationNgxModule.forRoot(environment),
        ReactiveValidationModule,
        RecaptchaModule,
        DfsSharedModule
    ]
})
export class CustomerStartedModule {}
