import { Component, Injectable, Input, OnInit } from '@angular/core';
import { FileSaverService } from 'ngx-filesaver';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { ViewAttachmentsRepository } from './view-attachments.repository';
import { CreditApplicationAttachmentItem } from './credit-attachment.model';
import { ToastrService } from 'ngx-toastr';

@Injectable()
export class ViewCreditAttachmentsModalService {
  constructor(private readonly bsModalService: BsModalService) {}

  show(
    applicationId: number,
    fileAttachments: CreditApplicationAttachmentItem[]
  ) {
    const initialState = { applicationId, fileAttachments };
    this.bsModalService.show(ViewCreditAttachmentsModalComponent, {
      initialState,
      ignoreBackdropClick: true,
      class: 'modal-xl',
    });
  }
}

@Component({
  styleUrls: [`../../PortalMain.scss`],
  template: `
    <div class="modal-header">
      <h4 class="modal-title">Application Attachments</h4>
    </div>
    <div class="modal-body">
      <div class="well" *ngIf="this.fileAttachments.length === 0">
        No attachments found for this application.
      </div>
    </div>
    <table
      *ngIf="this.fileAttachments.length > 0"
      class="table table-striped table-hover"
    >
      <thead>
        <tr>
          <th>File Name</th>
          <th>Description</th>
          <th></th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let attachment of this.fileAttachments">
          <td>{{ attachment.fileName }}</td>
          <td>{{ attachment.description }}</td>
          <td>
            <button
              class="dfs-btn dfs-btn-primary"
              type="button"
              (click)="this.download(attachment.id)"
            >
              Download
              <span class="fa-solid fa-download"></span>
            </button>
            <button
              class="dfs-btn dfs-btn-danger"
              type="button"
              (click)="this.delete(attachment)"
            >
              Delete
              <span class="fa-solid fa-xmark"></span>
            </button>
          </td>
        </tr>
      </tbody>
    </table>
    <div class="modal-footer">
      <button type="button" class="dfs-btn dfs-btn-danger" (click)="close()">
        Close
      </button>
    </div>
  `,
})
export class ViewCreditAttachmentsModalComponent implements OnInit {
  @Input() fileAttachments!: CreditApplicationAttachmentItem[];
  @Input() applicationId!: number;
  constructor(
    private readonly bsModalRef: BsModalRef,
    private readonly toastr: ToastrService,
    private readonly fileSaverService: FileSaverService,
    private readonly viewAttachmentsRepository: ViewAttachmentsRepository
  ) {}

  ngOnInit() {}

  download(attachmentId: number) {
    this.viewAttachmentsRepository
      .get(this.applicationId, attachmentId)
      .subscribe({
        next: (file) => this.fileSaverService.save(file.file, file.fileName),
        error: (error) => {
          console.error('error getting attachment', error);
          this.toastr.error(
            `There was an error getting the attachment`,
            'Error'
          );
        },
      });
  }
  delete(attachment: CreditApplicationAttachmentItem) {
    this.viewAttachmentsRepository
      .delete(this.applicationId, attachment.id)
      .subscribe({
        next: () => {
          const index = this.fileAttachments.indexOf(attachment);
          this.fileAttachments.splice(index, 1);
          this.toastr.success('Attachment deleted successfully');
        },
        error: (error) => {
          console.error('error deleting attachment', error);
          this.toastr.error(
            `There was an error deleting the attachment`,
            'Error'
          );
        },
      });
  }
  onSubmit() {
    this.close();
  }
  close() {
    this.bsModalRef.hide();
  }
}
