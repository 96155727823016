<div class="dfs-modal-dialog dfs-modal-content">
  <div class="dfs-modal-header">
    <h4 class="modal-title">{{ title }}</h4>
    <button
      type="button"
      class="dfs-modal-close-button"
      (click)="close()"
      aria-hidden="true"
    >
      &times;
    </button>
  </div>
  <div class="dfs-modal-body" [innerHTML]="message"></div>
  <div class="dfs-modal-footer">
    <button type="button" (click)="action()" class="dfs-btn dfs-btn-primary">
      <span
        *ngIf="okClicked"
        class="spinner-border spinner-border-sm"
        role="status"
        aria-hidden="true"
      ></span>
      {{ okButtonCaption }}
    </button>
    <button type="button" (click)="close()" class="dfs-btn dfs-btn-primary">
      {{ cancelButtonCaption }}
    </button>
  </div>
</div>
