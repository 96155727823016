import { Component, Input } from '@angular/core';

import { ContractTerms, CurrentUserService } from 'dfs-common-ngx';
import {
  ContractTypeEnum,
} from 'dfs-credit-application-ngx';
import { TermRateGroupFactory } from 'src/app/common/services/term-rate-group-factory.service';
import { ApplicationRate } from '../interfaces/ApplicationRate';

@Component({
  selector: 'dsa-interest-rate-guide',
  templateUrl: './interest-rate-guide.component.html',
})
export class InterestRateGuideDirective {
  constructor(
    private CurrentUserSvc: CurrentUserService,
    private termRateGroupFactorySvc: TermRateGroupFactory
  ) {}

  @Input() applicationRates!: ApplicationRate[];
  @Input() contractType!: any;

  // good post on template ordering https://stackoverflow.com/questions/52793944/angular-keyvalue-pipe-sort-properties-iterate-in-order
  public keepOrder = (a: any, b: any) => a;

  contractHeader?: string;
  ratesByType?: any;
  @Input() termLimit: number | undefined;
  @Input() amountToFinance: number | undefined;

  ngOnChanges(): void {
    this.contractHeader = this.buildHeader();

    this.ratesByType = this.GetRatesByType();
  }

  private GetRatesByType() {
    return this.termRateGroupFactorySvc.create(
      this.applicationRates,
      this.termLimit,
      this.amountToFinance
    );
  }

  private buildHeader() {
    switch (Number(this.contractType)) {
      case ContractTypeEnum.ContractFixed: {
        return 'Fixed Rates';
      }
      case ContractTypeEnum.ContractVariable: {
        return 'Variable Rates';
      }
      default: {
        return undefined;
      }
    }
  }
}
