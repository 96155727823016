import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { Validators } from 'angular-reactive-validation';
import {
  EquipmentType,
  FormSubmitNotificationService,
  SellerType,
  StepFormComponent,
} from 'dfs-credit-application-ngx';

@Component({
  selector: 'fin-submit',
  templateUrl: './submit.component.html',
})
export class SubmitComponent
  extends StepFormComponent
  implements OnInit, OnDestroy
{
  @Input() equipmentType!: EquipmentType;
  @Input() sellerType!: SellerType;
  @Input() captchaKey!: string;
  @Output() attachFileUpload = new EventEmitter<any>();
  @Output() captchaValue = new EventEmitter<string>();
  @Output() creditReportAuthorizedChange = new EventEmitter<boolean>();

  equipmentTypes = EquipmentType;
  sellerTypes = SellerType;
  showCaptchaError: boolean = true;
  licenseText: string = "Driver's License";

  constructor(
    private readonly fb: FormBuilder,
    submitNotificationService: FormSubmitNotificationService
  ) {
    super(submitNotificationService);
  }

  override ngOnInit() {
    super.ngOnInit();
    this.addFormControls();
  }

  private addFormControls() {
    this.form.addControl(
      'comments',
      this.fb.control(
        null,
        Validators.maxLength(
          255,
          (maxLength) => `Maximum length is ${maxLength} characters`
        )
      )
    );
    if (this.sellerType !== this.sellerTypes.dealerStart) {
      this.form.addControl(
        'creditReportAuthorized',
        this.fb.control(
          null,
          Validators.requiredTrue('Credit Report Authorization is required')
        )
      );
      this.form.addControl(
        'CustomerCreditReportAuthorization',
        this.fb.control(null)
      );
    }
  }

  resolved(captchaResponse: string) {
    this.showCaptchaError = !captchaResponse;
    this.captchaValue.emit(captchaResponse);
  }

  attachFile(fileData: any) {
    this.attachFileUpload.emit(fileData);
  }

  override ngOnDestroy(): void {
    super.ngOnDestroy();
  }

  creditReportAuthorizationChangeInSubmit(value: any) {
    if (this.sellerType !== this.sellerTypes.dealerStart) {
      this.creditReportAuthorizedChange.emit(value);
    }
  }
}
