import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Observable, map } from 'rxjs';

import { NextBusinessDayRequest } from './entities/NextBusinessDayRequest';
import { NextBusinessDayResponse } from './entities/NextBusinessDayResponse';
import { environment } from 'src/environments/environment';

@Injectable()
export class BusinessDaysRepo {
  constructor(private readonly http: HttpClient) {}

  add(considerationDate: Date): Observable<Date> {
    const request = new NextBusinessDayRequest();
    request.considerationDate = considerationDate;
    const url = environment.ApiBaseUrl + '/business-days/';
    return this.http
      .post<NextBusinessDayResponse>(url, request)
      .pipe(map((result) => result.nextBusinessDay));
  }
}
