<form
  [formGroup]="form"
  (submit)="submitForm()"
  autocomplete="off"
  class="p-4 p-md-5 shadow bg-light h-100"
>
  <small class="fw-semibold">Reset Password</small>
  <div *ngIf="error">
    There was an error resetting the password. The password link may have
    expired.
  </div>
  <div *ngIf="success">
    Your password has been reset. You can now
    <a [routerLink]="['/login']" (click)="closeDialog()">login</a> using the new
    password.
  </div>
  <div>
    <p-password
      id="password"
      [toggleMask]="true"
      placeholder="Password"
      formControlName="password"
      class="w-100 h-100"
      [ngClass]="{
        'is-invalid':
          (form.get('password')?.dirty || form.get('password')?.touched) &&
          form.get('password')?.errors
      }"
    >
    </p-password>
    <div
      *ngIf="
        (form.get('password')?.dirty || form.get('password')?.touched) &&
        form.get('password')?.errors
      "
      class="invalid-feedback"
    >
      <div *ngIf="form.get('password')?.errors?.['required']">
        Password is required
      </div>
      <div *ngIf="form.get('password')?.errors?.['pattern']">
        Password must contain between 8 and 20 characters, both lower and
        uppercase letters, and at least one number or special character.
      </div>
      <div *ngIf="form.get('password')?.errors?.['minLength']">
        Password must be at least 8 characterx.
      </div>
      <div *ngIf="form.get('password')?.errors?.['maxLength']">
        Password must be no more than 20 characterx.
      </div>
    </div>
  </div>
  <div class="form-floating mb-3">
    <p-password
      id="confirm"
      [toggleMask]="true"
      required="true"
      formControlName="confirm"
      [feedback]="false"
      class="w-100 h-100"
      [ngClass]="{
        'is-invalid':
          (form.get('confirm')?.dirty || form.get('confirm')?.touched) &&
          form.get('confirm')?.errors
      }"
    >
    </p-password>
    <div
      *ngIf="
        (form.get('confirm')?.dirty || form.get('confirm')?.touched) &&
        form.get('confirm')?.errors
      "
      class="invalid-feedback"
    >
      <div *ngIf="form.get('confirm')?.errors?.['required']">
        Confirm Password is required
      </div>
    </div>
  </div>
  <div
    *ngIf="
      (form.get('confirm')?.dirty || form.get('confirm')?.touched) &&
      form.errors
    "
    class="invalid-feedback d-block"
  >
    <div *ngIf="form.errors?.['passwordNotMatched']">
      Password and Confirm Password do not match.
    </div>
  </div>

  <button
    class="w-100 btn btn-primary"
    [disabled]="loading || form.invalid || success"
    type="submit"
  >
    <span *ngIf="loading" class="spinner-border spinner-border-sm me-1"></span>
    Reset Password
  </button>
</form>
