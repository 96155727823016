import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { Validators } from 'angular-reactive-validation';
import {
  FormSubmitNotificationService,
  StepFormComponent,
  LoanCalculatorService,
  ContractType,
  EquipmentType,
  SellerType,
  ContractTypeEnum,
} from 'dfs-credit-application-ngx';
import {
  CustomerContractTerms,
  EquipmentItem,
} from '../../customer-finish-application.model';
import { BasicTermsCalculation } from 'src/app/shared/wizard-forms/basic-terms-calculation';
import { ApplicationRate } from 'src/app/interfaces/ApplicationRate';
import { ApplicationTermsService } from 'src/app/shared/application-terms.service';

@Component({
  selector: 'cfa-contract-terms',
  templateUrl: 'contract-terms.component.html',
})
export class ContractTermsComponent
  extends StepFormComponent
  implements OnInit, OnDestroy
{
  @Input() equipmentItems!: any;
  @Input() customerState!: string;
  @Input() rates!: ApplicationRate[];
  @Input() equipmentType!: EquipmentType;
  @Input() sellerType!: SellerType;
  @Input() customerContractTerms!: CustomerContractTerms;

  loanCalculatorResults!: LoanCalculatorService;
  workingRates!: ApplicationRate[];

  get contractType() {
    return this.form?.get('contractTypeId')?.value;
  }
  get amountToFinance() {
    return this.form.get('amountToFinance');
  }
  get salesTax() {
    return this.form.get('salesTax');
  }
  constructor(
    submitNotificationService: FormSubmitNotificationService,
    private readonly loanCalculatorSvc: LoanCalculatorService,
    private readonly fb: FormBuilder,
    private readonly applicationTermsService: ApplicationTermsService
  ) {
    super(submitNotificationService);
  }

  readonly contractTypes = [
    { value: ContractTypeEnum.ContractFixed, text: 'Contract-Fixed' },
    { value: ContractTypeEnum.ContractVariable, text: 'Contract-Variable' },
    { value: ContractTypeEnum.LeaseLP, text: 'Lease-LP' },
    { value: ContractTypeEnum.LeaseTL, text: 'Lease-PRO' },
  ];

  override ngOnInit() {
    super.ngOnInit();
    this.addFormControls();

    this.form
      ?.get('contractTypeId')
      ?.valueChanges.subscribe({ next: () => this.selectInterestRate() });
    this.form
      ?.get('contractTerm')
      ?.valueChanges.subscribe({ next: () => this.selectInterestRate() });
    this.form
      ?.get('cashDown')
      ?.valueChanges.subscribe({ next: () => this.calculate() });
    this.setWorkingRates();
    this.calculate();
  }

  private addFormControls() {
    this.form.addControl(
      'paymentFrequency',
      this.fb.control(
        this.customerContractTerms.paymentFrequency,
        Validators.required('Payment Frequency is required')
      )
    );
    this.form.addControl(
      'contractTypeId',
      this.fb.control(
        this.customerContractTerms.contractTypeId,
        Validators.required('Contract Type is required')
      )
    );
    this.form.addControl(
      'contractTerm',
      this.fb.control(
        this.customerContractTerms.contractTerm,
        Validators.required('Contract Term is required')
      )
    );
    this.form.addControl(
      'cashDown',
      this.fb.control(this.customerContractTerms.cashDown)
    );
    this.form.addControl(
      'contractTerm',
      this.fb.control(
        this.customerContractTerms.contractTerm,
        Validators.required('Contract Term is required')
      )
    );
    this.form.addControl('amountToFinance', this.fb.control(null));
    this.form.addControl(
      'contractInterestRate',
      this.fb.control(this.customerContractTerms.contractInterestRate)
    );
    this.form.addControl(
      'saleLeasePrice',
      this.fb.control(this.customerContractTerms.saleLeasePrice)
    );
    this.form.addControl(
      'salesTax',
      this.fb.control(this.customerContractTerms.salesTax)
    );
    this.form.addControl(
      'isCustomInterestRate',
      this.fb.control(this.customerContractTerms.isCustomInterestRate)
    );
  }

  onCalculationsChange(basicTermsCalculate: BasicTermsCalculation) {
    this.form?.get('saleLeasePrice')?.setValue(basicTermsCalculate.salesPrice);
    this.form?.get('salesTax')?.setValue(basicTermsCalculate.salesTax);
    this.calculate();
  }

  calculate() {
    this.loanCalculatorSvc.equipment = this.equipmentItems;
    this.loanCalculatorSvc.cashDown = Number(this.form?.get('cashDown')?.value);
    this.loanCalculatorSvc.rentalCredit = Number(
      this.customerContractTerms.rentalCredit
    );
    this.loanCalculatorSvc.filingFee = this.customerContractTerms.filingFee;
    this.loanCalculatorSvc.dealerDocFee = Number(
      this.customerContractTerms.dealerDocFee
    );
    this.loanCalculatorSvc.contractType = this.contractType;
    this.loanCalculatorSvc.residualPercentage = Number(
      this.customerContractTerms.residualPercentage
    );
    this.loanCalculatorSvc.customerState = this.customerState;
    this.loanCalculatorSvc.dealerState = this.customerContractTerms.dealerState;

    this.loanCalculatorSvc.calculate();
    this.loanCalculatorResults = this.loanCalculatorSvc;

    this.amountToFinance?.setValue(this.loanCalculatorSvc.amountToFinance);
    this.salesTax?.setValue(this.loanCalculatorResults.salesTax);

    this.selectInterestRate();
  }

  setWorkingRates() {
    this.workingRates = this.applicationTermsService.getWorkingRates(
      this.rates,
      this.contractType
    );
  }

  selectInterestRate() {
    const term = this.form?.get('contractTerm')?.value;

    if (
      this.customerContractTerms.isCustomInterestRate ||
      term === null ||
      term === undefined
    ) {
      return;
    }

    const rateForTerms = this.applicationTermsService.getRateForTerms(
      this.workingRates,
      this.customerContractTerms.programTypeId,
      this.form?.get('contractTerm')?.value,
      this.amountToFinance?.value
    );

    if (rateForTerms !== null && rateForTerms > 0) {
      this.form?.get('contractInterestRate')?.setValue(rateForTerms);
    } else {
      this.form?.get('contractInterestRate')?.setValue(0);
    }
  }

  getContractTypeEnum(contractType: ContractType): number {
    switch (contractType) {
      case ContractType.ContractFixed:
        return 1;
      case ContractType.ContractVariable:
        return 2;
      case ContractType.LeaseLp:
        return 3;
      case ContractType.LeasePro:
        return 6;
    }
  }

  override ngOnDestroy(): void {
    super.ngOnDestroy();
  }
}
