import { Component, Inject, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DialogRef, DIALOG_DATA } from '@angular/cdk/dialog';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { CreditApplicationsRepo } from 'src/app/dfs-fin-api/CreditApplicationsRepo';
import { UserNotificationSvc } from 'src/app/common/services/UserNotificationSvc';

@Component({
  selector: 'fin-deleted-application-modal',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './deleted-application-modal.component.html',
  styleUrls: ['../../PortalMain.scss'],
})
export class DeletedApplicationModalComponent {
  @Input() customerName!: string;
  @Input() applicationId!: string;

  constructor(
    public dialogRef: BsModalRef,
    private readonly userNotificationSvc: UserNotificationSvc,
    private readonly creditApplicationsRepo: CreditApplicationsRepo
  ) {}
  close() {
    this.dialogRef.hide();
  }
  delete() {
    this.creditApplicationsRepo.deleteApp(this.applicationId).subscribe({
      next: () => {
        this.userNotificationSvc.success(
          'The application has been successfully deleted.',
          'Success',
          10000,
          this.userNotificationSvc.topRight
        );
      },
      error: () => {
        this.userNotificationSvc.error(
          'An error has occurred while deleting the application.',
          'Error',
          10000,
          this.userNotificationSvc.topRight
        );
      },
      complete: () => {
        this.dialogRef.hide();
      },
    });
  }
}
