import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";

import { Observable } from "rxjs";

import { Constants } from "./constants";
import { environment } from "src/environments/environment";

@Injectable()
export class ManufacturersRepo {
    constructor(private http: HttpClient) {}

    getAllByDealer(dealerType: string): Observable<any[]> {
      const url = `${environment.ApiBaseUrl}/${Constants.apiUrls.manufacturersApiSegment}?dealerType=${dealerType}`;
      return this.http.get<any[]>(url);
  }
}
