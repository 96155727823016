import { Component, Input, EventEmitter, Output } from '@angular/core';
import { AbstractControl, FormGroup } from '@angular/forms';

@Component({
  selector: 'fin-credit-report-authorization',
  styleUrls: ['../../../PortalMain.scss'],
  templateUrl: './credit-report-authorization.component.html',
})
export class CreditReportAuthorizationComponent {
  @Input() form!: FormGroup;
  @Output() creditReportAuthorizedChange = new EventEmitter<boolean>();
  isCollapsed: boolean = true;

  get creditReportAuthorized(): AbstractControl {
    return this.form.get('creditReportAuthorized')?.value ?? false;
  }

  clickHappend(event: any) {

    this.creditReportAuthorizedChange.emit(event.target.checked);
  }
}
