import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder } from '@angular/forms';
import {
  EquipmentType,
  FormSubmitNotificationService,
  StepFormComponent,
} from 'dfs-credit-application-ngx';
import { Validators } from 'angular-reactive-validation';
import { StatesService } from 'src/app/services/states.service';
import { ICounty, IState } from 'src/app/interfaces/state';

@Component({
  selector: 'cfa-equipment',
  templateUrl: './equipment.component.html',
  styleUrls: [`../../../PortalMain.scss`],
})
export class EquipmentComponent
  extends StepFormComponent
  implements OnInit, OnDestroy
{
  @Input() equipmentType: EquipmentType | undefined;

  statesList!: IState[];
  countyList!: ICounty[];
  previouslySelectedState!: string;

  get equipments(): any {
    const equipments = this.form.get('equipments') as any;
    return equipments.controls.filter(
      (e: any) => !e.value.tradeInFlag && !e.value.isAncillary
    );
  }
  get ancillaryItems(): any {
    const equipments = this.form.get('equipments') as any;
    return equipments.controls.filter(
      (e: any) => !e.value.tradeInFlag && e.value.isAncillary
    );
  }
  get tradeIn(): any {
    const equipments = this.form.get('equipments') as any;
    return equipments.controls.filter(
      (e: any) => e.value.tradeInFlag === true
    )[0];
  }

  get customFarmer(): any {
    return this.form?.get('customFarmer');
  }

  get damageInsurance(): any {
    return this.form.get('damageInsurance');
  }

  equipmentTypes = EquipmentType;

  constructor(
    submitNotificationService: FormSubmitNotificationService,
    private readonly stateService: StatesService
  ) {
    super(submitNotificationService);
    stateService.getStatesList().subscribe((stateList) => {
      this.statesList = stateList.sort((a, b) => {
        const nameA = a.name.toUpperCase();
        const nameB = b.name.toUpperCase();
        return nameA < nameB ? -1 : nameA > nameB ? 1 : 0;
      });
      if (this.form.get('equipmentState')?.value !== null) {
        this.previouslySelectedState = this.form.get('equipmentState')?.value;
        this.setCountiesForState(
          this.statesList.find((s) => s.code === this.previouslySelectedState)
        );
      }
    });
  }

  filterStateList(term: string, item: IState) {
    term = term.toUpperCase();
    return item.name.toUpperCase().startsWith(term);
  }

  setCountiesForState(stateCode: any) {
    if (
      this.previouslySelectedState &&
      this.previouslySelectedState !== stateCode.code
    ) {
      this.form.get('equipmentCounty')?.setValue(null);
    }
    const stateId = this.statesList.find(
      (s) => s.code === stateCode.code
    )!.stateId;
    this.stateService.getCountyListByState(stateId).subscribe((countyList) => {
      this.countyList = countyList;
    });
    this.previouslySelectedState = stateCode.code;
  }
  override ngOnInit(): void {
    super.ngOnInit();

    this.addFormValidation();
  }

  private addFormValidation() {
    this.form
      ?.get('equipmentCounty')
      ?.setValidators([
        Validators.required('Equipment county required'),
        Validators.maxLength(
          30,
          (maxLength) => `Maximum length is ${maxLength} characters`
        ),
      ]);

    this.form
      ?.get('equipmentState')
      ?.setValidators([
        Validators.required('Equipment state required'),
        Validators.maxLength(
          2,
          (maxLength) => `Maximum length is ${maxLength} characters`
        ),
      ]);
    this.form
      ?.get('damageInsurance')
      ?.setValidators(
        Validators.required('Damage insurance question required')
      );

    if (
      this.equipmentType === EquipmentType.farm ||
      this.equipmentType === EquipmentType.commercial
    ) {
      this.form
        ?.get('customFarmer')
        ?.setValidators(
          Validators.required('Custom harvesting question is required')
        );
    }
    this.form.updateValueAndValidity();
  }

  override ngOnDestroy(): void {
    super.ngOnDestroy();
  }
}
