import {
  FormGroup,
  FormBuilder,
  Validators,
  AbstractControl,
  FormControlName,
} from '@angular/forms';
import { Component, OnInit } from '@angular/core';
import { PaymentCalculatorService } from '../services/payment-calculator.service';
import {
  AmortizationLineItem,
  AmortizationSchedule,
} from '../interfaces/amortization-schedule';
import { CurrencyPipe, PercentPipe } from '@angular/common';

@Component({
  selector: 'fin-payment-calculator',
  templateUrl: './loan-payment-calculator.component.html',
  styleUrls: ['./marketing-styles.css'],
})
export class LoanPaymentCalculatorComponent implements OnInit {
  paymentCalculatorForm!: FormGroup;
  amortizationSchedule!: AmortizationSchedule;
  outputHtml: string = '';
  submitted: boolean = false;
  isCalculating: boolean = false;

  paymentFrequencyList = [
    { key: 1, value: 'Annual' },
    { key: 2, value: 'Semi-Annual' },
    { key: 3, value: 'Quarterly' },
    { key: 4, value: 'Monthly' },
  ];
  loanTermList = [
    { key: 3, value: '3 years' },
    { key: 4, value: '4 years' },
    { key: 5, value: '5 years' },
    { key: 6, value: '6 years' },
    { key: 7, value: '7 years' },
  ];

  get amountFinancedDisplay() {
    return this.paymentCalculatorForm.get('amountFinanced')?.value;
  }
  get amortizationDetail(): AmortizationLineItem[] {
    return this.amortizationSchedule.amortizationLineItems.filter((element) => {
      return element.type === 'Payment';
    });
  }
  get amortizationTerms(): AmortizationLineItem[] {
    return this.amortizationSchedule.amortizationLineItems?.filter(
      (element) => {
        return element.type === 'Loan';
      }
    );
  }
  get amortizationSummary(): AmortizationLineItem[] {
    return this.amortizationSchedule.amortizationLineItems.filter((element) => {
      return element.type === 'GrandTotal';
    });
  }
  get paymentCount() {
    return this.amortizationSchedule.amortizationLineItems.filter(
      (x) => x.type === 'Payment'
    ).length;
  }
  get showAmortizationSchedule() {
    return this.paymentCalculatorForm.get('showAmortization')?.value;
  }
  constructor(
    private readonly fb: FormBuilder,
    private readonly paymentCalculatorSvc: PaymentCalculatorService,
    private currencyPipe: CurrencyPipe,
    private pctPipe: PercentPipe
  ) {}
  ngOnInit(): void {
    this.addFormControls();
  }
  addFormControls() {
    this.paymentCalculatorForm = this.fb.group({
      salesPrice: this.fb.control(null, Validators.required),
      downPayment: this.fb.control(null),
      salesTax: this.fb.control(null),
      loanAmount: this.fb.control(null),
      rate: [null, Validators.required],
      term: [null, Validators.required],
      paymentFrequency: [null, Validators.required],
      showAmortization: null,
    });
  }
  isControlValid(controlName: string): boolean {
    const ctrl = this.paymentCalculatorForm.controls[controlName];
    const ret = (this.submitted || ctrl.touched || ctrl.dirty) && !ctrl.valid;
    return ret;
  }
  calculateLoanAmount() {
    const salesPriceControl = this.paymentCalculatorForm.get('salesPrice');
    const downPaymentControl = this.paymentCalculatorForm.get('downPayment');
    const salesTaxControl = this.paymentCalculatorForm.get('salesTax');

    const salesPrice = salesPriceControl?.value;
    const downPayment = downPaymentControl?.value;
    const salesTax = salesTaxControl?.value;

    const loanAmount =
      Number(salesPrice) + Number(salesTax) - Number(downPayment);

    const loanAmountControl = this.paymentCalculatorForm.get('loanAmount');
    loanAmountControl?.setValue(loanAmount > 0 ? loanAmount : null, {
      emitEvent: false,
    });
  }
  formatControlValue(ctrl: AbstractControl | null) {
    const amt2Format = ctrl?.value;
    ctrl?.setValue(
      this.currencyPipe.transform(amt2Format, 'USD', 'symbol', '1.2-2'),
      { emitEvent: false }
    );
  }
  formatInterestRate() {
    const interestRateControl = this.paymentCalculatorForm.get('rate');
    const amt = interestRateControl?.value.replace(/[a-zA-Z%,]/g, '');
    const amt2Format = Number(amt) / 100;
    interestRateControl?.setValue(this.pctPipe.transform(amt2Format, '1.3-3'), {
      emitEvent: false,
    });
  }
  computePayments() {
    if (this.paymentCalculatorForm.invalid) {
      this.paymentCalculatorForm.updateValueAndValidity();
      return;
    }
    this.submitted = true;
    this.isCalculating = true;
    const amtFinanced = this.paymentCalculatorForm.get('loanAmount')?.value;
    const rate = this.paymentCalculatorForm.get('rate')?.value;
    const pmtFreq = this.paymentCalculatorForm.get('paymentFrequency')?.value;
    const frequencyLabel = this.paymentFrequencyList[pmtFreq - 1].value.replace(
      '-',
      ''
    );
    const termYears = this.paymentCalculatorForm.get('term')?.value;
    const termMonths = termYears * 12;
    this.paymentCalculatorSvc
      .computePayments(amtFinanced, rate, frequencyLabel, termYears, termMonths)
      .subscribe((data) => {
        this.amortizationSchedule = data;
        this.isCalculating = false;
      });
  }
  fieldHasError(controlName: string, errorName: string): boolean {
    const ctrl = this.paymentCalculatorForm.controls[controlName];
    const ret =
      (this.submitted || ctrl.touched || ctrl.dirty) &&
      ctrl.errors &&
      ctrl.errors[errorName];
    return ret;
  }
  getTermMonths(pmtFrequency: number, termYears: number): number {
    return pmtFrequency * termYears;
  }
}
