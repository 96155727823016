import { Component, Input } from '@angular/core';
import { SellerType } from 'dfs-credit-application-ngx';

@Component({
  selector: 'fin-submit-success-modal',
  templateUrl: './submit-success-modal.component.html',
})
export class SubmitSuccessModalComponent {
  @Input() sellerType!: SellerType;
  sellerTypes = SellerType;
  rapportAppNumber!: number;

  getAppNumberForPopup() {
    return this.sellerType === this.sellerTypes.customerFinish
      ? `(App # ${this.rapportAppNumber})`
      : '';
  }
  homeRedirect() {
    window.location.href = window.location.origin;
  }
  cancel() {
    window.close();
  }
}
