import { Component } from '@angular/core';


@Component({
  selector: 'fin-customer-application',
  templateUrl: './customer-application.component.html'
})
export class CustomerApplicationComponent {

}
