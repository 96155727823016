import { Injectable } from '@angular/core';
import { SellerType } from 'dfs-credit-application-ngx';
import { SubmitSuccessModalComponent } from './submit-success-modal.component';
import { BsModalService } from 'ngx-bootstrap/modal';

@Injectable({
  providedIn: 'root',
})
export class SubmitSuccessModalService {
  constructor(private readonly bsModalService: BsModalService) {}

  show(sellerType?: SellerType, rapportAppNumber?: number) {
    this.bsModalService.show(SubmitSuccessModalComponent, {
      initialState: {
        sellerType: sellerType,
        rapportAppNumber: rapportAppNumber,
      },
      ignoreBackdropClick: true,
    });
  }
}
