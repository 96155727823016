<label class="sidebar-header" for="sideList">{{ sideNavData?.header }}</label>
<ul *ngIf="!loading" class="sidebar-nav" id="sideList">
  <li *ngFor="let item of sideNavData?.itemList; let i = index">
    <a
      *ngIf="item.routeType === 1"
      [ngClass]="{
        'sidebar-item': !isSelected(item),
        'sidebar-item-selected': isSelected(item)
      }"
      [routerLink]="item.routerLink"
      [queryParams]="buildqueryParams(item.queryParams)"
    >
      <span class="menu-icon">
        <i class="ri-vip-diamond-fill"></i>
      </span>
      <span class="menu-title">{{ item.label }}</span>
      <span *ngIf="item.markNew" class="menu-suffix">
        <span class="badge primary">New</span>
      </span>
    </a>
    <a
      *ngIf="item.routeType === 2"
      [ngClass]="{
        'sidebar-item': !isSelected(item),
        'sidebar-item-selected': isSelected(item)
      }"
      [href]="item.routerLink"
    >
      <span class="menu-icon">
        <i class="ri-vip-diamond-fill"></i>
      </span>
      <span class="menu-title">{{ item.label }}</span>
      <span *ngIf="item.markNew" class="menu-suffix">
        <span class="badge primary">New</span>
      </span>
    </a>
    <a
      *ngIf="item.routeType === 3"
      [class]="item.routerLink"
      class="sidebar-item"
      style="cursor: pointer"
      (click)="clickEvent(item.routerLink)"
    >
      <span class="menu-icon">
        <i class="ri-vip-diamond-fill"></i>
      </span>
      <span class="menu-title">{{ item.label }}</span>
      <span *ngIf="item.markNew" class="menu-suffix">
        <span class="badge primary">New</span>
      </span>
    </a>
  </li>
</ul>
