export class ApplicationItem {
  applicationId: string;
  applicationDate: Date;
  salespersonName: string;
  primaryCustomerName: string;
  dealerLocation: string;
  primaryCustomerCity: string;
  primaryCustomerState: string;
  sentToCustomerDate: Date;
  salespersonId: number;
  dealerCodeId: number;

  private constructor(
      applicationId: string,
      applicationDate: Date,
      salespersonName: string,
      primaryCustomerName: string,
      dealerLocation: string,
      primaryCustomerCity: string,
      primaryCustomerState: string,
      sentToCustomerDate: Date,
      salespersonId: number,
      dealerCodeId: number
    ) {
      this.applicationId = applicationId;
      this.applicationDate = applicationDate;
      this.salespersonName = salespersonName;
      this.primaryCustomerName = primaryCustomerName;
      this.dealerLocation = dealerLocation;
      this.primaryCustomerCity = primaryCustomerCity;
      this.primaryCustomerState = primaryCustomerState;
      this.sentToCustomerDate = sentToCustomerDate;
      this.salespersonId = salespersonId;
      this.dealerCodeId = dealerCodeId;
    }

    static create(data: { applicationId: string; applicationDate: Date; salespersonName: string; primaryCustomerName: string; dealerLocation: string; primaryCustomerCity: string; primaryCustomerState: string; sentToCustomerDate: Date; salespersonId: number; dealerCodeId: number; }) {
      return new ApplicationItem(
        data.applicationId,
        data.applicationDate,
        data.salespersonName,
        data.primaryCustomerName,
        data.dealerLocation,
        data.primaryCustomerCity,
        data.primaryCustomerState,
        data.sentToCustomerDate,
        data.salespersonId,
        data.dealerCodeId
      );
    }

    static createList(data: { applicationId: string; applicationDate: Date; salespersonName: string; primaryCustomerName: string; dealerLocation: string; primaryCustomerCity: string; primaryCustomerState: string; sentToCustomerDate: Date; salespersonId: number; dealerCodeId: number; }[]) {
      const list: ApplicationItem[] = [];

      data.forEach(item => {
        list.push(ApplicationItem.create(item));
      });

      return list;
    }
}
