import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';

import { Constants } from './constants';
import { environment } from 'src/environments/environment';

@Injectable()
export class AssetCodesRepo {
  constructor(private http: HttpClient) {}

  getByManufacturer(
    manufacturerCode: string,
    dealerType: string
  ): Observable<any> {
    const assetsUrlSegment = '/assets';
    const dealerTypesSegment = '/dealer-types/';
    const url = `${environment.ApiBaseUrl}/${Constants.apiUrls.manufacturersApiSegment}${manufacturerCode}${dealerTypesSegment}${dealerType}${assetsUrlSegment}`;
    return this.http.get(url);
  }
  getAncillaryCodes(): Observable<any> {
    const ancillarySegment = 'ancillary-codes';
    const url = `${environment.ApiBaseUrl}/${ancillarySegment}/types`;
    return this.http.get(url);
  }
}
