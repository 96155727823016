export interface Config {
  production: boolean;
  ApiBaseUrl: string;
  QuotingApiBaseUrl: string;
  ContractTermsApiBaseUrl: string;
  DfsCryptKey: string;
  DfsCryptIv: string;
  CaptchaKey: string;
  leaseMatrixFileName: string;
  filingFee: number;
  sixYearFinanceThreshold: number;
  sevenYearFinanceThreshold: number;
}

export const environmentBase = {
  ApiBaseUrl: '/api-dev',
  production: false,
  QuotingApiBaseUrl: '/api-quotingdev',
  ContractTermsApiBaseUrl: '/api-contracttermsdev',
  DfsCryptKey: '2023!DFS_KeyTest', //needs to be 16 characters
  DfsCryptIv: '2023!DFS_IV_Test', //needs to be 16 characters
  CaptchaKey: '6LdVqG4gAAAAABPTSIocqqnK14DyV72U8ieKjRpN',
  leaseMatrixFileName: 'LEASE MATRIX.pdf',
  defaultFilingFee: 400,
  sixYearFinanceThreshold: 100000,
  sevenYearFinanceThreshold: 150000,
};
