import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { AbstractControl, FormControl } from '@angular/forms';
import { SellerType } from './seller-type.model';
import { Validators } from 'angular-reactive-validation';
import {
  FormSubmitNotificationService,
  EquipmentType,
  StepFormComponent,
} from 'dfs-credit-application-ngx';
import { SalesPerson } from './sales-person.model';
import { SalesPersonsRepositoryService } from './dealer-seller/sales-persons-repository.service';

import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'ca-basic-information',
  templateUrl: 'basic-information.component.html',
  styleUrls: [`../../../PortalMain.scss`],
})
export class BasicInformationComponent
  extends StepFormComponent
  implements OnInit, OnDestroy
{
  _salesPersonsData!: SalesPerson[];
  @Input()
  get salesPersonsData() {
    return this._salesPersonsData;
  }
  set salesPersonsData(salesPersonsData: SalesPerson[]) {
    this.salesPersonsLoading = true;
    this._salesPersonsData = salesPersonsData;
    this.filterSalespersons(this.equipmentType);
  }

  @Input() salesPersonsLoading: boolean = false;
  @Output() setRates = new EventEmitter();
  applicationTypeSelected = false;
  equipmentType!: string;

  salesPersonsDisplay!: SalesPerson[];

  get salesPerson(): AbstractControl {
    return this.form.get('salesPerson') as AbstractControl;
  }

  get sellerType(): string {
    return this.form.get('sellerType')!.value;
  }
  get equipmentTypeControl(): AbstractControl {
    return this.form.get('equipmentType') as AbstractControl;
  }
  get sellerTypeControl(): AbstractControl {
    return this.form.get('sellerType') as AbstractControl;
  }
  sellerTypes = SellerType;
  equipmentTypes = EquipmentType;

  constructor(
    submitNotificationService: FormSubmitNotificationService,
    private readonly toastr: ToastrService,
    private readonly salesPersonsRepo: SalesPersonsRepositoryService
  ) {
    super(submitNotificationService);
  }

  override ngOnInit() {
    super.ngOnInit();
    this.addFormControls();

    this.form.get('sellerType')!.valueChanges.subscribe((val) => {
      this.resetSellerTypeForm();
    });
    this.filterSalespersons(this.equipmentType);
  }
  filterSalespersons(productLine: string) {
    const isProductLineChanging = this.equipmentType !== productLine;
    this.equipmentType = productLine;
    if (!this.salesPersonsData) return;

    this.salesPersonsDisplay = this.salesPersonsData.filter(
      (item) =>
        productLine == this.equipmentTypes.irrigation
          ? item.productLine === this.equipmentTypes.irrigation
          : item.productLine !== this.equipmentTypes.irrigation // currently irrigation or everything else for Farm or Commercial/Construction
    );
    if (
      isProductLineChanging &&
      !this.salesPersonsDisplay?.find(
        (item) => item.userId == this.salesPerson.value?.userId
      )
    ) {
      this.salesPerson?.setValue(null);
    }
    this.setRates.emit();
  }
  private resetSellerTypeForm() {
    const formControls: string[] = [
      'salesPerson',
      'sellerName',
      'sellerAddress',
      'sellerCity',
      'sellerState',
      'salespersonName',
      'dealer',
      'dealerCity',
      'dealershipName',
    ];

    formControls.forEach((fc) => {
      this.form.get(fc)!.setValue(null);
    });
    formControls.forEach((fc) => {
      this.form.get(fc)!.clearValidators();
    });
    formControls.forEach((fc) => {
      this.form.get(fc)!.updateValueAndValidity();
    });
  }

  private addFormControls() {
    this.form.addControl(
      'equipmentType',
      new FormControl(null, Validators.required('Equipment Type is required'))
    );
    this.form.addControl(
      'sellerType',
      new FormControl(null, Validators.required('Seller Type is required'))
    );
    this.form.addControl(
      'sellerName',
      new FormControl(
        null,
        Validators.maxLength(
          75,
          (maxLength) => `Maximum length is ${maxLength} characters`
        )
      )
    );
    this.form.addControl(
      'sellerAddress',
      new FormControl(
        null,
        Validators.maxLength(
          40,
          (maxLength) => `Maximum length is ${maxLength} characters`
        )
      )
    );
    this.form.addControl(
      'sellerCity',
      new FormControl(
        null,
        Validators.maxLength(
          25,
          (maxLength) => `Maximum length is ${maxLength} characters`
        )
      )
    );
    this.form.addControl(
      'sellerState',
      new FormControl(
        null,
        Validators.maxLength(
          2,
          (maxLength) => `Maximum length is ${maxLength} characters`
        )
      )
    );
    this.form.addControl('salesPerson', new FormControl(null));
    this.form.addControl(
      'salespersonName',
      new FormControl(
        null,
        Validators.maxLength(
          75,
          (maxLength) => `Maximum length is ${maxLength} characters`
        )
      )
    );
    this.form.addControl('dealer', new FormControl(null));
    this.form.addControl('dealerCity', new FormControl(null));
    this.form.addControl('dealershipName', new FormControl(null));
    this.form.addControl('salespersonManuallyEntered', new FormControl(false));
    this.form.addControl('dealerManuallyEntered', new FormControl(false));
  }

  override ngOnDestroy(): void {
    super.ngOnDestroy();
  }
}
