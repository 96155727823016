import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable()
export class CreditApplicationsRepository {
  constructor(private httpClient: HttpClient) {}
  updateDealer(appId: number, dealerId: number): Observable<any> {
    let url = `${environment.ApiBaseUrl}/users/credit-applications/${appId}/${dealerId}`;
    return this.httpClient.put<any>(url, null);
  }
}
