<fin-header-nav></fin-header-nav>

<nav id="sidebar" [class.active]="!isActiveSideBar" *ngIf="isLoggedIn">
  <div id="navwrapper" class="card" style="min-height: 300px">
    <fin-left-side-nav></fin-left-side-nav>
  </div>
</nav>
<div class="wrapper">
  <ng-container *ngIf="isLoggedIn">
    <nav id="sidebar" [class.active]="!isActiveSideBar"></nav>
    <button
      id="float-btn"
      [class.active]="!isActiveSideBar"
      (click)="toggleSidebar()"
      type="button"
      class="fixed-top"
      [ngClass]="sideBarIcon"
      style="vertical-align: middle"
      *ngIf="isLoggedIn"
      ngbTooltip="{{ tooltip }}"
    ></button>
  </ng-container>
  <div id="content">
    <div>
      <router-outlet> <!-- Main body area --> </router-outlet>
    </div>

    <fin-footer></fin-footer>
  </div>
</div>
