import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { DealerRegistration } from '../interfaces/dealer-registration';
import { CustomerRegistration } from '../interfaces/customer-registration';


@Injectable({
  providedIn: 'root',
})
export class UserRegistrationRepoService {
  private userRegistrationUrl = `${environment.ApiBaseUrl}/users/registration`;
  private headers = new HttpHeaders({
      'Content-Type': 'application/json; charset=utf-8',
      'Access-Control-Allow-Methods': 'OPTIONS, GET, POST',
    });

  constructor(private httpClient: HttpClient) {}

  registerDealer(dealerRegistration: DealerRegistration): Observable<string> {
    return this.httpClient.post<string>(
      this.userRegistrationUrl + '/dealer',
      dealerRegistration,
      {headers: this.headers}
    );
  }

  registerCustomer(customerRegistration: CustomerRegistration): Observable<string> {
    return this.httpClient.post<string>(
      this.userRegistrationUrl + '/customer',
      customerRegistration,
      {headers: this.headers}
    );
  }

}
