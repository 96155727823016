import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";

import { environment } from "src/environments/environment";

@Injectable()
export class CustomersRepo {
    constructor(private http: HttpClient) {}
        private readonly customersUrlSegment = 'business-entities';

        getBySsn(ssn: string) {
            const url = this.buildCustomerUrl(ssn);

            return this.http.get(url);
        }

        update(customer: { ssn: string; }) {
            const url = this.buildCustomerUrl(customer.ssn);

            return this.http.put(url, customer);
        }

        private buildCustomerUrl(ssn: string) {
            return environment.ApiBaseUrl + '/' + this.customersUrlSegment + '/' + ssn;
        }
}
