import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { EquipmentTypes } from './equipment-types.enum';
import { SalesPointService } from '../services/sale-points.service';
import { ISalesPoint } from '../interfaces/sales-point';
import { CurrentUserService, User } from 'dfs-common-ngx';
import { UserRole } from '../enums/user-role';

@Component({
  selector: 'fin-financing',
  templateUrl: './financing-info.component.html',
  styleUrls: ['./marketing-styles.css'],
})
export class FinancingComponent implements OnInit {
  @Input() equipmentType: string = EquipmentTypes.farm;
  public equipmentTypes = EquipmentTypes;
  navigationSubscription: Subscription;
  bulletSubscription!: Subscription;
  public salesBulletPoints: ISalesPoint[] = [];
  loggedIn = false;
  isDealer: boolean = false;
  isCustomer: boolean = false;

  ngOnInit(): void {
    this.currentUserService.profileO.subscribe((user) => {
      this.loggedIn = !!user;
    });
    var profile = this.currentUserService.profile as User;
    if (profile) {
      this.isCustomer = profile.role.includes(UserRole.Customer);
      this.isDealer = profile.role.includes(UserRole.Dealer);
    }
  }

  get productOfferingApplicationLink(): string {
    switch (this.equipmentType) {
      case EquipmentTypes.farm:
        return '/assets/forms/FarmEquipmentCreditApplication.pdf';
      case EquipmentTypes.irrigation:
        return '/assets/forms/IrrigationCreditApplication.pdf';
      default:
        return '/assets/forms/FarmEquipmentCreditApplication.pdf';
    }
  }
  get productOfferingPageImage(): string {
    switch (this.equipmentType) {
      case EquipmentTypes.farm:
        return 'assets/images/farm-equipment-hero.jpg';
      case EquipmentTypes.irrigation:
        return 'assets/images/irrigation-equipment-hero.jpg';
      default:
        return 'assets/images/farm-equipment-hero.jpg';
    }
  }
  get productOfferingPageHeader(): string {
    switch (this.equipmentType) {
      case EquipmentTypes.farm:
        return 'Equipment Financing';
      case EquipmentTypes.irrigation:
        return 'Irrigation Financing';
      default:
        return 'Equipment Financing';
    }
  }
  get productOfferingSalesHeader(): string {
    switch (this.equipmentType) {
      case EquipmentTypes.farm:
        return 'Making every effort to give you every advantage.';
      case EquipmentTypes.irrigation:
        return 'We can customize solutions to fit your cash flow.';
      default:
        return 'Making every effort to give you every advantage.';
    }
  }
  get productOfferingSalesImage(): string {
    switch (this.equipmentType) {
      case EquipmentTypes.farm:
        return 'assets/images/farm-benefits.jpg';
      case EquipmentTypes.irrigation:
        return 'assets/images/irrigation-benefits.jpg';
      default:
        return 'assets/images/farm-benefits.jpg';
    }
  }
  get productOfferingSalesDescription(): string {
    switch (this.equipmentType) {
      case EquipmentTypes.farm:
        return 'DFS Finance has been providing ag producers custom, competitive farm equipment financing for over 50 years. Working with an extensive network of equipment dealers and manufacturers, we offer financing on everything from equipment to grain bins.';
      case EquipmentTypes.irrigation:
        return 'DFS Finance has been committed to irrigation equipment financing for over 50 years. We work fast to provide dealers and producers with the flexible and competitive financing their operation needs.';
      default:
        return 'DFS Finance has been providing ag producers custom, competitive farm equipment financing for over 50 years. Working with an extensive network of equipment dealers and manufacturers, we offer financing on everything from equipment to grain bins.';
    }
  }

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private salesPointService: SalesPointService,
    private currentUserService: CurrentUserService
  ) {
    this.navigationSubscription = router.events.subscribe((e) => {
      if (e instanceof NavigationEnd) {
        this.getEquipmentTypeFromRoute();
        this.loadBulletPoints();
      }
    });
  }
  getEquipmentTypeFromRoute() {
    const params = this.route.snapshot.paramMap;
    this.equipmentType = params.get('equipmentType')!.toString();
  }

  loadBulletPoints() {
    this.bulletSubscription = this.salesPointService
      .getBulletPointsForEquipmentType()
      .subscribe({
        next: (bulletPoints) => {
          this.salesBulletPoints = bulletPoints
            .filter((p) => p.equipmentType === this.equipmentType)
            .sort((a, b) => (a.order > b.order ? 1 : -1));
        },
      });
  }

  ngOnDestroy() {
    if (this.navigationSubscription) this.navigationSubscription.unsubscribe();
    if (this.bulletSubscription) this.bulletSubscription.unsubscribe();
  }

  customerRoute() {
    this.router.navigate(['/application']);
  }

  dealerRoute() {
    if (this.loggedIn && this.isDealer) {
      this.router.navigate(['/creditApplication']);
    } else {
      this.router.navigate(['/login']);
    }
  }
}
