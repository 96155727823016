import { Component, OnDestroy, OnInit } from '@angular/core';
import {
  AddressDetails,
  CustomerAccountItem,
} from './models/customer-account-item';
import { CustomerAccountsService } from './services/customer-accounts.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'fin-invoice-delivery',
  templateUrl: './invoice-delivery.component.html',
  styleUrls: ['../PortalMain.scss'],
})
export class InvoiceDeliveryComponent implements OnInit {
  contractNumbers: string[] = [];
  customerAccounts: CustomerAccountItem[] = [];
  pageHeader: string = 'Invoice Delivery';
  redirectUrl: string = '/invoiceDelivery';
  addressDetails!: AddressDetails;

  invoiceDeliveryOptions = [
    { id: 1, description: 'US Mail' },
    { id: 2, description: 'Email' },
    { id: 3, description: 'ACH Payment' },
  ];

  public selectedContract: string = '';
  public redirecting: boolean = false;
  public loadingData: boolean = true;
  constructor(
    private readonly customerAccountSvc: CustomerAccountsService,
    private readonly toastr: ToastrService
  ) {}

  ngOnInit(): void {
    this.customerAccountSvc
      .getAccountsIncludeInvoiceDelivery()
      .subscribe((data) => {
        this.customerAccounts = data.filter(
          (item) => item.accountClosed === false
        );
        this.contractNumbers = this.customerAccounts.map(
          (a) => a.contractNumber
        );
        this.loadingData = false;
      });
  }

  loadInvoiceOption(InvoiceCode: string): any[] {
    if (InvoiceCode == 'S') {
      return this.invoiceDeliveryOptions.filter(
        (item) => item.description === 'ACH Payment'
      );
    }

    return this.invoiceDeliveryOptions.filter(
      (item) => item.description !== 'ACH Payment'
    );
  }
  updateInvoiceDelivery(
    invoiceDeliveryMethod: number,
    contractNumber: string,
    addressDetails: AddressDetails
  ) {
    this.customerAccountSvc
      .updateInvoiceDeliveryMethod(
        invoiceDeliveryMethod.toString(),
        contractNumber,
        addressDetails
      )
      .subscribe({
        next: () => {
          this.toastr.success(
            'Your invoice preferences have been updated successfully'
          );
        },
        error: (err) => {
          this.toastr.error(
            `There was an error updating your invoice preferences. Please contact
          <a href="mailto:OnlineSupport@dfsfin.com">
            Online Support
          </a> if the issue persists.`,
            'Error',
            { enableHtml: true }
          );
        },
      });
  }
  shouldDisplaySpinner(contractNumber: string): boolean {
    return this.redirecting && this.selectedContract === contractNumber;
  }

  showSpinner(contractNumber: string) {
    this.selectedContract = contractNumber;
    this.redirecting = true;
  }
  hasSelectedACHDelivery(): boolean {
    const result = this.customerAccounts.filter(
      (acct) => acct.contractDetails.invoiceDeliveryMethod === 3
    );
    return result.length > 0;
  }
}
