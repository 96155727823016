import { Injectable } from '@angular/core';
import { BsModalService } from 'ngx-bootstrap/modal';
import { ViewReassignAppModalComponent } from './view-reassign-app-modal.component';

@Injectable({ providedIn: 'root' })
export class ViewReassignAppModalService {
  constructor(private readonly bsModalService: BsModalService) {}

  show(application: any) {
    const initialState = { application };
    this.bsModalService.show(ViewReassignAppModalComponent, {
      initialState,
      ignoreBackdropClick: true,
      class: 'modal-lg',
    });
  }
}
