<main>
  <section
    style="
      background-image: url('assets/images/customer-registration-hero.jpg');
      background-size: cover;
      background-position: center;
    "
  >
    <div class="container px-4 py-5">
      <div class="row align-items-start g-lg-5 py-5">
        <div class="col-lg-10 text-lg-start text-light">
          <h1 class="display-4 lh-1 mb-3 py-4">Customer Registration</h1>
        </div>
      </div>
    </div>
  </section>

  <div class="container">
    <div class="row px-4 py-4 mt-5 text-center">
      <p class="display-5 mb-4">Register your new or existing account</p>
      <div class="col-lg-10 mx-auto">
        <p class="fs-5 mb-4">
          Make secure online payments, update your insurance, and access account
          balances, pay-off balances, payment history, and start/maturity dates.
        </p>
      </div>
    </div>
  </div>

  <div class="container px-4 mb-5">
    <div class="row g-5">
      <div class="col-12">
        <form class="card p-4 fs-6" [formGroup]="customerRegForm">
          <div class="row gx-5 gy-4">
            <div class="col-12">
              <label for="userName" class="form-label"
                >Choose a Login Username<span class="text-danger"
                  >*</span
                ></label
              >
              <input
                type="text"
                class="form-control fs-6"
                id="userName"
                formControlName="userName"
                [ngClass]="{
                  'is-invalid':
                    (submitted ||
                      customerRegForm.controls['userName'].touched) &&
                    customerRegForm.controls['userName'].errors
                }"
              />
              <div
                *ngIf="
                  (submitted || customerRegForm.controls['userName'].touched) &&
                  customerRegForm.controls['userName'].errors
                "
                class="invalid-feedback"
              >
                <div
                  *ngIf="
                    customerRegForm.controls['userName'].errors['required']
                  "
                >
                  UserName is required
                </div>
              </div>
            </div>
            <div class="col-md-6">
              <label for="password" class="form-label w-100"
                >Password<span class="text-danger">*</span></label
              >
              <p-password
                id="password"
                [toggleMask]="true"
                required="true"
                formControlName="password"
                class="w-100"
                [ngClass]="{
                  'is-invalid':
                    (submitted ||
                      customerRegForm.controls['password'].touched) &&
                    customerRegForm.controls['password'].errors
                }"
              >
                <ng-template pTemplate="header">
                  <h6>Pick a password</h6>
                </ng-template>
                <ng-template pTemplate="footer">
                  <p-divider></p-divider>
                  <p class="mt-2">Password Requirements:</p>
                  <ul class="pl-2 ml-2 mt-0" style="line-height: 1.5">
                    <li>At least one lowercase</li>
                    <li>At least one uppercase</li>
                    <li>At least one numeric or special character</li>
                    <li>Must contain between 8 and 20 characters</li>
                  </ul>
                </ng-template>
              </p-password>
              <div
                *ngIf="
                  (submitted || customerRegForm.controls['password'].touched) &&
                  customerRegForm.controls['password'].errors
                "
                class="invalid-feedback"
              >
                <div
                  *ngIf="
                    customerRegForm.controls['password'].errors['required']
                  "
                >
                  Password is required
                </div>
                <div
                  *ngIf="customerRegForm.controls['password'].errors['pattern']"
                >
                  Passwords must contain between 8 and 20 characters, both lower
                  and uppercase letters, and at least one number or special
                  character
                </div>
              </div>
            </div>
            <div class="col-md-6">
              <label for="confirmPassword" class="form-label w-100"
                >Confirm Password<span class="text-danger">*</span></label
              >
              <p-password
                id="confirmPassword"
                [toggleMask]="true"
                required="true"
                formControlName="confirmPassword"
                [feedback]="false"
                class="w-100"
                [ngClass]="{
                  'is-invalid':
                    (submitted ||
                      customerRegForm.controls['confirmPassword'].touched) &&
                    customerRegForm.controls['confirmPassword'].errors
                }"
              >
              </p-password>
              <div
                *ngIf="
                  (submitted ||
                    customerRegForm.controls['confirmPassword'].touched) &&
                  customerRegForm.controls['confirmPassword'].errors
                "
                class="invalid-feedback"
              >
                <div
                  *ngIf="
                    customerRegForm.controls['confirmPassword'].errors[
                      'required'
                    ]
                  "
                >
                  Confirm Password is required
                </div>
                <div
                  *ngIf="
                    customerRegForm.controls['confirmPassword'].errors[
                      'matching'
                    ]
                  "
                >
                  Confirm Password does not match
                </div>
              </div>
            </div>
            <div class="col-md-12">
              <label for="email" class="form-label required"
                >Email address<span class="text-danger">*</span></label
              >
              <input
                type="email"
                class="form-control fs-6"
                id="email"
                formControlName="email"
                [ngClass]="{
                  'is-invalid':
                    (submitted || customerRegForm.controls['email'].touched) &&
                    customerRegForm.controls['email'].errors
                }"
                required
              />
              <div
                *ngIf="
                  (submitted || customerRegForm.controls['email'].touched) &&
                  customerRegForm.controls['email'].errors
                "
                class="invalid-feedback"
              >
                <div
                  *ngIf="customerRegForm.controls['email'].errors['required']"
                >
                  Email is required
                </div>
                <div *ngIf="customerRegForm.controls['email'].errors['email']">
                  Enter a valid email
                </div>
              </div>
            </div>
            <div class="col-md-6">
              <label for="shortSsn" class="form-label"
                >Enter the last 4-digits of SSN or Tax ID<span
                  class="text-danger"
                  >*</span
                ></label
              >
              <input
                type="text"
                class="form-control fs-6"
                id="shortSsn"
                formControlName="shortSsn"
                [ngClass]="{
                  'is-invalid':
                    (submitted ||
                      customerRegForm.controls['shortSsn'].touched) &&
                    customerRegForm.controls['shortSsn'].errors
                }"
                required
              />
              <div
                *ngIf="
                  (submitted || customerRegForm.controls['shortSsn'].touched) &&
                  customerRegForm.controls['shortSsn'].errors
                "
                class="invalid-feedback"
              >
                <div
                  *ngIf="
                    customerRegForm.controls['shortSsn'].errors['required']
                  "
                >
                  SSN or TaxId is required
                </div>
                <div
                  *ngIf="
                    customerRegForm.controls['shortSsn'].errors['maxlength'] ||
                    customerRegForm.controls['shortSsn'].errors['minlength']
                  "
                >
                  Please enter exactly 4 digits
                </div>
                <div
                  *ngIf="customerRegForm.controls['shortSsn'].errors['pattern']"
                >
                  Only numbers allowed
                </div>
              </div>
            </div>
            <div class="col-md-6">
              <label for="contractAccountNumber" class="form-label required"
                >Contract or Account Number<span class="text-danger"
                  >*</span
                ></label
              >
              <input
                type="text"
                class="form-control fs-6"
                id="contractAccountNumber"
                placeholder="XXX-XXXXXXX-XXX or XXXXXXX"
                formControlName="contractAccountNumber"
                [ngClass]="{
                  'is-invalid':
                    (submitted ||
                      customerRegForm.controls['contractAccountNumber']
                        .touched) &&
                    customerRegForm.controls['contractAccountNumber'].errors
                }"
                required
              />
              <p class="text-danger">
                The Account Number/CCAN is located in the Contract Number with
                the leading zero removed. Please enter the numbers after the
                leading zero.
              </p>
              <div
                *ngIf="
                  (submitted ||
                    customerRegForm.controls['contractAccountNumber']
                      .touched) &&
                  customerRegForm.controls['contractAccountNumber'].errors
                "
                class="invalid-feedback"
              >
                <div
                  *ngIf="
                    customerRegForm.controls['contractAccountNumber'].errors[
                      'required'
                    ]
                  "
                >
                  Contract Number is required
                </div>
                <div
                  *ngIf="
                    customerRegForm.controls['contractAccountNumber'].errors[
                      'maxlength'
                    ]
                  "
                >
                  Max Length for a full contract number is 15 characters
                </div>
              </div>
            </div>
            <div class="col-12">
              <re-captcha
                class="form-control mb-2 fs-6"
                class="mb-2 fs-6"
                (resolved)="getCaptchaValue($event)"
                siteKey="{{ captchaKey }}"
              ></re-captcha>
              <p class="text-danger" *ngIf="!captchaIsValid">
                Please validate you are not a robot before submitting:
              </p>
            </div>
            <div class="col-12">
              <button
                class="btn btn-primary mt-2"
                type="submit"
                (click)="onSubmit($event)"
              >
                Submit
              </button>
              <dfs-spinner
                style="position: relative"
                [radius]="10"
                [lines]="10"
                [width]="5"
                [length]="6"
                [top]="'.9%'"
                [left]="'-25px'"
              ></dfs-spinner>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</main>
