import { AfterViewInit, Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { Dialog } from '@angular/cdk/dialog';
import { CurrentUserService, User } from 'dfs-common-ngx';
import { ResetPasswordDialogComponent } from '../auth/reset-password-dialog.component';
import { UserRole } from '../enums/user-role';

@Component({
  selector: 'fin-reset-password',
  templateUrl: '../marketing/home.component.html',
  styleUrls: ['../marketing/marketing-styles.css'],
})
export class ResetPasswordComponent implements OnInit, AfterViewInit {
  constructor(
    public dialog: Dialog,
    private route: ActivatedRoute,
    private currentUserService: CurrentUserService
  ) {}
  loggedIn = false;
  private token: string | undefined;
  makePaymentLink: String = '/login';
  isDealer: boolean = false;
  isCustomer: boolean = false;

  customerRoute() {}

  dealerRoute() {}

  ngOnInit(): void {
    this.token = this.route.snapshot.queryParams['token'];
    this.currentUserService.profileO.subscribe(
      (user) => (this.loggedIn = !!user)
    );
    var profile = this.currentUserService.profile as User;
    if (profile) {
      this.isCustomer = profile.role.includes(UserRole.Customer);
      this.isDealer = profile.role.includes(UserRole.Dealer);
    }
  }

  ngAfterViewInit(): void {
    this.dialog.open(ResetPasswordDialogComponent, {
      width: '450px',
      data: this.token,
    });
  }
}
