<main>
  <section
    style="
      background-image: url('assets/images/about-hero.jpg');
      background-size: cover;
      background-position: center;
    "
  >
    <div class="container px-4 py-5">
      <div class="row align-items-start g-lg-5 py-5">
        <div class="col-lg-10 text-lg-start text-light py-5"></div>
      </div>
    </div>
  </section>
  <section class="bg-secondary mb-5">
    <div class="container py-5">
      <div class="row px-4 py-4">
        <div class="col-lg-6">
          <h1 class="fs-2">About DFS</h1>
        </div>
        <div class="col-lg-6">
          <p class="fs-5">
            DFS Finance is an industry leading agricultural lender and a
            division of FNBO (First National Bank of Omaha). Our
            relationship-driven approach to business sets us apart and continues
            to be our calling card. We focus on customer-led service and unique
            solutions to support the agricultural community.
          </p>
        </div>
      </div>
    </div>
  </section>
  <div class="container">
    <div class="d-md-flex flex-md-equal w-100 pt-5 my-md-5">
      <div class="overflow-hidden d-flex justify-content-center">
        <img class="h-100" src="assets/images/about-story.jpg" />
      </div>
      <div class="px-3 px-md-5 overflow-hidden">
        <div class="mb-3 p-3">
          <p class="display-6 text-primary mb-4">Our Story</p>
          <p>
            DFS Finance is one of the largest family owned ag lenders and a
            proud part of FNBO. FNBO has deep heartland roots and a history of
            serving the agricultural community for over 165 years. DFS Finance
            is a respected leader in equipment finance, forging long-term
            partnerships with equipment dealers, dealer associations,
            manufacturers, and customers.
          </p>
          <p>
            We look at the American agriculture story with fondness and respect.
            It’s a story we’re proud to play an important part in. New
            challenges present themselves every year that force the ag industry
            to innovate. We do the same, always looking for ways to provide
            better flexibility and solutions that keep growers growing, on the
            cutting-edge, and protected from loss.
          </p>
          <br />
          <p class="display-6 text-primary mb-4">Mission Statement</p>
          <p>
            DFS Finance is a relationship driven provider of retail finance and
            insurance products for the agricultural equipment industry.
          </p>
        </div>
      </div>
    </div>
  </div>
  <div class="px-4 py-4 my-5 text-center">
    <p class="display-5 mb-4">Careers at DFS</p>
    <div class="col-lg-11 mx-auto">
      <p class="fs-5 mb-4">
        We’re always looking for exceptional people who care about people and
        their work.
      </p>
      <div class="d-grid gap-2 d-sm-flex justify-content-sm-center">
        <a
          class="btn btn-outline-primary px-4 gap-3"
          [routerLink]="['/careers']"
          >Careers</a
        >
      </div>
    </div>
  </div>
</main>
