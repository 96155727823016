<main>
  <section
    style="
      background-image: url('assets/images/become-partner-hero.jpg');
      background-size: cover;
      background-position: center;
    "
  >
    <div class="container px-4 py-5">
      <div class="row align-items-start g-lg-5 py-5">
        <div class="col-lg-10 text-lg-start text-light py-5"></div>
      </div>
    </div>
  </section>
  <section class="bg-secondary mb-5">
    <div class="container py-5">
      <div class="row px-4 py-4">
        <div class="col-lg-6">
          <h1 class="fs-2">Become a Dealer Partner</h1>
          <a
            class="btn btn-primary mt-4 mb-4 text-black"
            target="_blank"
            href="assets/forms/DealerSignUpPackage.pdf"
            >Download Sign-up Package</a
          >
        </div>
        <div class="col-lg-6">
          <p class="fs-5">
            DFS Finance is, always has been, and always will be a
            relationship-driven company. We’re a true partner, helping you grow
            your business and providing your customers with the competitive
            financing options and service they deserve.
          </p>
        </div>
      </div>
    </div>
  </section>

  <div class="container px-5" id="featured-3">
    <div class="px-4 mt-5 text-center">
      <p class="display-5 mb-4">What makes DFS Finance different?</p>
      <div class="col-lg-11 mx-auto">
        <p class="fs-5 mb-4">
          We balance automation and online access with personal connection and
          service.
        </p>
      </div>
    </div>
    <div class="row g-5 pt-5 pb-3 row-cols-1 row-cols-md-3">
      <div class="feature col">
        <div class="feature-icon">
          <img
            type="image/svg+xml"
            src="assets/svg/simplicity-icon.svg"
            alt="Your browser does not support SVGs"
          />
        </div>
        <h4 class="my-4">Simplicity</h4>
        <p class="">
          We make it easy to submit applications online, send docs and receive
          auto-approval.
        </p>
      </div>
      <div class="feature col ps-md-5">
        <div class="feature-icon">
          <img
            type="image/svg+xml"
            src="assets/svg/relationships-icon.svg"
            alt="Your browser does not support SVGs"
          />
        </div>
        <h4 class="my-4">Relationships</h4>
        <p class="">
          We build connections through our personal service and authenticity.
        </p>
      </div>
      <div class="feature col ps-md-5">
        <div class="feature-icon">
          <img
            type="image/svg+xml"
            src="assets/svg/innovation-icon.svg"
            alt="Your browser does not support SVGs"
          />
        </div>
        <h4 class="my-4">Innovation</h4>
        <p class="">
          We implement solutions that we know will improve our customers'
          experience.
        </p>
      </div>
      <div class="feature col">
        <div class="feature-icon">
          <img
            type="image/svg+xml"
            src="assets/svg/responsiveness-icon.svg"
            alt="Your browser does not support SVGs"
          />
        </div>
        <h4 class="my-4">Responsiveness</h4>
        <p class="">
          When the financing needs to go through, we take the extra steps to
          make it happen.
        </p>
      </div>
      <div class="feature col ps-md-5">
        <div class="feature-icon">
          <img
            type="image/svg+xml"
            src="assets/svg/flexibility-icon.svg"
            alt="Your browser does not support SVGs"
          />
        </div>
        <h4 class="my-4">Flexibility</h4>
        <p class="">
          We keep our approach to our business as human as possible. It’s not
          just ones and zeros and automation.
        </p>
      </div>
    </div>
  </div>

  <div
    class="container-fluid bg-primary px-5 mb-5"
    style="margin-top: 175px; padding-bottom: 100px"
  >
    <div class="row justify-content-center">
      <div
        class="d-md-flex flex-md-equal w-100 ps-md-3"
        style="margin-top: -100px; max-width: 1400px"
      >
        <div class="bg-secondary pt-3 pb-4 px-3 px-md-5 overflow-hidden">
          <div class="my-3 p-3">
            <h2 class="fs-4 mb-4">Ready to set up your online account?</h2>
            <p class="mb-4">
              Whether you’re already an authorized dealer or still considering
              becoming a partner, you can register and get access to the tools
              of the dashboard today.
            </p>
            <a
              class="btn btn-primary text-black"
              [routerLink]="['/dealerRegistration']"
              >Dealer Account Registration</a
            >
          </div>
        </div>
        <div class="bg-success pt-3 pb-4 px-3 px-md-5 overflow-hidden">
          <div class="my-3 py-3">
            <h2 class="fs-4 mb-4">Interested in becoming a dealer partner?</h2>
            <p class="mb-4">
              To sign up today, fill out the agreement form, and our account
              management team will be in contact with you promptly.<br /><br />
            </p>
            <a
              class="btn btn-primary text-black"
              target="_blank"
              href="assets/forms/DealerSignUpPackage.pdf"
              >Download Sign-up Package</a
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</main>
