<div id="page-content-wrapper">
  <div class="container">
    <h2>
      Credit Application
      <dfs-spinner
        style="position: relative"
        [radius]="10"
        [lines]="10"
        [width]="5"
        [length]="8"
        [top]="'20px'"
        [left]="'30px'"
      ></dfs-spinner>
    </h2>
    <hr />
    <div style="height: 200px" *ngIf="!originalApplication"></div>
    <div class="card">
      <form
        *ngIf="originalApplication"
        class="wizard-container"
        [formGroup]="creditApplicationForm"
        novalidate
        #caFormDirective="ngForm"
      >
        <ca-steps></ca-steps>

        <div class="wizard-page-container">
          <ng-container [ngSwitch]="currentStep.stepIndex">
            <ng-container *ngSwitchCase="1">
              <br />
              <ca-applicant-details
                [step]="currentStep"
                [form]="applicantDetailsForm"
              ></ca-applicant-details>
            </ng-container>
            <ng-container *ngSwitchCase="2">
              <br />
              <ca-financial-info
                [step]="currentStep"
                [form]="businessFinancialInformationForm"
                [mainApplicantDateOfBirth]="mainApplicantDateOfBirth"
                [yearsInBusinessOptions]="yearsInBusinessOptions"
              ></ca-financial-info>
            </ng-container>
            <ng-container *ngSwitchCase="3">
              <br />
              <cfa-equipment
                [step]="currentStep"
                [form]="equipmentForm"
                [equipmentType]="originalApplication.equipmentType"
              ></cfa-equipment>
            </ng-container>
            <ng-container *ngSwitchCase="4">
              <br />
              <cfa-contract-terms
                [step]="currentStep"
                [form]="contractTermsForm"
                [equipmentItems]="equipmentItems"
                [sellerType]="sellerType"
                [customerContractTerms]="originalApplication.contractTerms"
                [customerState]="mainApplicantState"
                [equipmentType]="originalApplication.equipmentType"
                [rates]="rates"
              ></cfa-contract-terms>
            </ng-container>
            <ng-container *ngSwitchCase="5">
              <fin-submit
                [step]="currentStep"
                [form]="submissionDetailsForm"
                [equipmentType]="originalApplication.equipmentType"
                [sellerType]="sellerType"
                (attachFileUpload)="attachFile($event)"
                (creditReportAuthorizedChange)="
                  creditReportAuthorizationChangeInSubmit($event)
                "
              ></fin-submit>
            </ng-container>
          </ng-container>
        </div>

        <div class="wizard-button-row">
          <button
            type="button"
            (click)="onPreviousStep()"
            [disabled]="isFirstStep"
            class="dfs-btn dfs-btn-primary"
          >
            Previous
          </button>
          <button
            type="button"
            (click)="onNextStep($event)"
            [ngClass]="
              nextButtonText === 'Submit'
                ? 'dfs-btn dfs-btn-success'
                : 'dfs-btn dfs-btn-primary'
            "
          >
            {{ nextButtonText }}
          </button>
        </div>
      </form>

      <div *ngIf="isShowInvalidEntries" class="text-danger">
        Some of the entries on the application are invalid and need to be
        corrected. Required fields are marked with an asterisk *
        <br />
      </div>
      <br />
    </div>
  </div>
</div>
