import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { IJobApplication } from '../interfaces/job-application';

@Injectable({
  providedIn: 'root',
})
export class JobApplicationsRepoService {
  private jobApplicationUrl = `${environment.ApiBaseUrl}/job-applications`;

  constructor(private httpClient: HttpClient) {}
  add(jobApplication: IJobApplication): Observable<string> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json; charset=utf-8',
      'Access-Control-Allow-Methods': 'OPTIONS, GET, POST',
    });
    return this.httpClient.post<string>(
      this.jobApplicationUrl,
      jobApplication,
      { headers: headers }
    );
  }
  uploadAttachments(
    applicationId: string,
    attachments: FormData
  ): Observable<void> {
    const headers = new HttpHeaders({
      'Access-Control-Allow-Methods': 'OPTIONS, GET, POST',
    });
    const attachmentsUrl = `${this.jobApplicationUrl}/${applicationId}/attachments`;

    return this.httpClient.post<void>(attachmentsUrl, attachments, {
      headers: headers,
    });
  }
}
