import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Validators } from 'angular-reactive-validation';
import {
  FormSubmitNotificationService,
  ValidatableFormComponent,
} from 'dfs-credit-application-ngx';
import { FormGroup } from '@angular/forms';

@Component({
  selector: 'cfa-equipment-item',
  templateUrl: `./equipment-item.component.html`,
})
export class EquipmentItemComponent
  extends ValidatableFormComponent
  implements OnInit, OnDestroy
{
  @Input() form!: FormGroup;

  get disabledValue(): boolean {
    return true;
  }

  get description(): string {
    return this.form?.get('description')?.value;
  }
  get transactionAmount(): string {
    return this.formatCurrency(this.form?.get('transactionAmount')?.value);
  }
  get salesTax(): string {
    return this.formatCurrency(this.form?.get('salesTax')?.value);
  }
  get amountOwed(): string {
    return this.formatCurrency(this.form?.get('amountOwed')?.value);
  }
  get tradeInFlag(): boolean {
    return this.form?.get('tradeInFlag')?.value;
  }

  constructor(submitNotificationService: FormSubmitNotificationService) {
    super(submitNotificationService);
  }

  override ngOnInit() {
    super.ngOnInit();

    this.addFormValidation();
  }

  formatCurrency(currencyToFormat: number): string {
    const formatter = new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
    });
    return formatter.format(currencyToFormat);
  }

  addFormValidation() {
    this.form
      ?.get('transactionAmount')
      ?.setValidators(Validators.required('Required'));
  }

  override ngOnDestroy(): void {
    super.ngOnDestroy();
  }
}
