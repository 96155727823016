import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { environment } from '../../environments/environment';

@Injectable()
export class FinishAppLinkRepo {
  constructor(
    private readonly http: HttpClient,
  ) {}

    create(applicationId: string) {
      const url =
        environment.ApiBaseUrl + '/applications/' + applicationId + '/finish-app-links';
      return this.http.post(url, {});
    }
}
