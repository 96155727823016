import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Observable } from 'rxjs';

import { ISimpleItem } from '../shared/simpleList';
import { environment } from '../../environments/environment';

@Injectable()
export class SupervisorSalesmenRepository {
  readonly baseURL: string = `${environment.ApiBaseUrl}/supervisor-salesmen/`;
  constructor(private httpClient: HttpClient) {}

  getSalesmenForReassignment(dealerCodeId:number): Observable<ISimpleItem[]> {
    const url = this.baseURL + `reassignment/${dealerCodeId}`;
    return this.httpClient.get<ISimpleItem[]>(url);
  }
}
