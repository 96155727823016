import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class EsignatureEnvelopeStatusService {
  constructor(private readonly http: HttpClient) {}

  getCurrentEnvelopeStatus(
    appId: number | string
  ): Observable<any> {
    const url = `${environment.ApiBaseUrl}/applications/${appId}/esignature-envelope-statuses/latest`;

    return this.http.get(url).pipe((result) => {
      return result;
    });
  }
}
