import { Component, Injectable, Input } from '@angular/core';
import { NgForm } from '@angular/forms';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { Recipient } from 'src/app/shared/models/recipient';
import { RecipientList } from 'src/app/shared/models/recipient-list';
import { RecipientRoleType } from 'src/app/shared/models/recipient-role-type';
import { StatusType } from 'src/app/shared/models/status-type';
import { EsignatureEnvelopeService } from 'src/app/shared/esignatures/esignature-envelope.service';
import { ToastrService } from 'ngx-toastr';
import { CurrentUserService, Dealer } from 'dfs-common-ngx';
import { ReplaySubject, Subject } from 'rxjs';
import { PopupMessageModalService } from 'src/app/shared/esignatures/popup-message-modal.service';

@Injectable()
export class EsignatureModalRecipientService {
  constructor(private readonly bsModalService: BsModalService) {}

  show(appId: number, recipientList: RecipientList) {
    const initialState = { appId, recipientList };
    const modalRef = this.bsModalService.show(ViewEsignatureModalComponent, {
      initialState,
      ignoreBackdropClick: true,
      class: 'modal-lg',
    });
    return modalRef.content?.onClose;
  }
}

@Component({
  templateUrl: 'esignature-modal-recipient.service.html',
  styleUrls: ['../../PortalMain.scss'],
})
export class ViewEsignatureModalComponent {
  @Input() appId!: number;
  @Input() recipientList!: RecipientList;

  dealerRecipient!: Recipient;
  ccRecipient: Recipient = new Recipient();
  signingLocation: string = '';
  updateDealerEmail: boolean = false;
  ccRecipientEnabled: boolean = false;
  nonDealerRecipients!: Recipient[];
  submitButtonText: string = 'Submit';
  isSubmitting: boolean = false;
  ccRecipientIndex!: number;
  buttonClicked!: string;
  currentUserPhone!: string;
  tempRecipientList: string[] = [];

  public readonly onClose: Subject<any | undefined> = new ReplaySubject();

  get recipientPhoneList(): string[] {
    let result: string[] = [];
    this.recipientList.recipients.forEach((element) => {
      result.push(element.phone);
    });
    return result;
  }

  constructor(
    private readonly bsModalRef: BsModalRef,
    private readonly esigEnvelopeSvc: EsignatureEnvelopeService,
    private readonly modalMessageSvc: PopupMessageModalService,
    private readonly toastr: ToastrService,
    private readonly currentUserSvc: CurrentUserService
  ) {}

  ngOnInit(): void {
    this.signingLocation = this.formatSigningLocation(
      String(this.recipientList.signingLocation)
    );
    this.dealerRecipient = this.recipientList.recipients.filter(
      (r: Recipient) => r.role === RecipientRoleType.Dealer
    )[0];

    this.nonDealerRecipients = this.recipientList.recipients.filter(
      (r: Recipient) =>
        r.role !== RecipientRoleType.DfsRepresentative &&
        r.role !== RecipientRoleType.Dealer
    );
    if (this.signingLocation == 'Remote') {
      this.ccRecipient.isCarbonCopyRecipient = true;
      this.ccRecipientIndex =
        this.recipientList.recipients.filter((x) => x.role).length - 1;
      this.recipientList.recipients.splice(
        this.ccRecipientIndex,
        0,
        this.ccRecipient
      );
    }

    // If dealer recipient name is not set, retrieve it from user profile
    const dealer = this.currentUserSvc.profile as Dealer;
    this.dealerRecipient.name = this.dealerRecipient.name || dealer.name;
    this.currentUserPhone = dealer.phone.replace(/[-+()\s]/g, '');
    this.reloadRecipientList();
  }

  formatSigningLocation = (signingLocation: string) => {
    return signingLocation.replace(/([a-z])([A-Z])/g, '$1-$2').trim();
  };

  close() {
    this.bsModalRef.hide();
    this.buttonClicked = 'Close';
    this.onClose.next(this.buttonClicked);
  }

  submit(esigRecipientForm: NgForm, event: MouseEvent) {
    if (esigRecipientForm.invalid) {
      return;
    }

    if (!this.ccRecipientEnabled && this.signingLocation == 'Remote') {
      this.recipientList.recipients.splice(this.ccRecipientIndex, 1);
    }

    this.submitButtonText = 'Submitting...';
    this.isSubmitting = true;
    (event.target as HTMLButtonElement).disabled = true;

    const envelope = {
      Id: 0,
      Status: StatusType.None,
      Recipients: this.recipientList.recipients,
      UpdateDealerEmail: this.updateDealerEmail,
    };

    this.esigEnvelopeSvc.createEnvelope(this.appId, envelope).subscribe({
      next: () => {
        this.isSubmitting = false;
        this.bsModalRef.hide();
        this.toastr.success(
          `E-Signature Envelope Successfully Created`,
          'Success'
        );
        this.buttonClicked = 'Submit';
        this.onClose.next(this.buttonClicked);
      },
      error: () => {
        this.isSubmitting = false;

        this.toastr.error(
          `There was an issue starting the E-Signature process. If the issue persists, contact your Credit Manager for assistance.`,
          'Issue Submitting For E-Signature'
        );
      },
    });
  }
  reloadRecipientList() {
    this.tempRecipientList = this.recipientPhoneList;
  }
  askToUpdatePhone(esigRecipientForm: NgForm, i: number) {
    this.reloadRecipientList();
    if (esigRecipientForm.invalid) {
      return;
    }
    const okButtonCaption: string = 'Yes';
    const cancelButtonCaption: string = 'No';
    const title: string = "Update Customer's Mobile Phone #";
    const message: string =
      "Do you want to update this customer's account with this phone number?";
    const modalRef: BsModalRef = this.modalMessageSvc.show(
      title,
      message,
      okButtonCaption,
      cancelButtonCaption
    );
    modalRef.content.onClose.subscribe({
      next: (response: string) => {
        if (response === okButtonCaption) {
          this.recipientList.recipients[i].updatePhoneInRapport = true;
        }
      },
    });
  }
}
