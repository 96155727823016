import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';

import { NgbDropdownModule, NgbPopover } from '@ng-bootstrap/ng-bootstrap';
import { TableModule } from 'primeng/table';
import { NgSelectModule } from '@ng-select/ng-select';
import { ApplicationGridComponent } from './application-grid/application-grid.component';
import { InterestRateGuideDirective } from './interest-rate-guide.component';
import { CurrencyMaskDirective } from './currency-mask.directive';
import { CurrencyMaskService } from './currency-mask.service';
import { preventPhoneMatchValidator } from './preventPhoneMatchValidator';
import { BasicContractTermsComponent } from './wizard-forms/basic-contract-terms.component';
import { ReactiveFormsModule } from '@angular/forms';
import { DfsFormGroupDirective } from 'dfs-common-ngx';
import { ApplicationTermsService } from './application-terms.service';
import { SubmitComponent } from './wizard-forms/submit/submit.component';
import { CreditReportAuthorizationComponent } from './wizard-forms/submit/credit-report-authorization.component';
import { FileUploadComponent } from './wizard-forms/submit/file-upload.component';
import { SubmitSuccessModalComponent } from './wizard-forms/submit/submit-success-modal.component';
import { CollapseModule } from 'ngx-bootstrap/collapse';
import { RecaptchaModule } from 'ng-recaptcha';
import { SubmitSuccessModalService } from './wizard-forms/submit/submit-success-modal.service';

@NgModule({
  declarations: [
    ApplicationGridComponent,
    InterestRateGuideDirective,
    CurrencyMaskDirective,
    preventPhoneMatchValidator,
    BasicContractTermsComponent,
    SubmitComponent,
    CreditReportAuthorizationComponent,
    FileUploadComponent,
    SubmitSuccessModalComponent,
  ],
  imports: [
    CommonModule,
    RouterModule,
    NgbDropdownModule,
    NgbPopover,
    TableModule,
    NgSelectModule,
    ReactiveFormsModule,
    CollapseModule.forRoot(),
    RecaptchaModule,
  ],
  exports: [
    ApplicationGridComponent,
    InterestRateGuideDirective,
    CurrencyMaskDirective,
    preventPhoneMatchValidator,
    BasicContractTermsComponent,
    SubmitComponent,
    SubmitSuccessModalComponent,
    FileUploadComponent,
    CreditReportAuthorizationComponent,
  ],
  providers: [
    CurrencyMaskService,
    DfsFormGroupDirective,
    ApplicationTermsService,
    SubmitSuccessModalService,
  ],
})
export class DfsSharedModule {}
