import { Directive, Input } from '@angular/core';
import { Recipient } from 'src/app/shared/models/recipient';
import { RecipientList } from 'src/app/shared/models/recipient-list';
import {
  NG_VALIDATORS,
  Validator,
  ValidationErrors,
  FormGroup,
  AbstractControl,
} from '@angular/forms';
import { EsignatureRecipientStatus } from './esignatures/esignature-recipient-status';

@Directive({
  selector: '[prevent-phone-match-validator]',
  providers: [
    {
      provide: NG_VALIDATORS,
      useExisting: preventPhoneMatchValidator,
      multi: true,
    },
  ],
})
export class preventPhoneMatchValidator implements Validator {
  @Input('user-number')
  userNumber: string | undefined;
  @Input('mobile-number')
  mobileNumber: string | undefined;
  @Input('recipient-list')
  recipientList: string[] | undefined;
  validate(_control: AbstractControl<any, any>): ValidationErrors | null {
    const cleanMobileNumber =
      this.mobileNumber && this.mobileNumber!.replaceAll('-', '');
    const cleanUserNumber = this.userNumber!.replaceAll('-', '');

    if (cleanUserNumber === cleanMobileNumber) {
      return { mobileMatchesDealer: true };
    }

    const recipientMatch = this.recipientList!.filter(
      (r) =>
        r && this.mobileNumber && r!.replaceAll('-', '') === cleanMobileNumber
    );
    if (recipientMatch.length > 1) {
      return { duplicateMobileFound: true };
    }

    return null;
  }
}
