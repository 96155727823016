import { NgModule} from '@angular/core';
import { CustomerFinishAppComponent } from './customer-finish-app/customer-finish-app.component';
import { CommonModule } from "@angular/common";
import { HttpClientModule } from "@angular/common/http";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { ReactiveValidationModule } from "angular-reactive-validation";
import { DfsCreditApplicationNgxModule } from "dfs-credit-application-ngx";
import { NgSelectModule } from "@ng-select/ng-select";
import { ToastrModule } from "ngx-toastr";
import { DfsCommonNgxModule } from "dfs-common-ngx";
import { ButtonsModule } from "ngx-bootstrap/buttons";
import { ContractTermsComponent } from "./wizard-forms/contract-terms/contract-terms.component";
import { EquipmentItemComponent } from "./wizard-forms/equipment/equipment-item.component";
import { EquipmentComponent } from "./wizard-forms/equipment/equipment.component";
import { DfsSharedModule } from "../shared/dfs-shared.module";
import { environment } from 'src/environments/environment';

@NgModule({
  declarations: [
    CustomerFinishAppComponent,
    EquipmentComponent,
    EquipmentItemComponent,
    ContractTermsComponent,
  ],
  providers: [],
  imports: [
    CommonModule,
    HttpClientModule,
    ReactiveFormsModule,
    ReactiveValidationModule,
    DfsCreditApplicationNgxModule.forRoot(environment),
    NgSelectModule,
    ToastrModule.forRoot({ timeOut: 15000 }),
    DfsCommonNgxModule.forRoot({ useAuth: false }),
    ButtonsModule,
    FormsModule,
    DfsSharedModule,
  ],
})
export class CustomerFinishModule {}
