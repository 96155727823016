import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Observable, map } from 'rxjs';

import { environment } from '../../environments/environment';
import { DocumentDetails } from './entities/DocumentDetails';
import { CurrentUserService } from 'dfs-common-ngx';
import { HttpHeaderParseSvc } from '../common/services/HttpHeaderParseSvc';
import { Constants } from './constants';

@Injectable()
export class DocumentsRepo {
  constructor(
    private readonly http: HttpClient,
    private readonly currentUserSvc: CurrentUserService,
    private readonly httpHeaderParseSvc: HttpHeaderParseSvc
  ) {}

  get(
    applicationId: number,
    calculateStartDate: boolean
  ): Observable<DocumentDetails> {
    const url = `${this.getUserDocumentUrl()}/${applicationId}/${calculateStartDate}`;

    return this.http.get<DocumentDetails>(url);
  }

  update(document: DocumentDetails): Observable<unknown> {
    const url = this.getUserDocumentUrl();

    return this.http.put(url, document);
  }

  generate(
    document: DocumentDetails
  ): Observable<{ file: Blob; fileName: string }> {
    const url = this.getUserDocumentUrl();
    return this.http
      .post(url, document, { responseType: 'blob', observe: 'response' })
      .pipe(
        map((result) => {
          const retVal = this.buildFile(result);
          if (retVal) return retVal;
          else throw new Error('null response');
        })
      );
  }

  sendAttachmentsEmail(
    rapportAppNumber: number | undefined,
    customerName: string,
    businessType: string,
    documents: any
  ) {
    const custName = this.cleanStringForUrl(customerName);
    const busType = this.cleanStringForUrl(businessType);
    const url = `${this.getUserDocumentUrl()}/sendAttachedPdf/'${custName}'/'${busType}'/${rapportAppNumber}`;
    const uploads = new FormData();
    uploads.append(documents.fileName, documents.file);
    return this.http.post(url, uploads);
  }

  private buildFile(
    httpResult: HttpResponse<Blob>
  ): { file: Blob; fileName: string } | undefined {
    if (httpResult.body) {
      const fileName = this.httpHeaderParseSvc.getFileName(httpResult);
      return {
        file: httpResult.body,
        fileName: fileName,
      };
    } else {
      return undefined;
    }
  }

  private getUserDocumentUrl(): string {
    const userIdentifier = this.currentUserSvc.profile?.systemId;

    return (
      `${environment.ApiBaseUrl}/${Constants.apiUrls.usersUrlSegment}/${userIdentifier}` +
      `${Constants.apiUrls.documentSegment}`
    );
  }
  private cleanStringForUrl(parameter: string) {
    let tempString = parameter;
    tempString = tempString.replace('&', '¥');
    tempString = tempString.replace('/', 'α');
    return tempString;
  }
}
