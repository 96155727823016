import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { JobApplicationComponent } from './job-postings/job-application.component';
import { AboutComponent } from './marketing/about.component';
import { ApplicationFormsComponent } from './marketing/application-forms.component';
import { BecomeADealerComponent } from './marketing/become-a-dealer.component';
import { CareersComponent } from './marketing/careers.component';
import { ContactComponent } from './marketing/contact.component';
import { CustomerApplicationComponent } from './marketing/customer-application.component';
import { FaqsComponent } from './marketing/faqs.component';
import { FinancingComponent } from './marketing/financing-info.component';
import { HomeComponent } from './marketing/home.component';
import { LoginPageComponent } from './marketing/login-page.component';
import { LoanPaymentCalculatorComponent } from './marketing/loan-payment-calculator.component';
import { ResetPasswordComponent } from './auth/reset-password.component';
import { UnimplementedRouteComponent } from './unimplemented-route/unimplemented-route.component';
import { DealerRegistrationComponent } from './marketing/registration/dealer-registration.component';
import { CustomerRegistrationComponent } from './marketing/registration/customer-registration.component';

import { DfsContactComponent } from './contact-us/dfs-contact.component';
import { LeaseMatrixProgramsComponent } from './lease-matrix-programs/lease-matrix-programs.component';
import { ApplicationArchiveComponent } from './application-archive/application-archive.component';
import { DealerStartApplicationComponent } from './dealer-started/dealer-start-app.component';
import { ApplicationRatesResolverService } from './services/application-rates-resolver.service';
import { UserDealerLinkResolverService } from './services/user-dealer-link-resolver.service';
import { CustomerFinishAppComponent } from './customer-finish/customer-finish-app/customer-finish-app.component';
import { DocumentPreviewComponent } from './document-preview/document-preview/document-preview.component';
import { DocumentDetailsResolverService } from './services/document-details-resolver.service';
import { authGuard } from './auth/auth.guard';
import { PayOnlineComponent } from './customer-accounts/pay-online.component';
import { InvoiceDeliveryComponent } from './customer-accounts/invoice-delivery.component';

// NB: New route paths also need entries in interfaces/seo-sitetag.ts for now for the meta tags.
const routes: Routes = [
  //authGuard NOT needed
  { path: '', component: HomeComponent, pathMatch: 'full' },
  { path: 'home', component: HomeComponent },
  { path: 'login', component: LoginPageComponent },
  { path: 'forgotpassword', component: LoginPageComponent },
  { path: 'resetpassword', component: ResetPasswordComponent },
  { path: 'faqs', component: FaqsComponent },
  { path: 'about', component: AboutComponent },
  { path: 'careers', component: CareersComponent },
  { path: 'contact', component: ContactComponent },
  { path: 'dealerRegistration', component: DealerRegistrationComponent },
  { path: 'customerRegistration', component: CustomerRegistrationComponent },
  { path: 'financing/:equipmentType', component: FinancingComponent },
  { path: 'becomeADealer', component: BecomeADealerComponent },
  { path: 'paymentCalculator', component: LoanPaymentCalculatorComponent },
  { path: 'applicationforms', component: ApplicationFormsComponent },
  {
    path: 'jobApplication/:jobId/:jobTitle',
    component: JobApplicationComponent,
  },
  {
    path: 'CompleteApplication',
    component: CustomerFinishAppComponent,
  },

  //authGuard NEEDED
  {
    path: 'dealerContact',
    canActivate: [authGuard],
    component: DfsContactComponent,
  },
  {
    path: 'programs',
    canActivate: [authGuard],
    component: LeaseMatrixProgramsComponent,
  },
  {
    path: 'leaseMatrix',
    canActivate: [authGuard],
    component: LeaseMatrixProgramsComponent,
  },
  {
    path: 'applicationArchive',
    canActivate: [authGuard],
    component: ApplicationArchiveComponent,
  },
  {
    path: 'requestPayoff',
    canActivate: [authGuard],
    loadChildren: () =>
      import('./payoff-quotes/payoff-quotes.module').then(
        (m) => m.PayoffQuotesModule
      ),
  },
  {
    path: 'quotes',
    canActivate: [authGuard],
    loadChildren: () =>
      import('./quoting/quoting.module').then((m) => m.QuotingModule),
  },
  {
    path: 'creditApplication',
    canActivate: [authGuard],
    loadChildren: () =>
      import('./credit-application/credit-applications.module').then(
        (m) => m.CreditApplicationsModule
      ),
  },
  {
    path: 'generate-documents',
    canActivate: [authGuard],
    loadChildren: () =>
      import('./credit-application/credit-applications.module').then(
        (m) => m.CreditApplicationsModule
      ),
  },
  {
    path: 'dealerDashboard',
    canActivate: [authGuard],
    loadChildren: () =>
      import('./dealer-dashboard/dealer-dashboard.module').then(
        (m) => m.DealerDashboardModule
      ),
  },
  {
    path: 'dealerDashboard2',
    canActivate: [authGuard],
    loadChildren: () =>
      import('./dealer-dashboard2/dealer-dashboard2.module').then(
        (m) => m.DealerDashboard2Module
      ),
  },
  {
    path: 'adminPermissions',
    canActivate: [authGuard],
    loadChildren: () =>
      import('./dealer-admin/dealer-admin.module').then(
        (m) => m.DealerAdminModule
      ),
  },
  {
    path: 'application', //CustomerStarted
    component: CustomerApplicationComponent,
  },
  {
    path: 'dealerStartApp/:id',
    canActivate: [authGuard],
    component: DealerStartApplicationComponent,
    resolve: {
      applicationRates: ApplicationRatesResolverService,
      userDealerLink: UserDealerLinkResolverService,
    },
  },
  {
    path: 'documentPreview/:id',
    canActivate: [authGuard],
    component: DocumentPreviewComponent,
    data: { editsAllowed: false },
    resolve: {
      documentDetails: DocumentDetailsResolverService,
    },
  },
  {
    path: 'accountDetail',
    canActivate: [authGuard],
    loadChildren: () =>
      import('./customer-accounts/customer-accounts.module').then(
        (m) => m.CustomerAccountsModule
      ),
  },
  {
    path: 'paymentHistory',
    canActivate: [authGuard],
    loadChildren: () =>
      import('./customer-accounts/customer-accounts.module').then(
        (m) => m.CustomerAccountsModule
      ),
  },
  {
    path: 'invoiceDelivery',
    canActivate: [authGuard],
    component: InvoiceDeliveryComponent,
  },
  {
    path: 'payOnline',
    canActivate: [authGuard],
    component: PayOnlineComponent,
  },
  {
    path: 'customerContracts',
    canActivate: [authGuard],
    loadChildren: () =>
      import('./customer-contracts/customer-contracts.module').then(
        (m) => m.CustomerContractsModule
      ),
  },
  {
    path: 'changePassword',
    canActivate: [authGuard],
    loadChildren: () =>
      import('./utilities/utilities.module').then((m) => m.UtilitiesModule),
  },
  {
    path: 'update-address',
    canActivate: [authGuard],
    loadChildren: () =>
      import('./utilities/utilities.module').then((m) => m.UtilitiesModule),
  },
  {
    path: 'update-insurance',
    canActivate: [authGuard],
    loadChildren: () =>
      import('./utilities/utilities.module').then((m) => m.UtilitiesModule),
  },
  {
    path: 'dfs-admin',
    canActivate: [authGuard],
    loadChildren: () =>
      import('./dfs-admin/dfs-admin.module').then((m) => m.DfsAdminModule),
  },
  { path: '**', component: UnimplementedRouteComponent },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      onSameUrlNavigation: 'reload',
      scrollPositionRestoration: 'enabled',
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
