import { Component } from '@angular/core';

@Component({
  selector: 'fin-about',
  templateUrl: './about.component.html',
  styleUrls: ['./marketing-styles.css']
})
export class AboutComponent {

}
