<div class="d-md-flex w-100 my-md-5 align-items-center bg-secondary">
  <div class="col-md-6 overflow-hidden">
    <img class="w-100" src="assets/images/careers-join.jpg" />
  </div>
  <div class="col-md-6 px-3 px-md-5 overflow-hidden">
    <div class="mb-3 p-3">
      <p class="display-6 mb-4">Join Our Team</p>
      <p class="w-75 mb-4">
        Be a part of our caring, flexible, family-oriented work culture.
      </p>
      <div *ngIf="this.jobPostingsCount == 0">
        We currently have no positions open. Please check back later.
      </div>
      <div *ngIf="this.jobPostingsCount > 0">
        <div class="row" *ngFor="let item of this.jobPostings">
          <div class="w-50" style="font-size: medium; font-weight: bold">
            {{ item.jobTitle }}
          </div>
          <a
            class="btn btn-outline-primary w-25 mb-sm-3 display-6"
            style="font-size: medium; font-weight: bold"
            (click)="this.showJobDescription(item)"
            >Description</a
          >
          <a
            class="btn btn-outline-primary w-25 mb-sm-3 display-6"
            style="font-size: medium; font-weight: bold"
            [routerLink]="['/jobApplication/' + item.id + '/' + item.jobTitle]"
            >Apply</a
          >
        </div>
      </div>
    </div>
  </div>
</div>
