import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { MessageBoxModalService, SpinnerService } from 'dfs-common-ngx';
import { ToastrService } from 'ngx-toastr';
import { concatMap, finalize, of } from 'rxjs';
import { environment } from 'src/environments/environment';
import { IJobApplication } from '../interfaces/job-application';
import { JobApplicationsRepoService } from '../services/job-applications-repo.service';
import { SelfIdentificationModalService } from './self-identification-modal.service';

@Component({
  selector: 'fin-job-application',
  templateUrl: './job-application.component.html',
  styleUrls: ['../marketing/marketing-styles.css'],
})
export class JobApplicationComponent implements OnInit {
  jobId: string = '';
  jobTitle: string = '';
  jobApplication = new IJobApplication();
  jobApplicationForm!: FormGroup;
  jobApplicationAttachments: File[] = [];
  fileAttachments = new FormData();

  submitted = false;
  captchaIsValid = false;
  captchaKey = environment.CaptchaKey; //  '6LdVqG4gAAAAABPTSIocqqnK14DyV72U8ieKjRpN';
  referralOptions = ['College/University', 'AgCareers', 'CareerLink', 'Other'];

  get visaSponsorshipRequired(): boolean {
    return this.jobApplicationForm.get('visaSponsorship')!.value === true;
  }
  get referralReasonOther(): boolean {
    const referralCtrl = this.jobApplicationForm.get('referral');
    return referralCtrl?.value === 'Other';
  }
  constructor(
    private readonly formBuilder: FormBuilder,
    private activeRoute: ActivatedRoute,
    private router: Router,
    private readonly toastr: ToastrService,
    private readonly spinnerService: SpinnerService,
    private readonly messageBoxModalService: MessageBoxModalService,
    private readonly jobApplicationService: JobApplicationsRepoService,
    private readonly selfIdentificationModalService: SelfIdentificationModalService
  ) {
    this.getJobInfoFromRoute();
  }
  ngOnInit(): void {
    this.createForm();
  }

  createForm() {
    this.jobApplicationForm = this.formBuilder.group({
      jobPostingId: [this.jobId],
      firstName: ['', [Validators.required]],
      lastName: ['', [Validators.required]],
      email: ['', [Validators.required, Validators.email]],
      salaryRange: [null, [Validators.required]],
      referral: ['', [Validators.required]],
      heardAboutUs: null,
      workEligible: ['', [Validators.required]],
      visaSponsorship: ['', [Validators.required]],
    });
  }
  getCaptchaValue(captcha: string) {
    this.captchaIsValid = !(captcha === null || captcha === undefined);
  }
  getJobInfoFromRoute() {
    this.jobTitle = this.activeRoute.snapshot.params['jobTitle'];
    this.jobId = this.activeRoute.snapshot.params['jobId'];
  }
  isControlValid(controlName: string): boolean {
    const ctrl = this.jobApplicationForm.controls[controlName];
    const ret = (this.submitted || ctrl.touched || ctrl.dirty) && !ctrl.valid;
    return ret;
  }
  hasCompletedSelfIdentification(): boolean {
    const eeoInfo = this.jobApplication?.selfIdentification;
    return (
      eeoInfo.optOut === true || (eeoInfo.gender !== '' && eeoInfo.race !== '')
    );
  }
  fieldHasError(controlName: string, errorName: string): boolean {
    const ctrl = this.jobApplicationForm.controls[controlName];
    const ret =
      (this.submitted || ctrl.touched || ctrl.dirty) &&
      ctrl.errors &&
      ctrl.errors[errorName];
    return ret;
  }
  getEeoInformation() {
    this.selfIdentificationModalService.show(
      this.jobApplication.selfIdentification
    );
  }
  referralSelected() {
    const referral = 'referral';
    const heardAboutUs = 'heardAboutUs';

    const referralCtrl = this.jobApplicationForm.get(referral);
    const heardAboutUsCtrl = this.jobApplicationForm.get(heardAboutUs);
    if (referralCtrl?.value === 'Other') {
      referralCtrl?.clearValidators();
      heardAboutUsCtrl?.setValidators(Validators.required);
    } else {
      heardAboutUsCtrl?.clearValidators();
      referralCtrl?.setValidators(Validators.required);
    }
    referralCtrl?.updateValueAndValidity;
    heardAboutUsCtrl?.updateValueAndValidity;
  }
  submit(event: MouseEvent) {
    if (!this.captchaIsValid) {
      return;
    }
    this.submitted = true;
    if (this.jobApplicationForm.get('heardAboutUs')?.value) {
      this.jobApplication.referral =
        this.jobApplicationForm.get('heardAboutUs')?.value;
    }
    if (this.jobApplicationForm.invalid) {
      return;
    }
    const newApplication = {
      ...this.jobApplication,
      ...this.jobApplicationForm.value,
    };

    (event.target as HTMLButtonElement).disabled = true;
    this.spinnerService.show();
    this.jobApplicationService
      .add(newApplication)
      .pipe(
        concatMap((appId) => {
          if (this.fileAttachments.entries().next().done) {
            return of({});
          }
          return this.jobApplicationService.uploadAttachments(
            appId,
            this.fileAttachments
          );
        }),
        finalize(() => {
          (event.target as HTMLButtonElement).disabled = false;
          this.spinnerService.hide();
          this.router.navigate(['/careers']);
        })
      )
      .subscribe({
        next: () => {
          this.toastr.success(
            `We have received your resume.
                      Thank you for your interest in DFS Finance and our open position for which you have applied.
                      If you are selected for an interview, you can expect to be contacted by our Human Resource department shortly.
                      We do appreciate the time that you have invested in this process.
                      `,
            'Job Application Submitted',
            { enableHtml: true, positionClass: 'toast-top-full-width' }
          );
        },
        error: (err) => {
          console.error(err);
          this.toastr.error(
            `<a href="mailto:OnlineSupport@dfsfin.com">
                      There was an issue submitting the application.
                      If the issue persists, click here to contact Online Support.
                    </a>`,
            'Error',
            { enableHtml: true }
          );
        },
      });
  }
  getApplicationDataFromForm() {
    const appForm = this.jobApplicationForm.controls;
    const referral = appForm['referral'].value;
    this.jobApplication.referral =
      referral !== 'Other' ? referral : appForm['visaSponsorship'].value;
  }

  attachFile(identifier: string, event: any) {
    const fileData: File = event.target.files[0];
    const fileUpload = { fileType: identifier, file: fileData };
    this.attachFileRaised(fileUpload);
  }
  attachFileRaised(fileData: any) {
    const fileSizeLimit = 1024 * 1000 * 8;
    if (fileData.file.size > fileSizeLimit) {
      const modalTitle = 'Attachment File Size';
      const modalMessage =
        'Attachment is larger than 8MB.  It will not be uploaded.';
      this.messageBoxModalService.show(modalMessage, modalTitle);
      return;
    } else {
      if (this.fileAttachments.has(fileData.fileType)) {
        this.fileAttachments.delete(fileData.fileType);
      }
      this.fileAttachments.append(fileData.fileType, fileData.file);
    }
  }
  isResumeUploaded(): boolean {
    return this.fileAttachments.has('Resume');
  }
}
