import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { environment } from '../../environments/environment';
import { Code } from './entities/Code';
import { DealerCodeDisplay } from '../interfaces/dealer-code-display';

@Injectable()
export class DealerCodesRepo {
  constructor(private http: HttpClient) {}

  getDealerCodes() {
    const dealerCodesSegment = '/dealer-codes';
    const url = environment.ApiBaseUrl + dealerCodesSegment;
    return this.http.get<Code[]>(url);
  }

  getDealerCodesForAdminUser(userId: number) {
    const dealerCodesSegment = `/dealer-codes/userId/${userId}`;
    const url = environment.ApiBaseUrl + dealerCodesSegment;
    return this.http.get<Code>(url);
  }
  getByDealerType(dealerTypeId: number) {
    const dealerCodesSegment = `/dealer-codes/dealerTypeId/${dealerTypeId}`;
    const url = environment.ApiBaseUrl + dealerCodesSegment;
    return this.http.get<DealerCodeDisplay[]>(url);
  }
  getDealerCodeDefault(dealerTypeId: number) {
    const dealerCodesSegment = `/dealer-codes/default/dealerTypeId/${dealerTypeId}`;
    const url = environment.ApiBaseUrl + dealerCodesSegment;
    return this.http.get<DealerCodeDisplay>(url);
  }
}
