import { Dialog } from '@angular/cdk/dialog';
import { Injectable } from '@angular/core';
import { BsModalService } from 'ngx-bootstrap/modal';
import { EsignatureStatusComponent } from './esignature-status.component';

@Injectable({
  providedIn: 'root',
})
export class ViewEsignatureEnvelopeStatusService {
  modalRef: any;
  constructor(
    private dialog: Dialog,
    private readonly modalService: BsModalService
  ) {}

  display(esignatureEnvelope: any[]) {
    return this.dialog.open(EsignatureStatusComponent, {
      disableClose: true,
      data: esignatureEnvelope,
    }).closed;
  }
}
