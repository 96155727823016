import { Component, OnInit } from '@angular/core';
import {
  FormGroup,
  UntypedFormBuilder,
  Validators,
  FormControl,
} from '@angular/forms';
import Validation from './password-validator';
import { SpinnerService } from 'dfs-common-ngx';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { StatesService } from '../../services/states.service';
import { IState } from '../../interfaces/state';
import { environment } from '../../../environments/environment';
import { UserRegistrationRepoService } from '../../services/user-registration.service';
import { CustomerRegistration } from '../../interfaces/customer-registration';

@Component({
  selector: 'customer-registration',
  templateUrl: './customer-registration.component.html',
  styleUrls: ['./../marketing-styles.css', '../../PortalMain.scss'],
})
export class CustomerRegistrationComponent implements OnInit {
  customerRegForm: FormGroup = new FormGroup({
    userName: new FormControl(''),
    password: new FormControl(''),
    email: new FormControl(''),
    shortSsn: new FormControl(''),
    contractAccountNumber: new FormControl(''),
  });

  customerRegistration: CustomerRegistration = new CustomerRegistration();
  submitted = false;
  errorMessage: string = '';
  statesList: IState[] = [];
  showCaptchaError: boolean = true;
  captchaIsValid = false;
  captchaKey = environment.CaptchaKey;

  constructor(
    private formBuilder: UntypedFormBuilder,
    private statesService: StatesService,
    private readonly spinnerService: SpinnerService,
    private readonly toastr: ToastrService,
    private router: Router,
    private readonly userRegistrationSvc: UserRegistrationRepoService
  ) {}

  ngOnInit(): void {
    this.customerRegForm = this.formBuilder.group(
      {
        userName: ['', [Validators.required]],
        password: [
          '',
          [
            Validators.required,
            Validators.pattern(
              /(?=^.{8,20}$)((?=.*\d)|(?=.*\W+))(?![.\n])(?=.*[A-Z])(?=.*[a-z]).*$/
            ),
          ],
        ],
        confirmPassword: ['', [Validators.required]],
        email: ['', [Validators.required, Validators.email]],
        shortSsn: [
          '',
          [
            Validators.required,
            Validators.maxLength(4),
            Validators.minLength(4),
            Validators.pattern('^[0-9]*$'),
          ],
        ],
        contractAccountNumber: [
          '',
          [Validators.required, Validators.maxLength(15)],
        ],
      },
      { validators: [Validation.match('password', 'confirmPassword')] }
    );

    this.statesService.getStatesList().subscribe({
      next: (states) => {
        this.statesList = states;
      },
      error: (err) => (this.errorMessage = err),
    });
  }
  getCaptchaValue(captcha: string) {
    this.captchaIsValid = !(captcha === null || captcha === undefined);
  }

  onSubmit(event: MouseEvent): void {
    if (!this.captchaIsValid) {
      return;
    }
    this.submitted = true;

    if (this.customerRegForm.invalid) {
      return;
    }

    (event.target as HTMLButtonElement).disabled = true;
    this.spinnerService.show();

    const newCustomerUserRegistration = {
      ...this.customerRegistration,
      ...this.customerRegForm.value,
    };

    this.userRegistrationSvc
      .registerCustomer(newCustomerUserRegistration)
      .subscribe({
        next: () => {
          (event.target as HTMLButtonElement).disabled = false;
          this.spinnerService.hide();
          this.toastr.success(
            `Your Customer Registration has been created.`,
            'Registration Successfull',
            { enableHtml: true, positionClass: 'toast-top-full-width' }
          );
          this.router.navigate(['/login']);
        },
        error: (err) => {
          (event.target as HTMLButtonElement).disabled = false;
          this.spinnerService.hide();
          this.toastr.error(
            'There was an error during registration: ' + err.error.message,
            'Error',
            { enableHtml: true }
          );
        },
      });
  }
}
