import { Component, Injectable, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { IEeoDisclosure } from '../interfaces/eeo-disclosure';

@Injectable({
  providedIn: 'root',
})
export class SelfIdentificationModalService {
  private modalRef: BsModalRef = new BsModalRef();

  constructor(private readonly bsModalService: BsModalService) {}
  show(selfIdentification: IEeoDisclosure) {
    this.bsModalService.show(SelfIdentificationModalComponent, {
      initialState: {
        selfIdentification: selfIdentification,
      },
      ignoreBackdropClick: true,
      class: 'modal-xl',
    });
  }
}

@Component({
  styleUrls: ['../marketing/marketing-styles.css'],
  templateUrl: './self-identification.html',
})
export class SelfIdentificationModalComponent implements OnInit {
  @Input() selfIdentification!: IEeoDisclosure;
  selfIdentificationForm!: FormGroup;
  submitted: boolean = false;
  modalRef: BsModalRef;

  get selfIdentificationFormData() {
    return this.selfIdentificationForm;
  }
  constructor(
    bsModalRef: BsModalRef,
    private readonly formBuilder: FormBuilder
  ) {
    this.modalRef = bsModalRef;
  }

  ngOnInit() {
    this.selfIdentificationForm = this.formBuilder.group({
      gender: ['', [Validators.required]],
      race: ['', [Validators.required]],
      optOut: false,
    });
    const optOutCtrl = this.selfIdentificationForm.get('optOut');
    optOutCtrl?.valueChanges.subscribe((value) => this.setValidation(value));
  }

  setValidation(checkedValue: string) {
    const whatsMyValue = checkedValue;
    if (this.selfIdentificationForm.get('optOut')?.value == true) {
      this.selfIdentificationForm
        .get('race')
        ?.removeValidators([Validators.required]);
      this.selfIdentificationForm
        .get('gender')
        ?.removeValidators([Validators.required]);
    }
    if (this.selfIdentificationForm.get('optOut')?.value == false) {
      this.selfIdentificationForm
        .get('race')
        ?.setValidators([Validators.required]);
      this.selfIdentificationForm
        .get('gender')
        ?.setValidators([Validators.required]);
    }
    this.selfIdentificationForm.get('gender')?.updateValueAndValidity();
    this.selfIdentificationForm.get('race')?.updateValueAndValidity();
  }
  isControlValid(controlName: string): boolean {
    const controlToCheck = this.selfIdentificationForm.get(controlName);
    return (
      (this.submitted || controlToCheck?.touched) &&
      controlToCheck !== null &&
      controlToCheck?.errors?.['required']
    );
  }

  close() {
    this.submitted = true;
    if (this.selfIdentificationForm.invalid) {
      return;
    }
    this.selfIdentification.gender =
      this.selfIdentificationForm.controls['gender'].value;
    this.selfIdentification.race =
      this.selfIdentificationForm.controls['race'].value;
    this.selfIdentification.optOut =
      this.selfIdentificationForm.controls['optOut'].value;
    const myself = {
      ...this.selfIdentificationForm,
      ...this.selfIdentification,
    };
    this.modalRef.hide();
  }
}
