<div id="page-content-wrapper">
  <h3 class="m-3">Online Payment</h3>
  <div class="card-body row">
    <div>
      <p class="px-3">
        Selecting the 'Make Payment' button below will redirect you to our
        online payment partner to complete your transaction.
      </p>
      <div>
        <button
          type="button"
          class="dfs-btn dfs-btn-primary px-3"
          aria-label="Continue"
          [disabled]="waitingForUrl"
          (click)="redirectToPaymentPortal()"
        >
          Make Payment
        </button>
      </div>
    </div>
  </div>
</div>
