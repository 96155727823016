import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { CustomerFinishedApplication } from './customer-finish-application.model';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({ providedIn: 'root' })
export class CustomerFinishedAppRepo {
  private readonly baseUrl = `${environment.ApiBaseUrl}/customer-finished-applications`;

  constructor(private httpClient: HttpClient) {}

  getOne(
    applicationId: string,
    authToken: string
  ): Observable<CustomerFinishedApplication> {
    let url = `${this.baseUrl}/${applicationId}`;

    var headers = this.buildHeaders(authToken);

    return this.httpClient.get<CustomerFinishedApplication>(url, { headers });
  }

  update(
    application: CustomerFinishedApplication,
    authToken: string
  ): Observable<CustomerFinishedApplication> {
    const headers = this.buildHeaders(authToken);

    return this.httpClient.put<CustomerFinishedApplication>(
      this.baseUrl,
      application,
      { headers }
    );
  }

  private buildHeaders(authToken: string) {
    return new HttpHeaders({
      Authorization: `Bearer ${authToken}`,
    });
  }
}
