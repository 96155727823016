import { Component, Injectable, Input, OnInit } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { DealerStartedApplicationsRepository } from './dealer-started-applications.repository';
import { of, Subject } from 'rxjs';
import { concatMap } from 'rxjs/operators';
import { DealerStartApplication } from './dealer-start-application.model';
import { ToastrService } from 'ngx-toastr';
import { DealerStartedApplicationAttachmentRepository } from './dealer-started-application-attachments.repository';

@Injectable({ providedIn: 'root' })
export class DealerStartSubmitConfirmationModalService {
  constructor(private readonly bsModalService: BsModalService) {}

  show(
    creditApp: DealerStartApplication,
    fileAttachments: FormData
  ): BsModalRef {
    const initialState = { creditApp, fileAttachments };
    return this.bsModalService.show(
      DealerStartSubmitConfirmationModalServiceModalComponent,
      {
        initialState,
        ignoreBackdropClick: true,
      }
    );
  }
}

@Component({
  styleUrls: [`../PortalMain.scss`],
  template: `
    <div class="modal-header">
      <h3 class="modal-title">Email Application to Customers</h3>
    </div>
    <div class="modal-body">
      <div>
        <h4>
          The application will be emailed to: {{ customerName }} at:
          {{ customerEmail }}
        </h4>
      </div>
    </div>
    <div class="modal-footer">
      <button
        type="button"
        class="dfs-btn dfs-btn-primary"
        (click)="onSubmit($event)"
      >
        Send
      </button>
      <button type="button" class="dfs-btn dfs-btn-danger" (click)="close()">
        Close
      </button>
    </div>
  `,
})
export class DealerStartSubmitConfirmationModalServiceModalComponent
  implements OnInit
{
  @Input() creditApp!: DealerStartApplication;
  @Input() fileAttachments!: FormData;
  customerEmail!: string;
  customerName!: string;

  public onClose!: Subject<boolean>;

  constructor(
    private readonly bsModalRef: BsModalRef,
    private readonly dealerStartedAppsRepo: DealerStartedApplicationsRepository,
    private readonly toastr: ToastrService,
    private readonly dealerAttachmentRepo: DealerStartedApplicationAttachmentRepository
  ) {}

  ngOnInit() {
    const basicInfo = this.creditApp.basicInformation;
    this.customerEmail = basicInfo.customerEmail;
    this.customerName = basicInfo.customerName;
    this.onClose = new Subject();
  }

  onSubmit(event: MouseEvent) {
    (event.target as HTMLButtonElement).disabled = true;
    this.dealerStartedAppsRepo
      .create(this.creditApp)
      .pipe(
        concatMap((appId) => {
          if (this.fileAttachments.entries().next().done) {
            return of(appId);
          }
          return this.dealerAttachmentRepo.submit(appId, this.fileAttachments);
        })
      )
      .subscribe({
        next: () => {
          this.onClose.next(true);
        },
        error: (err) => {
          console.error(err);
          this.toastr.error(
            `<a href="mailto:OnlineSupport@dfsfin.com">
          There was an issue submitting the application.
          If the issue persists, click here to contact Online Support.
         </a>`,
            'Error',
            { enableHtml: true }
          );
        },
      });
    this.bsModalRef.hide();
  }
  close() {
    this.onClose.next(false);
    this.bsModalRef.hide();
  }
}
