import { Component, HostListener, Input, OnInit } from '@angular/core';
import { FormArray, FormGroup, UntypedFormBuilder } from '@angular/forms';
import { of } from 'rxjs';
import { concatMap } from 'rxjs/operators';
import { ActivatedRoute, Router } from '@angular/router';
import {
  CurrentUserService,
  Dealer,
  MessageBoxModalService,
} from 'dfs-common-ngx';
import {
  EquipmentType,
  SellerType,
  StepModel,
  StepsService,
  FormSubmitNotificationService,
} from 'dfs-credit-application-ngx';
import { ToastrService } from 'ngx-toastr';
import { DealerStartApplication } from './dealer-start-application.model';
import { DealerStartedApplicationsRepository } from './dealer-started-applications.repository';
import { DealerStartedApplicationAttachmentRepository } from './dealer-started-application-attachments.repository';
import { ViewCreditAttachmentsModalService } from './wizard-forms/view-credit-attachments-modal.service';
import { CreditApplicationAttachmentItem } from './wizard-forms/credit-attachment.model';
import { DealerStartSubmitConfirmationModalService } from './dealer-start-submit-confirmation-modal.service';
import { UserDealerLink } from '../dfs-fin-api/entities/user-dealer-link.model';
import { ApplicationRate } from '../interfaces/ApplicationRate';
import { RatesDisplayOption } from '../interfaces/rates-display-option';
import { DealerContractTermsRepo } from '../dfs-fin-api/DealerContractTermsRepo';

@Component({
  selector: 'dca-dealer-start-app',
  templateUrl: 'dealer-start-app.component.html',
  styleUrls: [`../PortalMain.scss`],
})
export class DealerStartApplicationComponent implements OnInit {
  rates!: ApplicationRate[];
  applicationid!: number;
  locations!: Array<UserDealerLink>;
  bonusRateTypes: RatesDisplayOption[] = [];
  dealerUser!: Dealer;

  applicationOwner: boolean | undefined;

  creditApplicationForm!: FormGroup;
  dealerStartApp: DealerStartApplication | undefined;
  dealerStartAppAttachments!: CreditApplicationAttachmentItem[];
  invalidSubmitAttempted = false;
  equipmentType: EquipmentType | undefined;
  sellerTypeEnum = SellerType;
  creditAttachmentRepo: any;
  isSubmissionSuccessful = false;
  isExistingSavedApp = false;
  fileAttachments = new FormData();

  @HostListener('window:beforeunload', ['$event'])
  checkForChanges($event: BeforeUnloadEvent) {
    if (this.creditApplicationForm.dirty && !this.isSubmissionSuccessful) {
      $event.returnValue =
        'You are about to leave the credit application. The application will not be saved. Are you sure you wish to continue?';
    }
  }

  get currentStep(): StepModel {
    return this.stepsService.currentStep;
  }
  get steps(): StepModel[] {
    return this.stepsService.steps;
  }
  get nextButtonText() {
    return !this.stepsService.isLastStep
      ? 'Next'
      : this.isExistingSavedApp
      ? 'Save'
      : 'E-mail app';
  }
  get isFirstStep() {
    return this.stepsService.isFirstStep;
  }
  get isShowInvalidEntries(): boolean {
    return (
      this.invalidSubmitAttempted &&
      (!this.currentStep.isComplete ||
        (this.stepsService.isLastStep && !this.creditApplicationForm.valid))
    );
  }
  get basicInformationForm() {
    return this.creditApplicationForm.get('basicInformation');
  }
  get equipmentForm() {
    return this.creditApplicationForm.get('equipment');
  }
  get equipmentItems() {
    return this.equipmentForm?.get('equipments')?.value;
  }
  get contractTermsForm() {
    return this.creditApplicationForm.get('contractTerms');
  }
  get submissionDetails() {
    return this.creditApplicationForm.get('submissionDetails');
  }
  get dealerLocation() {
    return this.basicInformationForm?.get('dealerLocation')?.value;
  }

  constructor(
    private readonly stepsService: StepsService,
    private readonly submitNotificationService: FormSubmitNotificationService,
    private readonly messageBoxService: MessageBoxModalService,
    private readonly currentUserSvc: CurrentUserService,
    private readonly viewAttachmentsModalService: ViewCreditAttachmentsModalService,
    private readonly dealerStartedAppsRepo: DealerStartedApplicationsRepository,
    private readonly dealerAttachmentRepo: DealerStartedApplicationAttachmentRepository,
    private readonly submitModalSvc: DealerStartSubmitConfirmationModalService,
    private dealerContractTermsRepo: DealerContractTermsRepo,
    private readonly toastr: ToastrService,
    private readonly fb: UntypedFormBuilder,
    private readonly route: ActivatedRoute,
    private router: Router
  ) {
    this.stepsService.steps = [
      {
        stepIndex: 1,
        title: 'Basic Information',
        isComplete: false,
      },
      {
        stepIndex: 2,
        title: 'Equipment',
        isComplete: false,
      },
      {
        stepIndex: 3,
        title: 'Contract Terms',
        isComplete: false,
      },
      {
        stepIndex: 4,
        title: 'Submit',
        isComplete: false,
      },
    ];

    this.applicationid = Number(this.route.snapshot.params['id']);
  }

  ngOnInit() {
    this.dealerUser = this.currentUserSvc.profile as Dealer;

    this.loadRateBonusTypes();

    this.route.data.subscribe((data: any) => {
      this.rates = data.applicationRates;
      this.locations = data.userDealerLink;
    });

    this.creditApplicationForm = this.fb.group({
      basicInformation: this.fb.group({}),
      equipment: this.fb.group({
        equipments: this.fb.array([]),
        equipmentCounty: this.fb.control(null),
        equipmentState: this.fb.control(null),
        customFarmer: this.fb.control(null),
        equipmentLocationLegalDescription: this.fb.control(null),
        damageInsurance: this.fb.control(null),
        purchaseRefinanceOption: this.fb.control(null),
      }),
      contractTerms: this.fb.group({
        paymentFrequency: this.fb.control(null),
        contractTypeId: this.fb.control(null),
        bonusType: this.fb.control(null),
        rentalCredit: this.fb.control(0),
        cashDown: this.fb.control(null),
        dealerDocFee: this.fb.control(0),
        filingFee: this.fb.control(0),
        contractTerm: this.fb.control(null),
        interestRate: this.fb.control(null),
        residualPercentage: this.fb.control(null),
        isCustomInterestRate: this.fb.control(false),
        customRateReason: this.fb.control(null),
        amountToFinance: this.fb.control(null),
        programTypeId: this.fb.control(null),
      }),
      submissionDetails: this.fb.group({
        comments: this.fb.control(null),
        dealerCreditReportAuthorization: this.fb.control(
          this.dealerUser.dealerUserId
        ),
      }),
      userId: this.fb.control(this.dealerUser?.systemId),
      phone: this.fb.control(this.dealerUser?.phone),
      contractTermsId: this.fb.control(this.rates[0].contractTermsId),
      applicationStartedTypeCode: this.fb.control(
        this.dealerStartApp?.applicationStartedTypeCode
      ),
    }) as FormGroup;

    this.dealerStartAppAttachments = [];
    if (this.applicationid && this.applicationid != 0) {
      this.dealerStartedAppsRepo
        .get(this.applicationid, this.dealerUser?.systemId || '')
        .subscribe((dsaData: DealerStartApplication) => {
          this.isExistingSavedApp = true;
          this.dealerStartApp = dsaData;
          this.populateDealerStartApp();
          this.dealerAttachmentRepo
            .getAll(this.applicationid)
            .subscribe((att) => {
              this.dealerStartAppAttachments = att;
            });
        });
    }

    if (this.dealerUser.productLine === 'Irrigation') {
      this.equipmentType = EquipmentType.irrigation;
    } else {
      this.equipmentType = EquipmentType.farm;
    }
  }

  loadRateBonusTypes() {
    this.dealerContractTermsRepo
      .getBonusRateTypes(
        this.dealerUser?.dealerCode,
        this.dealerUser?.dealerTypeId
      )
      .subscribe((types) => {
        this.bonusRateTypes = types;
      });
  }

  private populateDealerStartApp(): void {
    this.creditApplicationForm.patchValue({
      basicInformation: this.dealerStartApp?.basicInformation,
      equipment: this.dealerStartApp?.equipment,
      contractTerms: this.dealerStartApp?.contractTerms,
      submissionDetails: this.dealerStartApp?.submissionDetails,
      applicationStartedTypeCode:
        this.dealerStartApp?.applicationStartedTypeCode,
    });

    const dealerUserId = this.dealerStartApp?.dealerUserId;
    this.applicationOwner =
      dealerUserId === null ||
      dealerUserId === 0 ||
      this.dealerUser.dealerUserId === dealerUserId;

    const contractTermsForm = this.creditApplicationForm.get('contractTerms');
    contractTermsForm
      ?.get('cashDown')
      ?.setValue(this.dealerStartApp?.contractTerms.cashDown);

    const equipArray = this.fb.array([]);

    this.dealerStartApp?.equipment.equipments.forEach((eq) => {
      const newEquip = this.fb.group({
        year: eq.year,
        condition: eq.condition,
        makeCode: eq.makeCode,
        model: eq.model,
        assetCode: eq.assetCode,
        description: eq.description,
        serialNumber: eq.serialNumber,
        transactionAmount: eq.transactionAmount,
        hoursUsed: eq.hoursUsed,
        dealerCost: eq.dealerCost,
        salesTax: eq.salesTax,
        tradeInFlag: eq.tradeInFlag,
        amountOwed: eq.amountOwed,
        toWhom: eq.toWhom,
        isOwedToDfs: eq.isOwedToDfs,
        dfsContractNumbers: eq.dfsContractNumbers,
      });
      equipArray.push(newEquip);
    });
    const equipmentGroup = this.creditApplicationForm.get(
      'equipment'
    ) as FormGroup;
    equipmentGroup.setControl('equipments', equipArray);
    equipmentGroup.addControl(
      'purchaseRefinanceOption',
      this.fb.control(this.dealerStartApp?.equipment.purchaseRefinanceOption)
    );
  }

  onNextStep() {
    this.equipmentForm?.updateValueAndValidity();

    if (this.currentStep && !this.currentStep.isComplete) {
      this.submitNotificationService.notify();
      this.invalidSubmitAttempted = true;
      this.markCurrentFormAsTouched(this.currentStep.title);
      return;
    }

    this.invalidSubmitAttempted = false;
    if (!this.stepsService.isLastStep) {
      this.stepsService.moveToNextStep();
    } else {
      this.onSubmit();
    }
  }

  onPreviousStep() {
    if (!this.stepsService.isFirstStep) {
      this.stepsService.moveToPreviousStep();
    }
  }

  markCurrentFormAsTouched(formName: string) {
    switch (formName) {
      case 'Basic Information':
        this.basicInformationForm?.markAllAsTouched();
        break;
      case 'Equipment':
        this.equipmentForm?.markAllAsTouched();
        break;
      case 'Contract Terms':
        this.contractTermsForm?.markAllAsTouched();
        break;
      case 'Submit':
        this.submissionDetails?.markAllAsTouched();
        break;
      default:
        throw new Error('Invalid step name');
    }
  }
  creditReportAuthorizationChangeInSubmit(value: any) {
    let submissionDetail = this.submissionDetails?.get(
      'dealerCreditReportAuthorization'
    );

    if (value === true) {
      submissionDetail?.setValue(this.dealerUser.dealerUserId.toString());
    } else {
      submissionDetail?.setValue(null);
    }
  }
  attachFile(fileData: any) {
    const fileSizeLimit = 1024 * 1000 * 8;
    if (fileData.file.size > fileSizeLimit) {
      const modalTitle = 'Attachment File Size';
      const modalMessage =
        'Attachment is larger than 8MB.  It will not be uploaded.';
      this.messageBoxService.show(modalMessage, modalTitle);
      return;
    } else {
      if (this.fileAttachments.has(fileData.fileType)) {
        this.fileAttachments.delete(fileData.fileType);
      }
      this.fileAttachments.append(fileData.fileType, fileData.file);
      this.creditApplicationForm.markAsDirty();
    }
  }
  viewAttachments() {
    this.viewAttachmentsModalService.show(
      this.applicationid,
      this.dealerStartAppAttachments
    );
  }

  onSubmit(): void {
    if (this.creditApplicationForm.valid) {
      this.invalidSubmitAttempted = false;
      if (this.isExistingSavedApp) {
        if (this.creditApplicationForm.dirty) {
          this.updateApp();
        } else {
          this.creditSummaryReRoute();
        }
      } else {
        this.saveApp();
      }
    } else {
      this.invalidSubmitAttempted = true;
    }
  }

  updateApp() {
    this.dealerStartedAppsRepo
      .get(this.applicationid, this.currentUserSvc.profile?.systemId || '')
      .subscribe((dsaData: DealerStartApplication) => {
        const unsubmittedStatusId = 0;
        if (dsaData.applicationStatusId !== unsubmittedStatusId) {
          // check current status somehow (on submit check status and alert if already submitted)
          const messageTitle = 'Save Not Allowed';
          const messageBody =
            'The application has been submitted by the customer, your changes will not be saved.  Please contact your credit manager.';
          this.messageBoxService.show(messageBody, messageTitle);
          return;
        }

        const creditApp = {
          ...this.creditApplicationForm.value,
        } as DealerStartApplication;

        creditApp.dealerUserId = dsaData.dealerUserId;

        this.dealerStartedAppsRepo
          .update(this.applicationid, creditApp)
          .pipe(
            concatMap(() => {
              if (this.fileAttachments.entries().next().done) {
                return of(this.applicationid);
              }
              return this.dealerAttachmentRepo.submit(
                this.applicationid,
                this.fileAttachments
              );
            })
          )
          .subscribe({
            next: () => {
              this.creditSummaryReRoute();
            },
            error: (error) => {
              // reroute or let them try again on fail?
              this.toastr.error(`There was an error saving the app`, 'Error');
            },
          });
      });
  }

  saveApp(): void {
    const creditApp = {
      ...this.creditApplicationForm.value,
    } as DealerStartApplication;

    const modalRef = this.submitModalSvc.show(creditApp, this.fileAttachments);
    modalRef.content.onClose.subscribe((closedResult: boolean) => {
      if (closedResult === true) {
        this.creditSummaryReRoute();
      }
    });
  }

  creditSummaryReRoute() {
    this.isSubmissionSuccessful = true;
    this.router.navigate(['creditApplication']);
  }

  getAllFormErrors(form: FormGroup | FormArray): { [key: string]: any } | null {
    let hasError = false;
    const result = Object.keys(form.controls).reduce((acc, key) => {
      const control = form.get(key);
      const errors =
        control instanceof FormGroup || control instanceof FormArray
          ? this.getAllFormErrors(control)
          : control?.errors;
      if (errors) {
        acc[key] = errors;
        hasError = true;
      }
      return acc;
    }, {} as { [key: string]: any });
    return hasError ? result : null;
  }
}
